/* assets/src/scripts/fortuna/accordeon.js */
(function(modulr, $) {
    "use strict";

    modulr.define("accordeon", function(require, exports, module) {

        var SELECTOR_ACCORDEON = ".accordeon";
        var SELECTOR_ACCORDEON_ITEM = ".accordeon-item";
        var SELECTOR_ACCORDEON_ITEM_HEADER = ".accordeon-item .header";
        
        var CLASS_EXPANDED = "expanded";
        
        function constructor(wrapper) {
            return new Accordeon(wrapper);
        };
        
        function builder() {
            $(SELECTOR_ACCORDEON).forEach(function(item) {
                constructor(item);
            });
        };
        
        function Accordeon(wrapper) {
            this._$wrapper = $(wrapper);
            this._initializeEvents();
        };
        
        Accordeon.prototype._initializeEvents = function() {
            var that = this;
            this._$wrapper.delegate("click", SELECTOR_ACCORDEON_ITEM_HEADER, function() {
                that.toggle(this);
            });
        };
        
        Accordeon.prototype.toggle = function(item) {
            var $closest = $(item).closest(SELECTOR_ACCORDEON_ITEM);
            if ($closest.length) {
                $(SELECTOR_ACCORDEON_ITEM + "." + CLASS_EXPANDED, this._$wrapper).toggleClass(CLASS_EXPANDED, false);
                $closest.toggleClass(CLASS_EXPANDED, true);
            }
        };
        
        module.exports = {
            newInstance: constructor,
            builder: builder
        };
        
    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/account-banner.js */
(function($) {
    'use strict';

    modulr.define("account.banner", function(require, exports, module) {

        var REDIRECT_URL = '#account-banner-redirect-url';
        var FIRST_LINE = '#account-banner-first-line';
        var SECOND_LINE = '#account-banner-second-line';
        var PICTURE_ID = '#account-banner-picture-id';
        var EVENT_LISTENER = 'account-banner-redirect-url';
        var PICTURE_CONTAINER = '.account-banner-picture';

        var GTM = require("gtm")();

        var AccountBanner = function() {
            this.init();
        }

        AccountBanner.prototype.init = function() {
            $.ftnAjax('/restapi/cms/getAccountBanner', {
                method: "GET",
                data: {
                    "site": document.body.getAttribute('data-site'),
                    "Locale": document.documentElement.getAttribute('lang')
                },
                success: function(data) {
                    if (data !== 'null' && data !== undefined) {
                        var accountBanner = JSON.parse(data);

                        $(REDIRECT_URL).attr("style", "display: flex;");
                        $(FIRST_LINE).html(accountBanner.localizedProperties.firstLine);
                        $(SECOND_LINE).html(accountBanner.localizedProperties.secondLine);
                        if(accountBanner.pictureId)
                          $(PICTURE_ID).attr("src", "/picture/original/" + accountBanner.pictureId);
                        else
                          $(PICTURE_CONTAINER).attr("style", "display:none");

                        $(REDIRECT_URL).attr("href", accountBanner.redirectUrl);

                        var eventData = {
                            detail: {
                                clicked: false,
                                title: "account-banner",
                                id: "",
                                placement: window.location.href,
                                link: accountBanner.redirectUrl.startsWith('http') ? accountBanner.redirectUrl :
                                                 window.location.protocol + "//" + window.location.host + "/" +
                                                 accountBanner.redirectUrl.replace(/\//g, ''),
                                channel: "sportsbook"
                            }
                        }

                        GTM.trackMarcommImpression(eventData);

                        document.getElementById(EVENT_LISTENER).addEventListener("click", function() {
                            eventData.detail.clicked = true;
                            GTM.trackMarcommImpression(eventData);
                        }, { once: true });
                    }
                }
            })
        }

        module.exports = function() {
            return new AccountBanner();
        }

    })
})($);
;
/* assets/src/scripts/fortuna/after-login-popups.js */
(function(modulr, $) {
    "use strict";

    modulr.define("AfterLoginPopups", function(require, exports, module) {

        function AfterLoginPopups(popups) {
            this._popups = popups;
            this.show();
        };

        AfterLoginPopups.prototype.show = function() {
            var popupData = this._popups.shift();
            if (popupData) {
                this.showPopup(popupData);
            }
        };

        AfterLoginPopups.prototype.showPopup = function(popupData) {
            if (popupData) {
                var that = this;
                if (popupData.dataUrl) {
                    var time = new Date();
                    $.ftnAjax(popupData.dataUrl + "?_ts=" + time.getTime(), {
                        success: function(data) {
                            try {
                                var jsonData = JSON.parse(data);
                                that.handleData(popupData, jsonData);
                            } catch (e) {}
                        }
                    });
                } else {
                    that.handleData(popupData, popupData.staticData);
                }
            }
        };

        AfterLoginPopups.prototype.handleData = function(popupData, jsonData) {
            switch (popupData.type) {
                case "crmPopup":
                    require("CrmPopup")(jsonData, this, popupData.i18n);
                    break;
                case "bonusPopup":
                    require("bonuses").showBonusPopup(jsonData, popupData.staticData, this);
                    break;
                case "directMoneyPopup":
                    require("bonuses").showDirectMoneyPopup(jsonData, popupData.staticData, this);
                    break;
                case "consents":
                    require("consents.popups")(jsonData, popupData.options, this);
                    break;
                case "imsWaitingMessages":
                    require("playtech.ims-waiting-messages")(jsonData, this);
                    break;
                case "changeVoluntaryUsernamePopup":
                    require("changeUsernamePopup")(popupData);
                    break;
                case "changeObligatoryUsernamePopup":
                    require("changeUsernamePopup")(popupData);
                    break;
                case "skUserREPPopup":
                    require("skUserREPPopup")(popupData.i18n);
                    break;
            }
            if (popupData.seenNotification) {
                var time = new Date();
                $.ftnAjax(popupData.seenNotification + "?_ts=" + time.getTime());
            }
        };

        module.exports = function(popups) {
            return new AfterLoginPopups(popups);
        }

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/ajax-tabs.js */
(function(modulr, $) {
    "use strict";

    modulr.define("components.ajax-tabs", function(require, exports, module) {

        var CLASS_LOADED = "loaded";
        var STATS_FAIL_ID = 'stats-rendering-failed';
        var INTERVAL_RECONNECT = 10000;

        function __loadDataOnTabSwitch(anchor, tab, content, evt) {
            if (this.__reconnectFn) {
                window.clearTimeout(this.__reconnectFn);
                this.__reconnectFn = null;
            }
            var $tab = $(tab);
            if (!$tab.hasClass(CLASS_LOADED)) {
                var tabsAnchor = tab.dataset.tabsAnchor;
                var datatype = tab.dataset.tabsContentType;
                var link = $tab.attr("data-tabs-ajax-url");
                if (!link) {
                    link = $.Link($tab.attr("href")).ajaxify().getUrl();
                }
                $.ftnAjax(link, {
                    success: function(data) {
                        if (datatype == "json") {
                            try {
                                var json = JSON.parse(data);
                                switch (tabsAnchor) {
                                    case 'event-stats':
                                        var mountId = '#event-stats';
                                        var detail = true;
                                        break;
                                    case 'competition-stats':
                                        var mountId = '#competition-stats';
                                        var detail = false;
                                        break;
                                    default:
                                        var mountId = '#vue-stats';
                                }
                                new InjectFrontendApplication({
                                    manifestUrl: '/fe/offer-statistics/manifest.json',
                                    libraryName: 'offerStatistics',
                                    appId: 'offer-statistics-app',
                                    module: false,
                                }).init().then(function() {
                                    window.OfferStatistics.mount(mountId, {
                                        statistics: json,
                                        detail: detail
                                    });
                                });
                            } catch(e) {
                                var errorMessage = document.getElementById(STATS_FAIL_ID);
                                if (errorMessage) {
                                    errorMessage.classList.remove('hidden');
                                }
                                console.warn("Statistics data malformed, could not be rendered");
                            }
                        } else {
                            $(content).html(data);
                        }
                        $tab.toggleClass(CLASS_LOADED, true);
                    },
                    error: function(xhr, status, body) {
                        if (status === 0) {
                            this.__reconnectFn = window.setTimeout(function() {
                                __loadDataOnTabSwitch(anchor, tab, content, evt);
                            }, INTERVAL_RECONNECT);
                        }
                    }
                });
            }
        }

        function AjaxTabs(elm) {
            this.__tabs = require("components.tabs").instance(elm);
            this.__tabs.onSwitch(__loadDataOnTabSwitch);
        }

        AjaxTabs.prototype.onSwitch = function(callback) {
            this.__tabs.onSwitch(callback);
        };

        function instance(elm) {
            var $elm = $(elm);
            if (!$elm[0]._js) {
                $elm.forEach(function(itm) {
                    itm._js = {};
                });
            }
            if (!$elm[0]._js.ajaxTabs) {
                var inst = new AjaxTabs($elm);
                $elm.forEach(function(itm) {
                    itm._js.ajaxTabs = inst;
                });
            }
            return $elm[0]._js.ajaxTabs;
        }

        module.exports = {
            factory: function() {
                $(SELECTOR_TABS).forEach(function(itm) {
                    instance(itm)
                });
            },
            instance: instance
        }

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/analytics.js */
(function(modulr, $) {
    "use strict";
    
    var MAX_ACTIONS_PER_PAGE = 20;
    var actionsCounter = 0;
    
    function trackEvent(category, action, label, value) {
        if (typeof ga == "undefined" || !ga) return;
        if (!category) return;
        if (!action) return;
        var attrs = {
            hitType: "event",
            eventCategory: category,
            eventAction: action
        };
        if (label) {
            attrs.eventLabel = label;
        }
        if (!isNaN(parseInt(value))) {
            attrs.eventValue = parseInt(value);
        }
        ga("send", attrs);
        if (MAX_ACTIONS_PER_PAGE) {
            actionsCounter++;
            if (MAX_ACTIONS_PER_PAGE <= actionsCounter) {
                actionsCounter = 0;
                trackPageview(location.pathname + "?eventsreload");
            }
        }
    };
    
    function trackPageview(page) {
        if (!page) {
            page = location.pathname + location.search;
            if ($.cookie("locale")) {
                page = location.pathname + location.search + (location.search.length > 0 ? "&" : "?") + "language=" + $.cookie("locale");
            }
        }
        ga("send", "pageview", page);
    };
    
    function trackLogin() {
        var link = $.Link(window.location.href);
        trackPageview(link.appendOrReplaceParam("log", "success").getRelative());
    };
    
    function trackDepositSend(amount) {
        trackEvent("deposit", "send", amount);
    };
    
    function trackDepositResultSuccess(amount) {
        trackEvent("deposit", "result-success", amount);
    };
    
    function trackDepositResultFail(amount) {
        trackEvent("deposit", "result-fail", amount);
    };
    
    function trackDepositResultWaiting(amount) {
        trackEvent("deposit", "result-waiting", amount);
    };
    
    function trackDepositError(message) {
        trackEvent("deposit", "error", message);
    };
    
    function trackWithdrawSend(method, amount) {
        if (!method) return;
        trackEvent("withdraw", method, amount);
    };
    
    function trackWithdrawError(message) {
        trackEvent("withdraw", "error", message);
    };
    
    modulr.define("analytics", function(require, exports, module) {
        
        module.exports = {
            trackEvent: trackEvent,
            trackPageview: trackPageview,
            trackLogin: trackLogin,
            Deposit: {
                send: trackDepositSend,
                success: trackDepositResultSuccess,
                fail: trackDepositResultFail,
                error: trackDepositError,
                waiting: trackDepositResultWaiting
            },
            Withdraw: {
                send: trackWithdrawSend,
                error: trackWithdrawError
            }
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/android-downloader.js */
(function(modulr, $) {
	"use strict";

	modulr.define("android-downloader", function(require, exports, module) {

		function AndroidDownloader() {
			this.element = $("#android-downloader");
			this.initialize();
		};

		AndroidDownloader.prototype.initialize = function() {
			var that = this;
			$(".close", this.element).on("click", function(evt) {
				evt.preventDefault();
				that.hide(15);
			});
			$(".download", this.element).on("click", function() {
				that.hide(90);
			});
			$(function() {
				that.arrange();
			});
		};

		AndroidDownloader.prototype.hide = function(expires) {
			if (!expires) expires = 90;
			$.cookie("android-downloader", "hidden", {path: "/", expires: expires});
			this.hideElement();
		};

		AndroidDownloader.prototype.arrange = function() {
			document.documentElement.classList.add("android-downloader-visible");
		};

		AndroidDownloader.prototype.hideElement = function() {
			document.documentElement.classList.remove("android-downloader-visible");
		};

		module.exports = function() {
			return new AndroidDownloader();
		}

	});

})(modulr, $);
;
/* assets/src/scripts/fortuna/bonus-opt-mkt.js */
(function ($) {
    modulr.define("bonus-opt-mkt", function (require, exports, module) {

		var _MKT_BONUS_LIST_SELECTOR = 'mkt-bonus-list';
    var _MKT_BONUS_LIST_SELECTOR_SB = 'mkt-bonus-list-SB';
    var _MKT_BONUS_SELECTOR = 'article.mkt-bonus-casino';
    var _MKT_BONUS_SELECTOR_SB = 'article.mkt-bonus-SB';
    var _MKT_BTN_OPT_IN_SELECTOR = 'bonus-btn-optin';
		var _MKT_BTN_OPT_OUT_SELECTOR = 'bonus-btn-optout';

    var ENDPOINTS = {
      GET_OPTED_IN_BONUS_TEMPLATES: '/tracking/user/promotions/getOptedInBonusTemplates',
      OPT_IN_TO_BONUS_TEMPLATE: '/tracking/user/promotions/optInToBonusTemplate',
      OPT_OUT_FROM_BONUS_TEMPLATE: '/tracking/user/promotions/optOutFromBonusTemplate',
      GET_OPTED_IN_BONUS_TEMPLATES_SB: window.APIGW_URL+'/bm/api/v2/bonus/segmentation/optIn/definitions',
      OPT_IN_TO_BONUS_TEMPLATE_SB: window.APIGW_URL+'/bm/api/v2/bonus/segmentation/optIn/',
      OPT_OUT_FROM_BONUS_TEMPLATE_SB: window.APIGW_URL+'/bm/api/v2/bonus/segmentation/optIn/',
    };

		var BonusOptMkt = function (isSBBonuses) {
      this.isSBBonuses = isSBBonuses;
      this.mktBonusList = this.isSBBonuses
        ? document.getElementById(_MKT_BONUS_LIST_SELECTOR_SB)
        : document.getElementById(_MKT_BONUS_LIST_SELECTOR);
      this.mktBonus = this.isSBBonuses
        ? document.querySelectorAll(_MKT_BONUS_SELECTOR_SB)
        : document.querySelectorAll(_MKT_BONUS_SELECTOR);

      this.getOptInBonusURL = this.isSBBonuses ? ENDPOINTS.GET_OPTED_IN_BONUS_TEMPLATES_SB : ENDPOINTS.GET_OPTED_IN_BONUS_TEMPLATES;

      this.initialize();
		};

		BonusOptMkt.prototype.initialize = function () {
			this.bind();
            this.getOptedInBonusTemplates();
		};

		BonusOptMkt.prototype.bind = function () {
			this.onClickOptBtns();
		};

        BonusOptMkt.prototype.getOptedInBonusTemplates = function () {
          var ajaxOptions = {
            url: this.getOptInBonusURL,
            method: "GET",
            success: function(data) {
              var optedInBonuses = JSON.parse(data);
              this.setOptedInBonusTemplates(optedInBonuses);
            }.bind(this),
            complete: function () {
              this.mktBonusList.style.visibility = "visible";
            }.bind(this),
          };

          if (this.isSBBonuses) {
            ajaxOptions.xhrFields = {
              withCredentials: true
            };
          }
          $.ftnAjax(ajaxOptions);
        };

        BonusOptMkt.prototype.setOptedInBonusTemplates = function (optedInBonuses) {
            const bonusTemplateOptIns = optedInBonuses.bonusTemplateOptIns || optedInBonuses;

            bonusTemplateOptIns.forEach(function(item) {
              const templateCode = item.templateCode || item;

              this.mktBonus.forEach(function(el) {
                    const dataset = el.dataset;
                    if(dataset.bonusId === templateCode) {
                        el.querySelector('#bonus-btn-optout').style.display = "block";
                        el.querySelector('#bonus-btn-optin').style.display = "none";
                    }
                });
            }.bind(this))
        };

      BonusOptMkt.prototype.optInToBonusTemplate = function (target, options) {

        if(this.isSBBonuses){
          fetch(ENDPOINTS.OPT_IN_TO_BONUS_TEMPLATE_SB + options.templateCode, {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            },
            credentials: "include"
          }).then(function(res) {
            if(res.status === 201) {
              target.srcElement.style.display = "none";
              target.srcElement.nextElementSibling.style.display = "block";
            }
          }).catch(function(err) {
          });
        }else{
          fetch(ENDPOINTS.OPT_IN_TO_BONUS_TEMPLATE, {
            method: "POST",
            body: JSON.stringify(options),
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
          }).then(function(res) {
            if(res.status === 201) {
              target.srcElement.style.display = "none";
              target.srcElement.nextElementSibling.style.display = "block";
            }
          }).catch(function(err) {

          });
        };
      };

        BonusOptMkt.prototype.optOutFromBonusTemplate = function (target, options) {
          var ajaxOptions = {};
          if(this.isSBBonuses){
            ajaxOptions = {
              url: ENDPOINTS.OPT_OUT_FROM_BONUS_TEMPLATE_SB + options.templateCode,
              method: "DELETE",
              contentType: "application/json",
              xhrFields: {
                withCredentials: true
              },
              success: function() {
                target.srcElement.style.display = "none";
                target.srcElement.previousElementSibling.style.display = "block";
              },
              error: function(err) {

              }
            };
          }else{
            ajaxOptions = {
              url: ENDPOINTS.OPT_OUT_FROM_BONUS_TEMPLATE,
              method: "POST",
              contentType: "application/json",
              data: JSON.stringify(options),
              success: function() {
                target.srcElement.style.display = "none";
                target.srcElement.previousElementSibling.style.display = "block";
              },
              error: function(err) {

              }
            };
          };
          $.ftnAjax(ajaxOptions);
        },

        BonusOptMkt.prototype.onClickOptBtns = function () {
            this.mktBonusList.addEventListener('click', function(target) {
                if (target.srcElement.className.includes(_MKT_BTN_OPT_OUT_SELECTOR)) {
                    this.optOutFromBonusTemplate(target, {
                        'templateCode': target.srcElement.dataset.bonusId,
                    });
                }

                if (target.srcElement.className.includes(_MKT_BTN_OPT_IN_SELECTOR)) {
                    this.optInToBonusTemplate(target, {
                        'templateCode': target.srcElement.dataset.bonusId,
                    });
                }
            }.bind(this));
        };

		module.exports = function (isSBBonuses) {
			return new BonusOptMkt(isSBBonuses);
		};
	});
})($);
;
/* assets/src/scripts/fortuna/bonus-section.js */
(function ($) {
  "use strict";

  if (!$) {
    return;
  }

  modulr.define("bonuses", function (require, exports, module) {

    var MessageDialog = window.mobile.dialogs.dialogMessage;
    var AlertDialog = window.mobile.dialogs.dialogAlert;
    var ConfirmDialog = window.mobile.dialogs.dialogConfirm;

    var SELECTOR_PAGE_CONTENT = "#page main";
    var SELECTOR_CONDITION_OPENERS = "a.bonus-conditions";
    var SELECTOR_CONDITIONS = ".conditions";
    var SELECTOR_BONUS_BOX = ".bonus";
    var SELECTOR_ACTIVE_STEP = ".step.active";

    var TPL_BONUS_POPUP_CONTENT = '<div class="bonus-top" style="display: flex;flex-direction: column;align-items: center;margin-bottom: 10px"><div class="icon-box" style="width: 120px; height: 120px"><span class="icon {{type}}" style="display: block; height: 100%; width: 100%; background-size: contain; background-repeat: no-repeat"></span></div><h3 class="bonus-title">{{title}}</h3><div class="description">{{description}}</div></div><div class="bonus-bottom"><a href="#" class="conditions-opener">{{CONDITIONS_OPENER_TEXT}}</a><div class="mobile-checkbox empty-control"><input type="checkbox" id="agree-with-bonus-conditions-{{id}}" name="agree-with-bonus-conditions-{{id}}" class="hidden" /><label for="agree-with-bonus-conditions-{{id}}" id="agree-with-bonus-{{id}}-conditions-label"><span class="cell"><span class="checkbox"></span></span><span class="cell"><span class="main">{{CHECKBOX_TEXT}}</span></span></label></div><div class="error-message hidden">{{CONDITIONS_NOT_AGREED}}</div></div>';
    var TPL_BONUS_POPUP_CONTENT_WO_CHECKBOX = '<div class="bonus-top" style="display: flex;flex-direction: column;align-items: center;margin-bottom: 10px"><div class="icon-box" style="width: 120px; height: 120px"><span class="icon {{type}}" style="display: block; height: 100%; width: 100%; background-size: contain; background-repeat: no-repeat"></span></div><h3 class="bonus-title">{{title}}</h3><div class="description">{{description}}</div><a href="#" class="conditions-opener">{{CONDITIONS_OPENER_TEXT}}</a></div><div class="bonus-bottom"></div>';

    var GTM = require("gtm")();

    function initializeBonusConditionOpeners() {
      $(SELECTOR_PAGE_CONTENT).delegate("click", SELECTOR_CONDITION_OPENERS, function (evt) {
        showBonusConditions(evt);
      });
    };

    function showBonusConditions(evt) {
      evt.preventDefault();
      var conditions = $(SELECTOR_CONDITIONS, $(evt.target).parent(SELECTOR_BONUS_BOX));
      if (conditions.length) {
        MessageDialog(conditions.html(), {
          specialClass: "bonus-conditions"
        });
      }
    }

    function scrollToActiveStep() {
      var actives = $(SELECTOR_PAGE_CONTENT + " " + SELECTOR_ACTIVE_STEP);
      if (actives.length) {
        if (actives[0].scrollIntoViewIfNeeded) {
          actives[0].scrollIntoViewIfNeeded();
        } else if (actives[0].scrollIntoView) {
          actives[0].scrollIntoView();
        }
      }
    }

    function showDirectMoneyPopup(params, staticData, afterLoginPopups) {
      var bonuses = [];
      var directMoneyBonusesId = null;
      if (params && params.finishedDirectMoneyBonuses) {
        bonuses = params.finishedDirectMoneyBonuses;
      }
      if (params && params.finishedDirectMoneyBonusesId) {
        directMoneyBonusesId = params.finishedDirectMoneyBonusesId;
      }
      if (bonuses.length) {
        var content = "";
        content += "<p>" + staticData.i18n.PEREX + "</p>";
        content += "<table>";
        for (var i = 0; i < bonuses.length; i++) {
          content += "<tr>";
          content += "<td>" + bonuses[i].date + "</td>";
          content += '<td class="ta-right">' + bonuses[i].amount + "</td>";
          content += "</tr>";
        }
        content += "</table>";
        AlertDialog(content, {
          "specialClass": "bonus-direct-money-popup",
          "titleLabel": staticData.i18n.TITLE,
          "okButtonLabel": staticData.i18n.BUTTON,
          "okCallback": function () {
            if (afterLoginPopups) {
              afterLoginPopups.show();
            }
          },
          "link": staticData.sectionLink
        });
        $.cookie("viewed_direct_money_popup", directMoneyBonusesId, {path: "/", expires: 7});
      }
    }

    function BonusPopup(bonuses, params, afterLoginPopups) {
      this.bonuses = [];
      this.directlyActivatedBonuses = null;
      if (bonuses && bonuses.offeredAndDirectlyActivated) {
        this.bonuses = bonuses.offeredAndDirectlyActivated;
      }
      if (bonuses && bonuses.directlyActivatedIds) {
        this.directlyActivatedBonuses = bonuses.directlyActivatedIds;
      }
      this.params = params;
      this.afterLoginPopups = afterLoginPopups;
      this.setDirectlyActivatedBonusesCookie();
      this.showNextBonus();
    }

    BonusPopup.prototype.setDirectlyActivatedBonusesCookie = function () {
      if (this.directlyActivatedBonuses) {
        $.cookie("viewed_directly_activated_bonuses", this.directlyActivatedBonuses, {path: "/", expires: 7});
      }
    };

    BonusPopup.prototype.showNextBonus = function () {
      if (this.bonuses.length) {
        var bonus = this.bonuses.shift();
        this.open(bonus);
      } else if (this.afterLoginPopups) {
        this.afterLoginPopups.show();
      }
    };

    BonusPopup.prototype.open = function (bonus) {
      var that = this;
      var dialog;
      if (bonus.status === "OFFERED") {
        var postponeButton = null;
        if (that.params.i18n.POSTPONE) {
            postponeButton = '<a href="' + (that.params.postponeLink || "#") + '" id="bonus-postpone-button">' + that.params.i18n.POSTPONE + '</a>';
        }
        dialog = ConfirmDialog(this.buildBonusDialogContent(bonus), {
          "specialClass": "bonus-popup",
          "titleLabel": that.params.i18n.TITLE,
          "okButtonLabel": that.params.i18n.ACTIVATE,
          "okButtonLink": that.params.acceptLink.replace(/\{\{bonusId}}/, bonus.id),
          "cancelButtonLabel": that.params.i18n.DEACTIVATE,
          "cancelButtonLink": that.params.declineLink.replace(/\{\{bonusId}}/, bonus.id),
          "additionalButton": postponeButton

        });
        $(".ok", dialog._html).on("click", function (evt) {
          that.activateBonus(evt, dialog, bonus);
        });
        $(".cancel", dialog._html).on("click", function (evt) {
          that.deactivateBonus(evt, dialog, bonus);
        });
        if (that.params.i18n.POSTPONE) {
            $("#bonus-postpone-button", dialog._html).on("click", function (evt) {
                that.postponeBonus(evt, dialog, bonus);
            });
        }
        $("#agree-with-bonus-conditions-" + bonus.id).on("change", function () {
          if (this.checked) {
            $(".error-message", dialog).toggleClass("hidden", true);
          }
        });
      } else {
        var title = bonus.status === "OFFERED" ? that.params.i18n.TITLE : that.params.i18n.TITLE_ACTIVATED;
        dialog = AlertDialog(this.buildBonusDialogContentWOCheckbox(bonus), {
          "specialClass": "bonus-popup",
          "titleLabel": title,
          "okButtonLabel": that.params.i18n.GO_TO_BONUSES,
          "link": that.params.sectionLink
        });
      }
      if (GTM) {
        GTM.trackBonusPopupRendered({
          detail: {
            bonus_code: bonus.id,
            location: "gm_login_popup",
          }
        });
      }

      $(".conditions-opener").on("click", function (evt) {
        that.showBonusConditions(evt, bonus, dialog);
      });
    };

    BonusPopup.prototype.showBonusConditions = function (evt, bonus, dialog) {
      evt.preventDefault();
      var that = this;
      dialog.hide();
      AlertDialog(bonus.condition, {
        "specialClass": "bonus-conditions-popup",
        "titleLabel": that.params.i18n.CONDITIONS,
        "okButtonLabel": that.params.i18n.CLOSE,
        "okCallback": function () {
          dialog.show();
        }
      });
    };

    BonusPopup.prototype.buildBonusDialogContent = function (bonus) {
      return TPL_BONUS_POPUP_CONTENT
        .replace(/\{\{id\}\}/g, bonus.id)
        .replace(/\{\{title\}\}/g, bonus.title)
        .replace(/\{\{type\}\}/g, bonus.type)
        .replace(/\{\{description\}\}/g, bonus.description)
        .replace(/\{\{CONDITIONS_OPENER_TEXT\}\}/g, this.params.i18n.CONDITIONS)
        .replace(/\{\{CHECKBOX_TEXT\}\}/g, this.params.i18n.AGREE_WITH_CONDITIONS)
        .replace(/\{\{CONDITIONS_NOT_AGREED\}\}/, this.params.i18n.CONDITIONS_NOT_AGREED);
    };

    BonusPopup.prototype.buildBonusDialogContentWOCheckbox = function (bonus) {
      return TPL_BONUS_POPUP_CONTENT_WO_CHECKBOX
        .replace(/\{\{title\}\}/g, bonus.title)
        .replace(/\{\{type\}\}/g, bonus.type)
        .replace(/\{\{description\}\}/g, bonus.description)
        .replace(/\{\{CONDITIONS_OPENER_TEXT\}\}/g, this.params.i18n.CONDITIONS);
    };

    BonusPopup.prototype.activateBonus = function (evt, dialog, bonus) {
      evt.stopPropagation();
      evt.preventDefault();
      var that = this;
      var conditionsAcceptor = document.getElementById("agree-with-bonus-conditions-" + bonus.id);
      if (!conditionsAcceptor || (conditionsAcceptor && conditionsAcceptor.checked)) {
        $(".error-message", dialog._html).toggleClass("hidden", true);
        var url = evt.target.href;
        if (url) {
          $.ftnAjax($.Link(url).ajaxify().getUrl(), {
            method: "POST",
            data: "bonusId=" + bonus.id,
            beforeSend: function () {
              window.mobile.offer.toggleLoader(true);
            },
            success: function (data) {
              var jsonData = JSON.parse(data);
              that.showResultDialog(jsonData.status, jsonData.message, dialog);
            },
            error: function () {
              that.showResultDialog("error", that.params.i18n.ERROR_DESCRIPTION, dialog);
            },
            complete: function () {
              window.mobile.offer.toggleLoader(false);
              if (GTM) {
                GTM.trackBonusPopupAccepted({
                  detail: {
                    bonus_code: bonus.id,
                    location: "gm_login_popup",
                  }
                });
              }
            }
          });
        }
      } else {
        $(".content", dialog._html).css({
          "height": $(".content", dialog._html).height() + "px"
        });
        $(".content-inner", dialog._html).css({
          "height": $(".content-inner", dialog._html).height() - parseInt($(".content-inner", dialog._html).css("padding-top")) - parseInt($(".content-inner", dialog._html).css("padding-bottom")) + "px"
        });
        $(".error-message", dialog._html).toggleClass("hidden", false).scrollIntoView();
      }
    };

    BonusPopup.prototype.deactivateBonus = function (evt, dialog, bonus) {
      evt.stopPropagation();
      evt.preventDefault();
      var that = this;
      var url = evt.target.href;
      if (url) {
        $.ftnAjax($.Link(url).ajaxify().getUrl(), {
          method: "POST",
          data: "bonusId=" + bonus.id,
          beforeSend: function () {
            window.mobile.offer.toggleLoader(true);
          },
          success: function (data) {
            var jsonData = JSON.parse(data);
            if (jsonData.status === "ok") {
              that.showNextBonus();
            } else {
              that.showResultDialog(jsonData.status, jsonData.message, dialog);
            }
          },
          error: function () {
            that.showResultDialog("error", that.params.i18n.ERROR_DESCRIPTION, dialog);
          },
          complete: function () {
            dialog.hideAndDestroy();
            window.mobile.offer.toggleLoader(false);
            if (GTM) {
              GTM.trackBonusPopupDeclined({
                detail: {
                  bonus_code: bonus.id,
                  location: "gm_login_popup",
                }
              });
            }

          }
        });
      }
    };

    BonusPopup.prototype.postponeBonus = function (evt, dialog, bonus) {
        evt.stopPropagation();
        evt.preventDefault();
        var that = this;
        var url = evt.target.href;
        if (url) {
          $.ftnAjax($.Link(url).ajaxify().getUrl(), {
            method: "POST",
            data: "bonusId=" + bonus.id,
            beforeSend: function () {
              window.mobile.offer.toggleLoader(true);
            },
            success: function (data) {
              var jsonData = JSON.parse(data);
              if (jsonData.status === "ok") {
                that.showNextBonus();
              } else {
                that.showResultDialog(jsonData.status, jsonData.message, dialog);
              }
            },
            error: function () {
              that.showResultDialog("error", that.params.i18n.ERROR_DESCRIPTION, dialog);
            },
            complete: function () {
              dialog.hideAndDestroy();
              window.mobile.offer.toggleLoader(false);
              if (GTM) {
                GTM.trackBonusPopupPostponed({
                  detail: {
                    bonus_code: bonus.id,
                    location: "gm_login_popup",
                  }
                });
              }

            }
          });
        }
      };

    BonusPopup.prototype.showResultDialog = function (status, message, dialog) {
      var that = this;
      var title = status === "ok" ? this.params.i18n.SUCCESS_TITLE : this.params.i18n.ERROR_TITLE;
      var okButtonLabel = this.bonuses.length ? this.params.i18n.OK : this.params.i18n.GO_TO_BONUSES;
      var okButtonCallback = !this.bonuses.length ? false : function () {
        that.showNextBonus();
      };
      var okButtonLink = this.bonuses.length ? "#" : this.params.sectionLink;
      message = message || (status === "ok" ? this.params.i18n.SUCCESS_MESSAGE : this.params.i18n.ERROR_MESSAGE);
      dialog.hideAndDestroy();
      AlertDialog(message, {
        "titleLabel": title,
        "okButtonLabel": okButtonLabel,
        "link": okButtonLink,
        "okCallback": okButtonCallback,
        "specialClass": "bonus-activation-result"
      });
    };

    var Bonuses = {
      initActiveBonusesPage: function () {
        initializeBonusConditionOpeners();
        scrollToActiveStep();
      },
      showBonusPopup: function (bonuses, params, afterLoginPopups) {
        new BonusPopup(bonuses, params, afterLoginPopups);
      },
      showDirectMoneyPopup: showDirectMoneyPopup,
      initializeBonusConditionOpeners: initializeBonusConditionOpeners
    };

    module.exports = Bonuses;

  });

})($);
;
/* assets/src/scripts/fortuna/branches-detail.js */
function initBranchMap() {
    new BranchDetail()
}

function BranchDetail() {
    var _this = this
    this.mapWrapper = document.getElementById('branches-detail-map-wrapper');
    var branchLongitude = this.mapWrapper.dataset["branchLongitude"];
    var branchLatitude = this.mapWrapper.dataset["branchLatitude"];
    var branchLatLng = new google.maps.LatLng(branchLatitude, branchLongitude);
    this.map = new google.maps.Map(this.mapWrapper, {
        mapTypeControl: false,
        streetViewControl: false,
        center: branchLatLng,
        zoom: 12
    });
    this.addBranchToMap(branchLatLng)
}

BranchDetail.prototype = {

    addBranchToMap: function(myLatLng) {
        var marker = new google.maps.Marker({
            position: myLatLng,
            icon: this.mapWrapper.dataset.markerIcon
        });
        marker.setMap(this.map);
    }

}
;
/* assets/src/scripts/fortuna/branches-locator.js */
function initBranchesMap() {
    new BranchesLocator()
}

function Geolocation() {
    this.options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };
}

Geolocation.prototype = {

    getCurrentPosition: function(onSuccess) {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onSuccess, this.onError, this.options);
        }
    },

    onError: function(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
    }
};

function BranchesLocator() {
	var _this = this;
    this.markers = [];
    this.mapWrapper = document.getElementById('branches-map-wrapper');
    var zoom = parseInt(this.mapWrapper.getAttribute("data-default-zoom"));
	this.map = new google.maps.Map(this.mapWrapper, {
        mapTypeControl: false,
        streetViewControl: false,
        center: {lat: parseFloat(_this.mapWrapper.getAttribute("data-default-latitude")), lng: parseFloat(_this.mapWrapper.getAttribute("data-default-longitude"))},
        zoom: (isNaN(zoom) ? 6 : zoom)
    });
    this.infowindow = this.createInfoWindow();
    this.addMarkers();
    var mcOptions = {
        styles: [
            {
                textColor: this.mapWrapper.dataset.markerTextColor,
                url: this.mapWrapper.dataset.markerGroupSmallIcon,
                height: 26,
                width: 26
            },
            {
                textColor: this.mapWrapper.dataset.markerTextColor,
                url: this.mapWrapper.dataset.markerGroupMediumIcon,
                height: 26,
                width: 26
            },
            {
                textColor: this.mapWrapper.dataset.markerTextColor,
                url: this.mapWrapper.dataset.markerGroupLargeIcon,
                height: 38,
                width: 38
            }
        ]
    };
    this.markerClusterer = new MarkerClusterer(this.map, this.markers, mcOptions);
    this.findMyPosition();
    if (this.mapWrapper.getAttribute("data-disable-center-to-my-position") !== "true") {
    	this.centerMapToMyPosition()
    }
    this.bind()
}

BranchesLocator.prototype = {

    bind: function() {
    	var that = this;
        var goToMyPosition = document.querySelector('#branches .go-to-my-position');
        goToMyPosition.addEventListener('click', this.goToMyPosition.bind(this));
        var searchHeader = document.querySelector('#branches .branches-header .more-info');
        searchHeader.addEventListener('click', this.toggleHeader.bind(this));
        var searchBranches = document.querySelector('#branches .branches-search input');
        searchBranches.addEventListener('keyup', this.findBranches.bind(this));
        document.body.addEventListener('click', this.closeSuggest.bind(this));
        $(this.mapWrapper).delegate("click", ".go-to-branch", function(evt) {
        	that.redirectToBranchDetail(evt);
        });
        $(document.body).delegate("click", "#branches .branches-search li a", function(evt) {
        	that.redirectToBranchDetail(evt);
        });
        var findPath = document.querySelector("#branches .find-path");
        findPath.addEventListener("click", function(evt) {
        	that.navigateToNearestToCenter(evt);
        });
    },

    findBranches: function(ev) {
        var text = ev.target.value;
        var that = this;
        if (!text || text.length < 3) {
        	var searchResult = document.querySelector('.suggest-list-wrapper');
        	searchResult.innerHTML = "";
        	return false;
        }

        $.ftnAjax('/ajax/branches/suggest?regex=' + text, {
            success: function(data) {
                var response = JSON.parse(data);
                var searchResult = document.querySelector('.suggest-list-wrapper');
                searchResult.style.maxHeight = that.mapWrapper.offsetHeight - 30 + "px";
                searchResult.innerHTML = response.htmlContent
            },
            error: function() {
                window.mobile.dialogs.dialogToast(getTranslation("suggestLoadingError"));
            }
        });
    },

    closeSuggest: function(ev) {
        var node = ev.target;
        var isEl = null;
        while(node !== this) {
            if($(node).is('.branches-search')) {
                isEl = true;
                break;
            }
            if (node === document.body || node == null) {
                break;
            }
            node = node.parentNode;
        }
        if (!isEl) {
            document.querySelector('.suggest-list-wrapper').innerHTML = '';
            document.querySelector('.branches-header').style.display = 'block';
            document.querySelector('.branches-search').style.display = 'none'
        }

    },

    toggleHeader: function(ev) {
        ev.stopPropagation();
        document.querySelector('.branches-header').style.display = 'none';
        document.querySelector('.branches-search').style.display = 'block';
        document.getElementById("branch-search-input").focus();
    },

    goToMyPosition: function() {
        this.mylatLng && this.map.panTo(this.mylatLng)
    },

    centerMapToMyPosition: function() {
        _this = this;
        var geolocation = new Geolocation();
        var position = geolocation.getCurrentPosition(function(pos) {
            _this.mylatLng = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
            var nearestMarker = _this.findNearestMarker(_this.mylatLng);
            _this.addMyPositionToMap(_this.mylatLng);
            var LatLngBoundsLiteral = _this.getBoundsForMeAndNearestMarker(_this.mylatLng, nearestMarker.getPosition());
            _this.map.fitBounds(LatLngBoundsLiteral);
            _this.setNearestBranchDirectionsToNavButton(nearestMarker);
        })

    },

    findMyPosition: function() {
    	var _this = this;
    	var geolocation = new Geolocation();
    	var position = geolocation.getCurrentPosition(function(pos) {
    		_this.mylatLng = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
    		_this.addMyPositionToMap(_this.mylatLng);
    		var nearestMarker = _this.findNearestMarker(_this.mylatLng);
            _this.setNearestBranchDirectionsToNavButton(nearestMarker);
    	});
    },

    addMyPositionToMap: function(myLatLng) {
        var marker = new google.maps.Marker({
            position: myLatLng,
            icon: this.mapWrapper.dataset.markerMeIcon,
            map: this.map
        });
    },

    getBoundsForMeAndNearestMarker: function(myLatLng, marketLatLng) {
        var latDistance = Math.abs(myLatLng.lat() - marketLatLng.lat());
        var lngDistance = Math.abs(myLatLng.lng() - marketLatLng.lng());
        if(latDistance < 0.0001) latDistance = 0.0001;
        if(lngDistance < 0.0002) lngDistance = 0.0002;
        var north = myLatLng.lat() - latDistance;
        var sounth = myLatLng.lat() + latDistance;
        var west = myLatLng.lng() - lngDistance;
        var east = myLatLng.lng() + lngDistance;
        return {
            east: east,
            north: north,
            south: sounth,
            west: west
        }
    },

    findNearestMarker: function(myPosition) {
        var marker = null;
        var minDistance = 0;
        for(var i = 0, len = this.markers.length; i !== len; i++) {
            var distance =  google.maps.geometry.spherical.computeDistanceBetween(myPosition,  this.markers[i].getPosition());
            if (!minDistance || minDistance > distance) {
                minDistance = distance;
                marker = this.markers[i]
            }
        }
        return marker
    },

    addMarkers: function() {
        var _this = this;
        var markers = JSON.parse(this.mapWrapper.dataset.branches);
        for(var i = 0, len = markers.length; i !== len; i++) {
            var position = new google.maps.LatLng(markers[i].lat, markers[i].lng);
            var marker = new google.maps.Marker({
                position: position,
                icon: this.mapWrapper.dataset.markerIcon
            });
            marker.addListener("click", (function (marker, id) {
                return function() {
                    _this.map.panTo(marker.getPosition());
                    $.ftnAjax('/ajax/branches/detail?id=' + id, {
                        success: function(data) {
                            var response = JSON.parse(data);
                            _this.infowindow.setContent(response.htmlContent);
                            _this.infowindow.open(_this.map, marker)
                        },
                        error: function() {
                            window.mobile.dialogs.dialogToast(getTranslation("branchLoadingError"));
                        }
                    });
                }
            })(marker, markers[i].id));
            this.markers.push(marker)
        }
    },

    createInfoWindow: function() {
        return new google.maps.InfoWindow({
            disableAutoPan: true
        });
    },

    redirectToBranchDetail: function(evt) {
    	var target = evt.target;
    	while (target && !target.href) {
    		target = target.parentNode;
    	}
    	if (target) {
    		evt.preventDefault();
    		var that = this;
    		var link = $.Link(target.href)
    			.appendOrReplaceParam("lat", that.map.getCenter().lat())
    			.appendOrReplaceParam("lng", that.map.getCenter().lng())
    			.appendOrReplaceParam("zoom", that.map.getZoom());
    		window.location.href = link.getUrl();
    	}
    },

    setNearestBranchDirectionsToNavButton: function(nearestBranch) {
    	if (!nearestBranch) return;
    	var findPath = document.querySelector(".find-path");
    	if (findPath) {
    		findPath.href = findPath.href + nearestBranch.getPosition().lat() + "," + nearestBranch.getPosition().lng();
    	}
    },

    navigateToNearestToCenter: function(evt) {
    	evt.preventDefault();
    	var nearest = this.findNearestMarker(this.map.getCenter());
    	if (nearest) {
    		var lnk = $.Link(evt.target.href);
    		lnk.filename = nearest.getPosition().lat() + "," + nearest.getPosition().lng();
    		window.open(lnk.getUrl());
    	}
    }

}
;
/* assets/src/scripts/fortuna/browse-history.js */
(function(modulr, $) {
    "use strict";

    var STORAGE_KEY = "history";
    var IGNORED_PATHS = ["/set", "/send", "/container", "/save-card"];
    var COOKIE_LAST_VIEWED_PAGE = "last_viewed_page";
    var COOKIE_CLOSE_URL = "close_url";
    
    var SELECTOR_BACK = ".history-back";
    var SELECTOR_CLOSE = ".history-close";
    
    var DEEPLINK_PROTOCOL = "ftn";

    function BrowseHistory() {
        var history = ($.getSessionStorage(STORAGE_KEY) || []);

        this.history = history.filter(function(entry) {
          return entry && entry.url;
        });

        this.initialize();
        this.saveScrollPosition = true;
    };

    BrowseHistory.prototype.initialize = function() {
        var that = this;
        $(window).on("pagehide", function() {
            that.onUnload();
        });

        $(window).on("message", this.onMessage.bind(this));

        $(function() {
            that.onLoad();
            var page = $("#page");
            page.delegate("click", SELECTOR_BACK, function(evt) {
                that.onBack(evt, this);
            });
            page.delegate("click", SELECTOR_CLOSE, function(evt) {
                that.onClose(evt, this);
            });
        });

        window.addEventListener('browse-history:replace', this.replace.bind(this));
    };

    BrowseHistory.prototype.onMessage = function(evt) {
        if (evt.origin !== window.location.origin) {
            return;
        }

        var message = evt.data;

        if (message === "PageLoad") {
            this.onLoad();
        }

        if (message === "PageUnload") {
            this.onUnload();
        }
    };

    BrowseHistory.prototype.onBack = function(evt, elm) {
        // back bude obslouzeno pouze pokud elm nezacina s DEEP_PROTOCOL
        if (!(elm && elm.href && elm.href.startsWith(DEEPLINK_PROTOCOL))) {
            // Prvni shift odstrani aktualne zobrazenou stranku, ktera se ulozila na load
            this.history.shift();
            if (this.history.length) {
                evt.preventDefault();
                this.saveScrollPosition = false;
                var previous = this.history[0];
                this.persist();
                window.location.href = previous.url;
            }
        }
    };

    BrowseHistory.prototype.onClose = function(evt, elm) {
        var index;
        var item;
        if (!elm || !elm.href || !elm.href.startsWith(DEEPLINK_PROTOCOL)) {
            if (!elm.href.startsWith(window.location.protocol + "//" + window.location.host)) {
                window.location.href = elm.href;
                return;
            }
            // Detect if we are in container browser and if true redirect to back url
            if (window.top && window.top !== window) {
                var lnk = $.Link(window.top.location.href);
                var backUrl = lnk.getParam("back_url");
                if (backUrl) {
                    window.top.location = backUrl;
                    return;
                }
            }
            for (var i = 0, l = this.history.length; i < l; i++) {
                if (this.history[i].main) {
                    item = this.history[i];
                    index = i;
                    break;
                }
            }
            if (item) {
                evt.preventDefault();
                this.saveScrollPosition = false;
                this.history = this.history.slice(index);
                this.persist();
                window.location.href = item.url;
            }
        }
    };

    BrowseHistory.prototype.onLoad = function() {
        this.push();
    };

    BrowseHistory.prototype.onUnload = function() {
        if (this.saveScrollPosition) {
            var scrollElement = this.getScrollElement();
            if (scrollElement && this.history[0]) {
                this.history[0].scrollTop = scrollElement.scrollTop;
                this.persist();
            }
        }
    };

    BrowseHistory.prototype.replace = function(url) {
        url = typeof url === "string" ? url : window.location.href;
        var link = $.Link(url);
        var base = "/" + link.path.join("/");
        var main = this.isMain(url);
        this.history[0] = this.createHistoryEntry(base, url, main, 0);
        this.persist();
    };

    BrowseHistory.prototype.push = function(url, main) {
        url = typeof url === "string" ? url : window.location.href;
        var link = $.Link(url);
        if (typeof main === "undefined") main = this.isMain(url);
        var base = "/" + link.path.join("/");
        if (!this.isInIgnoredPaths(base)) {
            var previous;
            // Detekce, jestli nebylo stisknuto tlačítko zpět
            if (this.history[1] && this.history[1].url === url) {
                this.history.shift();
            }
            previous = this.history[0];
            if (previous && previous.url === url) {
                this.scrollToSavedPosition(previous.scrollTop);
            } else if (previous && previous.base === base) {
                this.history[0] = this.createHistoryEntry(base, url, main, 0);
            } else {
                this.history.unshift(this.createHistoryEntry(base, url, main, 0));
            }
            this.persist();
            $.cookie(COOKIE_LAST_VIEWED_PAGE, encodeURIComponent(window.location.href), {path: "/"});
            if (link.getParam("close_url")) {
                $.cookie(COOKIE_CLOSE_URL, link.getParam("close_url"), {path: "/"});
            } else if (main) {
                $.cookie(COOKIE_CLOSE_URL, encodeURIComponent(link.getUrl()), {path: "/"});
            }
        }
    };

    BrowseHistory.prototype.persist = function() {
        $.sessionStorage(STORAGE_KEY, this.history);
    };
    
    BrowseHistory.prototype.scrollToSavedPosition = function(position) {
    	var that = this;
    	var scrollElement = this.getScrollElement();
        if (scrollElement) {
            scrollElement.scrollTop = position;
        }
    };

    BrowseHistory.prototype.isMain = function(url) {
        return !$("#page header .simple-header").length && !$("#page #ticket-wrapper:not(.on-page) .simple-header").length;
    };

    BrowseHistory.prototype.isInIgnoredPaths = function(path) {
        var split = path.split("/");
        var last = "/" + split[split.length - 1];
        return IGNORED_PATHS.indexOf(last) > -1;
    };

    BrowseHistory.prototype.createHistoryEntry = function(base, url, main, scrollTop) {
        return {
            "base": base,
            "url": url,
            "main": main,
            "scrollTop": scrollTop
        };
    };
    
    BrowseHistory.prototype.getScrollElement = function() {
    	return document.scrollingElement;
    };

    var bh = new BrowseHistory();

    modulr.define("browse-history", function(require, exports, module) {
        module.exports = bh;
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/change-username-popup.js */
(function (modulr) {
  "use strict";
  modulr.define("changeUsernamePopup", function (require, exports, module) {

    var AlertDialog = window.mobile.dialogs.dialogAlert;
    var FormValidator = require("form-validator");

    var UsernamePopup = function (popupData) {
        if (popupData.isObligatory === "true") {
            this.showObligatoryPopup(popupData);
        } else {
            this.showVoluntaryPopup(popupData);
        }
        var $changeUsernameForm = $("form.change-username-form");
        this._validator = FormValidator($changeUsernameForm);
    };

    UsernamePopup.prototype.onOkClick = function (popupData) {
      if (this._validator && this._validator.isValid()) {
          var that = this;
          var newUsername = document.getElementById("username").value;
          var ajaxData;
          if (popupData.oldUsername) {
              ajaxData = "newUsername=" + newUsername + "&oldUsername=" +  popupData.oldUsername;
          }
          else{
              ajaxData = "newUsername=" + newUsername;
          }
          $.ftnAjax($.Link(popupData.url).ajaxify().getUrl(), {
              method: "POST",
              data: ajaxData,
              beforeSend: function () {
                  window.mobile.offer.toggleLoader(true);
              },
              success: function (data) {
                  var jsonData = JSON.parse(data);
                  window.mobile.offer.toggleLoader(false);
                  that.showResultDialog(jsonData.status, popupData, jsonData.message);
              },
              error: function (data) {
                  var jsonData = JSON.parse(data);
                  window.mobile.offer.toggleLoader(false);
                  that.showResultDialog("error", popupData, jsonData.message);
              }
          });
          return true;
      }
      return false;
    };

    UsernamePopup.prototype.showResultDialog = function (jsonDataStatus, popupData, errorMessage) {
        if (jsonDataStatus === "error") {
            if (popupData.isObligatory === "true") {
                this.showObligatoryPopup(popupData, errorMessage);
            } else {
                AlertDialog(errorMessage, {
                    "okButtonLabel": popupData.i18n.ERROR_BUTTON,
                    "link": "/"
                });
                $.removeCookie("change_username_voluntary_popup_seen", {path: "/"});
            }
        } else {
            AlertDialog(popupData.i18n.SUCCESS_MESSAGE, {
                "okButtonLabel": popupData.i18n.SUCCESS_BUTTON,
                "link": popupData.successUrl,
              okCallback: function () {
                  window.location.href = window.location.href;
              }
            });
        }
    };

    UsernamePopup.prototype.createContent = function (popupData, errorMessage) {
      var prefix = "";
      if (errorMessage) {
        prefix = '<div class="error-message">{{errorMessage}}</div>'.replace(/\{\{errorMessage\}\}/, errorMessage);
      }
      var html = prefix.concat('<form class="change-username-form novalidation">' +
          '<h3 class="popup-heading">{{labelText}}</h3>'+
          '<p>{{additionalInformation}}</p>' +
          '<div class="MaterialInput change-username">' +
          '<input type="text"' +
          '       onkeyup="this.setAttribute(\'value\', this.value);"' +
          '       id="username" name="username" class="validation w100"' +
          '       pattern="^[A-Za-z0-9]{5,20}$" data-validators="required pattern"' +
          '       data-validation-error-required-message="{{description}}"' +
          '       data-validation-pattern-message="{{description}}"' +
          '       autocapitalize="off" autocorrect="off"/>' +
          '<span class="bar"></span>' +
          '</div>' +
          '<p>{{description}}</p>' +
          '</form>'
        );
      return html.replace(/\{\{labelText\}\}/, popupData.i18n.LABEL_TEXT)
                 .replace(/\{\{additionalInformation\}\}/, popupData.i18n.ADDITIONAL_INFORMATION)
                 .replace(/\{\{description\}\}/g, popupData.i18n.DESCRIPTION);
    };

    UsernamePopup.prototype.showVoluntaryPopup = function (popupData) {
      var that = this;
      window.mobile.dialogs.dialogConfirm(this.createContent(popupData), {
        okButtonLabel: popupData.i18n.ACCEPT,
        cancelButtonLabel: popupData.i18n.CANCEL,
        okCallback: function () {
          return that.onOkClick(popupData);
        }
      });
      $.cookie("change_username_voluntary_popup_seen", "1", {path: "/", expires: 7});
    };

    UsernamePopup.prototype.showObligatoryPopup = function (popupData, errorMessage) {
      var that = this;
      window.mobile.dialogs.dialogConfirm(this.createContent(popupData, errorMessage), {
        okButtonLabel: popupData.i18n.ACCEPT,
        okCallback: function () {
          return that.onOkClick(popupData);
        },
        cancelButtonLabel: popupData.i18n.LOGOUT,
        cancelButtonLink: popupData.logoutUrl
      });
    };

    module.exports = function(popupData) {
      return new UsernamePopup(popupData);
    };

  });
})(modulr);
;
/* assets/src/scripts/fortuna/collapsible-box.js */
(function() {
    "use strict";

    var SELECTOR_BODY = "body";
    var SELECTOR_COLLAPSIBLE_CONTENT = ".content-box.collapsible";
    var SELECTOR_COLLAPSIBLE_CONTENT_HEADER = SELECTOR_COLLAPSIBLE_CONTENT + " > .header";
    var SELECTOR_CONTENT = ".content";

    var CLASS_CLOSED = "closed";
    var CLASS_LOADED = "loaded";
    var CLASS_LOADING = "loading";


    function hasScoreBoard() {
      var scoreboard = document.getElementById('scoreboard');

      if (scoreboard) {
        scoreboard.parentNode.classList.add("hasScoreBoard");
      }
    }

    function loadContent(elm, $parent) {
        var link = $.Link(elm.href);
        $.ftnAjax(link.ajaxify().getUrl(), {
            beforeSend: function() {
                $parent.toggleClass(CLASS_LOADING, true);
            },
            success: function(data) {
                $(SELECTOR_CONTENT, $parent).html(data);
                $parent.toggleClass(CLASS_LOADED, true);
                toggleCollapsibleBox($parent);
            },
            complete: function() {
                $parent.toggleClass(CLASS_LOADING, false);
            }
        });
    }

    function toggleCollapsibleBox($parent) {
        if ($parent.length) {
            var id = $parent[0].id;
            $parent.toggleClass(CLASS_CLOSED);
            if ($parent.hasClass(CLASS_CLOSED)) {
                $(document).fireEvent("collapsible:box:closed");
            }
            if (id) {
                var expires = $parent.attr("data-remember");
                var params = {
                    path: '/'
                };
                if ($parent.hasClass("openable")) {
                    if (!$parent.hasClass(CLASS_CLOSED)) {
                        if (!expires) {
                            params.expires = 7;
                        } else if (!isNaN(parseInt(expires))) {
                            params.expires = parseInt(expires)
                        }
                        $.cookie(id, "opened", params);
                    } else {
                        $.removeCookie(id, params);
                    }
               } else {
                    if ($parent.hasClass("closed")) {
                        if (!expires) {
                            params.expires = 7;
                        } else if (!isNaN(parseInt(expires))) {
                            params.expires = parseInt(expires)
                        }
                        $.cookie(id, "closed", params);
                    } else {
                        $.removeCookie(id, params);
                    }
                }
            }
        }
        window.dispatchEvent(new Event('initPrematchImpressions'));
    };

    function initCollapsibleBoxes() {
        $(SELECTOR_BODY).delegate("click", SELECTOR_COLLAPSIBLE_CONTENT_HEADER, function(evt) {
            evt.preventDefault();
            var $parent = $(this).parent(SELECTOR_COLLAPSIBLE_CONTENT);
            if ($parent.length) {
                if (!$parent.hasClass(CLASS_LOADING)) {
                    if (this.href && !$parent.hasClass(CLASS_LOADED) && $parent.hasClass(CLASS_CLOSED)) {
                        loadContent(this, $parent);
                    } else {
                        toggleCollapsibleBox($parent);
                    }
                }
            }
        });
    };

    $(function() {
      initCollapsibleBoxes();
      hasScoreBoard();
    });

})();
;
/* assets/src/scripts/fortuna/content-box-arranger.js */
(function($) {
	"use strict";

  function arrangeContentBoxes() {
    $(".content-box").forEach(function(item) {
      arrangeSingleContentBox(item);
    });
  };

  function arrangeSingleContentBox(box) {
		if (!box) return;
		var header = null;
		var content = null;
		for (var i = 0, l = box.children.length; i < l; i++) {
			var item = $(box.children[i]);
			if (item.hasClass("header")) {
				header = item;
			}
			if (item.hasClass("content")) {
				content = item;
			}
		}
		if (header && header.length && content && content.length) {
			var headerHeight = header.height();
			var contentTop = parseInt(content.css("top"));
			if (headerHeight > contentTop) {
				content.css({"top": headerHeight + "px"});
			}
		}
	};
	
	$(function() {
		arrangeContentBoxes();
		$(document).on("content:box:arrange", function(evt) {
		    arrangeContentBoxes();
		});
	});
	
})($);
;
/* assets/src/scripts/fortuna/crm-popup.js */
(function(modulr, $) {
    "use strict";

    modulr.define("CrmPopup", function(require, exports, module) {
        
        var MessageDialog = window.mobile.dialogs.dialogConfirm;
        var AlertDialog = window.mobile.dialogs.dialogAlert;
        
        function buildContentForMessageDialog(popupData) {
            var resContent = "";
            /*if (popupData.subject) {
                resContent += "<h3>" + popupData.subject + "</h3>";
            }*/
            if (popupData.body) {
                resContent += popupData.body;
            }
            return resContent;
        };
        
        function CrmPopup(data, afterLoginPopup, i18n) {
            this._popupData = data;
            this._i18n = i18n || {};
            this.show(afterLoginPopup);
        };
        
        CrmPopup.prototype.show = function(afterLoginPopup) {
            var that = this;
            if (this._popupData.buttonText) {
                MessageDialog(buildContentForMessageDialog(that._popupData), {
                    titleLabel: that._popupData.subject,
                    cancelButtonLabel: that._i18n.CLOSE_LABEL,
                    cancelCallback: function() {
                        afterLoginPopup.show();
                    },
                    okButtonLabel: that._popupData.buttonText,
                    okButtonLink: that._popupData.targetUrl,
                    okCallback: that._popupData.targetUrl ? function(evt) {
                        if (that._popupData.newWindow) {
                            evt.preventDefault();
                            window.open(that._popupData.targetUrl, "_blank", "");
                            afterLoginPopup.show();
                        }
                    } : function() {
                        afterLoginPopup.show();
                    },
                    specialClass: "crm-popup-message full-title"
                });
            } else {
                AlertDialog(buildContentForMessageDialog(that._popupData), {
                    titleLabel: that._popupData.subject,
                    okButtonLabel: that._i18n.CLOSE_LABEL,
                    okCallback: function() {
                        afterLoginPopup.show();
                    },
                    specialClass: "crm-popup-message full-title"
                });
            }
        };
        
        module.exports = function(data, afterLoginPopup, i18n) {
            return new CrmPopup(data, afterLoginPopup, i18n);
        }

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/deposit.js */
(function ($, modulr) {
  "use strict";

  modulr.define("deposit", function (require, exports, module) {

    var Analytics = require("analytics");

    var SELECTOR_DEPOSIT_FORM = ".deposit-form";
    var SELECTOR_QUICK_AMOUNTS = ".quick-deposit-amounts .button";
    var SELECTOR_AMOUNT_INPUT = "#id-amount";
    var SELECTOR_PAYMENT_METHOD_TYPE_CARD = "#payment-method-type-card";
    var SELECTOR_PAYMENT_METHOD_TYPE_BANK = "#payment-method-type-bank";

    function Deposit(form) {
      this.form = $(form);
      this.amountInput = $(SELECTOR_AMOUNT_INPUT);
      this.paymentMethodTypeCard = $(SELECTOR_PAYMENT_METHOD_TYPE_CARD);
      this.paymentMethodTypeBank = $(SELECTOR_PAYMENT_METHOD_TYPE_BANK);
      this.initialize();
      this.initializeEvents();
    };

    Deposit.prototype.initialize = function () {
      this.findAndTrackErrors();
    };

    Deposit.prototype.initializeEvents = function () {
      var that = this;
      this.form.delegate("click", SELECTOR_QUICK_AMOUNTS, function (evt) {
        that.fillAmountInput(evt, this);
      });

        this.form.on("submit", function() {
          if (this.form.attr("data-amount-save-disabled") === "true") {
              $.cookie("deposit_value", that.amountInput.val(), {expires: 365});
          }
          if (that.paymentMethodTypeCard.length > 0) {
            $.cookie("deposit_type", that.paymentMethodTypeCard[0].innerText, {expires: 365});
            $.cookie("deposit_method", "credit card", {expires: 365});
          }
          if (that.paymentMethodTypeBank.length > 0) {
            $.cookie("deposit_type", that.paymentMethodTypeBank[0].innerText, {expires: 365});
            $.cookie("deposit_method", "wire transfer", {expires: 365});
          }
          Analytics.Deposit.send(that.amountInput.val());
        });
    };

    Deposit.prototype.fillAmountInput = function (evt, elm) {
      if (!(evt && elm)) {
        return;
      }
      evt.preventDefault();
      var value = elm.getAttribute("data-value");
      if (value) {
        var $input = $(SELECTOR_AMOUNT_INPUT, this.form);
        if (!$input.attr("readonly")) {
          $(SELECTOR_AMOUNT_INPUT, this.form).val(value);
        }
      }
    };

    Deposit.prototype.findAndTrackErrors = function () {
      var errors = $(".error-message", this.form);
      if (errors.length) {
        Analytics.Deposit.error(errors.html());
      }
    };

    module.exports = function () {
      $(SELECTOR_DEPOSIT_FORM).forEach(function (itm) {
        new Deposit(itm);
      });
    };
  });
})($, modulr);
;
/* assets/src/scripts/fortuna/drawer.js */
(function($) {
    "use strict";

    modulr.define("drawer", function(require, exports, module) {

        var Drawer = function(drawerElem) {
            this._$drawer = $(drawerElem);

            this._$sessionDuration = $(Drawer.SESSION_TIMER_SELECTOR, this._$drawer);
            this._sessionTimer = require("session-timer");

            this._initializeEvents();
        };

        Drawer.ELEMENT_ID = 'drawer';
        Drawer.OPENER_SELECTOR = '.drawer-opener';
        Drawer.OVERLAY_SELECTOR = '#drawer .drawer-overlay';
        Drawer.SESSION_TIMER_SELECTOR = '.legal-notice .login-time span';
        Drawer.OPEN_CLASS = 'drawer-open';
        Drawer.NO_SCROLL_CLASS = 'no-scroll';
        Drawer.LOGOUT_BUTTON = window.features.apigwLoginEnabled ? 'logoutButtonApigw' : 'logoutButton';

        Drawer.prototype._initializeEvents = function() {
          var that = this;
          document.querySelector(Drawer.OVERLAY_SELECTOR).addEventListener('click', function () {
            document.getElementById(Drawer.ELEMENT_ID).classList.remove(Drawer.OPEN_CLASS);
          });
          var opener = document.querySelector(Drawer.OPENER_SELECTOR);
          if (opener) {
            opener.addEventListener('click', function () {
              that._open();
            });
          }
          var logoutButton = document.getElementById(Drawer.LOGOUT_BUTTON);
          if (logoutButton) {
            logoutButton.addEventListener('click', function () {
              window.dispatchEvent(new CustomEvent("yellowAI:before_logout", {
                detail: {
                  username: Playtech.getUserDetails.username,
                }
              }));

              // do Logout with invalidateRememberMe + disconnect to not show popup dialog
              if (window.InAppService) {
                window.InAppService.disconnect();
              }
              require("logout").doLogout();
            });
          }

        };

      Drawer.prototype._open = function() {
            this._sessionTimer.addElement(this._$sessionDuration);
            document.getElementById(Drawer.ELEMENT_ID).classList.add(Drawer.OPEN_CLASS);

        };

        Drawer.prototype._close = function() {
            document.getElementById(Drawer.ELEMENT_ID).classList.remove(Drawer.OPEN_CLASS);
        };

        module.exports = function(drawerElem) {
            return new Drawer(drawerElem);
        }

    });

})($);
;
/* assets/src/scripts/fortuna/event-detail-page.js */
(function($) {

    modulr.define("event-detail-page", function(require, exports, module) {

        const CLASS_ACTIVE = "active";
        const SELECTOR_BETS_SCROLLER = ".event-detail-wrapper .scrollable-area";
        const SELECTOR_EVENT_BOARD_TOP = ".event-detail-wrapper .event-board.top";
        const SELECTOR_EVENT_BOARD_BOTTOM = ".event-detail-wrapper .event-board.bottom";
        const CLASS_MARKET_HANDLE = "js-market-handle";
        const SELECTOR_MARKET_TARGET = ".js-market-target";

        const HEIGHT_HEADER = 46;

        function __initialize() {
            require("components.ajax-tabs").instance("#event-detail-tabs");

            Array.from(document.getElementsByClassName(CLASS_MARKET_HANDLE)).forEach(function($el) {
              $el.addEventListener('click', function() {
                $($el).closest(SELECTOR_MARKET_TARGET).toggleClass(CLASS_ACTIVE);
              });
            });

            __initializeEvents();
        }

        function __initializeEvents() {
            __initializeScroll();
        }

        function __initializeScroll() {
            $(window).on("scroll", function() {
                const top = document.scrollingElement.scrollTop;
                const fadeElems = document.querySelectorAll("[data-fade-on-scroll]");
                var i = fadeElems.length;
                var opacity = Math.max(0, ((top - (HEIGHT_HEADER / 2)) / HEIGHT_HEADER));

                while (i--) {
                    fadeElems[i].style.opacity = opacity;
                }
            });
        }

        function __openBetbuilderModal() {
          const eventId =  document.querySelector('#gtm-tracking-data').dataset.eventId;
          if(!window.mobile.ticket().isEventIdOrFixtureIdOnTicket(eventId)) {
            const betbuilderButton = document.querySelector('#modal-opener--betbuilder');
            betbuilderButton.click();
          }
        }

        function __navigateToTab() {
          const queryParams = new URLSearchParams(window.location.search);
            if(queryParams.has('tab')){
              const tabName = queryParams.get('tab');
              const tabElement = document.querySelector('.event-tabs li a[data-modal$="' + tabName + '"]') || document.querySelector('.event-tabs li a[data-tabs-anchor$="' + tabName + '"]');
              if(tabElement) {
                window.onload = function() {
                  tabElement.click();
                  if(tabName === 'betbuilder') {
                    __openBetbuilderModal()
                  }
                }
              }
            }
        }


        function EventDetailPage() {
            this.__eventBoardTop = $(SELECTOR_EVENT_BOARD_TOP);
            this.__eventBoardBottom = $(SELECTOR_EVENT_BOARD_BOTTOM);
            this.__betsHolder = $(SELECTOR_BETS_SCROLLER);
            __initialize(this);
            __navigateToTab()
        }

        module.exports = function() {
            return new EventDetailPage();
        };

    });

})($);

;
/* assets/src/scripts/fortuna/gtm.js */
(function () {
  "use strict";

  var GTM_PLATFORM = "mobile";

  var TYPE = {
    HOME: 'lobby',
    LIST: 'other',
    CATEGORY: 'category',
    CONTEST: 'league',
    ITEM: 'event',
    OTHER: 'other'
  };

  var CATEGORY = {
    PREMATCH: 'sportsbook',
    LIVE: 'live',
    EGAMES: 'egames',
    NONE: 'uncategorized'
  };

  var TRACKING = {
    PAGE_INFO: 'pageInformation',
    LOGIN_SUCCESS: 'login',
    LOGIN_FAILURE: 'login',
    ADD_BET: 'addToBetslip',
    REMOVE_BET: 'removeFromBetslip',
    PREPARE_TICKET: 'betslipPlacement',
    PLACE_TICKET: 'betslipPlacement',
    TICKET_PENDING: 'betslipAcceptance',
    TICKET_ACCEPTED: 'betslipAcceptance',
    TICKET_FAILED: 'betslipAcceptance',
    IMPRESSIONS: 'impression',
    MARCOMM_IMPRESSION: 'marcommImpression',
    MARCOMM_CLICK: 'marcommClick',
    OTP_IMPRESSION: 'otp-impression',
    OTP_CLICK: 'otp-click',
    OTP_DEPOSIT: 'otp-deposit',
    BONUS_POPUP_IMPRESSION: 'bonus_popup_impression',
    BONUS_ACCEPTED: 'bonus_accepted',
    BONUS_DECLINED: 'bonus_declined',
    BONUS_POSTPONED: 'bonus_postponed'
  };

  var STATUS = {
    FAILED: 'Failure',
    PENDING: 'Unresolved',
    SUCCESS: 'Success'
  };

  var BETBUILDER = {
    BET_VARIANT: 'Betbuilder',
  };

  var instance = null;

  var Logger = modulr.require("logger")("GTM");
  var dyEnabled;

  function getUserInfo() {
    if (window.FortunaTracking) {
      if (typeof window.FortunaTracking.fetchUserDetails === "function") {
        return window.FortunaTracking.fetchUserDetails();
      } else if (window.FortunaTracking.fetchUserDetails) {
        Logger.log("FortunaTracking.fetchUserDetails is present but not function!");
      }
    }
    return {
      accountNumber: "anonymous",
      personalId: "anonymous",
      firstName: "anonymous",
      surName: "anonymous",
      username: "anonymous"
    };
  }

  function getAmountFromCustomEvent(data) {
    var amount = '';

    if (Object.keys(data).length === 0
      || Object.keys(data.detail).length === 0
    ) {
      amount = data.detail;
    }

    return amount;
  }

  function getGame(betElm) {
    return parseMatchLabel(betElm).join("-");
  }

  function getMatchAndTeams(betElm) {
    var teams = parseMatchLabel(betElm);
    var data = {
      match: teams.join(" - ")
    };
    if (teams.length > 1) {
      data.teams = teams;
    }
    return data;
  }

  function parseMatchLabel(betElm) {
    var nameElm = $(".event-board h1.name");
    var name = [];
    if (!nameElm.length) {
      nameElm = $(betElm).parents(".event").find(".name");
    }
    if (nameElm.length) {
      name = nameElm[0].textContent.split("×");
      name = name.map(function (part) {
        return part.trim();
      });
    }
    return name;
  }

  function isDataLayerDefined() {
    return window.dataLayer && window.dataLayer.push;
  }

  function getTrackingStack() {
    return window.dataLayer && window.dataLayer.push ? window.dataLayer : null;
  }

  function getPageTypeCategory(pageCategory) {
    var page = {};
    page.type = TYPE.OTHER;
    page.category = CATEGORY.NONE;

    if(pageCategory != null && pageCategory != undefined)
    {
      if(pageCategory == 'home')
      {
        page.type = TYPE.OTHER;
        page.category = CATEGORY.NONE;
      }
      else if(pageCategory == 'live')
      {
        page.category = CATEGORY.LIVE;
        if (document.getElementById("live-events")) {
          page.type = TYPE.CATEGORY;
        }
        else if (document.getElementsByClassName("live-detail").length) {
          page.type = TYPE.ITEM;
        }
      }
      else if(pageCategory == 'prematch')
      {
        page.category = CATEGORY.PREMATCH;
        if (document.getElementsByClassName("sport-list-home").length) {
          page.type = TYPE.HOME;
        }
      }
      else if(pageCategory == 'egames')
      {
        page.type = TYPE.CATEGORY;
        page.category = CATEGORY.EGAMES;
      }
    }

    return page;
  }

  function addCategoryAndContest(tracking, betElm) {
    if (tracking) {
      var elm = document.getElementById("gtm-tracking-data");
      if (!elm && betElm) {
        while (betElm && betElm.classList && !betElm.classList.contains("events-list")) {
          betElm = betElm.parentNode;
        }
        elm = betElm;
      }
      var egames = document.getElementById("egames-overview");
      var contest = "-"
      if (elm && elm.dataset) {
        if (elm.dataset.sportName || elm.dataset.sportId) {
          var category = {
            name: elm.dataset.sportName || "-",
            id: elm.dataset.sportId || "-"
          }
          if (tracking.page) {
            tracking.page.type = TYPE.CATEGORY;
          }
        }
        if (elm.dataset.competitionName || elm.dataset.competitionId) {
          contest = {
            name: elm.dataset.competitionName || "-",
            id: elm.dataset.competitionId || "-"
          }
          if (tracking.page) {
            tracking.page.type = TYPE.CONTEST;
          }
        }
        if (elm.dataset.eventName || elm.dataset.eventSeo) {
          var item = {
            name: elm.dataset.eventName || "-",
            id: elm.dataset.eventSeo && elm.dataset.eventSeo.split("-").pop() || "-"
          }
          if (tracking.page) {
            tracking.page.type = TYPE.ITEM;
          }
        }
        if (category) {
          tracking.category = category;
        }
        if (contest) {
          if (window.location.href.includes("favorit-plus")) {
            tracking.category = contest;
            tracking.contest = category;
          } else {
            tracking.contest = contest;
          }
        }
        if (item) {
          tracking.item = item;
        }
        var elm = document.getElementById("event-board-bottom");
        if (elm) {
          var names = elm.querySelectorAll(".participants .name");
          var teams = [];
          var i = names.length;
          while (i--) {
            var name = names[i].textContent.trim();
            teams.push({
              name: name,
              id: name.toLowerCase().replace(/\s/g,"-")
            });
          }
          if (teams.length) {
            tracking.teams = teams;
          }
        }
      } else if(elm && elm.kind==="MAIN") {
        betElm = elm.elm;
        while (betElm && betElm.classList && !betElm.classList.contains("competition")) {
          betElm = betElm.parentNode;
        }
        elm = betElm;
        var name = elm.getElementsByClassName("title")[0];
        tracking.contest = {
          name: name ? name.textContent : "-",
          id: elm.id && elm.id.substring(3) || "-"
        };
        elm = elm.parentNode && elm.parentNode.parentNode && elm.parentNode.parentNode;
        if (elm && elm.classList.contains("sport")) {
          var name = elm.getElementsByClassName("title")[0];
          tracking.category = {
            name: name ? name.lastChild.nodeValue.trim() : "-",
            id: elm.id && elm.id.substring(3) || "-"
          };
        }
      } else if (egames) {
        tracking.category = {
          name: "-",
          id: egames.dataset.cnfActive_sport_id
        }
      } else {
        var sport = document.getElementById("live-sports-menu");
        var score = document.getElementById("scoreboard");
        if (sport) {
          sport = sport.getElementsByClassName("selected")[0];
          if(sport && tracking.pagePath){
            tracking.pagePath = tracking.pagePath + "/" + sport.dataset.filter;
          }
          if(tracking.event === TRACKING.ADD_BET)
          {
            if(betElm != null)
            {
              tracking.match.id = betElm.elm.dataset.matchId;

              tracking.category = {
                name: document.querySelector('#tab-' + sport.dataset.filter).querySelector('.title').innerHTML,
                id: sport.dataset.filter
              };

              tracking.contest = {
                name: betElm.elm.dataset.competitionName.toUpperCase(),
                id: betElm.elm.dataset.competitionId + '.' + sport.dataset.filter
              };
            }
          }
          else
          {
            if (sport) {
              sport = sport.dataset.filter || "-";
              tracking.category = {
                name: document.querySelector('#tab-' + sport).querySelector('.title').innerHTML,
                id: sport
              };
            }
          }
        } else if(score) {
          var scoreboardElement = score.getElementsByClassName("scoreboard " + score.dataset.sport)[0];
          var sportId;
          var sportName;
          var competitionId;
          var matchId;
          var team1;
          var team2;
          if(scoreboardElement != null)
          {
            sportId = scoreboardElement.getAttribute("data-sport-id");
            sportName = scoreboardElement.getAttribute("data-sport-name");
            competitionId = scoreboardElement.getAttribute("data-competition-id");
            matchId = scoreboardElement.getAttribute("data-match-id");
            team1 = scoreboardElement.getAttribute("data-firstTeam");
            team2 = scoreboardElement.getAttribute("data-secondTeam");
          }

          if(tracking.event === TRACKING.ADD_BET)
          {
            tracking.match = {
              name: team1 + " - " + team2,
              id: matchId
            };
          }
          else
          {
            tracking.item = {
              name: team1 + " - " + team2,
              id: matchId
            };
          }

          var teams = [];
          var team = document.getElementsByClassName("team1Name")[0];
          if (team) {
            team = team.textContent.trim() || "-";
            teams.push({
              name: team,
              id: team.toLowerCase()
            });
            team = document.getElementsByClassName("team2Name")[0];
            if (team) {
              team = team.textContent.trim() || "-";
              teams.push({
                name: team,
                id: team.toLowerCase()
              });
            }
            tracking.teams = teams;
          }

          var league = document.getElementsByClassName("league")[0];
          if (league) {
            league = league.textContent.trim() || "-";
            tracking.contest = {
              name: league.toUpperCase(),
              id: competitionId + "." + sportId
            };
          }

          tracking.category = {
            name: sportName,
            id: sportId
          };
        }
      }
    }
  }

  function addTeams(tracking, teams) {
    if (tracking && teams && teams.length) {
      tracking.teams = teams.map(function (team) {
        const teamTrimmed = team.trim();

        return {
          name: teamTrimmed || "-",
          id: teamTrimmed.replace(/\s+/g, "-").toLocaleLowerCase() || "-"
        }
      });
    }
  }

  function getBetsDetail(betElm) {
    var competitionNameEvent = $(".event-board h1.name");
    var competitionName;
    if (!competitionNameEvent.length) {
      competitionNameEvent = $(betElm).parents(".event").find(".name");
    }
    if (competitionNameEvent.length) {
      competitionName = competitionNameEvent.html()
        .replace('<span class="separator">×</span>', " v ")
        .replace(/<span .*>.*<\/span>/g, "")
        .replace(/(?:\r\n|\r|\n|\t|  )/g, "")
        .replace(/\|/g, " ");
    }

    var sportNameEvent = $(".data-sport");
    var sportName;
    if (!sportNameEvent.length) {
      var isSportName = $(betElm).parents(".content-box.collapsible.sport").find("a .title");
      if (isSportName.length) {
        sportNameEvent = $(betElm).parents(".content-box.collapsible.sport").find("a .title")[0].innerText;
        sportName = sportNameEvent.substr(sportNameEvent.lastIndexOf(" "), sportNameEvent.length).trim();
      }
      else {
        var parser = document.createElement('a');
        parser.href = location;
        var pathname = parser.pathname;
        if (pathname.includes("sazeni")) {
          pathname = pathname.substr("/sazeni/".length);
        }
        if (pathname.includes("sazky-live")) {
          pathname = pathname.substr("/sazky-live/".length);
        }
        pathname = pathname.substr(0, pathname.indexOf("/"));
        sportName = pathname;
      }
    }

    var tip = "-";
    var odd = "-";
    if (betElm && betElm.children && betElm.children.length > 1) {
      tip = betElm.children[0].textContent;
      odd = betElm.children[1].textContent;
    }

    return sportName + "_" + competitionName + "_" + tip + ":" + odd;

  }

  function decorateSecondaryCategory(primary, secondary) {
    if (primary === "home" || primary === "prematch" || primary === "myAccount") {
      return secondary;
    }
    if (primary === "live") {
      var elm = $("#live-sports-menu li.selected");
      if (elm.length) {
        return "live - " + elm.attr("data-filter").substring(3).toLowerCase();
      }
      elm = $("#scoreboard");
      if (elm.length) {
        return "live - " + elm.attr("data-sport").toLowerCase();
      }
      return "";
    }
    if (primary === "news") {
      if (secondary) {
        return document.title.split("|")[0].trim();
      }
      return null;
    }
    return secondary;
  }

  function GTM(dynamic_yields_enabled) {
    dyEnabled = dynamic_yields_enabled;
    this.initializeGlobal();
  }

  /**
   * This function returns ticket type based on select in ticket detail or ticket count in basket.
   * @return {String}
   */
  function getTicketType() {
    var ticketTypeElement = document.querySelector('#ticket #ticket-type');
    var betsCountElement = document.querySelector('#fixed-ticket-link span.value.bets-count');
    var ticketType = '-';

    if (ticketTypeElement) {
      ticketType = ticketTypeElement.value;
    } else if (betsCountElement) {
      var ticketCount = Number(betsCountElement.innerText);

      if (ticketCount <= 1) {
        ticketType = 'SOLO';
      } else if (ticketCount > 1) {
        ticketType = 'AKO';
      }
    }

    return ticketType;
  }

  function extractBetsFromTicket(type, status) {
    var ticket = document.getElementById("ticket");
    var accepted = document.querySelector("#ticket-wrapper .ticket-accepted-message");
    var stake = document.getElementById("stake-input") ? document.getElementById("stake-input").value : "";
    var win = document.querySelector(".total-prize .value .amount") ? document.querySelector(".total-prize .value .amount").innerText : "";

    // get rid of whitespace in numbers
    stake = stake.replace(/\s+/g, '');
    win = win.replace(/\s+/g, '');

    if (ticket) {
      var appendToId = ticket.dataset.kind === "LIVE" ? "L" : "M";
      var kind = CATEGORY[ticket.dataset.kind] || CATEGORY.PREMATCH;
      var generalInfo = {
        event: type,
        username: getUserInfo().username,
        ticketType: getTicketType(),
        ticketChannel: kind,
        betAmount: +stake,
        payOff: +win
      }

      if(accepted) {
        generalInfo.betslipId = accepted.dataset.ticketId;
      }
      var betlist = ticket.querySelectorAll("#ticket-content .bet");
      var n = betlist.length;
      var bets = [];
      while (n--) {
        if(betlist[n].getAttribute('data-action') !== 'Bonus' ){
          var name = betlist[n].getElementsByClassName("name");
          var bet = {
            id: betlist[n].dataset.id,
            betRatio: +betlist[n].dataset.value,
            match: {
              name: betlist[n].dataset.match,
              id: appendToId + betlist[n].dataset.country + betlist[n].dataset.matchId
            },
            contest: {
              name: betlist[n].dataset.action,
              id: appendToId + betlist[n].dataset.country + betlist[n].dataset.actionId
            },
            category: {
              name: betlist[n].dataset.sport,
              id: appendToId + betlist[n].dataset.country + betlist[n].dataset.sportId
            }
          }
          bets.unshift(bet);
        }
      }
      generalInfo.bets = bets;

      if (status) {
        generalInfo.status = status;
      }
    }
    return generalInfo || {};
  }

  GTM.prototype.initializeGlobal = function () {
    window.addEventListener("ticket:preparing", this.trackPrepareTicket.bind(this), false);
    window.addEventListener("ticket:placing", this.trackPlaceTicket.bind(this), false);

    // otp - one time payment (fast deposit)
    window.addEventListener("ticket:otp-impression", this.trackOTPImpression, false);
    window.addEventListener("ticket:otp-click", this.trackOTPClick, false);
    window.addEventListener("ticket:otp-deposit", this.trackOTPDeposit, false);

    window.addEventListener("contactform", this.trackContactForm.bind(this), false);

    window.addEventListener("promo:clicked", this.trackMarcommImpression.bind(this), false);

    window.addEventListener("bonus_popup_impression", this.trackBonusPopupRendered.bind(this), false);
    window.addEventListener("bonus_accepted", this.trackBonusPopupAccepted.bind(this), false);
    window.addEventListener("bonus_declined", this.trackBonusPopupDeclined.bind(this), false);
    window.addEventListener("bonus_postponed", this.trackBonusPopupPostponed.bind(this), false);

    if (!window.features.combinedBetslipEnabled) {
      window.addEventListener("bet:added", this.trackBetAdded.bind(this), false);
      window.addEventListener("bet:removed", this.trackBetRemoved.bind(this), false);
      window.addEventListener("ticket:prepared", this.trackTicketAccepted.bind(this), false);
      window.addEventListener("ticket:accepted", this.trackTicketAccepted.bind(this), false);
      window.addEventListener("ticket:pending", this.trackTicketUnresolved.bind(this), false);
      window.addEventListener("ticket:error", this.trackTicketError.bind(this), false);
    }

    var that = this;
    $(document).delegate("click", "#drawer .content a", function () {
      that.trackNavBar(this);
    });
    $(document).delegate("click", "#logout-button", function () {
      if (dyEnabled) {
        that.trackLogoutButtonDY();
      }
    });
    $(document).delegate("click", ".user-signpost .deposit-button", function () {
      that.trackMyAccountDepositClick(this);
    });
    $(document).delegate("click", "#live-sports-menu a", setTrackPageLevelData);
    $(document).delegate("click", "#live-sports-menu .carousel-item", setTrackPageLevelData);
    function setTrackPageLevelData () {
      var sport = null;
      try {
        sport = this.parentElement.getAttribute("data-filter").substring(3).toLowerCase();
      } catch (e) {
      }
      that.trackPageLevelData("live", (sport ? "live - " + sport : null));
    }
  };

  GTM.prototype.trackEvent = function (dataLayerObject) {
    if (isDataLayerDefined()) {
      dataLayer.push(dataLayerObject);
    }
  };

  GTM.prototype.trackBonusPopupRendered = function (e) {
    var tracker = getTrackingStack();
    var detail = e.detail;

    if (tracker && detail) {
      var tracking = {
        event: TRACKING.BONUS_POPUP_IMPRESSION,
        username: getUserInfo().username,
        bonus_code: detail.bonus_code,
        location: detail.location,
      };

      tracker.push(tracking);
    }
  };
  // Track bonus popup acceptance
  GTM.prototype.trackBonusPopupAccepted = function (e) {
    var tracker = getTrackingStack();
    var detail = e.detail;

    if (tracker && detail) {
      var tracking = {
        event: TRACKING.BONUS_ACCEPTED,
        username: getUserInfo().username,
        bonus_code: detail.bonus_code,
        location: detail.location,
      };

      tracker.push(tracking);
    }
  };

  // Track bonus popup decline
  GTM.prototype.trackBonusPopupDeclined = function (e) {
    var tracker = getTrackingStack();
    var detail = e.detail;

    if (tracker && detail) {
      var tracking = {
        event: TRACKING.BONUS_DECLINED,
        username: getUserInfo().username,
        bonus_code: detail.bonus_code,
        location: detail.location,
      };

      tracker.push(tracking);
    }
  };

  // Track bonus popup postponement
  GTM.prototype.trackBonusPopupPostponed = function (e) {
    var tracker = getTrackingStack();
    var detail = e.detail;

    if (tracker && detail) {
      var tracking = {
        event: TRACKING.BONUS_POSTPONED,
        username: getUserInfo().username,
        bonus_code: detail.bonus_code,
        location: detail.location,
      };

      tracker.push(tracking);
    }
  };

  GTM.prototype.trackContactForm = function (e) {
    var tracker = getTrackingStack();
    if (tracker && e && e.detail) {
      var data = e.detail;
      var ticketIdElement = document.getElementById("id-ticket-number");
      var matchesElement = document.getElementById("contact-form-matches");
      var tracking = {
        event: 'contactForm',
        username: data.username,
        email: data.email,
        subject: {
          id: "",
          name: data.subject,
          ticketId: ticketIdElement ? ticketIdElement.value : "",
          matches: matchesElement ? matchesElement.value : "",
        },
        content: data.content
      };
      if (data.ticket) {
        tracking.subject.ticket = data.ticket;
      }
      if (data.matches) {
        tracking.subject.matches = data.matches;
      }
      tracker.push(tracking);
    }
  };

  GTM.prototype.trackLiveImpressions = function (elements) {
    var tracker = getTrackingStack();
    if (tracker) {
      var tracking = {
        event: TRACKING.IMPRESSIONS,
        username: "anonymous",
        impressions: []
      };

      if(typeof Playtech.fetchUserDetails === 'function') {
        tracking.username = Playtech.fetchUserDetails().username;
      }

      var page = getPageTypeCategory(tracking);

      var i = elements.length;
      while (i--) {
        var element = elements[i];
        if (!element) {
          continue;
        }
        var impression = {
          channel: page.category,
          item: {
            id: element.dataset.id.includes("L") ? element.dataset.id : "L" + document.body.dataset.site + element.dataset.id,
            name: element.querySelector('.name').innerText.replace(" × ", " - ")
          },
          contest: {},
          category: {}
        };

        // GET CONTEST
        while (element && element.classList && !element.classList.contains("competition")) {
          element = element.parentNode;
        }

        var contestId = "";
        if(element.dataset.competitionid) {
          contestId += element.dataset.competitionid;
        }
        contestId += "."
        if(element.dataset.livesportid) {
          contestId += element.dataset.livesportid;
        }
        impression.contest.id = contestId;
        impression.contest.name = element.querySelector('h3').innerText;

        // GET CATEGORY
        while (element && element.classList && !element.classList.contains("sport")) {
          element = element.parentNode;
        }

        impression.category.id = element.id;
        impression.category.name = document.querySelector('#tab-' + impression.category.id).querySelector('.title').innerHTML;

        tracking.impressions.push(impression);
      }
      tracker.push(tracking);
    }
  }

  GTM.prototype.trackPrematchImpressions = function (elements) {
    var tracker = getTrackingStack();
    if (tracker) {
      var tracking = {
        event: TRACKING.IMPRESSIONS,
        username: "anonymous",
        impressions: [],
      };

      if(typeof Playtech.fetchUserDetails === 'function') {
        tracking.username = Playtech.fetchUserDetails().username;
      }

      var page = getPageTypeCategory(tracking);

      var i = elements.length;
      while (i--) {
        var element = elements[i];
        if (!element) {
          continue;
        }
        var impression = {
          channel: page.category,
          item: {
            id: element.id.includes("M") ? element.id : "M" + document.body.dataset.site + element.id,
            name: element.querySelector('.name').innerText.replace(" × ", " - ")
          },
        };

        addCategoryAndContest(impression);

        // this makes this part of prematch GTM work as expected
        // http://localhost.ifortuna.cz:8080/sazeni?filter=3hours
        if (!impression.category || impression.category === "-") {
          const eventListEl = element.parentElement;
          if (eventListEl.dataset) {
            impression.category = {
              id: eventListEl.dataset.sportId || "-",
              name: eventListEl.dataset.sportName || "-"
            };
          }
        }
        if (!impression.contest || impression.contest === "-") {
          const eventListEl = element.parentElement;
          if (eventListEl.dataset) {
            impression.contest = {
              id: eventListEl.dataset.leagueId || "-",
              name: eventListEl.dataset.competitionName || "-"
            };
          }
        }
        if (impression.category.name === "Favorit PLUS") {
          var contest = impression.contest;
          impression.contest = impression.category;
          impression.category = contest;
        }
        // this is end of the part required for prematch GTM work as expected on
        // http://localhost.ifortuna.cz:8080/sazeni?filter=3hours

        tracking.impressions.push(impression);
      }
      tracker.push(tracking);
    }
  }

  // Track when punter attempts to bet
  GTM.prototype.trackPlaceTicket = function () {
    var tracker = getTrackingStack();
    if (tracker) {
      var ticket = extractBetsFromTicket(TRACKING.PLACE_TICKET);
      tracker.push(ticket);
    }
  }

  // Track when punter only prepares ticket for later payment
  GTM.prototype.trackPrepareTicket = function () {
    var tracker = getTrackingStack();
    if (tracker) {
      var ticket = extractBetsFromTicket(TRACKING.PREPARE_TICKET);
      tracker.push(ticket);
    }
  };

  // Track when ticket was successfully accepted
  GTM.prototype.trackTicketAccepted = function (e) {
    var tracker = getTrackingStack();
    if (tracker) {
      var ticket = extractBetsFromTicket(TRACKING.TICKET_ACCEPTED, STATUS.SUCCESS);
      if (!ticket.betslipId && e.detail.id) {
        ticket.betslipId = e.detail.id;
      }
      tracker.push(ticket);

      if (ticket && dyEnabled) {
        var dataObject = [];
        dataObject.push({
          name: "betslip_complete",
          properties: {
            accountNumber: getUserInfo().personalId,
            userName: getUserInfo().username,
            currency: getUserInfo().currency,
            betAmount: ticket.betAmount,
            winAmount: ticket.payOff,
            bets: ticket.bets
          }
        });
        this.trackDY(dataObject);
      }
    }
  }

  // Track when the ticket was rejected from server
  GTM.prototype.trackTicketError = function (e) {
    var tracker = getTrackingStack();
    var error = e.detail;
    if (tracker) {
      var ticket = extractBetsFromTicket(TRACKING.TICKET_FAILED, STATUS.FAILED);
      ticket.error = {
        code: error.code || "-",
        message: error.message ? error.message.replace(/\s+/g, ' ').trim() : "-"
      };
      tracker.push(ticket);
    }
  };

  // Track when punter is waiting to get ticket approved
  GTM.prototype.trackTicketUnresolved = function (e) {
    var code = "257";
    var tracker = getTrackingStack();
    if (tracker) {
      var ticket = extractBetsFromTicket(TRACKING.TICKET_PENDING, STATUS.PENDING);
      ticket.error = {
        code: code,
        message: "-"
      };
      tracker.push(ticket);
    }
  };

  GTM.prototype.trackDepositSuccess = function (amount, currency, paymentMethod, paymentType, depositCount) {
    if (isDataLayerDefined()) {
      dataLayer.push({
        event: "deposit_success",
        accountNumber: getUserInfo().personalId,
        userName: getUserInfo().username,
        depAmount: amount,
        currency: currency,
        method: paymentMethod,
        type: paymentType,
        depositCount: depositCount + 1

      });
    }
  };

  GTM.prototype.trackDepositError = function (amount, currency, paymentMethod, paymentType, depositCount, failureReason) {
    if (isDataLayerDefined()) {
      dataLayer.push({
        event: "deposit_failed",
        accountNumber: getUserInfo().personalId,
        userName: getUserInfo().username,
        depAmount: amount,
        currency: currency,
        method: paymentMethod,
        type: paymentType,
        depositCount: depositCount,
        reason: failureReason

      });
    }
  };

  GTM.prototype.trackDepositSuccessDY = function (amount, currency, paymentMethod, paymentType, depositCount) {
    DY.API("event", {
      name: deposit_success,
      properties: {
        accountNumber: getUserInfo().personalId,
        userName: getUserInfo().username,
        depAmount: amount,
        currency: currency,
        method: paymentMethod,
        type: paymentType,
        depositCount: depositCount + 1
      }
    });
  };

  GTM.prototype.trackDepositErrorDY = function (amount, currency, paymentMethod, paymentType, depositCount, failureReason) {
    DY.API("event", {
      name: deposit_failed,
      properties: {
        accountNumber: getUserInfo().personalId,
        userName: getUserInfo().username,
        depAmount: amount,
        currency: currency,
        method: paymentMethod,
        type: paymentType,
        depositCount: depositCount,
        reason: failureReason
      }
    });
  };


  GTM.prototype.trackNavBar = function (elm) {
    if (isDataLayerDefined()) {
      var category = elm.innerHTML;
      dataLayer.push({
        event: "navBar",
        category: category,
        userID: getUserInfo().personalId
      });
    }
  };

  GTM.prototype.trackLogoutButtonDY = function () {
    var dataObject = {
      name: "logout_success",
      properties: {
        accountNumber: getUserInfo().accountNumber,
        firstName: getUserInfo().firstName,
        surName: getUserInfo().surName,
        userName: getUserInfo().username
      }
    };
    this.trackDY(dataObject);
  };

  GTM.prototype.trackMyAccountDepositClick = function () {
    if (isDataLayerDefined()) {
      dataLayer.push({
        event: "myAccountDepositclick",
        userID: getUserInfo().personalId
      });
    }
  };

  GTM.prototype.trackLimitsVerificationDY = function () {
    if (isDataLayerDefined()) {
      DY.API("event", {
        name: "verification_limits_complete",
        properties: {
          accountNumber: getUserInfo().accountNumber,
          firstName: getUserInfo().firstName,
          surName: getUserInfo().surName,
          userName: getUserInfo().username
        }
      });
    }
  };

  GTM.prototype.trackLimitsVerification = function () {
    dataLayer.push({
      event: "verification_limits_complete",
      accountNumber: getUserInfo().accountNumber,
      firstName: getUserInfo().firstName,
      surName: getUserInfo().surName,
      userName: getUserInfo().username
    });
  };

  GTM.prototype.trackMyAccountDepositClick = function () {
    if (isDataLayerDefined()) {
      dataLayer.push({
        event: "myAccountDepositclick",
        userID: getUserInfo().personalId
      });
    }
  };

  GTM.prototype.trackInfo = function (title) {
    if (isDataLayerDefined()) {
      if (!title) {
        title = document.title.split("|")[0].trim();
      }
      dataLayer.push({
        event: "info",
        userID: getUserInfo().personalId,
        article: title
      });
    }
  };

  GTM.prototype.trackSubsidise = function (direction, amount, status) {
    if (isDataLayerDefined()) {
      var directions = {
        MAIN_TO_CASINO: "sports-to-casino",
        CASINO_TO_MAIN: "casino-to-sports"
      };
      dataLayer.push({
        event: "subsidise",
        userID: getUserInfo().personalId,
        status: status,
        TransferAmount: amount,
        type: directions[direction]
      });
    }
  };

  GTM.prototype.trackBetAdded = function (e) {
    var tracker = getTrackingStack();
    var bet = e.detail;

    if (tracker && bet) {
      var play = getMatchAndTeams(bet.elm);
      var match = bet.market;
      if (match) {
        match = match.replace(/^[A-Z]+/, '');
      } else {
        match = bet.elm && bet.elm.parentNode && bet.elm.parentNode.parentNode && bet.elm.parentNode.parentNode.id;
        match = match ? match.substring(7) : "-"
      }
      var tracking = {
        event: TRACKING.ADD_BET,
        username: getUserInfo().username,
        betChannel: CATEGORY[bet.kind] || CATEGORY.PREMATCH,
        ticketType: getTicketType(),
        betId: bet.id,
        betVariant: bet.score,
        betRatio: +bet.rate,
        match: {
          name: play.match,
          id: match.includes("M") ? match : "M" + document.body.dataset.site + match
        },
        category: {},
        contest: {},
        teams: {}
      };

      addCategoryAndContest(tracking, bet);
      addTeams(tracking, play.teams);

      tracker.push(tracking);

      if (dyEnabled) {
        var dataObject = [];
        dataObject.push({
          name: "betslip_addTo",
          properties: {
            firstName: getUserInfo().firstName,
            surName: getUserInfo().surName,
            userName: getUserInfo().username,
            game: play.match,
            betRatio: bet.rate,
            ticketNumber: "1",
            bets: getBetsDetail(bet.elm)
          }
        });
        this.trackDY(dataObject);
      }
    }
  };

  GTM.prototype.betbuilderAddBet = function (trackingData) {
    const tracker = getTrackingStack();

    if (tracker) {
      const tracking = {
        event: "addToBetslip",
        username: getUserInfo().username,
        betChannel: CATEGORY.PREMATCH,
        betId: trackingData.oddsId,
        betVariant: BETBUILDER.BET_VARIANT,
        betRatio: trackingData.oddsValue,
        match: {
          name: trackingData.eventName || "",
          id: trackingData.eventSeo || "",
          type: CATEGORY.PREMATCH || "",
        },
        category: {
          name: trackingData.sportName || "",
          id: trackingData.sportId || "",
        },
        contest: {
          name: trackingData.competitionName || "",
          id: trackingData.competitionId || "",
        },
      };

      var teams = [];
      if (trackingData['eventName']) {
        teams = trackingData['eventName'].split('-');
      }

      addTeams(tracking, teams);

      tracker.push(tracking);
    }
  };

  GTM.prototype.trackOTPImpression = function (data) {
    var tracker = getTrackingStack();

    if (tracker) {
      tracker.push({
        event: TRACKING.OTP_IMPRESSION,
        amount: getAmountFromCustomEvent(data),
      });
    }
  };

  GTM.prototype.trackOTPClick = function (data) {
    var tracker = getTrackingStack();

    if (tracker) {
      tracker.push({
        event: TRACKING.OTP_CLICK,
        amount: getAmountFromCustomEvent(data),
      });
    }
  };

  GTM.prototype.trackOTPDeposit = function (data) {
    if (Object.keys(data).length === 0
      || Object.keys(data.detail).length === 0
    ) {
      return;
    }

    var trackingInfo = data.detail;

    var tracker = getTrackingStack();
    var tracking = {
      event: TRACKING.OTP_DEPOSIT,
      status: STATUS[trackingInfo.status] || '',
    };

    if (trackingInfo.errorMessage) {
        tracking.errorMessage = trackingInfo.errorMessage;
    }

    if (tracker) {
      tracker.push(tracking);
    }
  };

  GTM.prototype.trackBetRemoved = function (e) {
    var tracker = getTrackingStack();
    var ids = e.detail.id.split(',');
    if (tracker && ids) {
      var tracking = {
        event: TRACKING.REMOVE_BET,
        username: getUserInfo().username,
        betId: ids.map(String)
      }
      tracker.push(tracking);
      return tracking;
    }
  }

  GTM.prototype.trackPageLevelData = function (pageCategory, secondaryCategory) {
    if (window.isRedesignPage) {
      return
    }
    var tracker = getTrackingStack();
    var page = getPageTypeCategory(pageCategory);
    if (tracker) {
      var tracking = {
        event:  TRACKING.PAGE_INFO,
        username: getUserInfo().username,
        page: page,
        pagePath: window.location.pathname
      };
      addCategoryAndContest(tracking);
      tracker.push(tracking);
    }
  };

  GTM.prototype.trackDY = function (dataObject) {
    if (typeof DY !== "undefined" && DY.API) {
      DY.API("event", dataObject);
    }
  };

  GTM.prototype.trackLogin = function (data) {
    var tracker = getTrackingStack();
    if (tracker && data && data.status) {
      var idUsername = document.getElementById("id-username");
      var failed = data.status !== "Success";
      var tracking = {
        event: failed ? TRACKING.LOGIN_FAILURE : TRACKING.LOGIN_SUCCESS,
        username: data.user || data.userId || idUsername && idUsername.value || "-",
        status: failed ? STATUS.FAILED : STATUS.SUCCESS
      };
      if (failed) {
        tracking.error = {
          code: data.code || "-",
          message: data.message || data.playerMessage || "-"
        };
      }
      tracker.push(tracking);
    }
  };

  GTM.prototype.trackDYLogin = function () {
    var dataObject = [];
    dataObject.push({
      name: "login_success",
      properties: {
        accountNumber: getUserInfo().accountNumber,
        firstName: getUserInfo().firstName,
        surName: getUserInfo().surName,
        userName: getUserInfo().username
      }
    });
    this.trackDY(dataObject);
  };

  GTM.prototype.trackMarcommImpression = function (e) {
    var tracker = getTrackingStack();
    var detail = e.detail;

    if (tracker && detail) {
      var tracking = {
        event: !detail.clicked ? TRACKING.MARCOMM_IMPRESSION : TRACKING.MARCOMM_CLICK,
        username: getUserInfo().username,
        title: detail.title,
        id: detail.id,
        placement: detail.placement,
        link: detail.link,
        channel: detail.channel
      };

      tracker.push(tracking);
    }
  };

  modulr.define("gtm", function (require, exports, module) {

    module.exports = function (dynamic_yields_enabled) {
      if (!instance) {
        instance = new GTM(dynamic_yields_enabled);
      }
      return instance;
    };

  });

})();

;
/* assets/src/scripts/fortuna/infinite-scrolling.js */
(function($) {
    "use strict";

    modulr.define("infinite-scroll", function(require, exports, module) {

        var LoggedOutNotification = require("logged-out-notification");
        var Formatters = window.mobile.formatters;

        var CLASS_LOADER_HIDDEN = "vhidden";

        var InfiniteScroll = function(pagingElem, processFn) {
            this._$pagingElem = $(pagingElem);
            this._$scrollableContent = this._findScrollableContent();
            this._processFn = processFn;
            this._actualPage = 0;
            this._togglePagingElementToAutoLoading();
            this._initializeEvents();
            this._loadIfInViewport();
        };

        InfiniteScroll.prototype._BOTTOM_LOAD_OFFSET = 200;

        InfiniteScroll.prototype._findScrollableContent = function() {
            if ($("html").hasClass("ui-windows_mobile") || $("html").hasClass("ui-linear-layout")) {
                return $(window);
            } else {
                return this._$pagingElem.parent(".content")
            }
        };

        InfiniteScroll.prototype._initializeEvents = function() {
            var that = this;
            this._$scrollableContent.on("scroll.infinitePaging", $.debounce(function(evt) {
                var target = that._$scrollableContent[0] === window ? document.body : evt.target;
                if (target.scrollHeight - target.scrollTop - that._BOTTOM_LOAD_OFFSET <= target.clientHeight) {
                    that._loadNextPage();
                }
            }, 300));
            $(document).on("collapsible:box:closed", function() {
                that._loadIfInViewport();
            });        };

        InfiniteScroll.prototype._loadNextPage = function() {
            this._loadPage(this._actualPage + 1);
        };

        InfiniteScroll.prototype._loadPage = function(pageNumber) {
            var that = this;
            $.ftnAjax({
                method: "GET",
                url: $.Link(window.location.href).appendOrReplaceParam("page" , pageNumber).ajaxify().getUrl(),
                success: function(data) {
                    if (data.indexOf("<!-- USER_NOT_LOGGED -->") > -1) {
                        LoggedOutNotification().show();
                        return;
                    }
                    that._actualPage = pageNumber;
                    that._processResult(data);
                    that._loadIfInViewport();
                },
                error: function(xhr, textStatus) {
                    that._processLoadingError(textStatus);
                }
            });
        };

        InfiniteScroll.prototype._togglePagingElementToAutoLoading = function() {
            this._$pagingElem.toggleClass("auto-loading", true);
        };

        InfiniteScroll.prototype._hidePagingElement = function() {
            this._$pagingElem.toggleClass(CLASS_LOADER_HIDDEN, true);
        };

        InfiniteScroll.prototype._processResult = function(data) {
            if (this._processFn && typeof this._processFn === "function") {
                this._processFn(data);
            } else {
                if (this._processFn && typeof this._processFn === "string") {
                    switch (this._processFn.toUpperCase()) {
                        case "EVENTS_LIST":
                        	this._processEventsList(data);
                        	break;
                        case "TRANSACTION_HISTORY_BY_DAYS":
                            this._processResultTransactionHistoryByDay(data);
                            break;
                        case "TRANSACTION_HISTORY_FILTERED":
                            this._processResultTransactionHistoryFiltered(data);
                            break;
                        case "PREVIEWS":
                            this._processPreviews(data);
                            break;
                        case "NEWS":
                            this._processNews(data);
                            break;
                        default:
                            this._processResultDefault(data);
                    }
                } else {
                    this._processResultDefaultDefault(data);
                }
            }
            if (data.indexOf("<!-- PAGING:END -->") > -1) {
                this._hidePagingElement();
                this._$scrollableContent.off("scroll.infinitePaging");
            }
        };

        InfiniteScroll.prototype._processResultDefault = function(data) {
            var $data = $(data);
            var parent = this._$pagingElem[0].parentNode;
            $data.forEach(function(item) {
                parent.insertBefore(item, this._$pagingElem[0]);
            }, this);
        };

        InfiniteScroll.prototype._processResultTransactionHistoryByDay = function(data) {
            var $data = $(data);
            var thc = $("#transaction-history-content");
            $data.forEach(function(item) {
                var id = item.getAttribute("id");
                var existingElement = document.getElementById(id);
                if (existingElement) {
                    // Add items
                    var table = $(".content table tbody", existingElement);
                    var countElm = $(".transactions-count", existingElement);
                    var sumElm = $(".daily-balance-value", existingElement);
                    var count = parseInt(countElm[0].innerText.replace(/\s/g, ""));
                    var sum = parseFloat(sumElm[0].innerText.replace(/\s/g, ""));
                    $(".content table tbody tr", item).forEach(function(item) {
                        if (!document.getElementById(item.id)) {
                            table.append(item);
                            count++;
                            if (item.getAttribute("data-direction") === "POSITIVE") {
                                sum += parseFloat(item.children[2].innerHTML);
                            } else if (item.getAttribute("data-direction") === "NEGATIVE") {
                                sum -= parseFloat(item.children[2].innerHTML);
                            }
                        }
                    });
                    countElm.html(count);
                    sumElm.html(Formatters.moneyFormat(sum));
                } else {
                    thc[0].appendChild(item);
                }
            });
        };

        InfiniteScroll.prototype._processResultTransactionHistoryFiltered = function(data) {
            var $data = $("<table>" + data + "</table>");
            var filteredTable = $("#transaction-history-table tbody");
            if (!filteredTable.length) {
                filteredTable = $("#transaction-history-table");
            }
            $("tr", $data).forEach(function(item) {
                filteredTable[0].appendChild(item);
            });
        };

        InfiniteScroll.prototype._processPreviews = function(data) {
            var $data = $("<ul>" + data + "</ul>");
            var list = $("#page-content .previews .previews-list");
            $("li", $data).forEach(function(item) {
                list.append(item);
            });
        };

        InfiniteScroll.prototype._processNews = function(data) {
            var $data = $("<ul>" + data + "</ul>");
            var list = $("#page-content .news .news-list");
            $("li", $data).forEach(function(item) {
                list.append(item);
            });
        };

        InfiniteScroll.prototype._processEventsList = function(data) {
        	var $data = $(data);
        	var eventsList = $(".events-list");
        	$("li", $data).forEach(function(item) {
        		eventsList.append(item);
        	});
        };

        InfiniteScroll.prototype._processLoadingError = function(status) {

        };

        InfiniteScroll.prototype._loadIfInViewport = function() {
            var that = this;
            if (this._$pagingElem.isInViewport() && !this._$pagingElem.hasClass(CLASS_LOADER_HIDDEN)) {
                this._loadNextPage();
            }
        };

        module.exports = function(pagingElem, processFn) {
            return new InfiniteScroll(pagingElem, processFn);
        };

    });

})($);
;
/* assets/src/scripts/fortuna/input-focuser.js */
(function($) {
    "use strict";
    
    function onInputFocus(input) {
        var $input = $(input);
        if ($input.prop("readOnly")) {
            $input.fireEvent("blur");
            return;
        }
        window.setTimeout(function() {
            if (input.type === "number" && !input.disabled && !input.readOnly) {
                selectText(input);
            }
            $input.attr("data-focused", "true");
        }, 50);
        $input.on("blur.inputfocuser", function() {
            $input.attr("data-focused", null);
            $input.off("blur.inputfocuser");
        });
    };
    
    function selectText(input) {
        if (input.getAttribute("data-focused") === "true") return;
        var selected = false;
        if (typeof input.setSelectionRange === "function") {
            try {
                input.setSelectionRange(0, input.value.length);
                selected = true;
            } catch (e) {}
        }
        if (!selected && typeof input.select === "function") {
            input.select();
        }
    }
    
    $(function() {
        $("body").delegate("focusin", "input", function() {
            onInputFocus(this);
        });
    });
    
})($);
;
/* assets/src/scripts/fortuna/keep-alive.js */
var keepAlive = (function($) {

  var REAL_MODE = 1,
    REQUEST_PERIOD = 300000, // keep alive iapi request period in ms
    COOKIE_NAME = "keep-alive",
    API_GW_ENDPOINT = '/_keep-alive-gm',
    apigwTimeoutId,
    user = {
      username: null,
      lastModifiedDate: null,
      loggedIn: false
    };

  // function for renew / creation of keep-alive cookie
  var renewCookie = debounce(function() {
    document.cookie = COOKIE_NAME + "=" + getTimeStamp() + ";path=/";
  }, 250);

  // Self explaining function that generates current timestamp
  function getTimeStamp() {
    var date = new Date();
    return date.getTime();
  }

  // function where all event listeners that triggers keep alive are initiated
  function initializeListeners() {
    // odd button that adds bet to a bet slip
    $(document).delegate("click", ".odd-button", function () {
      renewCookie()
    });

    // watches typing inside every textarea
    $(document).delegate("keypress", "textarea", function () {
      renewCookie()
    });

    // watches typing inside every input
    $(document).delegate("keypress", "input", function () {
      renewCookie()
    });

    // every link on website
    $(document).delegate("click", "a[href]", function () {
      renewCookie()
    });

    // scroll event listener
    var scrollElement = $(".scrollable-area");
    for (var i = 0; i < scrollElement.length; i++) {
      scrollElement[i].addEventListener('scroll', function () {
        renewCookie()
      });
    }
  }

  // debouncer so we don't spam the cookie creation
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  // periodic timer that sends keep alive messages. To modify time period look to constants at the top of the file.
  function keepAliveTimer(timer) {
    setTimeout(function () {
      var timeFromLastAction = getTimeStamp() - getCookieValue(COOKIE_NAME);
      window.iapiKeepAlive(REAL_MODE, timeFromLastAction);
      keepAliveTimer(REQUEST_PERIOD);
    }, timer);
  }

  function keepAliveApiGwTimer(timer) {
   apigwTimeoutId = setTimeout(function () {
      var timeFromLastAction = getTimeStamp() - getCookieValue(COOKIE_NAME);
      apigw_keepAliveCall(timeFromLastAction);
      keepAliveApiGwTimer(REQUEST_PERIOD);
    }, timer);
  }

  /**
   * Call "keep alive" endpoint on APIGW in order to have consistent sessions
   * @param timeFromLastAction
   */
  function apigw_keepAliveCall(timeFromLastAction) {
    fetch(getKeepAliveUrl(timeFromLastAction), {
      credentials: 'include',
      method: 'GET'
    }).then(function (response) {
      var username = null;
      if (!response.ok) {
        catchError(response);
      } else {
        username = window.Playtech.getUserDetails.username;
        updateUser(username, true);
      }
    }).catch(catchError);
  }

  function catchError( error ){
    updateUser(null, false);
    clearTimeout(apigwTimeoutId);
  }

  /**
   * Generate keep alive endpoint url with "millisFromLastAction" param
   * @param timeFromLastAction
   * @returns {string}
   */
  function getKeepAliveUrl(timeFromLastAction) {
    return window.APIGW_URL +
      API_GW_ENDPOINT + '?' +
      new URLSearchParams({
        millisFromLastAction: timeFromLastAction
      })
  }

  /**
   * Update user
   * @param username
   * @param loggedIn
   */
  function updateUser(username, loggedIn) {
    user.username = username;
    user.lastModifiedDate = new Date();
    user.loggedIn = loggedIn;
  }

  function getData (){
      return user;
  }

  //exposing getdata as getLoggedInUserData to window object
  window.getLoggedInUserData = getData;

  // cookie parser to get timestamp of last user interaction
  function getCookieValue(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  (function(){
    function keepAliveGMInit() {
      renewCookie();
      keepAliveTimer(REQUEST_PERIOD);
      if (window.features.apigwLoginEnabled) {
        keepAliveApiGwTimer(REQUEST_PERIOD);
      }
      updateUser(window.Playtech.getUserDetails.username, true);
      window.postMessage({type: 'FTNUserAlreadyLoggedIn', payload: {username: window.Playtech.getUserDetails.username}}, "*");
      initializeListeners();
    }

    if (typeof window.Playtech === 'undefined') {
      window.addEventListener('message', function (event) {
        if (event.data.type !== 'FTN_getUserDetails' || !window.Playtech.getUserDetails.loggedin) {
          return;
        }
        // Timeout that prevents running certain functions before requested parts of page are loaded
        setTimeout(function () {
          keepAliveGMInit();
        }, 15);
      });
    } else if (window.Playtech.getUserDetails.loggedin) {
      keepAliveGMInit();
    }
  }());

  return {
    renewCookie: renewCookie
  };
})($);
;
/* assets/src/scripts/fortuna/logged-out-notification.js */
(function($) {
    "use strict";
    
    modulr.define("logged-out-notification", function(require, exports, module) {
        
        var AlertDialog = window.mobile.dialogs.dialogAlert;
        
        var ID_COMMON_CONFIGURATION = "common-configuration";
        
        function LoggedOutNotification() {
            this.i18n = this.loadTranslations();
        };
        
        LoggedOutNotification.prototype.loadTranslations = function() {
            var configurationElement = document.getElementById("common-configuration");
            if (!configurationElement) return {};
            return {
                title: configurationElement.getAttribute("data-lon-title"),
                message: configurationElement.getAttribute("data-lon-message"),
                loginurl: configurationElement.getAttribute("data-lon-login-url")
            };
        };
        
        LoggedOutNotification.prototype.show = function() {
            var that = this;
            AlertDialog(this.i18n.message, {
                titleLabel: that.i18n.title,
                okCallback: function() {
                    that.redirect();
                }
            });
        };
        
        LoggedOutNotification.prototype.redirect = function() {
            window.location.href = this.i18n.loginurl;
        };
        
        module.exports = function() {
            return new LoggedOutNotification();
        }
        
    });
})($);
;
/* assets/src/scripts/fortuna/login.js */
(function(modulr, $) {
    "use strict";

    modulr.define("login", function(require, exports, module) {

        var CLASS_HIDDEN = "hidden";
        var SELECTOR_LOGIN_FORM = "#login";
        var SELECTOR_LOGIN_AREA = SELECTOR_LOGIN_FORM + " .login";
        var SELECTOR_TOGGLEABLE_PASSWORDS = SELECTOR_LOGIN_FORM + " .show-password";
        var SELECTOR_ERROR_MESSAGE = SELECTOR_LOGIN_FORM + " .error-message";
        var REMEMBER_ME_USERNAME_KEY = "username_rememberMe";

        function initialize(loginInstance) {
          loginInstance._form.on("submit", function(evt) {
            loginInstance._passwordToggler.hidePassword();
          });
        }
        function removeRememberMeAndDoLogout() {
          if ($.cookie(REMEMBER_ME_USERNAME_KEY)) {
            var allSessions = 0;
            var realMode = 1;
            var behaviour = "root";

            $.removeCookie(REMEMBER_ME_USERNAME_KEY, {path: "/", domain: getDomain()});
            iapiLogout( allSessions, realMode, behaviour, 1);
          }
        }

        function getDomain() {
          var hostname = location.hostname;
          var parts = hostname.split('.');
          if (parts.length > 2) {
            parts = parts.slice(-2);
          }
          var domain = parts.join('.');
          return '.'.concat(domain);
        }


      function Login(props) {
            this._form = $(SELECTOR_LOGIN_FORM);
            this._passwordToggler = require("password-toggler")($(SELECTOR_TOGGLEABLE_PASSWORDS));
            this._i18n = props.i18n;
            this._changePasswordUrl = props.changePasswordUrl;
            initialize(this);
            removeRememberMeAndDoLogout();
        }

        Login.prototype.showError = function(error) {
            if (error) {
              if(error.errorCode === 2 || error.errorCode === 104) {
                var changePasswordPopupDescription = "<h3>" + this._i18n.CHANGE_PASSWORD_POPUP_TITLE + "</h3>" + "<p>"+ this._i18n.CHANGE_PASSWORD_POPUP_TEXT + "</p>";
                window.mobile.dialogs.dialogConfirm(changePasswordPopupDescription, {
                  specialClass: "change-password-dialog",
                  okButtonLabel: this._i18n.CHANGE_PASSWORD_POPUP_OK_BTN,
                  cancelButtonLabel: this._i18n.CHANGE_PASSWORD_POPUP_CANCEL_BTN,
                  cancelCallback: function() {
                    window.location.href = this._changePasswordUrl;
                  }.bind(this)
                });
              } else {
                var errorMessage = $(SELECTOR_ERROR_MESSAGE);
                if (!errorMessage.length) {
                  $(SELECTOR_LOGIN_AREA).prepend('<div class="error-message hidden"></div>');
                  errorMessage = $(SELECTOR_ERROR_MESSAGE);
                }
                var errorText = error.actions && error.actions.PlayerActionShowMessage;
                // we expect only one item in the array based on Playtech specification
                errorText = errorText && errorText[0] && errorText[0].message || error.playerMessage;
                errorMessage.html(errorText);
                errorMessage.toggleClass(CLASS_HIDDEN, false);
              }
                var GTM = require("gtm")();
                if (GTM) {
                    GTM.trackLogin({
                        status: "Failed",
                        code: error.errorCode,
                        message: error.errorText,
                        playerMessage: error.playerMessage
                    });
                }
            }
        };

        Login.prototype.hideError = function() {
            $(SELECTOR_ERROR_MESSAGE).toggleClass(CLASS_HIDDEN, true);
        };

      Login.prototype.showUnhandledError = function(error) {
        if (error) {
          var errorMessage = $(SELECTOR_ERROR_MESSAGE);
          if (!errorMessage.length) {
            $(SELECTOR_LOGIN_AREA).prepend('<div class="error-message hidden"></div>');
            errorMessage = $(SELECTOR_ERROR_MESSAGE);
          }
          var errorText = error;
          errorMessage.html(errorText);
          errorMessage.toggleClass(CLASS_HIDDEN, false);
          var GTM = require("gtm")();
          if (GTM) {
            GTM.trackLogin({
              status: "Failed",
              code: 500,
              message: error,
              playerMessage: error
            });
          }
        }
      };

        module.exports = function(props) {
            return new Login(props);
        };

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/logout.js */
(function(modulr) {
  "use strict";

  modulr.define("logout", function(require, exports, module) {

    var FTN_LOGOUT_URL =  window.APIGW_URL + "/_logout-gm";
    var REMEMBER_ME_USERNAME_KEY = "username_rememberMe";

    module.exports = {
      doLogout: function() {
        $.removeCookie(REMEMBER_ME_USERNAME_KEY, {domain: this.getDomain(), path: "/"});

        iapiSetCallout(iapiCALLOUT_LOGOUT, this.calloutLogout());
        // allSession = 0, behaviour = root, realMode=1 , invalidateRemembeMe =1
        iapiLogout(0, 1, "root", 1);
      },

      getDomain:function() {
        var hostname = location.hostname;
        var parts = hostname.split('.');
        if (parts.length > 2) {
          parts = parts.slice(-2);
        }
        var domain = parts.join('.');
        return '.'.concat(domain);
      },

      calloutLogout: function() {
        return function(response) {
          window.dispatchEvent(new CustomEvent("yellowAI:logout"));
          var form = document.createElement("form");
          form.method = "POST";
          form.action = FTN_LOGOUT_URL;
          document.body.appendChild(form);
          form.submit();
        }
      }
    };
  })
})(modulr);
;
/* assets/src/scripts/fortuna/mobile_betting.js */
(function(modulr) {
    modulr.define('mobile_betting', function(require, exports, module) {
        var DEFAULT_OPTIONS = {
            i18n: {
                openANDROIDfromIOSWarning: "You are trying to open ANDROID page from iOS. Continue?",
                openIOSfromANDROIDWarning: "You are trying to open iOS page from ANDROID. Continue?",
                unsupportedDeviceWarning: "You have unsupported device. Would you like to continue?"
            }
        };

        function Control(el, options) {
            this.$el = $(el);
            this.options = $.extend({}, DEFAULT_OPTIONS, options || {});
            this._bind();
        }

        var ControlProto = Control.prototype;

        ControlProto._bind = function() {
            var that = this;
            this.$el.delegate('click', '.button.download', function(event) {
                that._handleDownloadButton(event, this);
            });
        };

        ControlProto._handleDownloadButton = function(event, button) {
            var os = button.parentNode.getAttribute('data-os');

            switch (os) {
                case 'android':
                    if ($.BrowserDetect.isIOS()) {
                        if (!window.confirm(this.options.i18n.openANDROIDfromIOSWarning)) {
                            event.preventDefault();
                        }
                    } else if (!$.BrowserDetect.isAndroid()) {
                        // warn - you have unsupported device, would you like to continue
                        if (!window.confirm(this.options.i18n.unsupportedDeviceWarning)) {
                            event.preventDefault();
                        }
                    }
                    break;
                case 'ios':
                    if ($.BrowserDetect.isAndroid()) {
                        if (!window.confirm(this.options.i18n.openIOSfromANDROIDWarning)) {
                            event.preventDefault();
                        }
                    } else if (!$.BrowserDetect.isIOS()) {
                        // warn - you have unsupported device, would you like to continue
                        if (!window.confirm(this.options.i18n.unsupportedDeviceWarning)) {
                            event.preventDefault();
                        }
                    }
                    break;
                default:
                    event.preventDefault();
                    // scroll to section for others
                    var $othersSection = $('#mobile_betting_others');
                    var $scrollable = $("#page main");
                    var scrollable = $scrollable[0];
                    if (scrollable) {
                        scrollable.scrollTop = Math.max(0, (($othersSection.offset().y || 0) - $scrollable.offset().y));
                    }
            }
        };

        module.exports = Control;
    });
})(modulr);

;
/* assets/src/scripts/fortuna/mobile-file-input.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("mobile-file-input", function(require, exports, module) {
        
        var SELECTOR_MOBILE_FILE_INPUT = ".mobile-file-input";
        var SELECTOR_INPUT = "input[type=file]";
        var SELECTOR_FILE_NAME = ".file-name";
        var SELECTOR_CLEAR = ".clear";
        
        var CLASS_SET = "set";
        
        function MobileFileInput(rootElm) {
            this._root = $(rootElm);
            this._input = $(SELECTOR_INPUT, this._root);
            this._fileName = $(SELECTOR_FILE_NAME, this._root);
            this._clearButton = $(SELECTOR_CLEAR, this._root);
            this._initializeEvents();
        };
        
        MobileFileInput.prototype._initializeEvents = function() {
            var that = this;
            this._input.on("change", function() {
                that._onValueSet();
            });
            this._clearButton.on("click", function(evt) {
                evt.preventDefault();
                that._input.val(null);
                that._onValueSet();
            });
        };
        
        MobileFileInput.prototype._onValueSet = function() {
            if (this._input.val()) {
                var name = this._input.val().split(/[\\|\/]/);
                name = name[name.length - 1];
                this._fileName.html(name);
                this._root.toggleClass(CLASS_SET, true);
            } else {
                this._fileName.html("");
                this._root.toggleClass(CLASS_SET, false);
            }
        };
        
        module.exports = {
            factory: function() {
                $(SELECTOR_MOBILE_FILE_INPUT).forEach(function(itm) {
                    new MobileFileInput(itm);
                });
            },
            newInstance: function(elm) {
                return new MobileFileInput(elm);
            }
        }
        
    });
})(modulr, $);
;
/* assets/src/scripts/fortuna/modernizr.tests.js */
(function(Modernizr, $) {
    Modernizr.addTest("input-date", function() {
        return Modernizr.inputtypes.date;
    });
    Modernizr.addTest("ANDROID", function() {
        return $.BrowserDetect.isAndroid();
    });
    Modernizr.addTest("WINDOWS_MOBILE", function() {
        return $.BrowserDetect.isWindows();
    });
    Modernizr.addTest("SAFARI_MOBILE_15", function() {
        return $.BrowserDetect.isSafariMobile15();
    });
    Modernizr.addTest('pointerevents', function(){
        var element = document.createElement('x');
        element.style.cssText = 'pointer-events:auto';
        return element.style.pointerEvents === 'auto';
    });
    Modernizr.addTest('linear-layout', function() {
    	return ((navigator.userAgent.indexOf("534.30") > -1 || navigator.userAgent.indexOf("533.1") > -1) && $.BrowserDetect.isAndroid());
    });
    Modernizr.addTest('iframe', function() {
    	return window !== window.top;
    });
})(Modernizr, $);
;
/* assets/src/scripts/fortuna/namespace.js */
/** Global namespace */
window.F = window.F || {};
;
/* assets/src/scripts/fortuna/password-toggler.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("password-toggler", function(require, exports, module) {
        
        function PasswordToggler(element, onToggle, inputType) {
            this.element = $(element);
            this.onToggle = onToggle;
            this.inputType = inputType || "text";
            this.initialize();
        };
        
        PasswordToggler.prototype.initialize = function() {
            var that = this;
            this.element.on("click", function(evt) {
                evt.preventDefault();
                that.togglePassword();
            });
        };
        
        PasswordToggler.prototype.showPassword = function($input) {
            if (!$input) {
                $input = $(this.element.attr("rel"));
            }
            $input.attr("type", this.inputType);
            if ($input.attr("autocomplete") === "off") {
                $input.attr("data-keep-autocomplete", "true");
            } else {
                $input.attr("autocomplete", "off");
            }
            this.changeIcon(this.element, $input);
        };
        
        PasswordToggler.prototype.hidePassword = function($input) {
            if (!$input) {
                $input = $(this.element.attr("rel"));
            }
            $input.attr("type", "password");
            if ($input.attr("data-keep-autocomplete") !== "true") {
                $input.attr("autocomplete", null);                  
            }
            this.changeIcon(this.element, $input);
        };
        
        PasswordToggler.prototype.togglePassword = function(onToggle) {
            var $input = $(this.element.attr("rel"));
            if ($input.attr("type") === "password") {
                this.showPassword($input);
            } else {
                this.hidePassword($input);
            }
            if (onToggle && typeof onToggle === "function") {
                onToggle($input, this.element);
            }
            if (this.onToggle && typeof this.onToggle === "function") {
                this.onToggle($input, this.element);
            }
        };
        
        PasswordToggler.prototype.changeIcon = function(iconElm, input) {
            var isPassword = input.attr("type") === "password";
            $(iconElm).toggleClass("eye", isPassword).toggleClass("eye-disabled", !isPassword);
            
        };
        
        module.exports = function(element, onToggle) {
            return new PasswordToggler(element, onToggle);
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/payment-card-renew-dialog.js */
(function(modulr, $) {
    "use strict";

    modulr.define("payment-card-renew-dialog", function(require, exports, module) {

        var MessageDialog = window.mobile.dialogs.dialogMessage;
        var AlertDialog = window.mobile.dialogs.dialogAlert;
        var ConfirmDialog = window.mobile.dialogs.dialogConfirm;
        var SELECTOR_DIALOG_OPENER = ".js-renew-card-form-opener";

        var initialized = false;

        function init() {
            $(function() {
                if (!initialized) {
                    $(SELECTOR_DIALOG_OPENER).on("click", function(evt) {
                        openDialog(this, evt);
                    });
                    initialized = true;
                }
            });
        };

        function openDialog(elm, evt) {
            if (elm.nextElementSibling && elm.nextElementSibling.tagName === "DIV" && elm.nextElementSibling.className.indexOf("hidden") > -1) {
                if (evt) {
                    evt.preventDefault();
                }
                initializeDialog(MessageDialog(elm.nextElementSibling.innerHTML.replace(/\{\{##}}/g, "<").replace(/\{\{\/##}}/g, ">"), {
                    closeOnShim: false,
                    specialClass: "payment-card-renew-dialog"
                }));
            }
        };

        function initializeDialog(dialog) {
            $("form", dialog._html).on("submit", function(evt) {
                evt.preventDefault();
                var form = this;
                var url = this.action;
                if (url) {
                    url = $.Link(url).ajaxify().getUrl();
                    var data = $(form).serializeMap();
                    doAjaxRequest(url, data);
                    dialog.hideAndDestroy();
                }
            });
        };

        function doAjaxRequest(url, data) {
            $.ftnAjax(url, {
                method: "POST",
                data: data,
                beforeSend: function() {
                    window.mobile.offer.toggleLoader(true);
                },
                success: function(ajaxData) {
                    var jsonData = JSON.parse(ajaxData);
                    if (jsonData) {
                        if (jsonData.result === "ok") {
                            AlertDialog(jsonData.message, {
                                titleLabel: jsonData.title,
                                okButtonLabel: jsonData.closeLabel,
                                okCallback: function() {
                                    if (data.activate) {
                                        $.ftnAjax(data.activate, {
                                            method: "POST",
                                            data: {
                                                id: data.id
                                            },
                                            beforeSend: function() {
                                                window.mobile.offer.toggleLoader(true);
                                            },
                                            success: function(ajaxData) {
                                                if (data.redirect) {
                                                    window.location.href = data.redirect;
                                                } else {
                                                    window.location.href = window.location.href;
                                                }

                                            }
                                        });
                                    } else {
                                        window.mobile.offer.toggleLoader(true);
                                        window.location.href = window.location.href;
                                    }

                                }

                            });
                        } else {
                            ConfirmDialog(jsonData.message, {
                                titleLabel: jsonData.title,
                                okButtonLabel: jsonData.retryLabel,
                                okCallback: function() {
                                    doAjaxRequest(url, data);
                                },
                                cancelButtonLabel: jsonData.closeLabel
                            });
                        }
                    }
                },
                complete: function() {
                    window.mobile.offer.toggleLoader(false);
                }
            });
        };

        module.exports = init;

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/preview-article.js */
(function(modulr, $) {
    "use strict";
    
    function PreviewArticle() {
        $(".article.preview").delegate("click", "a", function(evt) {
            evt.preventDefault();
        });
    };
    
    modulr.define("preview-article", function(require, exports, module) {
        module.exports = function() {
            return new PreviewArticle();
        }
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/quick-navigation.js */
(function ($) {

    modulr.define("quick-navigation", function (require, exports, module) {

        var _SELECTOR_DATA_LINK = "#quick-navigation",
            _SELECTOR_DATA_LINK_ATTR = "data-link",
            _SELECTOR_CONTENT = "#quick-navigation-content",
            _NAVIGATION_TEMPLATE = '<li><a href="{{url}}"><span class="icon"><img src="data:image/png;base64,{{icon}}"/></span><span class="text">{{label}}</span></a>',

            loadData = function (link) {
                $.ftnAjax(link, {
                    success: function(data) {
                        appendNavigations(data);
                    },
                    error: function() {
                        window.mobile.dialogs.dialogToast("Failed to load quick navigation data.");
                    }
                });
            },

            appendNavigations = function (data) {
                var navigations = JSON.parse(data).items,
                    content = $(_SELECTOR_CONTENT),
                    i;

                for (i = 0; i < navigations.length; i += 1) {
                    content[0].appendChild(
                        $(_NAVIGATION_TEMPLATE
                            .replace("{{url}}", navigations[i].url)
                            .replace("{{icon}}", navigations[i].icon)
                            .replace("{{label}}", navigations[i].label)
                        )[0]
                    );
                }
            },

            QuickNavigation = function () {
                this._initialize();
            };

        QuickNavigation.prototype._initialize = function () {
            this.loadLink = $(_SELECTOR_DATA_LINK).attr(_SELECTOR_DATA_LINK_ATTR);
        };

        QuickNavigation.prototype.addNavigations = function () {
            loadData(this.loadLink);
        };

        module.exports = function () {
            return new QuickNavigation();
        };

    });
})($);
;
/* assets/src/scripts/fortuna/redirectToPost.js */
(function (modulr, $) {
    modulr.define("redirectToPost", function (require, exports, module) {
        function initRedirect() {
            document.getElementById("form-post").submit();
        }
        module.exports = initRedirect;
    })
})(modulr, $);
;
/* assets/src/scripts/fortuna/refresh-page-tab-visibility-detector.js */
(function() {
    modulr.define("tabVisibilityDetector", function (require, exports, module) {

        "use-strict";
        var VISIBLE = "visible";
        var HIDDEN = "hidden";
        var EVENTS = {
            focus: VISIBLE,
            blur: HIDDEN,
            focusin: VISIBLE,
            focusout: HIDDEN,
            pageshow: VISIBLE,
            pagehide: HIDDEN
        };
        var EVENT_NAME = "visibilitychange";

        function registerListener(listeners, newListener) {
            if (listeners && typeof newListener == "function" && typeof listeners.indexOf == "function" &&
                listeners.indexOf(newListener) < 0) {
                listeners.push(newListener);
            }
        };

        function removeListener(listeners, listener) {
            var listenerIndex;
            if (listeners && typeof listeners.indexOf == "function" && typeof listeners.splice == "function" &&
                (listenerIndex = listeners.indexOf(listener)) > -1) {
                listeners.splice(listenerIndex, 1);
            }
        };

        function runListeners(listeners) {
            var args = [];
            for (var i = 1; i < arguments.length; i++) {
                args.push(arguments[i]);
            }
            for (var i = 0; i < listeners.length; i++) {
                if (typeof listeners[i] == "function") {
                    listeners[i].apply(window, args);
                }
            }
        };

        function TabVisibilityDetector() {

            this.__hidden = "hidden";
            this.__listeners = {
                onchange: [],
                onhide: [],
                onshow: []
            };
            if ("hidden" in document) {
                document.addEventListener(EVENT_NAME, this.__onVisibilityChange());
            } else if ((this.__hidden = "mozHidden") in document) {
                document.addEventListener("moz" + EVENT_NAME, this.__onVisibilityChange());
            } else if ((this.__hidden = "webkitHidden") in document) {
                document.addEventListener("webkit" + EVENT_NAME, this.__onVisibilityChange());
            } else if ((this.__hidden = "msHidden") in document) {
                document.addEventListener("ms" + EVENT_NAME, this.__onVisibilityChange());
            } else if ("onfocusin" in document) {
                document.onfocusin = document.onfocusout = this.__onVisibilityChange();
            } else {
                window.onpageshow = window.onpagehide = window.onfocus = window.onblur = this.__onVisibilityChange();
            }
        };

        TabVisibilityDetector.prototype.addOnChangeListener = function (callback) {
            registerListener(this.__listeners.onchange, callback);
            return this;
        };

        TabVisibilityDetector.prototype.removeOnChangeListener = function (callback) {
            removeListener(this.__listeners.onchange, callback);
            return this;
        };

        TabVisibilityDetector.prototype.addOnHideListener = function (callback) {
            registerListener(this.__listeners.onhide, callback);
            return this;
        };

        TabVisibilityDetector.prototype.removeOnHideListener = function (callback) {
            removeListener(this.__listeners.onhide, callback);
            return this;
        };

        TabVisibilityDetector.prototype.addOnShowListener = function (callback) {
            registerListener(this.__listeners.onshow, callback);
            return this;
        };

        TabVisibilityDetector.prototype.removeOnShowListener = function (callback) {
            removeListener(this.__listeners.onshow, callback);
            return this;
        };

        TabVisibilityDetector.prototype.__onVisibilityChange = function () {
            var that = this;
            return function (evt) {
                evt = evt || window.event;
                var state;
                if (evt.type in EVENTS) {
                    state = EVENTS[evt.type];
                } else {
                    state = document[that.__hidden] ? HIDDEN : VISIBLE;
                }
                runListeners(that.__listeners.onchange, evt, state);
                runListeners((state === VISIBLE ? that.__listeners.onshow : that.__listeners.onhide), evt);
            };
        };

        module.exports = new TabVisibilityDetector();
    });
})($);
;
/* assets/src/scripts/fortuna/refresh-page-view.js */
(function() {
    modulr.define("refresh-page", function(require, exports, module) {

        "use strict";

        var tabVisibilityDetector = require("tabVisibilityDetector");

        module.exports = (function () {
            function logSessionStorage(message) {
                window.sessionStorage.setItem("lastUpdated",  new Date().getTime() + '- ' + message);
            }

            tabVisibilityDetector.addOnHideListener(function () {
                logSessionStorage("Hide event triggered!");
            });
            tabVisibilityDetector.addOnShowListener(function () {
                var FIVE_MINUTE = 5 * 60 * 1000;
                var now = new Date();
                var item = window.sessionStorage.getItem("lastUpdated");
                if (!item) {
                  location.reload();
                }
                var dateFromStorage = item.substring(0, item.indexOf('-'));
                if ((now.getTime() - dateFromStorage) > FIVE_MINUTE) {
                    window.mobile.offer.toggleLoader(true);
                    location.reload();
                }
            });
        });

    });
    modulr.require("refresh-page")();
})($);

;
/* assets/src/scripts/fortuna/responsive-image.js */
(function () {
    var IMAGE_CLASS = ".fluid";
    var PICTURE = "picture/";
    var PICTURE_ORIGINAL = PICTURE.concat("original");
    var THUMBNAIL_STR = "thumbnail";
    var SMALL_STR = "small";
    var MEDIUM_STR = "medium";
    var MEDIUM = 640;
    var SMALL = 480;
    var THUMBNAIL = 200;
    var DATA_SRC = "data-src-";

    function initResponsiveImage() {
        $(IMAGE_CLASS).forEach(function (item) {

                    var width = window.screen.availWidth;
                    var originalSrc = item.src;
                    var serverAdress = originalSrc.substr(0, originalSrc.indexOf(PICTURE_ORIGINAL));
                    var pictureID = originalSrc.substr(originalSrc.indexOf(PICTURE_ORIGINAL) + PICTURE_ORIGINAL.length);
                    returnImageSourceBasedOnWidth(width, item, serverAdress, pictureID);
                    item.onerror = function () {
                        this.src = originalSrc;
                    };

            }
        );
    }

    function returnImageSourceBasedOnWidth(width, item, serverAdress, pictureID) {

        if (width < THUMBNAIL) {
            conditionSize(THUMBNAIL_STR, item, serverAdress, pictureID);
            }
        else if (width > THUMBNAIL && width < SMALL) {
            conditionSize(SMALL_STR, item, serverAdress, pictureID);

        }
        else if (width > SMALL && width < MEDIUM) {
            conditionSize(MEDIUM_STR, item, serverAdress, pictureID);
        }
        else {
            item.src = serverAdress.concat(PICTURE_ORIGINAL.concat(pictureID));
        }
    }
    function conditionSize(sizeName, item, serverAdress, pictureID) {
        if (item.getAttribute(DATA_SRC.concat(sizeName))) {
            item.src = item.getAttribute(DATA_SRC.concat(sizeName));
        }
        else {
            item.src = serverAdress.concat((PICTURE.concat(sizeName)).concat(pictureID));
        }
    }
    $(function () {
        initResponsiveImage();
    });
})();

;
/* assets/src/scripts/fortuna/search.js */
(function($) {

  modulr.define("search", function(require, exports, module) {

    var _ERASER = "eraser"
    var _SEARCH_CONTENT_PARENT = "search_content_parent"
    var _FOUND = "search_found"

    var Search = function(inputElm) {
      this._inputElm = document.getElementById(inputElm);
      this._eraser = document.getElementById(_ERASER);
      this._searchParent = document.getElementById(_SEARCH_CONTENT_PARENT);
      this._foundEl = document.getElementById(_FOUND);

      this.eraserHandler = this.eraserHandler.bind(this);
      this.onKeySearch = this.onKeySearch.bind(this);
      this.debounce = this.debounce.bind(this)
      this.searchSuccessHandler = this.searchSuccessHandler.bind(this)

      this.initialize();
    };

    Search.prototype.initialize = function() {
      this.initializeEvents();
      this.focusInput();
      const url = new URL(window.location.href);
      const searchValue = url.searchParams.get('searchValue');
      if (searchValue) {
        this._inputElm.value = decodeURIComponent(searchValue);
        this.onKeySearch();
      }
    };

    Search.prototype.debounce = function(func, wait, immediate) {
      var timeout;
      return function() {
        var args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(this, args);
        }.bind(this);
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(this, args);
      };
    };

    Search.prototype.initializeEvents = function(){
      this._inputElm.addEventListener("keyup",this.debounce(this.onKeySearch,500))
      this._eraser.addEventListener('click', this.eraserHandler)
    }

    Search.prototype.focusInput = function(){
      this._inputElm.focus();
    }

    Search.prototype.eraserHandler = function(){
      this._inputElm.value = "";
      this._eraser.classList.remove('show');
      this.onKeySearch();
    }

    Search.prototype.searchSuccessHandler = function(data){
      this._searchParent.classList.add('on');
      this._foundEl.innerHTML = data;
    }

    Search.prototype.onKeySearch = function(){
      var str = this._inputElm.value;
      var length = str.length;
      if(length > 0){
        this._eraser.classList.add("show");
      }else{
        this._eraser.classList.remove("show");
      }
      if (length >= 3) {
        var lnk = $.Link(window.location.href);
        $.ftnAjax(lnk.ajaxify().appendOrReplaceParam("searchValue", str).getUrl(), {
          success: this.searchSuccessHandler
        });
      } else {
        this._searchParent.classList.remove('on');
      }
    }

    module.exports = function(inputElm) {
      return new Search(inputElm);
    }
  });
})($);
;
/* assets/src/scripts/fortuna/select-menu.js */
(function($) {
    "use strict";

    var openInstance = null;

    modulr.define("select-menu", function(require, exports, module) {

        var SELECTOR_SELECT = ".select";
        var SELECTOR_ORIGINAL_SELECT = "select";
        var SELECTOR_SELECTED_OPTION = "option[value='{{VALUE}}']";

        var CLASS_SELECTED = "selected";

        var SelectMenu = function(elemId) {
            this._$selectMenu = $(elemId);
            this._$select = $(SELECTOR_SELECT, this._$selectMenu);
            this._$originalSelect = $(SELECTOR_ORIGINAL_SELECT, this._$selectMenu);
            this.initialize();
        };

        SelectMenu.prototype.initialize = function() {
            var that = this;
            this.setSelected();
            this._$select.on("click", function(evt) {
                that.openOriginalSelect(evt);
            });
            this._$originalSelect.on("change", function(evt) {
                that.changeSelectLabel();
                that.setSelected();
            });
        };

        SelectMenu.prototype.openOriginalSelect = function(evt) {
            if ($("html").hasClass("ui-pointerevents")) return;
            evt.preventDefault();
            if (document.createEvent) {
                var event = document.createEvent("MouseEvents");
                event.initMouseEvent("mousedown", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                this._$originalSelect[0].dispatchEvent(event);
            } else {
                this._$originalSelect[0].fireevent("onmousedown");
            }
        };

        SelectMenu.prototype.changeSelectLabel = function() {
            var context = this._$originalSelect;
            var replace = SELECTOR_SELECTED_OPTION.replace("{{VALUE}}", this._$originalSelect.val());
            var $1 = $(replace, this._$originalSelect);
            this._$select.html($1.html(), context);
        };

        SelectMenu.prototype.setSelected = function() {
            this._$selectMenu.toggleClass(CLASS_SELECTED, !!this._$originalSelect.val());
        };

        module.exports = function(elemId) {
            return new SelectMenu(elemId);
        }

    });

})($);

;
/* assets/src/scripts/fortuna/server-time.js */
(function(modulr) {
    "use strict";
    
})(modulr);
;
/* assets/src/scripts/fortuna/session-timer.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("session-timer", function(require, exports, module) {
        
        function milisToHHMMSS(milis) {
            if (!milis) return "00:00:00";
            var seconds = parseInt(milis / 1000);
            var hours = parseInt(seconds / 3600);
            seconds -= hours * 3600;
            var minutes = parseInt(seconds / 60);
            seconds -= minutes * 60;
            return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
        }
        
        function SessionTimer() {
            this.__timerElements = [];
        };
        
        SessionTimer.prototype.addElement = function(elm) {
            this.__timerElements.push(elm);
            if (this.__timerElements.length === 1) {
                this._start();
            }
            return this;
        };
        
        SessionTimer.prototype.removeElement = function(elm) {
            var index = this.__timerElements.indexOf(elm);
            if (index > -1) {
                this.__timerElements.splice(index, 1);
            }
            if (!this.__timerElements.length) {
                this._stop();
            }
            return this;
        };
        
        SessionTimer.prototype._start = function() {
            var loginAt = parseInt($.cookie("login-time"));
            if (!isNaN(loginAt)) {
                var that = this;
                if (this.__timerElements.length) {
                    function increase() {
                        var now = new Date();
                        var diff = now.getTime() - loginAt;
                        var time = milisToHHMMSS(diff);
                        for (var i = 0; i < that.__timerElements.length; i++) {
                            $(that.__timerElements[i]).html(time);
                        }
                        that._sessionTimerTimeout = window.setTimeout(increase, 1000);
                    }
                    increase();
                } else {
                    this._stop();
                }
            }
            return this;
        };
        
        SessionTimer.prototype._stop = function() {
            if (this._sessionTimerTimeout != null) {
                window.clearTimeout(this._sessionTimerTimeout);
                this._sessionTimerTimeout = null
            }
            return this;
        };
        
        module.exports = new SessionTimer();
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/show-more-box.js */
(function($) {
    "use strict";
    
    function initialize() {
        $("body").delegate("click", ".show-more-box .show-more", function(evt) {
            evt.preventDefault();
            $($(this).closest(".show-more-box")).toggleClass("expanded");
        });
    };

    $(function() {
        initialize();
    });

})($);
;
/* assets/src/scripts/fortuna/sk-user-rep-popup.js */
(function(modulr) {
    "use strict";

    modulr.define("skUserREPPopup", function(require, exports, module) {

        var AlertDialog = window.mobile.dialogs.dialogAlert;

        function SKUserREPPopup(i18n) {
            this._i18n = i18n || {
                "REP_TITLE" : "ROV title",
                "REP_MESSAGE" : "ROV message",
                "REP_BUTTON" : "ROV button"
            };
            this.show();
        }

        SKUserREPPopup.prototype.show = function() {
            var that = this;
            AlertDialog(that._i18n.REP_MESSAGE, {
                titleLabel: that._i18n.REP_TITLE,
                okButtonLabel: that._i18n.REP_BUTTON
            });
            $.cookie("sk_user_rep_popup_seen", "1", {path: "/", expires: 30});
        };

        module.exports = function(i18n) {
            return new SKUserREPPopup(i18n);
        };

    });

})(modulr);
;
/* assets/src/scripts/fortuna/snackbar.js */
(function(modulr) {
    "use strict";
    
    modulr.define("snackbar", function(require, exports, module) {
        
        var SELECTOR_PAGE = "#page";
        
        var TPL_SNACKBAR = '<div class="snackbar {{SPECIAL_CLASS}}" id="{{ID}}"><div>{{CONTENT}}</div><div><a href="{{BUTTON_LINK}}">{{BUTTON_LABEL}}</a></div></div>';
        
        var SUFFIX_COOKIE_DISPLAYS = "-d";
        var SUFFIX_COOKIE_DISPLAY_TILL = "-fd";
        
        /**
         * Initiliazes snackbar
         * 
         * @param {Snackbar} snackbar Instance of snackbar
         * @private
         * */
        function initialize(snackbar) {
            var prerendered = true;
            if (!snackbar.__conf.id) {
                snackbar.__conf.id = "snackbar-" + $.Utils.hashCode(JSON.stringify(snackbar.__conf));
            }
            var snackbarElm = $("#" + snackbar.__conf.id);
            if (!snackbarElm || !snackbarElm.length) {
                snackbarElm = constructElement(snackbar);
                prerendered = false;
            }
            snackbar.__element = snackbar.__element = snackbarElm;
            if (snackbar.__conf.behaviour) {
                // Vytahnuti dat pro snackbar z cookies
                var storedData = getCookies(snackbar);
                var maxDisplays = (snackbar.__conf.behaviour.display && snackbar.__conf.behaviour.display.count) || $.CONSTANTS.MAX_SAFE_INTEGER;
                if (maxDisplays > storedData.display.count && !prerendered) {
                    // kdyz to neni predrenderovano a zaroven to jeste nebylo zobrazeno s dostatecnym poctem, tak to do stranky musime pridat
                    appendElement(snackbar);
                } else if (maxDisplays <= storedData.display.count && prerendered) {
                    // kdyz to uz bylo zobrazeno mockrat a je to uz renderovano ve strance, tak to musime odebrat
                    snackbar.__element.remove();
                }
                // Pokud uz to bylo zobrazeno mockrat, tak uz dal nepokracujeme
                if (maxDisplays <= storedData.display.count) {
                    return;
                }
                if (prerendered) {
                    window.mobile.bottomMessagesHolder.addMessage(snackbar.__element, null, true);
                }
                // Musi byt na ondomready, protoze to tady jeste neni vyrenderovane cele
                $(function() {
                    moveFixedTicketButton(snackbar);
                })
                // Ulozeni updatovanych informaci do cookies
                storedData.display.count++;
                if (!storedData.display.till && snackbar.__conf.behaviour.display && snackbar.__conf.behaviour.display.period) {
                    if (typeof snackbar.__conf.behaviour.display.period == "number") {
                        var displayTillDate = new Date();
                        storedData.display.till = displayTillDate.setTime(displayTillDate.getTime() + snackbar.__conf.behaviour.display.period);
                    } else {
                        storedData.display.till = snackbar.__conf.behaviour.display.period;
                    }
                }
                saveCookies(snackbar, storedData);
                
                // Ovladani auto hide funkce
                if (snackbar.__conf.behaviour.autohide) {
                    snackbar.__autohideTimeout = window.setTimeout(function() {
                        hideSnackbar(snackbar);
                    }, snackbar.__conf.behaviour.autohide);
                }
            }
            
            if (snackbar.__conf.button && typeof snackbar.__conf.button.callback == "function") {
                snackbar.__element.find(SELECTOR_SNACKBAR_BUTTON).on("click", function(evt) {
                    if (snackbar.__conf.button.callback.call(this, evt)) {
                        hideSnackbar(snackbar);
                    }
                    
                });
            }
        };
        
        /**
         * Construct snackbar element and appends it to page to correct place
         * 
         * @param {Snackbar} snackbar - Instance of snackbar
         * @returns {Dollar} Instance of Dollar including snackbar element
         * @private
         * */
        function constructElement(snackbar) {
            return $($.Utils.expandVariables(TPL_SNACKBAR, {
                "SPECIAL_CLASS": snackbar.__conf.specialClass || "default",
                "ID": snackbar.__conf.id,
                "CONTENT": snackbar.__conf.content || "<!-- NO CONTENT -->",
                "BUTTON_LINK": ((snackbar.__conf.button && snackbar.__conf.button.link) ? snackbar.__conf.button.link : "#"),
                "BUTTON_LABEL": ((snackbar.__conf.button && snackbar.__conf.button.label) ? snackbar.__conf.button.label : "OK")
            }));
        };
        
        /**
         * Appends snackbar element to correct position
         * 
         * @param {Snackbar} snackbar - Instance of snackbar
         * @private
         * @throws Error when snackbar has no element defined
         * */
        function appendElement(snackbar) {
            if (!snackbar.__element || !snackbar.__element.length) {
                throw "SnackbarError: Snackbar has no element defined. Possibly not initialized yet.";
            }
            window.mobile.bottomMessagesHolder.addMessage(snackbar.__element, true, true);
        };
        
        /**
         * Removes snackbar element
         * 
         * @param {Snackbar} snackbar - Instance of snackbar
         * @private
         * */
        function hideSnackbar(snackbar) {
            snackbar.__element.remove();
            moveFixedTicketButton(snackbar);
        };
        
        /**
         * Gets cookies related to snackbar instance
         * 
         * @param {Snackbar} snackbar - Instance of Snackbar
         * @return {Object} Object with snackbar cookies with configuration
         * @private
         * */
        function getCookies(snackbar) {
            // Get cookie with count of snackbar displays
            var displays = $.Utils.asInt($.cookie(snackbar.__conf.id + SUFFIX_COOKIE_DISPLAYS), 0);
            // Get cookie with display till date
            var displayTillDate;
            var displayTill = $.cookie(snackbar.__conf.id + SUFFIX_COOKIE_DISPLAY_TILL);
            if (displayTill) {
                displayTillDate = new Date();
                displayTillDate.setTime(displayTill);
            }
            return {
                "display": {
                    "count": displays || 0,
                    "till": displayTillDate
                }
            };
        };
        
        /**
         * Saves cookies related to snackbar instance
         * 
         * @param {Snackbar} snackbar - Instance of Snackbar
         * @param {Object} data - Object with data to store
         * @private
         * */
        function saveCookies(snackbar, data) {
            var expires = null;
            if (data.display.till) {
            	var expires = new Date();
            	expires.setTime(data.display.till + (expires.getTimezoneOffset() * 60 * 1000));
            	$.cookie(snackbar.__conf.id + SUFFIX_COOKIE_DISPLAY_TILL, data.display.till, {
                    path: snackbar.__conf.path,
                    expires: expires
                });
            }
        	$.cookie(snackbar.__conf.id + SUFFIX_COOKIE_DISPLAYS, data.display.count, {
                path: snackbar.__conf.path,
                expires: expires
            });
        };
        
        /**
         * Moves fixed ticket button to up to prevent hiding behind snackbar
         * 
         * @param {Snackbar} snackbar - Instance of Snackbar
         * @private
         * */
        function moveFixedTicketButton(snackbar) {
            window.mobile.bottomMessagesHolder.resize();
        };
        
        /**
         * Constructor of Snackbar
         * 
         * @param {Object} conf - Configuration of snackbar
         * @param {String} conf.id - Id of snackbar element. Automatically generated when undefined
         * @param {String} [conf.path] - Path to store cookies with configuration. Default is empty string to store instance configuration just for current page. For global use "/".
         * @param {String} [conf.content] - Text content of snackbar
         * @param {Object} [conf.button] - Configuration of snackbar button
         * @param {String} [conf.button.link=#] - Url of button
         * @param {String} [conf.button.label=Ok] - Label of button
         * @param {Function} [conf.button.callback] - Callback after button clicked
         * @param {Object} [conf.behaviour] - Configuration of snackbar behaviour
         * @param {Integer} [conf.behaviour.autohide=0] - Whether to automatically hide snackbar after defined amount of time (ms)
         * @param {Object} [conf.behaviour.display] - Configuration for snackbar display
         * @param {Integer} [conf.behaviour.display.count=0] - How many times display snackbar per amount of time (0 is unlimited)
         * @param {Integer|Date} [conf.behaviour.display.period=0] - Defines display period (0 means session then time in ms)
         * @constructor
         * @public
         * */
        function Snackbar(conf) {
            this.__conf = conf || {};
            initialize(this);
        };
        
        module.exports = function(conf) {
            return new Snackbar(conf);
        };
    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/special-fragment.js */
(function(modulr, $) {
	"use strict";
	
	modulr.define("special-fragment", function(require, exports, module) {
		
		function SpecialFragment() {
			this.element = $("#special-fragment");
			this.initialize();
		};
		
		SpecialFragment.prototype.initialize = function() {
			$(".message", this.element).forEach(function(item) {
				var $item = $(item);
				if ($.cookie("sf-" + item.id)) {
					$item.toggleClass("hidden");
				}
				item.innerHTML = '<div class="content">' + item.innerHTML + '</div>';
				if (item.getAttribute("data-closeable") === "true") {
					item.innerHTML = '<a href="#" class="close"><i class="icon-cancel"></i></a>' + item.innerHTML;
					$item.delegate("click", ".close", function(evt) {
						evt.preventDefault();
						if (!item.id) return;
						var cookieParams = {};
						var expires = item.getAttribute("data-expires");
						if (expires && !isNaN(parseInt(expires))) {
							cookieParams.expires = expires;
						}
						$.cookie("sf-" + item.id, "hidden", cookieParams);
						$item.toggleClass("hidden", true);
					});
				}
			});
		};
		
		module.exports = function() {
			return new SpecialFragment();
		};
		
	});
	
})(modulr, $);
;
/* assets/src/scripts/fortuna/stats.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("stats", function(require, exports, module) {
        
        var SELECTOR_TENNIS_TOURNAMENT_AND_RESULT_TABLE = ".table-tennis-tournament-result";
        
        function initialize() {
            initializeResizeTennisTournamentAndResultTable();
        };
        
        function initializeResizeTennisTournamentAndResultTable() {
            resizeTennisTournamentAndResultTable();
            $("#prev-matches-home .switcheable-content, #prev-matches-away .switcheable-content")
                .on("gm:sc:shown", function() {
                    resizeTennisTournamentAndResultTable(this);
                });
        };
        
        function resizeTennisTournamentAndResultTable(parent) {
            if (!parent) parent = document;
            $(parent).find(SELECTOR_TENNIS_TOURNAMENT_AND_RESULT_TABLE).forEach(function(table) {
                var $table = $(table);
                var $firstCell = $(table).find("td:first-child");
                var $lastCell = $(table).find("td:last-child");
                var tableWidth = $table.width();
                $firstCell.width(0);
                var textWidth = $lastCell.find("span").width() + 1;
                if (textWidth < tableWidth) {
                    $firstCell.width(tableWidth - textWidth + "px");
                }
            });
        };
        
        module.exports = {
            initialize: initialize,
            resizeTennisTournamentAndResultTable: resizeTennisTournamentAndResultTable
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/stomp-connector.js */
(function(modulr, Stomp, SockJS) {
    "use strict";
    
    modulr.define("stomp-connector", function(require, exports, module) {
        
        var RECONNECT_AFTER = 5000;
        
        module.exports = function(url, connectedFn) {
            var Logger = require("logger")("stomp-connector");
            var client,
                timeout;
            
            
            function success(frame) {
                connectedFn(client);
                window.clearTimeout(timeout);
                timeout = null;
                $(window).on("unload", function() {
            		client.disconnect();
                });
            };
            
            function error() {
                if (timeout) {
                    window.clearTimeout(timeout);
                }
                timeout = window.setTimeout(function() {
                    connect();
                }, RECONNECT_AFTER);
            };
            
            function connect() {
                var ws = new SockJS(url);
                client = Stomp.over(ws);
                client.debug = function() {
                    Logger.log.apply(Logger, arguments);
                };
                ws.addEventListener("heartbeat", function() {
                    ws.close();
                });
                client.connect({}, success, error);
            }
            
            connect();
        };
        
    });
    
})(modulr, Stomp, SockJS);
;
/* assets/src/scripts/fortuna/switcheable-content.js */
(function(modulr, $) {
    
    modulr.define("components.switcheable-content", function(require, exports, module) {
        
        var CLASS_HIDDEN = "hidden";
        var CLASS_COLLAPSED = "collapsed";
        var CLASS_EXPANDED = "expanded";
        
        var SELECTOR_SWITCHEABLE_CONTENT = ".switcheable-content";
        var SELECTOR_EXPAND = ".sc-expand";
        var SELECTOR_COLLAPSE = ".sc-collapse";
        var SELECTOR_SWITCH = ".sc-switch";
        var SELECTOR_ITEMS = ".sc-hidden";
        
        var DATA_ATTRIBUTE_NAME = "data-sc-name";
        var DATA_ATTRIBUTE_APPLIED_FILTERS = "data-sc-filters";
        
        var SPLIT_SIGN = " ";
        
        function getExpandSelector(switcheableContent) {
            var name = getSpecialName(switcheableContent);
            if (!name) return SELECTOR_EXPAND;
            return SELECTOR_EXPAND + "-" + name;
        };
        
        function getCollapseSelector(switcheableContent) {
            var name = getSpecialName(switcheableContent);
            if (!name) return SELECTOR_COLLAPSE;
            return SELECTOR_COLLAPSE + "-" + name;
        };
        
        function getSwitchSelector(switcheableContent) {
            var name = getSpecialName(switcheableContent);
            if (!name) return SELECTOR_SWITCH;
            return SELECTOR_SWITCH + "-" + name;
        };
        
        function getItemsSelector(switcheableContent) {
            var name = getSpecialName(switcheableContent);
            if (!name) return SELECTOR_ITEMS;
            return SELECTOR_ITEMS + "-" + name;
        };
        
        function getSpecialName(switcheableContent) {
            return switcheableContent.__root.attr(DATA_ATTRIBUTE_NAME);
        };
        
        function __initialize(switcheableContent) {
            if (switcheableContent.__root.attr("data-sc-collapsed")) {
                hideItems(switcheableContent);
            }
            __initializeEvents(switcheableContent);
        };
        
        function __initializeEvents(switcheableContent) {
            switcheableContent.__root
                .delegate("click", getExpandSelector(switcheableContent), function(evt) {
                    evt.preventDefault();
                    showItems(switcheableContent);
                })
                .delegate("click", getCollapseSelector(switcheableContent), function(evt) {
                    evt.preventDefault();
                    hideItems(switcheableContent);
                })
                .delegate("change", getSwitchSelector(switcheableContent), function() {
                    switchItems(this, switcheableContent);
                });
        };
        
        function appendFilter(switcheableContent, itm) {
            var filters = itm.getAttribute(DATA_ATTRIBUTE_APPLIED_FILTERS);
            if (!filters) {
                filters = [];
            } else {
                filters = filters.split(SPLIT_SIGN);
            }
            if (filters.indexOf(switcheableContent.__name) < 0) {
                filters.push(switcheableContent.__name);
            }
            itm.setAttribute(DATA_ATTRIBUTE_APPLIED_FILTERS, filters.join(SPLIT_SIGN));
        };
        
        function removeFilter(switcheableContent, itm) {
            var filters = itm.getAttribute(DATA_ATTRIBUTE_APPLIED_FILTERS);
            if (!filters) {
                filters = [];
            } else {
                filters = filters.split(SPLIT_SIGN);
            }
            var filterPosition = filters.indexOf(switcheableContent.__name);
            if (filterPosition > -1) {
                filters.splice(filterPosition, 1);
            }
            if (filters.length) {
                itm.setAttribute(DATA_ATTRIBUTE_APPLIED_FILTERS, filters.join(SPLIT_SIGN));
                return false;
            } else {
                itm.removeAttribute(DATA_ATTRIBUTE_APPLIED_FILTERS);
                return true;
            }
        };
        
        function hideItems(switcheableContent) {
            switcheableContent.__root.find(getItemsSelector(switcheableContent))
                .toggleClass(CLASS_HIDDEN, true)
                .forEach(function(itm) {
                    appendFilter(switcheableContent, itm);
                });
            switcheableContent.__root.find(getExpandSelector(switcheableContent)).toggleClass(CLASS_HIDDEN, false);
            switcheableContent.__root.find(getCollapseSelector(switcheableContent)).toggleClass(CLASS_HIDDEN, true);
            switcheableContent.__root
                .toggleClass(CLASS_COLLAPSED, true)
                .toggleClass(CLASS_EXPANDED, false);
            switcheableContent.__root.fireEvent("gm:sc:hidden");
        };
        
        function showItems(switcheableContent) {
            switcheableContent.__root.find(getItemsSelector(switcheableContent))
                .forEach(function(itm) {
                    if (removeFilter(switcheableContent, itm)) {
                        $(itm).toggleClass(CLASS_HIDDEN, false);
                    };
                });
            switcheableContent.__root.find(getExpandSelector(switcheableContent)).toggleClass(CLASS_HIDDEN, true);
            switcheableContent.__root.find(getCollapseSelector(switcheableContent)).toggleClass(CLASS_HIDDEN, false);
            switcheableContent.__root
                .toggleClass(CLASS_COLLAPSED, false)
                .toggleClass(CLASS_EXPANDED, true);
            switcheableContent.__root.fireEvent("gm:sc:shown");
        };
        
        function switchItems(elm, switcheableContent) {
            if (elm.checked) {
                hideItems(switcheableContent);
            } else {
                showItems(switcheableContent);
            }
        };
        
        function SwitcheableContent(elm) {
            this.__root = $(elm);
            this.__name = getSpecialName(this);
            __initialize(this);
        }
        
        module.exports = {
            factory: function() {
                $(SELECTOR_SWITCHEABLE_CONTENT).forEach(function(itm) {
                    new SwitcheableContent(itm);
                });
            },
            instance: function(elm) {
                return new SwitcheableContent(elm);
            }
        };
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/tabs.js */
(function(modulr, $) {
    "use strict";

    modulr.define("components.tabs", function(require, exports, module) {

        const DATA_ATTRIBUTE_ANCHOR = "data-tabs-anchor";

        const CLASS_ACTIVE = "active";
        const CLASS_HIDDEN = "hidden";

        const SELECTOR_TABS = ".js-local-tabs";
        const CLASS_EXPANDING_LAYOUT = "expanding";
        const CLASS_STAY_AT_POSITION = "stay-at-position";
        const SELECTOR_SWITCHERS = ".switchers a";
        const SELECTOR_TAB_CONTENT = ".tab-content";
        const SELECTOR_RELATED_SWITCHERS = SELECTOR_SWITCHERS + "[href$='{{ANCHOR}}'], " + SELECTOR_SWITCHERS + "[" + DATA_ATTRIBUTE_ANCHOR + "='{{ANCHOR}}']";
        const SELECTOR_BETBUILDER_BUTTON_LOADER = "js-betbuilder-button-loader";
        const SELECTOR_BETBUILDER_MODAL_OPENER = "modal-opener--betbuilder";

        const TPL_SELECTOR_SWITCHERS = ".switchers.{{TABS_NAME}} a";
        const ANCHOR_BETBUILDER = "event-betbuilder";

        function __getSwitchersSelector(tabs) {
            const name = tabs.__root.attr("data-tabs-name");
            if (name) {
                return $.Utils.expandVariables(TPL_SELECTOR_SWITCHERS, {
                    "TABS_NAME": name
                });
            }
            return SELECTOR_SWITCHERS;
        }

        function __getTabsSelector(tabs, switchers) {
            if (!switchers) {
                switchers = tabs.__root.find(__getSwitchersSelector(tabs));
            }
            return switchers.map(function(itm) {
                var anchor = itm.getAttribute(DATA_ATTRIBUTE_ANCHOR);
                if (!anchor) {
                    anchor = $.Link(itm.href).anchor;
                }
                if (anchor) {
                    return "#" + anchor;
                }
                return null;
            }).filter(function(itm) {
                return !!itm;
            }).join(", ");
        }

        function __initialize(tabs) {
            __switchTab(tabs);
            tabs.__root.delegate("click", __getSwitchersSelector(tabs), function(evt) {
                __switchTab(tabs, this, evt);
            });
        }

        function __switchTab(tabs, elm, evt) {
            // remove previously added class to the html
            const prevTab = document.querySelector(SELECTOR_SWITCHERS + ".active");

            if (prevTab && prevTab.dataset.layoutClass) {
                document.querySelector("html").classList.remove(prevTab.dataset.layoutClass);
            }

            const container = tabs.__root[0];
            const switchers = tabs.__root.find(__getSwitchersSelector(tabs));
            const contents = tabs.__root.find(__getTabsSelector(tabs, switchers));
            const betBuilderButtonLoaderEl = document.getElementById(SELECTOR_BETBUILDER_BUTTON_LOADER);
            const modalOpenerBetbuilderEl = document.getElementById(SELECTOR_BETBUILDER_MODAL_OPENER);

            betBuilderButtonLoaderEl && betBuilderButtonLoaderEl.classList.remove(CLASS_HIDDEN);
            modalOpenerBetbuilderEl && modalOpenerBetbuilderEl.classList.add(CLASS_HIDDEN);

            if (elm) {
                const wrapper = elm.parentNode.parentNode;
                if (wrapper.parentNode.classList.contains(CLASS_EXPANDING_LAYOUT)) {
                    elm.scrollIntoView();
                }
            }

            if (!container.classList.contains(CLASS_STAY_AT_POSITION)) {
                document.scrollingElement.scrollTop = 0;
            }

            if (!elm) elm = switchers.filter(function(i) { return $(i).hasClass(CLASS_ACTIVE); })[0];
            if (!elm) elm = switchers.get(0);
            if (!elm) return;

            if (evt) evt.preventDefault();

            switchers.toggleClass(CLASS_ACTIVE, false);

            var anchor = $(elm).attr(DATA_ATTRIBUTE_ANCHOR);
            if (!anchor) {
              anchor = $.Link(elm.href).anchor;
            }

            setTimeout(function() {
                if (!window.mobile) {
                    return;
                }

                if (anchor === ANCHOR_BETBUILDER) {
                    window.mobile.betbuilder.onShowBetBuilderTab(function () {
                        betBuilderButtonLoaderEl && betBuilderButtonLoaderEl.classList.add(CLASS_HIDDEN);
                        modalOpenerBetbuilderEl && modalOpenerBetbuilderEl.classList.remove(CLASS_HIDDEN);
                    }.bind(this));
                } else {
                    window.mobile.betbuilder.onShowMarketsTab(function () {
                        betBuilderButtonLoaderEl && betBuilderButtonLoaderEl.classList.add(CLASS_HIDDEN);
                        modalOpenerBetbuilderEl && modalOpenerBetbuilderEl.classList.remove(CLASS_HIDDEN);
                    }.bind(this));
                }
            }, 0);

            const tab = tabs.__root
                .find($.Utils.expandVariables(SELECTOR_RELATED_SWITCHERS, {"ANCHOR": anchor}));

            tab.toggleClass(CLASS_ACTIVE, true);

            // add e.g. no-scroll class to html so that view is full-screen and we can e.g. display full-size IMG widget
            if (tab[0].dataset && tab[0].dataset.layoutClass) {
                document.querySelector("html").classList.add(tab[0].dataset.layoutClass);
            }

            const tabContent = contents.toggleClass(CLASS_HIDDEN, true)
                .filter(function(i) {
                    return i.id === anchor;
                }).toggleClass(CLASS_HIDDEN, false);

            if (tabs.__onSwitchCallback && tabs.__onSwitchCallback.length) {
                for (var i = 0; i < tabs.__onSwitchCallback.length; i++) {
                    if (typeof tabs.__onSwitchCallback[i] === "function") {
                        tabs.__onSwitchCallback[i].call(tabs, anchor, elm, tabContent, evt);
                    }
                }
            }
            // Allows asynchronously load the micro-frontends based on the tab switch event
            window.dispatchEvent(new CustomEvent('tabs-switched', {detail: {tabs: tabs, elm: elm, evt: evt}}))
        }

        function Tabs(elm) {
            this.__root = $(elm);
            __initialize(this);
        }

        Tabs.prototype.onSwitch = function(callback) {
            if (!this.__onSwitchCallback) {
                this.__onSwitchCallback = [];
            }
            this.__onSwitchCallback.push(callback);
        };

        function instance(elm) {
            var $elm = $(elm);
            if (!$elm[0]._js) {
                $elm.forEach(function(itm) {
                    itm._js = {};
                });
            }
            if (!$elm[0]._js.tabs) {
                var inst = new Tabs($elm);
                $elm.forEach(function(itm) {
                    itm._js.tabs = inst;
                });
            }
            return $elm[0]._js.tabs;
        }

        module.exports = {
            factory: function() {
                $(SELECTOR_TABS).forEach(function(itm) {
                    instance(itm);
                });
            },
            instance: instance
        };

    });

})(modulr, $);

;
/* assets/src/scripts/fortuna/ticket-list.js */
(function($) {
    "use strict";

    modulr.define("ticket-list", function(require, exports, module) {

        var _$ticketFilterContent = null;
        var _$ticketList = null;
        var _translations = null;
        var _filterActive = false;

        var getTicketFilterContent = function() {
            if (_$ticketFilterContent == null) {
                _$ticketFilterContent = $("#ticket-filter-content");
            }
            return _$ticketFilterContent;
        };

        var getTicketList = function() {
            if (_$ticketList == null) {
                _$ticketList = $("#ticket-list");
            }
            return _$ticketList
        };

        var getTranslation = function(key) {
            return _translations[key] || key;
        }

        var onTicketFilterIconClick = function(evt) {
            openFilterDialog(getTicketFilterContent().html());
        };

        var openFilterDialog = function(content) {
            var resetLink = null;
            if (_filterActive) {
                resetLink = '<a href="' + getTranslation("filterResetUrl") + '" class="c-gray">' + getTranslation("filterReset") + '</a>'
            }
            window.mobile.dialogs.dialogConfirm(content, {
                titleLabel: getTranslation("filterTitle"),
                specialClass: "filter-dialog",
                okButtonLabel: getTranslation("filterConfirm"),
                cancelButtonLabel: getTranslation("filterCancel"),
                cancelCallback: onFilterDialogCancelled,
                okCallback: onFilterDialogSubmitted,
                additionalButton: resetLink,
                initCallback: onFilterDialogInit
            });
        };

        var onFilterDialogCancelled = function() {
            var dialogContent = $(".dialog.confirm.filter-dialog .content-inner").html();
            getTicketFilterContent().html(dialogContent);
        };

        var onFilterDialogSubmitted = function() {
            $("#ticket-filter-form")[0].submit();
        };

        var onFilterDialogInit = function() {
            getTicketFilterContent().html("");
            if (!Modernizr.inputtypes.date) {
                var SelectMenu = require("select-menu");
                var selects = [
                    "#date-from-day-mobile-select",
                    "#date-from-month-mobile-select",
                    "#date-from-year-mobile-select",
                    "#date-to-day-mobile-select",
                    "#date-to-month-mobile-select",
                    "#date-to-year-mobile-select"
                ];
                for (var i = 0, l = selects.length; i < l; i++) {
                    SelectMenu(selects[i]);
                }
                var $dateFrom = $(".filter-dialog .date-from");
                var $dateTo = $(".filter-dialog .date-to");
                $dateFrom.on("change", function(evt) {
                    setDate($dateFrom, "#id-date-from");
                });
                $dateTo.on("change", function(evt) {
                    setDate($dateTo, "#id-date-to");
                });
            }
        };

        var setDate = function(dateElms, targetId) {
            var dateParts = {day: null, month: null, year: null};
            dateElms.forEach(function(item) {
                if ($(item).hasClass("day")) {
                    dateParts.day = item.value;
                } else if ($(item).hasClass("month")) {
                    dateParts.month = item.value;
                } else if ($(item).hasClass("year")) {
                    dateParts.year = item.value;
                }
            });
            if (dateParts.day && dateParts.month && dateParts.year) {
                var date = "";
                date += dateParts.year + "-";
                if (dateParts.month < 10) {
                    date += "0"
                }
                date += dateParts.month + "-";
                if (dateParts.day < 10) {
                    date += "0";
                }
                date += dateParts.day;
                $(targetId).val(date);
            }
        };

        var loadMoreTickets = function(target) {
            var $target = $(target);
            if ($target.is(".loading")) {
                return;
            }
            if (!$target.is(".load-more")) {
                $target = $target.parent(".load-more");
            }
            var url = $target.attr("href");
            if (url) {
                $.ftnAjax($.Link(url).ajaxify().getUrl(), {
                    beforeSend: function() {
                        $target.toggleClass("loading", true);
                    },
                    success: function(data) {
                        processTickets(data, target);
                    },
                    error: function() {
                        window.mobile.dialogs.dialogToast(getTranslation("ticketLoadingError"));
                    },
                    complete: function() {
                        $target.toggleClass("loading", false);
                    }
                });
            }
        };

        var processTickets = function(data, target) {
            var ticketList = getTicketList();
            ticketList.html(ticketList.html() + data);
            var $target = $(target);
            if (data.indexOf("_NO MORE TICKETS_") > -1) {
                $target.toggleClass("hidden", true);
            } else {
                var nextPageLink = $.Link($target.attr("href"));
                var actualPage = nextPageLink.getParam("page") || 0;
                nextPageLink.appendOrReplaceParam("page", parseInt(actualPage) + 1);
                $target.attr("href", nextPageLink.getUrl());
            }
        };

        var ticketList = {

            init: function(translations, filterActive) {
                _translations = translations;
                _filterActive = filterActive;
                $("#more .load-more").on("click", function(evt)  {
                    evt.preventDefault();
                    loadMoreTickets(evt.target);
                });
                $("#ticket-filter").on("click", function(evt) {
                    evt.preventDefault();
                    onTicketFilterIconClick(evt);
                });
            }

        };

        module.exports = ticketList;

    });
})($);
;
/* assets/src/scripts/fortuna/tooltips.js */
(function($) {
    "use strict";

    var SELECTOR_TOOLTIPS = ".tooltip";

    function initialize() {
        $("body").delegate("click", SELECTOR_TOOLTIPS, function(evt) {
            if (this.getAttribute("data-message")) {
                evt.preventDefault();
                window.mobile.dialogs.dialogAlert(this.getAttribute("data-message"));
            }
        });
    };

    $(function() {
        initialize();
    });

})($);
;
/* assets/src/scripts/fortuna/transfer-funds.js */
(function(modulr, $) {
	"use strict";

	modulr.define("transfer-funds", function(require, exports, module) {

		var SELECTOR_FORM = ".transfer-funds-form";
		var SELECTOR_TRANSFER_TYPE = SELECTOR_FORM + " input[name=transferType]";
		var SELECTOR_TRANSFER_TYPE_CHECKED = SELECTOR_TRANSFER_TYPE + ":checked";
		var SELECTOR_PROMOCODE_ROW = SELECTOR_FORM + " .promocode-row";

		var AlertDialog = window.mobile.dialogs.dialogAlert;
		var ConfirmDialog = window.mobile.dialogs.dialogConfirm;
		var Formatters = window.mobile.formatters;

		function TransferFunds(i18n) {
			this.i18n = i18n;
			this.form = $(SELECTOR_FORM);
			this.initializeEvents();
		};

		TransferFunds.prototype.initializeEvents = function() {
			var that = this;
			this.form.on("submit", function(evt) {
				that.onFormSubmit(evt);
			});
			$(SELECTOR_TRANSFER_TYPE).on("change", function() {
				that.onTransferTypeChange(this);
			});
		};

		TransferFunds.prototype.onFormSubmit = function(evt) {
			var that = this;
			var values = this.form.serializeMap();
			if (!values.transferType) {
				evt.preventDefault();
				AlertDialog(this.i18n.TRANSFER_TYPE_NOT_SELECTED, {
					titleLabel: that.i18n.ERROR_TITLE
				});
				return;
			}
			if (!values.amount) {
				evt.preventDefault();
				AlertDialog(this.i18n.AMOUNT_NOT_VALID_NUMBER, {
					titleLabel: that.i18n.ERROR_TITLE
				});
				return;
			}
			var parsedValue = parseFloat(values.amount);
			if (isNaN(parsedValue)) {
				evt.preventDefault();
				AlertDialog(this.i18n.AMOUNT_NOT_VALID_NUMBER, {
					titleLabel: that.i18n.ERROR_TITLE
				});
				return;
			}
			if (parsedValue <= 0) {
				evt.preventDefault();
				AlertDialog(this.i18n.AMOUNT_LOWER_THAN_ZERO, {
					titleLabel: that.i18n.ERROR_TITLE
				});
				return;
			}
			this.sendForm(evt, values);
		};

		TransferFunds.prototype.onTransferTypeChange = function(elm) {
			$(SELECTOR_PROMOCODE_ROW).toggleClass("hidden", elm.value === "CASINO_TO_MAIN");
		};

		TransferFunds.prototype.sendForm = function(evt, values) {
			if (!values) return;
			evt && evt.preventDefault();
			var that = this;
			var url = $.Link(this.form.attr("action"));
			$.ftnAjax(url.ajaxify().getUrl(), {
				method: "POST",
				data: values,
				beforeSend: function() {
				    window.mobile.offer.toggleLoader(true);
				},
				success: function(data) {
					var xmlData = $.parseXml(data);
					var rootElm = $("response", xmlData);
					if (rootElm.attr("status") === "ok") {
					    var screen = $("screen", xmlData);
					    var wrapper = $("#transfer-wrapper");
					    wrapper.html($.parseCData(screen[0]));
					} else {
					    var bonusConfirm = $("bonus-confirm", xmlData);
					    var text;
					    var title;
					    if (bonusConfirm.length) {
					        title = $.parseCData($("title", bonusConfirm)[0]);
					        text = $.parseCData($("text", bonusConfirm)[0]);
					        var okLabel = $.parseCData($("ok-label", bonusConfirm)[0]);
					        var cancelLabel = $.parseCData($("cancel-label", bonusConfirm)[0]);
					        ConfirmDialog(text, {
                                titleLabel: title,
                                okButtonLabel: okLabel,
                                cancelButtonLabel: cancelLabel,
                                okCallback: function() {
                                    values.cancelBonuses = true;
                                    that.sendForm(null, values);
                                }
                            });
					    } else {
					        var errorMessage = $("error-message", xmlData);
					        if (errorMessage.length) {
					            text = $.parseCData(errorMessage[0]);
					        } else {
					            text = that.form.attr("data-error-message");
					        }
					        title = that.form.attr("data-error-title");
					        AlertDialog(text, {
                                titleLabel: title
                            });
					    }
					}
				},
				error: function() {
					var text = that.form.attr("data-error-message");
					var title = that.form.attr("data-error-title");
					AlertDialog(text, {
						titleLabel: title
					});
				},
				complete: function() {
				    window.mobile.offer.toggleLoader(false);
				}
			});
		};

		TransferFunds.prototype.reset = function() {
			this.form[0].reset();
			this.onTransferTypeChange($(SELECTOR_TRANSFER_TYPE_CHECKED)[0]);
		};

		module.exports = function(i18n) {
			return new TransferFunds(i18n);
		}

	});

})(modulr, $);
;
/* assets/src/scripts/fortuna/web-messages.js */
(function(modulr, $) {
    "use strict";

    modulr.define("components.web-messages", function(require, exports, module) {

        var TPL_WEB_MESSAGE = '<div class="web-message {{TYPE}}" id="web-message-{{ID}}"><div>{{CONTENT}}</div><a href="#" class="hide">&times;</a></div>';
        var LOCAL_STORAGE_KEY = "web_messages";
        var LOCAL_STORAGE_PARAMS = {path: "/"};
        
        var instance;

        function getInstance() {
            if (!instance) {
                instance = new WebMessages();
            }
            return instance;
        };

        function getStoredData() {
            var string = $.getLocalStorage(LOCAL_STORAGE_KEY, LOCAL_STORAGE_PARAMS);
            try {
                return JSON.parse(string);
            } catch (e) {}
            return {};
        };

        function storeData(data) {
            $.localStorage(LOCAL_STORAGE_KEY, JSON.stringify(data), LOCAL_STORAGE_PARAMS);
        };

        function filterMessages(messages) {
            var filteredMessages = [];
            if (messages && messages.length) {
                var storedData = getStoredData();
                var now = new Date();
                for (var i = 0; i < messages.length; i++) {
                    var msg = messages[i];
                    if (!storedData[msg.id] || (msg.showAgainAfter > -1 && now.getTime() - storedData[msg.id] > msg.showAgainAfter)) {
                        filteredMessages.push(msg);
                    }
                }
            }
            return filteredMessages;
        };

        function WebMessages() {
            this.__messages = null;
        };

        WebMessages.prototype.setMessages = function(messages) {
            this.__messages = filterMessages(messages);
            this.showNextMessage();
            return this;
        };

        WebMessages.prototype.showNextMessage = function() {
            var that = this;
            var msg = this.__messages.shift();
            if (msg) {
                var bmh = window.mobile.bottomMessagesHolder;
                if (bmh) {
                    var html = $($.Utils.expandVariables(TPL_WEB_MESSAGE, {
                        "TYPE": msg.type,
                        "ID": msg.id,
                        "CONTENT": msg.content
                    }));
                    $("a.hide", html).on("click", function(evt) {
                        evt.preventDefault();
                        var data = getStoredData();
                        data[msg.id] = (new Date()).getTime();
                        storeData(data);
                        bmh.removeMessage(html);
                        that.showNextMessage();
                    });
                    bmh.addMessage(html, true, false);
                }
            }
        };
        
        module.exports = {
            getInstance: getInstance,
            setMessages: function(messages) {
                return getInstance().setMessages(messages);
                
            }
        };
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/window-orientation-change.js */
/**
 * @example
 * var WindowOrientationChange = require("window-orientation-change");
 * 
 * WindowOrientationChange.onChange(function() {
 *   // Do whatever you want to do after phone orientation change.
 * });
 */
(function (modulr, $) {
  "use strict";
  modulr.define("window-orientation-change", function(require, exports, module) {
    /**
     * Constructor for WindowOrientationChange. It calls onChange callback when mobile orientation is changed.
     */
    function WindowOrientationChange() {
      // this, inside of event listener, contains Window object. It's a reason of that.
      var that = this;

      window.addEventListener("orientationchange", function(e) {
        that.onChange(e);
      });
    }

    /**
     * This callback will be called after mobile orientation changed.
     */
    WindowOrientationChange.prototype.onChange = function() {}

    module.exports = new WindowOrientationChange();
  });
})(modulr, $);

;
/* assets/src/scripts/fortuna/window-resize.js */
(function () {

    function windowResizeOnPageLoad(){
        $('.fluid ').forEach(function(item) {
            var height = (innerHeight || (document.documentElement.clientHeight || window.screen.height)) /2;
            var width =  (innerWidth || (document.documentElement.clientWidth || window.screen.width)) /2;
            var imgHeight = $(item).height();
            if (imgHeight > height){
               /*Lowering image, original size: " + imgHeight + " to counted size: " + height + "and lowering " + imgWidth + " to " + width);*/
                item.height = height;
                item.width = width;
            }
        });
        var scrollPage = $.getSessionStorage("scroll_page_location");
        if (scrollPage){
            console.log("Setting scroll page" + scrollPage);
            document.documentElement.scrollTop = scrollPage;
        }
    }
    $(function () {
        windowResizeOnPageLoad();

    });
})();

;
/* assets/src/scripts/fortuna/withdraw.js */
(function(modulr, $) {
    "use strict";
    
    var AlertDialog = window.mobile.dialogs.dialogAlert;
    var Analytics = modulr.require("analytics");

    modulr.define("withdraw", function(require, exports, module) {
        
        var SELECTOR_AMOUNT_INPUT = "#id-amount";
        var SELECTOR_METHOD_SELECTS = "input[name=withdrawType]";
        var SELECTOR_FORM = ".withdraw-form";
        
        function Withdraw(credit, i18n) {
            this.credit = credit;
            this.i18n = i18n;
            this.form = $(SELECTOR_FORM);
            this.amountInput = $(SELECTOR_AMOUNT_INPUT);
            this.initialize();
        }
        
        Withdraw.prototype.initialize = function() {
            this.initializeAmountInput();
            this.initializeMethodSelectors();
            this.initializeForm();
        };
        
        Withdraw.prototype.initializeAmountInput = function() {
        	this.amountInput.attr("type", "text");
            this.amountInput.on("focus", function() {
                this.scrollIntoView && this.scrollIntoView();
                var value = parseFloat(this.value.replace(/ /g, "").replace(",", "."));
                this.setAttribute("type", "number");
                this.value = value;
                this.setAttribute("data-just-focused", "true");
                var that = this;
                window.setTimeout(function() {
                    that.removeAttribute("data-just-focused");
                }, 100);
            });
            this.amountInput.on("blur", function() {
                var that = this;
                if (this.getAttribute("data-just-focused")) {
                    return;
                }
                that.setAttribute("type", "text");
            });
        };
        
        Withdraw.prototype.initializeMethodSelectors = function() {
            var that = this;
            $(SELECTOR_METHOD_SELECTS).on("change", function() {
                that.onWithdrawMethodChange(this);
            });
        };
        
        Withdraw.prototype.initializeForm = function() {
            var that = this;
            this.form.on("submit", function(evt) {
                that.onFormSubmit(evt);
            });
        };
        
        Withdraw.prototype.onWithdrawMethodChange = function(elm) {
            this.amountInput
                .attr("min", elm.getAttribute("data-min"))
                .attr("max", elm.getAttribute("data-max"));
            var info = document.getElementById("method-description");
            if (info) {
                info.innerHTML = elm.getAttribute("data-description");
            }
        };
        
        Withdraw.prototype.onFormSubmit = function(evt) {
            var that = this;
            var values = this.form.serializeMap();
            var withdrawType = values["withdrawType"];
            if (!withdrawType) {
                evt.preventDefault();
                AlertDialog(this.i18n.WITHDRAW_METHOD_NOT_SELECTED, {
                    titleLabel: that.i18n.ERROR_TITLE
                });
                Analytics.Withdraw.error(this.i18n.WITHDRAW_METHOD_NOT_SELECTED);
                return;
            }
            var amount = parseFloat(values["amount"]);
            if (isNaN(amount)) {
                evt.preventDefault();
                AlertDialog(this.i18n.NOT_VALID_NUMBER, {
                    titleLabel: that.i18n.ERROR_TITLE
                });
                Analytics.Withdraw.error(this.i18n.NOT_VALID_NUMBER);
                return;
            }
            if (amount === 0) {
                evt.preventDefault();
                AlertDialog(this.i18n.VALUE_IS_ZERO, {
                    titleLabel: that.i18n.ERROR_TITLE
                });
                Analytics.Withdraw.error(this.i18n.VALUE_IS_ZERO);
                return;
            }
            if (amount > this.credit) {
                evt.preventDefault();
                AlertDialog(this.i18n.VALUE_HIGHER_THAN_CREDIT, {
                    titleLabel: that.i18n.ERROR_TITLE
                });
                Analytics.Withdraw.error(this.i18n.VALUE_HIGHER_THAN_CREDIT);
                return;
            }
            var min = parseFloat(this.amountInput.attr("min"));
            if (!isNaN(min) && amount < min) {
                evt.preventDefault();
                AlertDialog(this.i18n.VALUE_LOWER_THAN_MINIMAL, {
                    titleLabel: that.i18n.ERROR_TITLE
                });
                Analytics.Withdraw.error(this.i18n.VALUE_LOWER_THAN_MINIMAL);
                return;
            }
            var max = parseFloat(this.amountInput.attr("max"));
            if (!isNaN(max) && amount > max) {
                evt.preventDefault();
                AlertDialog(this.i18n.VALUE_HIGHER_THAN_MAXIMAL, {
                    titleLabel: that.i18n.ERROR_TITLE
                });
                Analytics.Withdraw.error(this.i18n.VALUE_HIGHER_THAN_MAXIMAL);
                return;
            }
            $.cookie("last_withdraw", amount + "--" + withdrawType , {expires: 365});
            Analytics.Withdraw.send($("[name=withdrawType]:checked").attr("id").replace("withdraw-", ""), amount);
        };
        
        module.exports = function(credit, i18n) {
            return new Withdraw(credit, i18n);
        }
        
    });
})(modulr, $);
;
/* assets/src/scripts/fortuna/consents/consents-common.js */
(function(modulr, $) {
    "use strict";

    modulr.define("consents.common", function(require, exports, module) {

        function doConsentUpdateRequest(url, data, messages, onSuccess, onError) {
            if (url) {
                $.ftnAjax(url, {
                    method: "post",
                    data: data,
                    beforeSend: function() {
                        window.mobile.offer.toggleLoader(true);
                    },
                    success: function(data, status, xhr) {
                        typeof onSuccess == "function" && onSuccess(data, status, xhr);
                    },
                    error: function(xhr, status, responseText) {
                        typeof onError == "function" && onError(xhr, status, responseText);
                        window.mobile.dialogs.dialogToast(messages.ERROR_COMUNICATION);
                    },
                    complete: function() {
                        window.mobile.offer.toggleLoader(false);
                    }
                });
            }
        };

        module.exports = {
            doConsentUpdateRequest: doConsentUpdateRequest
        };
    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/consents/consents-personal-data-settings.js */
(function (modulr, $) {
  "use strict";

  modulr.define("consents.personal-settings", function (require, exports, module) {

    var SELECTOR_CONSENTS_CHECKBOXES = "input[type=checkbox].consent";
    var SELECTOR_CHECKBOXES = ".notification-settings-form input[id*='newsletter']";
    var _form;

    function initialize(form, options) {
      _form = $(form);
      $(SELECTOR_CONSENTS_CHECKBOXES).on("change", function () {
        onConsentChange(this, options);
      });
    };

    function onConsentChange(consent, options) {

      if (!consent.checked && consent.getAttribute("data-prompt")) {
        window.mobile.dialogs.dialogConfirm(consent.getAttribute("data-prompt"), {
          cancelButtonLabel: options.i18n.WITHDRAW_CONSENT,
          okButtonLabel: options.i18n.CANCEL_CHANGES,
          cancelCallback: function () {
            doUpdateConsentRequest(consent.value, {"userChoice": "withdraw"}, consent, options);
            var check = $(SELECTOR_CHECKBOXES, _form);
            for (var i = 0; i < check.length; i++) {
              check[i].checked = false;
              check[i].setAttribute("data-consent-agreement-needed", "1");
            }
          },
          okCallback: function () {
            restorePreviousValue(consent);
          }
        });
      } else if (!consent.checked) {
        doUpdateConsentRequest(consent.value, {"userChoice": "withdraw"}, consent, options);
        var check = $(SELECTOR_CHECKBOXES, _form);
        for (var i = 0; i < check.length; i++) {
          check[i].checked = false;
          check[i].setAttribute("data-consent-agreement-needed", "1");
        }
      } else {
        doUpdateConsentRequest(consent.value, {"userChoice": "agree"}, consent, options);
        var check = $(SELECTOR_CHECKBOXES, _form);
        for (var i = 0; i < check.length; i++) {
          check[i].checked = true;
        }
      }

    };

    function restorePreviousValue(consent) {

      consent.checked = !consent.checked;
    };

    function doUpdateConsentRequest(type, data, consent, options) {
      if (options.updateUrl) {
        var url = $.Link($.Utils.expandVariables(options.updateUrl, {"type": type})).ajaxify().getUrl();
        if (url) {
          require("consents.common").doConsentUpdateRequest(url, data, options.i18n, function () {
            window.location.href = window.location.href;
          }, function () {
            restorePreviousValue(consent);
          });
        }
      }
    };

    module.exports = function (form, options) {
      initialize(form, options);
    };

  });

})(modulr, $);
;
/* assets/src/scripts/fortuna/consents/consents-popup.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("consents.popups", function(require, exports, module) {
        
        function buildContent(consentsInstance, message) {
            var content = "";
            if (message.description) {
                content += message.description;
            }
            if (message.hasFullAgreement && consentsInstance.__commonData.fullAgreementUrl) {
                content += '<p><a href="' + $.Utils.expandVariables(consentsInstance.__commonData.fullAgreementUrl, {
                    "type": message.type
                }) + '"' + (consentsInstance.__commonData.targetBlank ? ' target="_blank"' : '') + '>' + consentsInstance.__commonData.i18n.FULL_AGREEMENT + '</a></p>';
            }
            if (message.hasFullConditions && consentsInstance.__commonData.fullConditionsUrl) {
                content += '<p><a href="' + $.Utils.expandVariables(consentsInstance.__commonData.fullConditionsUrl, {
                    "type": message.type
                }) + '"' + (consentsInstance.__commonData.targetBlank ? ' target="_blank"' : '') + '>' + consentsInstance.__commonData.i18n.FULL_CONDITIONS + '</a></p>';
            }
            if (consentsInstance.__commonData.checkbox) {
                content += consentsInstance.__commonData.checkbox;
            }
            return content;
        };

        function showPopup(consentsInstance, message) {
            window.mobile.dialogs.dialogConfirm(buildContent(consentsInstance, message), {
                specialClass: "consents-dialog",
                titleLabel: message.title,
                okButtonLabel: consentsInstance.__commonData.i18n.ACCEPT,
                cancelButtonLabel: consentsInstance.__commonData.i18n.CANCEL,
                okCallback: function() {
                    onOkClick(consentsInstance, message);
                },
                cancelCallback: function() {
                    onCancelClick(consentsInstance, message);
                }
            });
        };
        
        function onOkClick(consentsInstance, message) {
            if (typeof consentsInstance.__commonData.onAccept == "function") {
                consentsInstance.__commonData.beforeAccept();
            }
            doConsentUpdateRequest(consentsInstance, message, {
                "userChoice": "agree"
            }, {
                success: function() {
                    if (typeof consentsInstance.__commonData.onAccept == "function") {
                        consentsInstance.__commonData.onAccept();
                    }
                },
                error: function() {
                    if (typeof consentsInstance.__commonData.onError == "function") {
                        consentsInstance.__commonData.onError();
                    }
                }
            });
        };
        
        function onCancelClick(consentsInstance, message) {
            if (consentsInstance.__commonData.disableBackendCallOnDecline) {
                consentsInstance.__commonData.onDecline();
            } else {
                doConsentUpdateRequest(consentsInstance, message, {
                    "userChoice": "cancel",
                    "notShowNextTime": $("#consents-show-never-more")[0].checked
                }, {
                    success: function() {
                        if (typeof consentsInstance.__commonData.onDecline == "function") {
                            consentsInstance.__commonData.onDecline();
                        }
                    },
                    error: function() {
                        if (typeof consentsInstance.__commonData.onError == "function") {
                            consentsInstance.__commonData.onError();
                        }
                    }
                });
            }
        };
        
        function doConsentUpdateRequest(consentsInstance, message, data, callbacks) {
            if (consentsInstance.__commonData.updateUrl) {
                var url = $.Link($.Utils.expandVariables(consentsInstance.__commonData.updateUrl, {type: message.type})).ajaxify().getUrl();
                require("consents.common").doConsentUpdateRequest(url, data, consentsInstance.__commonData.i18n, function() {
                    consentsInstance.show();
                    if (callbacks && typeof callbacks.success == "function") {
                        callbacks.success();
                    }
                }, function() {
                    consentsInstance.show();
                    if (callbacks && typeof callbacks.success == "function") {
                        callbacks.error();
                    }
                });
            }
        };
        
        function Consents(consentsList, commonData, afterLoginPopupsInstance) {
            this.__consentsList = consentsList;
            this.__commonData = commonData;
            this.__afterLoginPopupsInstance = afterLoginPopupsInstance;
            this.show();
        };
        
        Consents.prototype.show = function() {
            var actualConsent = this.__consentsList.shift();
            if (actualConsent) {
                showPopup(this, actualConsent);
            } else if (this.__afterLoginPopupsInstance) {
                this.__afterLoginPopupsInstance.show();
            }
        };
        
        module.exports = function(consentsList, commonData, afterLoginPopupsInstance) {
            return new Consents(consentsList, commonData, afterLoginPopupsInstance);
        };
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/_number_inputs_fix.js */
(function(modulr, $) {
	"use strict";
	
	modulr.define("forms._number-inputs-fix", function(require, exports, module) {
		
		var SELECTOR_INPUT_NUMBER = "input[type=number]";
		
		function numberInputsFix(form) {
			$(form)
				.find(SELECTOR_INPUT_NUMBER)
					.forEach(function(itm) {
						itm.type = "text";
					})
					.on("focus", function() {
						this.type = "number";
					})
					.on("blur", function() {
						this.type = "text";
					});
		};
		
		module.exports = numberInputsFix;
		
	});
	
})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/_toggling-labels.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("_toggling-labels", function(require, exports, module) {
        
        var SELECTOR_BASE = ".toggling-label";
        var SELECTOR_INPUTS = SELECTOR_BASE + " input";
        var SELECTOR_SELECTS = SELECTOR_BASE + " select";
        var SELECTOR_ALL = [SELECTOR_INPUTS, SELECTOR_SELECTS].join(", ");
        
        var CLASS_FILLED = "filled";
        var CLASS_FOCUSED = "focused";
        
        function findParent(elm) {
            return $(elm).parent(SELECTOR_BASE);
        }
        
        function TogglingLabels(form) {
            this._initialize(form);
        };
        
        TogglingLabels.prototype._initialize = function(form) {
            $(SELECTOR_ALL, form).forEach(function(itm) {
                if (itm.value) {
                    findParent(itm).toggleClass(CLASS_FILLED, true);
                }
            });
            $(SELECTOR_INPUTS, form)
                .on("focus", function() {
                    findParent(this).toggleClass(CLASS_FOCUSED, true);
                    this.setAttribute("data-placeholder", this.getAttribute("placeholder") || "");
                    this.setAttribute("placeholder", "");
                })
                .on("blur", function() {
                    findParent(this)
                        .toggleClass(CLASS_FOCUSED, false)
                        .toggleClass(CLASS_FILLED, !!this.value);
                    this.setAttribute("placeholder", this.getAttribute("data-placeholder") || "");
                    
                });
            $(SELECTOR_SELECTS, form)
                .on("change", function() {
                    findParent(this).toggleClass(CLASS_FILLED, !!this.value);
                });
        };
        
        module.exports = function(form) {
            return new TogglingLabels(form);
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/add-personal-info-form.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("add-personal-info-form", function(require, exports, module) {
        
        var SELECTOR_PEP_YES = "#pep-yes";
        var SELECTOR_PEP_NO = "#pep-no";
        var SELECTOR_PEP_YES_LABEL = "label[for=pep-yes]";
        var SELECTOR_PEP_NO_LABEL = "label[for=pep-no]";
        var SELECTOR_PEP_YES_NOTICE = "#pep-yes-notice";
        var SELECTOR_AGREE_WITH_PEP_CONDITIONS = "input[type=hidden][name=agreeWithPEPConditions]";
        
        var ConfirmDialog = window.mobile.dialogs.dialogConfirm;
        
        function AddPersonalInfoForm(form) {
            this._form = $(form);
            this._pepYes = $(SELECTOR_PEP_YES, this._form);
            this._pepNo = $(SELECTOR_PEP_NO, this._form);
            this._pepYesLabel = $(SELECTOR_PEP_YES_LABEL, this._form);
            this._pepNoLabel = $(SELECTOR_PEP_NO_LABEL, this._form);
            this._pepYesNotice = $(SELECTOR_PEP_YES_NOTICE, this._form);
            this._agreeWithPEPConditions = $(SELECTOR_AGREE_WITH_PEP_CONDITIONS, this._form);
            this._initializeEvents();
        };
        
        AddPersonalInfoForm.prototype._initializeEvents = function() {
            var that = this;
            this._pepYesLabel.on("click", function() {
                that._onPepYesChange();
            })
            this._pepNoLabel.on("click", function() {
                that._agreeWithPEPConditions.val(null);
            });
        };
        
        AddPersonalInfoForm.prototype._onPepYesChange = function() {
            if (this._pepYesNotice.length && !this._pepYes.prop("checked")) {
                var that = this;
                var isNoChecked = that._pepNo.prop("checked");
                ConfirmDialog(this._pepYesNotice.html(), {
                    okButtonLabel: that._pepYesNotice.attr("data-ok-button-label"),
                    cancelButtonLabel: that._pepYesNotice.attr("data-cancel-button-label"),
                    okCallback: function() {
                        that._agreeWithPEPConditions.val(true);
                    },
                    cancelCallback: function() {
                        that._pepYes.prop("checked", false);
                        that._pepNo.prop("checked", isNoChecked);
                        that._agreeWithPEPConditions.val(null);
                    }
                });
            }
        }
        
        module.exports = function(form, i18n) {
            return new AddPersonalInfoForm(form);
        }
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/bank-account-form.js */
(function(modulr, $) {
    "use strict";

    modulr.define("bank-account-form", function(require, exports, module) {

        function AddBankAccountForm(form) {
            this._form = $(form);
            require("_toggling-labels")(form);
        };
        
        module.exports = function(form) {
            return new AddBankAccountForm(form);
        };

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/bank-account-verify-form.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("bank-account-verify-form", function(require, exports, module) {
        
        var MobileFileInput = require("mobile-file-input");
        
        var SELECTOR_SUBMIT_BUTTON = "button[type=submit]";
        var SELECTOR_IBAN_INPUT = "#id-iban";
        var SELECTOR_DOCUMENT_INPUT = "#id-document";
        
        var CLASS_HIDDEN = "hidden";
        var CLASS_UPLOADING = "uploading";

        function BankAccountVerifyForm(form) {
            this._form = $(form);
            this._ibanInput = $(SELECTOR_IBAN_INPUT, this._form);
            this._fileInput = MobileFileInput.newInstance(SELECTOR_DOCUMENT_INPUT);
            this._submit = $(SELECTOR_SUBMIT_BUTTON, this._form);
            this._initialize();
        };
        
        BankAccountVerifyForm.prototype._initialize = function() {
            this.toggleSubmitButton();
            this._initializeEvents();
        };
        
        BankAccountVerifyForm.prototype._initializeEvents = function() {
            var that = this;
            this._ibanInput.on("blur", function() {
                that.toggleSubmitButton();
            });
            this._fileInput._input.on("change", function() {
                that.toggleSubmitButton();
            });
            this._fileInput._clearButton.on("click", function() {
                that.toggleSubmitButton();
            });
            this._form.on("submit", function(evt) {
                if (that._form.hasClass(CLASS_UPLOADING)) {
                    evt.preventDefault();
                    return false;
                }
                that._form.toggleClass(CLASS_UPLOADING, true);
            });
        };
        
        BankAccountVerifyForm.prototype.toggleSubmitButton = function() {
            if (this._ibanInput.val() && this._fileInput._input.val()) {
                this._submit.toggleClass(CLASS_HIDDEN, false);
            } else {
                this._submit.toggleClass(CLASS_HIDDEN, true);
            }
        };
        
        module.exports = function(form) {
            return new BankAccountVerifyForm(form);
        }
        
    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/change-password-form.js */
(function($) {
    "use strict";

    modulr.define("change-password-form", function(require, exports, module) {

        var FormValidator = require("form-validator");
        var AlertDialog = window.mobile.dialogs.dialogAlert;
        var PasswordToggler = require("password-toggler");

        var SELECTOR_SHOW_PASSWORD = ".show-password";
        var SELECTOR_OLD_PASSWORD = "#id-old-password";
        var SELECTOR_NEW_PASSWORD = "#id-new-password";
        var SELECTOR_NEW_PASSWORD_CONFIRM = "#id-new-password-confirm";
        var CLASS_HIDDEN = "hidden";
        var CLASS_ICON_EYE = "eye";
        var CLASS_ICON_EYE_DISABLED = "eye-disabled";

        var ChangePasswordForm = function(form) {
            this._form = $(form);
            this._validator = FormValidator(form);
            this.initializeEvents();
        };

        ChangePasswordForm.prototype.initializeEvents = function() {
            var that = this;
            this._form.on("submit", function(evt) {
               that.onFormSubmit(evt);
            });
            var onToggle = function(input, toggler) {
            	var $input = $(input);
        		if ($input.is(SELECTOR_NEW_PASSWORD)) {
        			$(SELECTOR_NEW_PASSWORD_CONFIRM).parent("div").toggleClass(CLASS_HIDDEN, $input.attr("type") !== "password");
        		}
        	};
        	$(SELECTOR_SHOW_PASSWORD, this._form).forEach(function(item) {
            	PasswordToggler(item, onToggle);
            });
        };

        ChangePasswordForm.prototype.onFormSubmit = function(evt) {
            evt.preventDefault();
            this.precheck();
            if (this._validator.isValid()) {
                this.send();
            }
        };

        ChangePasswordForm.prototype.precheck = function() {
            var newPassword = $(SELECTOR_NEW_PASSWORD);
            if (newPassword.length && newPassword.attr("type") === "text") {
                $(SELECTOR_NEW_PASSWORD_CONFIRM).val(newPassword.val());
            }
        };

        ChangePasswordForm.prototype.send = function() {
            var that = this;
            var values = this._form.serialize();
            $.ftnAjax($.Link(this._form.attr("action")).ajaxify().getUrl(), {
                "method": "POST",
                "data": values,
                success: function(data) {
                    var jsonData = JSON.parse(data);
                    var text;
                    var title;
                    if (jsonData.status === "ok") {
                        text = that._form.attr("data-success-message");
                        title = that._form.attr("data-success-title");
                        $(SELECTOR_OLD_PASSWORD + ", " + SELECTOR_NEW_PASSWORD + ", " + SELECTOR_NEW_PASSWORD_CONFIRM, that._form).val("");
                    } else {
                        text = jsonData.message || that._form.attr("data-error-message");
                        title = that._form.attr("data-error-title");
                    }
                    AlertDialog(text, {
                        titleLabel: title
                    });
                },
                error: function() {
                    var text = jsonData.message || that._form.attr("data-error-message");
                    var title = that._form.attr("data-error-title");
                    AlertDialog(text, {
                        titleLabel: title
                    });
                }
            });
        };

        module.exports = function(form) {
            return new ChangePasswordForm(form);
        }

    });

})($);
;
/* assets/src/scripts/fortuna/forms/change-pin-form.js */
(function ($) {
  "use strict";

  modulr.define("change-pin-form", function (require, exports, module) {

    var FormValidator = require("form-validator");
    var AlertDialog = window.mobile.dialogs.dialogAlert;
    var PasswordToggler = require("password-toggler");

    var SELECTOR_SHOW_PIN = ".show-pin";
    var SELECTOR_NEW_PIN = "#id-new-pin";
    var SELECTOR_NEW_PIN_CONFIRM = "#id-new-pin-confirm";
    var CLASS_HIDDEN = "hidden";

    var ChangePinForm = function (form) {
      this._form = $(form);
      this._validator = FormValidator(form);
      this.initializeEvents();
    };

    ChangePinForm.prototype.initializeEvents = function () {
      var that = this;
      this._form.on("submit", function (evt) {
        that.onFormSubmit(evt);
      });
      var onToggle = function (input) {
        var $input = $(input);
        if ($input.is(SELECTOR_NEW_PIN)) {
          $(SELECTOR_NEW_PIN_CONFIRM).parent("div").toggleClass(CLASS_HIDDEN, $input.attr("type") !== "password");
        }
      };
      $(SELECTOR_SHOW_PIN, this._form).forEach(function (item) {
        PasswordToggler(item, onToggle);
      });
    };

    ChangePinForm.prototype.onFormSubmit = function (evt) {
      evt.preventDefault();
      this.precheck();
      if (this._validator.isValid()) {
        this.send();
      }
    };

    ChangePinForm.prototype.precheck = function () {
      var newPin = $(SELECTOR_NEW_PIN);
      if (newPin.length && newPin.attr("type") === "text") {
        $(SELECTOR_NEW_PIN_CONFIRM).val(newPin.val());
      }
    };

    ChangePinForm.prototype.send = function () {
      var that = this;
      var values = this._form.serialize();
      $.ftnAjax($.Link(this._form.attr("action")).ajaxify().getUrl(), {
        "method": "POST",
        "data": values,
        success: function (data) {
          var jsonData = {};
          try {
            jsonData = JSON.parse(data);
          } catch (e) {
            // do nothing
          }
          var text = jsonData.message || that._form.attr("data-success-message");
          var title = that._form.attr("data-success-title");
          AlertDialog(text, {
            titleLabel: title
          });
          that._form[0].reset();
        },
        error: function (xhr, status, responseText) {
          var jsonData = {};
          try {
            jsonData = JSON.parse(responseText);
          } catch (e) {
            // do nothing
          }
          if (jsonData.messages) {
            for (var i in jsonData.messages) {
              if (i) {
                $("[name=" + i + "]")
                  .toggleClass("error", true)
                  .after('<span class="error-description">' + jsonData.messages[i] + '</span>');
              }
            }
          }
          var text = jsonData.messages.global || that._form.attr("data-error-message");
          var title = that._form.attr("data-error-title");
          AlertDialog(text, {
            titleLabel: title
          });
        }
      });
    };

    module.exports = function (form) {
      return new ChangePinForm(form);
    };

  });
})($);
;
/* assets/src/scripts/fortuna/forms/complete-personal-data-form.js */
(function (modulr, $) {
  "use strict";

  modulr.define("complete-personal-data-form", function (require, exports, module) {

    var SELECTOR_INPUT = "input";
    var SELECTOR_INPUT_GROUP = ".input-group";
    var SELECTOR_NATIONALITY = "#id-nationality";
    var SELECTOR_POST_ADDRESS_BOX = "#post-address-box";
    var SELECTOR_BIRTH_NUMBER = "#id-birth-number";
    var SELECTOR_WHISPERER = ".whisperer";
    var SELECTOR_PERMANENT_RESIDENCE_COUNTRY = "#id-permanent-residence-country";
    var SELECTOR_PERMANENT_RESIDENCE_POSTAL = "#id-permanent-residence-postal";
    var SELECTOR_PERMANENT_RESIDENCE_CITY = "#id-permanent-residence-city";
    var SELECTOR_PERMANENT_RESIDENCE_CITY_FAKE = "#id-permanent-residence-city-fake";
    var SELECTOR_PERMANENT_RESIDENCE_STREET = "#id-permanent-residence-street";
    var SELECTOR_PERMANENT_RESIDENCE_HOUSE_NUMBER = "#id-permanent-residence-house-number";
    var SELECTOR_POST_ADDRESS_POSTAL = "#id-post-address-postal";
    var SELECTOR_POST_ADDRESS_CITY = "#id-post-address-city";
    var SELECTOR_POST_ADDRESS_CITY_FAKE = "#id-post-address-city-fake";
    var SELECTOR_POST_ADDRESS_STREET = "#id-post-address-street";
    var SELECTOR_POST_ADDRESS_HOUSE_NUMBER = "#id-post-address-house-number";

    var CLASS_FOCUSED = "focused";
    var CLASS_FILLED = "filled";
    var CLASS_ERROR = "error";
    var CLASS_HIDDEN = "hidden";

    var TIMEOUT_WHISPERER_BLUR = 300;
    var TIMEOUT_WHISPERER_OPEN = 300;

    var WHISPERER_SETTINGS = {
      "POSTAL": {
        "valueCondition": function (value) {
          return value && value.length > 2;
        },
        "urlResolver": function (url, elm) {
          return $.Link(url).appendParam("postal", elm.value);
        },
        "onItemSelect": function (evt, whisperer) {
          var target = $(evt.target).closest("li");
          var selectedPostal = $(".postal", target).text();
          var selectedCityElm = $(".city", target);
          var selectedCityName = selectedCityElm.text();
          var selectedCityCity = selectedCityElm.attr("data-city");
          var selectedCityPart = selectedCityElm.attr("data-city-part");
          var cities = $(".postal-" + selectedPostal, whisperer).map(function (itm) {
            var city = $(".city", itm);
            return {
              name: city.html(),
              city: city.attr("data-city"),
              part: city.attr("data-city-part"),
              hasStreets: city.attr("data-has-streets")
            };
          });
          this._items.POSTAL.val(selectedPostal);
          this._onPostalSet(selectedPostal, selectedCityName, cities, selectedCityCity, selectedCityPart);
          this._trySetStreetForCity(selectedCityName);
        },
        "emptyValue": function () {
          this._onPostalSet("", "", [], "", "");
        }
      },
      "STREET": {
        "valueCondition": function (value) {
          return value && value.length > 0;
        },
        "prerequisitiesMet": function () {
          return this._items.POSTAL.val() && this._items.CITY.val();
        },
        "urlResolver": function (url, elm) {
          var selectedCityItem = $("option[value='" + this._items.CITY.val() + "']", this._items.CITY_FAKE);
          return $.Link(url)
            .appendParam("postal", this._items.POSTAL.val())
            .appendParam("city", selectedCityItem.attr("data-city"))
            .appendParam("cityPart", selectedCityItem.attr("data-city-part"))
            .appendParam("street", elm.value);
        },
        "onItemSelect": function (evt) {
          var target = $(evt.target).closest("li");
          var streetElm = $(".street", target);
          var selectedStreet = streetElm.text();
          this._items.STREET.val(selectedStreet);
          this._onStreetSet(selectedStreet);
        }
      },
      "HOUSE_NUMBER": {
        "valueCondition": function (value) {
          return value && value.length > 0;
        },
        "prerequisitiesMet": function () {
          return this._items.POSTAL.val() && this._items.CITY.val() && this._items.STREET.val();
        },
        "urlResolver": function (url, elm) {
          var selectedCityItem = $("option[value='" + this._items.CITY.val() + "']", this._items.CITY_FAKE);
          return $.Link(url)
            .appendParam("postal", this._items.POSTAL.val())
            .appendParam("city", selectedCityItem.attr("data-city"))
            .appendParam("cityPart", selectedCityItem.attr("data-city-part"))
            .appendParam("street", (this._items.STREET.val() === this._items.CITY.val() ? "" : this._items.STREET.val()))
            .appendParam("houseNumber", elm.value);
        },
        "onItemSelect": function (evt) {
          var target = $(evt.target).closest("li");
          var streetElm = $(".house-number", target);
          var selectedStreet = streetElm.text();
          this._items.HOUSE_NUMBER.val(selectedStreet);
          this._onHouseNumberSet(selectedStreet);
        }
      }
    };

    function Address(elements, opts) {
      this._items = {
        COUNTRY: $(elements.COUNTRY),
        POSTAL: $(elements.POSTAL),
        CITY: $(elements.CITY),
        CITY_FAKE: $(elements.CITY_FAKE),
        STREET: $(elements.STREET),
        HOUSE_NUMBER: $(elements.HOUSE_NUMBER)
      };
      this._opts = opts;
      this._initialize();
      this._initializeEvents();
      this._useWhisperer = true;
    }

    Address.prototype._initialize = function () {
      this._setUseWhisperer();
      if (this._items.COUNTRY && this._items.COUNTRY.length) {
        this._opts.form.onPermanentAddressCountryChange(this._items.COUNTRY.val());
      }
      this._setFieldReadOnly(true, "CITY", "STREET", "HOUSE_NUMBER");
      this._initializeFields();
    };

    Address.prototype._initializeFields = function () {
      var postal = !!this._items.POSTAL.val();
      var street = !!this._items.STREET.val();
      var houseNumber = !!this._items.HOUSE_NUMBER.val();
      // Teď jsou všechny readOnly
      var isStreetEditable = postal && !street;
      var isHouseNumberEditable = street && !houseNumber;
      this._items.STREET.toggleClass("disabled", !isStreetEditable).prop("readOnly", !isStreetEditable);
      this._items.HOUSE_NUMBER.toggleClass("disabled", !isHouseNumberEditable).prop("readOnly", !isHouseNumberEditable);
    };

    Address.prototype._initializeEvents = function () {
      var that = this;
      this._items.COUNTRY.on("change", function () {
        that._onCountryChange(this.value);
      });
      this._items.POSTAL
        .on("focus", function () {
          this.setAttribute("data-value", this.value);
          that._toggleWhisperer(this, [], {
            show: true
          });
        })
        .on("keyup", function () {
          if (this.getAttribute("data-value") !== this.value) {
            that._whisper(this, "POSTAL");
          }
        })
        .on("blur", function () {
          that._toggleWhisperer(this);
        });
      this._items.CITY_FAKE.on("change", function () {
        that._onCityChange(this.value);
      });
      this._items.STREET
        .on("focus", function () {
          this.setAttribute("data-value", this.value);
          that._toggleWhisperer(this, [], {
            show: true
          });
        })
        .on("keyup", function () {
          if (this.getAttribute("data-value") !== this.value) {
            that._whisper(this, "STREET");
          }
        })
        .on("blur", function () {
          that._toggleWhisperer(this);
          if (this.value !== this.getAttribute("data-value")) {
            that._onStreetSet(this.value);
          }
        });
      this._items.HOUSE_NUMBER
        .on("focus", function () {
          this.setAttribute("data-value", this.value);
          that._toggleWhisperer(this, [], {
            show: true
          });
        })
        .on("keyup", function () {
          if (this.getAttribute("data-value") !== this.value) {
            that._whisper(this, "HOUSE_NUMBER");
          }
        })
        .on("blur", function () {
          that._toggleWhisperer(this);
        });
    };

    Address.prototype._cleanField = function () {
      for (var i = 0, l = arguments.length; i < l; i++) {
        if (this._items[arguments[i]]) {
          if (this["_cleanField" + arguments[i]] && typeof this["_cleanField" + arguments[i]] === "function") {
            this["_cleanField" + arguments[i]]();
          } else {
            this._items[arguments[i]].val("").parent(SELECTOR_INPUT_GROUP).toggleClass(CLASS_ERROR, true).toggleClass(CLASS_FILLED, false);
          }
        }
      }
      return this;
    };

    Address.prototype._cleanFieldCITY = function () {
      this._setCity("", [], "", "");
    };

    Address.prototype._setFieldReadOnly = function () {
      for (var i = 1, l = arguments.length; i < l; i++) {
        if (this._items[arguments[i]]) {
          this._items[arguments[i]].prop("readOnly", arguments[0]);
        }
      }
      return this;
    };

    Address.prototype._setUseWhisperer = function () {
      if (this._opts.form && this._items.COUNTRY.length) {
        this._useWhisperer = this._items.COUNTRY.val() === this._opts.form._homeCountry;
      }
    };

    Address.prototype._onCountryChange = function (country) {
      this._setUseWhisperer();
      this._cleanField("POSTAL", "CITY", "STREET", "HOUSE_NUMBER");
      this._setFieldReadOnly(this._useWhisperer, "CITY", "STREET", "HOUSE_NUMBER");
      this._opts.form.onPermanentAddressCountryChange(country);
    };

    Address.prototype._onPostalSet = function (postal, selectedCity, cities, city, cityPart) {
      this._cleanField("CITY", "STREET", "HOUSE_NUMBER");
      this._setFieldReadOnly(true, "HOUSE_NUMBER");
      this._setFieldReadOnly(!postal, "STREET");
      this._setCity(selectedCity, cities, city, cityPart);

    };

    Address.prototype._onCityChange = function (selectedCity) {
      this._cleanField("STREET", "HOUSE_NUMBER");
      this._setFieldReadOnly(true, "HOUSE_NUMBER");
      this._items.CITY.val(selectedCity);
      this._trySetStreetForCity(selectedCity);
    };

    Address.prototype._onStreetSet = function (selectedStreet) {
      this._cleanField("HOUSE_NUMBER");
      this._setFieldReadOnly(!selectedStreet, "HOUSE_NUMBER");
      this._items.STREET.parent(SELECTOR_INPUT_GROUP).toggleClass(CLASS_ERROR, !selectedStreet).toggleClass(CLASS_FILLED, !!selectedStreet);
    };

    Address.prototype._onHouseNumberSet = function () {
    };

    Address.prototype._setCity = function (selectedCity, cities) {
      var input = this._items.CITY;
      var select = this._items.CITY_FAKE;
      var selectHolder = select.parent(".mobile-select");
      var selectContent = "";
      for (var i = 0, l = cities.length; i < l; i++) {
        selectContent += '<option value="' + cities[i].name + '" data-city="' + cities[i].city + '" data-city-part="' + cities[i].part + '" data-has-streets="' + cities[i].hasStreets + '">' + cities[i].name + '</option>\n';
      }
      select.toggleClass(CLASS_HIDDEN, cities.length < 2);
      selectHolder.toggleClass(CLASS_HIDDEN, cities.length < 2);
      input.toggleClass(CLASS_HIDDEN, cities.length > 1);
      select.html(selectContent);
      select.val(selectedCity);
      input.val(selectedCity);
      $(".select", selectHolder).html(selectedCity);
      $(select.parent(SELECTOR_INPUT_GROUP)).toggleClass(CLASS_ERROR, !selectedCity).toggleClass(CLASS_FILLED, !!selectedCity);
    };

    Address.prototype._whisper = function (elm, type) {
      if (!this._useWhisperer) {
        return;
      }
      if (!elm) {
        return;
      }
      if (!elm || !WHISPERER_SETTINGS[type]) {
        return;
      }
      if (WHISPERER_SETTINGS[type].prerequisitiesMet && typeof WHISPERER_SETTINGS[type].prerequisitiesMet === "function" && !WHISPERER_SETTINGS[type].prerequisitiesMet.call(this)) {
        return;
      }
      var that = this;
      var url = elm.getAttribute("data-whisperer-url");
      if (!url) {
        return;
      }
      if (WHISPERER_SETTINGS[type].valueCondition.call(this, elm.value)) {
        url = WHISPERER_SETTINGS[type].urlResolver.call(this, url, elm);
        if (this._whispererTimeout) {
          window.clearTimeout(this._whispererTimeout);
          this._whispererTimeout = null;
        }
        this._whispererTimeout = window.setTimeout(function () {
          $.ftnAjax(url.ajaxify().getUrl(), {
            beforeSend: function () {
              $(elm.parentElement).toggleClass("loading", true);
            },
            success: function (data) {
              that._toggleWhisperer(elm, data, {
                onItemSelect: WHISPERER_SETTINGS[type].onItemSelect
              });
            },
            complete: function () {
              $(elm.parentElement).toggleClass("loading", false);
            }
          });
        }, TIMEOUT_WHISPERER_OPEN);
      } else {
        WHISPERER_SETTINGS[type].emptyValue && typeof WHISPERER_SETTINGS[type].emptyValue === "function" && WHISPERER_SETTINGS[type].emptyValue.call(this);
        this._toggleWhisperer(elm);
      }
    };

    Address.prototype._toggleWhisperer = function (elm, data, opts) {
      var whisperer = $(SELECTOR_WHISPERER, elm.parentElement);
      if (whisperer.length && opts && opts.show && whisperer.html() && whisperer.attr("input") === elm.value) {
        whisperer.toggleClass(CLASS_HIDDEN, false);
      }
      if (!data && whisperer.length) {
        if (this._whispererTimeout) {
          window.clearTimeout(this._whispererTimeout);
          this._whispererTimeout = null;
        }
        window.setTimeout(function () {
          whisperer.toggleClass(CLASS_HIDDEN, true);
        }, TIMEOUT_WHISPERER_BLUR);
        return;
      }
      if (!whisperer.length) {
        whisperer = $('<div class="whisperer hidden"></div>');
        $(elm.parentElement).append(whisperer);
      }
      // Check whether newly coming whisperer data are newer than displayed
      var incomingTimestamp = parseInt($(data).attr("data-timestamp"));
      var currentTimestamp = parseInt($("ul", whisperer).attr("data-timestamp"));
      var that = this;
      if (isNaN(currentTimestamp) || currentTimestamp < incomingTimestamp) {
        whisperer.toggleClass(CLASS_HIDDEN, false);
        whisperer.html(data);
        whisperer.attr("input", elm.value);
        $("li", whisperer).on("click", function (evt) {
          opts && typeof opts.onItemSelect === "function" && opts.onItemSelect.call(that, evt, whisperer);
        });
      }

    };

    Address.prototype.setRequired = function (isRequired) {
      for (var i = 0; i < this._items.length; i++)  {
          this._items[i].prop("required", isRequired);
      }
    };

    Address.prototype._trySetStreetForCity = function (selectedCity) {
      var selectedOption = $('option[value="' + selectedCity + '"]', this._items.CITY_FAKE);
      if (selectedOption.length && selectedOption.attr("data-has-streets") === "false") {
        this._items.STREET
          .val(selectedCity)
          .parent(SELECTOR_INPUT_GROUP)
          .toggleClass(CLASS_ERROR, false)
          .toggleClass(CLASS_FILLED, true);
        this._setFieldReadOnly(true, "STREET");
        this._setFieldReadOnly(false, "HOUSE_NUMBER");
      } else {
        this._setFieldReadOnly(false, "STREET");
        this._setFieldReadOnly(true, "HOUSE_NUMBER");
      }
    };

    function CompletePersonalDataForm(selector) {
      this._form = $(selector);
      this._homeCountry = "CZ";
      this._isPermanentCountryHomeCountry = false;
      this._initialize();
      this._initializeEvents();
    }

    CompletePersonalDataForm.prototype._initialize = function () {
      var that = this;
      this._postalAddress = new Address({
        POSTAL: $(SELECTOR_POST_ADDRESS_POSTAL, this._form),
        CITY: $(SELECTOR_POST_ADDRESS_CITY, this._form),
        CITY_FAKE: $(SELECTOR_POST_ADDRESS_CITY_FAKE, this._form),
        STREET: $(SELECTOR_POST_ADDRESS_STREET, this._form),
        HOUSE_NUMBER: $(SELECTOR_POST_ADDRESS_HOUSE_NUMBER, this._form)
      }, {
        form: that
      });
      this._permanentResidenceAddress = new Address({
        COUNTRY: $(SELECTOR_PERMANENT_RESIDENCE_COUNTRY, this._form),
        POSTAL: $(SELECTOR_PERMANENT_RESIDENCE_POSTAL, this._form),
        CITY: $(SELECTOR_PERMANENT_RESIDENCE_CITY, this._form),
        CITY_FAKE: $(SELECTOR_PERMANENT_RESIDENCE_CITY_FAKE, this._form),
        STREET: $(SELECTOR_PERMANENT_RESIDENCE_STREET, this._form),
        HOUSE_NUMBER: $(SELECTOR_PERMANENT_RESIDENCE_HOUSE_NUMBER, this._form)
      }, {
        form: that
      });
      if ($(SELECTOR_NATIONALITY, this._form).val() === "CZ") {
        this.toggleBirthNumberRequired(true);
      }
    };

    CompletePersonalDataForm.prototype._initializeEvents = function () {
      var that = this;
      $(SELECTOR_INPUT, this._form)
        .on("focus", function () {
          $(this).parent(SELECTOR_INPUT_GROUP).toggleClass(CLASS_FOCUSED, true).toggleClass(CLASS_ERROR, false);
          this.setAttribute("data-placeholder", this.getAttribute("placeholder"));
          this.setAttribute("placeholder", "");
        })
        .on("blur", function () {
          var $this = $(this);
          $this.parent(SELECTOR_INPUT_GROUP).toggleClass(CLASS_FOCUSED, false).toggleClass(CLASS_FILLED, !!this.value).toggleClass(CLASS_ERROR, this.required && !this.value);
          this.setAttribute("placeholder", this.getAttribute("data-placeholder"));
        });
      $(SELECTOR_NATIONALITY, this._form).on("change", function () {
        that.toggleBirthNumberRequired(this.value === "CZ");
      });
    };

    CompletePersonalDataForm.prototype.onPermanentAddressCountryChange = function (country) {
      $(SELECTOR_POST_ADDRESS_BOX, this._form).toggleClass(CLASS_HIDDEN, this._homeCountry === country);
      this._postalAddress.setRequired(this._homeCountry !== country);
      this._postalAddress._cleanField("POSTAL", "CITY", "STREET", "HOUSE_NUMBER");
    };

    CompletePersonalDataForm.prototype.toggleBirthNumberRequired = function (isRequired) {
      var birthNumber = $(SELECTOR_BIRTH_NUMBER);
      birthNumber.prop("required", isRequired);
      if (!birthNumber.val()) {
        $(birthNumber.parent(".row")).toggleClass(CLASS_ERROR, isRequired);
      }
    };

    module.exports = function (selector) {
      return new CompletePersonalDataForm(selector);
    };

  });

})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/contact-form.js */
(function($) {

  modulr.define("contact-form", function(require, exports, module) {

    var FormValidator = require("form-validator");
    var AlertDialog = window.mobile.dialogs.dialogAlert;
    var Form = require("form");

    var SELECTOR_GLOBAL_ERROR = ".error-message";
    var SELECTOR_TOGGLEABLE_LABELS = ".toggleable-label";

    var SPEED_TEST_IMAGE_URL = "/static/images/speed-test/image.png";
    var SPEED_TEST_IMAGE_SIZE = 9346; // Bytes

    var CLASS_JS_INVISIBLE = "js-invisible";

    var STATE = {
      SUCCESS: 'Success',
      INVALID: 'Invalid',
      FAILED: 'Failed'
    }

    function testConnectionSpeed(elm, content) {
      var start = 0, end = 0;
      var imageUrl = SPEED_TEST_IMAGE_URL + "?n=" + Math.random().toString(36).replace(/[^a-z]+/g, '');
      var image = new Image();
      image.onload = function() {
        end = new Date().getTime();
        var diff = (end - start) / 1000; // seconds
        var bitsLoaded = SPEED_TEST_IMAGE_SIZE * 8;
        var bps = bitsLoaded / diff;
        var result = bps / 1000;
        var unit = "kbps";
        if (result > 1000) {
          result /= 1000;
          unit = "Mbps";
        }
        if (result > 1000) {
          result /= 1000;
          unit = "Gbps";
        }
        elm.html(content.replace("NOT_MEASURED", result.toFixed(2) + " " + unit));
      }
      start = new Date().getTime();
      image.src = imageUrl;
    };

    function getContent(form) {
      return {
        firstName: form.firstName ? form.firstName.value : "" || "",
        surname: form.surName ? form.surName.value : "" || "",
        username: form.loginName.value || "",
        email: form.email.value || "",
        subject: form.emailSubject.value || "",
        ticket: "",
        matches: "",
        content: form.emailBody.value || ""
      };
    }

    var ContactForm = function(form, i18n, params) {
      Form(form);
      this._form = $(form);
      this._i18n = i18n;
      this.site = params.site;
      this.userLoggedIn = params.isUserLoggedIn;
      this.playtechFormUrl = params.playtech_form_url;
      this.playtechFormBrand = params.playtech_form_brand;
      this._validator = FormValidator(form);
      this.initializeEvents();
      this.prepareUserData();
    };

    ContactForm.prototype.initializeEvents = function() {
      var that = this;
      this._form.on("submit", function(evt) {
          that.onFormSubmit(evt);
      });
      var toggleableLabelInputs = $(SELECTOR_TOGGLEABLE_LABELS, this._form);
      toggleableLabelInputs.on("focus", function(evt) {
        that.onInputElementFocus(evt);
      });
      toggleableLabelInputs.on("blur", function(evt) {
        that.onInputElementBlur(evt);
      });
    };

    ContactForm.prototype.prepareUserData = function() {
      var userInfoElm = $("#id-user-info");
      var userInfo = userInfoElm.val();
      userInfo = (new Date()) + "&#13;&#10;" + userInfo;
      userInfo += "&#13;&#10;" + this._i18n.userinfo.javascript + " true";
      var cookiesEnabled = true;
      var value = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(10);
      $.cookie("cookie_test", value);
      if ($.cookie("cookie_test") !== value) cookiesEnabled = false;
      $.removeCookie("cookie_test");
      userInfo += "&#13;&#10;" + this._i18n.userinfo.cookies + " " + cookiesEnabled;
      userInfo += "&#13;&#10;" + this._i18n.userinfo.resolution + " " + window.innerWidth + "x" + window.innerHeight;
      userInfo += "&#13;&#10;" + this._i18n.userinfo.server + " " + $.cookie("LB");
      userInfo += "&#13;&#10;" + this._i18n.userinfo.connection + " NOT_MEASURED";
      userInfoElm.html(userInfo);
      testConnectionSpeed(userInfoElm, userInfo);
    };

    ContactForm.prototype.onFormSubmit = function(evt) {
        evt.preventDefault();
        if (this._validator.isValid()) {
          if (this.playtechFormUrl) {
            this.sendPlaytechIntranet();
          } else {
            this.send();
          }
        } else {
          var content = getContent(this._form[0]);
          content.status = STATE.INVALID;
        }
    };

    ContactForm.prototype.onInputElementFocus = function(evt) {
        $("label[for=" + evt.target.id + "]").toggleClass(CLASS_JS_INVISIBLE, false);
    };

    ContactForm.prototype.onInputElementBlur = function(evt) {
      if (!evt.target.value) {
        $("label[for=" + evt.target.id + "]").toggleClass(CLASS_JS_INVISIBLE, true);
      }
    };

    ContactForm.prototype.reset = function() {
      this._form[0].reset();
      $("label.item-label", this._form).forEach(function(item) {
        if (!$("#" + item.getAttribute("for"), this._form).val()) {
          $(item).toggleClass(CLASS_JS_INVISIBLE, true)
        }
      });
    };

    ContactForm.prototype.send = function() {
      var that = this;
      var values = this._form.serialize();
      var content = getContent(this._form[0]);
      $.ftnAjax($.Link(this._form.attr("action")).ajaxify().getUrl(), {
        method: "POST",
        data: values,
        success: function(data) {
        var jsonData = JSON.parse(data);
        if (jsonData.status === "ok") {
          var text = that._form.attr("data-success-message");
          var title = that._form.attr("data-success-title");
          $(SELECTOR_GLOBAL_ERROR, that._form).toggleClass("hidden", true);
          AlertDialog(text, {
            titleLabel: title
          });
          content.status = STATE.SUCCESS;
          window.dispatchEvent(new CustomEvent("contactform", {detail: content}));
          that.reset();
        } else {
          $(SELECTOR_GLOBAL_ERROR, that._form).toggleClass("hidden", false);
          content.status = STATE.FAILED;
          window.dispatchEvent(new CustomEvent("contactform", {detail: content}));
        }
        },
        error: function() {
          $(SELECTOR_GLOBAL_ERROR, that._form).toggleClass("hidden", false);
          content.status = STATE.FAILED;
        }
      });
    };

    ContactForm.prototype.sendPlaytechIntranet = function(){
      var method = "post";
      var dataForm = this._form[0];
      var content = getContent(dataForm);
      var form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute("id", 'roForm');
      form.setAttribute("action", this.playtechFormUrl);
      addElementToForm(form, "user_email", dataForm.elements.email.value);
      addElementToForm(form, "category", dataForm.elements.subject.value);
      addElementToForm(form, "topic", dataForm.elements.subject.value);
      addElementToForm(form, "message", dataForm.elements.emailBody.value);
      addElementToForm(form, "brand", this.playtechFormBrand);
      if (this.userLoggedIn) {
        addElementToForm(form, "username", dataForm.elements.loginName.value);
      }
      else{
        addElementToForm(form, "username", (dataForm.elements.firstName.value + " " + dataForm.elements.surName.value));
      }
      document.body.appendChild(form);
      var values = $('#roForm').serialize();
      var that = this;
      $.ftnAjax($('#roForm').attr("action"), {
        method: "POST",
        data: values,
        proccessor: "text",
        success: function(data) {
          if (data === "ok") {
            var text = that._form.attr("data-success-message");
            var title = that._form.attr("data-success-title");
            $(SELECTOR_GLOBAL_ERROR, that._form).toggleClass("hidden", true);
            AlertDialog(text, {
              titleLabel: title
            });
            content.status = STATE.SUCCESS;
            window.dispatchEvent(new CustomEvent("contactform", {detail: content}));
            that.reset();
          } else {
            $(SELECTOR_GLOBAL_ERROR, that._form).toggleClass("hidden", false);
            content.status = STATE.FAILED;
            window.dispatchEvent(new CustomEvent("contactform", {detail: content}));
          }
        },
        error:function() {
          $(SELECTOR_GLOBAL_ERROR, that._form).toggleClass("hidden", false);
          content.status = STATE.FAILED;
        }
      });
    };

    function addElementToForm(form, attributeName, attributeSelector){
      var newAttribute = document.createElement("input");
      newAttribute.setAttribute("name", attributeName);
      newAttribute.setAttribute("value",  attributeSelector);
      form.appendChild(newAttribute);
    }

    module.exports = function(form, i18n, params) {
      return new ContactForm(form, i18n, params);
    }

  });

})($);

;
/* assets/src/scripts/fortuna/forms/documents-upload-form.js */
(function(modulr, $) {
    "use strict";

    modulr.define("documents-upload-form", function(require, exports, module) {

        var SELECTOR_FIRST_DOCUMENT_TYPE = "#first-document-type-mobile-select";
        var SELECTOR_SECOND_DOCUMENT_TYPE = "#second-document-type-mobile-select";
        var SELECTOR_FIRST_DOCUMENT_FRONT_PAGE = "#first-document-front-page";
        var SELECTOR_FIRST_DOCUMENT_BACK_PAGE = "#first-document-back-page";
        var SELECTOR_SECOND_DOCUMENT_FRONT_PAGE = "#second-document-front-page";
        var SELECTOR_SECOND_DOCUMENT_BACK_PAGE = "#second-document-back-page";
        var SELECTOR_SUBMIT_BUTTON = "button[type=submit]";
        var SELECTOR_OPTION = "option";
        var SELECTOR_DOCUMENT_TYPE_OPTION = SELECTOR_OPTION + "[value={{SELECTED_OPTION}}]";
        var SELECTOR_ERROR_DESCRIPTION = ".error-description[data-for={{ELEMENT_NAME}}]";

        var CLASS_HIDDEN = "hidden";
        var CLASS_UPLOADING = "uploading";
        
        var SelectMenu = require("select-menu");
        var MobileFileInput = require("mobile-file-input");

        function DocumentsUploadForm(form) {
            this._form = $(form);
            this._documentTypeSelects = {
                first: SelectMenu(SELECTOR_FIRST_DOCUMENT_TYPE),
                second: SelectMenu(SELECTOR_SECOND_DOCUMENT_TYPE)
            };
            this._fileInputs = {
                first: {
                    front: MobileFileInput.newInstance(SELECTOR_FIRST_DOCUMENT_FRONT_PAGE),
                    back: MobileFileInput.newInstance(SELECTOR_FIRST_DOCUMENT_BACK_PAGE)
                },
                second: {
                    front: MobileFileInput.newInstance(SELECTOR_SECOND_DOCUMENT_FRONT_PAGE),
                    back: MobileFileInput.newInstance(SELECTOR_SECOND_DOCUMENT_BACK_PAGE)
                }
            };
            this._submitButton = $(SELECTOR_SUBMIT_BUTTON, this._form);
            this._initialize();
        };

        DocumentsUploadForm.prototype._initialize = function() {
            this._onDocumentTypeChange(this._documentTypeSelects.first._$originalSelect, this._documentTypeSelects.second._$originalSelect, this._fileInputs.first);
            this._onDocumentTypeChange(this._documentTypeSelects.second._$originalSelect, this._documentTypeSelects.first._$originalSelect, this._fileInputs.second);
            this._onFileInputValueChange();
            this._initializeEvents();
        };
        
        DocumentsUploadForm.prototype._initializeEvents = function() {
            var that = this;
            this._documentTypeSelects.first._$originalSelect.on("change", function() {
                that._onDocumentTypeChange($(this), that._documentTypeSelects.second._$originalSelect, that._fileInputs.first);
            });
            this._documentTypeSelects.second._$originalSelect.on("change", function() {
                that._onDocumentTypeChange($(this), that._documentTypeSelects.first._$originalSelect, that._fileInputs.second);
            });
            this._fileInputs.first.front._input.on("change", function() {
                that._onFileInputValueChange(this);
            });
            this._fileInputs.first.back._input.on("change", function() {
                that._onFileInputValueChange(this);
            });
            this._fileInputs.second.front._input.on("change", function() {
                that._onFileInputValueChange(this);
            });
            this._fileInputs.second.back._input.on("change", function() {
                that._onFileInputValueChange(this);
            });
            this._fileInputs.first.front._clearButton.on("click", function() {
                that._onFileInputValueChange(that._fileInputs.first.front._input);
            });
            this._fileInputs.first.back._clearButton.on("click", function() {
                that._onFileInputValueChange(that._fileInputs.first.back._input);
            });
            this._fileInputs.second.front._clearButton.on("click", function() {
                that._onFileInputValueChange(that._fileInputs.second.front._input);
            });
            this._fileInputs.second.back._clearButton.on("click", function() {
                that._onFileInputValueChange(that._fileInputs.second.back._input);
            });
            this._form.on("submit", function(evt) {
                if (that._form.hasClass(CLASS_UPLOADING)) {
                    evt.preventDefault();
                    return false;
                }
                that._form.toggleClass(CLASS_UPLOADING, true);
            });
        };
        
        DocumentsUploadForm.prototype._onDocumentTypeChange = function(select, oppositeSelect, fileInputs) {
            var selectedOptionSelector = SELECTOR_DOCUMENT_TYPE_OPTION.replace("{{SELECTED_OPTION}}", select.val());
            var selectedOption = $(selectedOptionSelector, select);
            var pagesCount = parseInt(selectedOption.attr("data-pages"));
            if (isNaN(pagesCount)) pagesCount = 2;
            // Skryti file inputu
            fileInputs.back._root.toggleClass(CLASS_HIDDEN, pagesCount < 2);
            fileInputs.back._input.prop("required", pagesCount > 1);
            // Zakazani duplicitnich moznosti
            $(SELECTOR_OPTION, oppositeSelect).prop("disabled", null);
            $(selectedOptionSelector, oppositeSelect).prop("disabled", "disabled");
            $(SELECTOR_ERROR_DESCRIPTION.replace("{{ELEMENT_NAME}}", select.attr("name"))).toggleClass(CLASS_HIDDEN, true);
        };
        
        DocumentsUploadForm.prototype._onFileInputValueChange = function(input) {
            if (!this._fileInputs.first.front._input.val() || !this._fileInputs.second.front._input.val() ||
                    (this._fileInputs.first.back._input.prop("required") && !this._fileInputs.first.back._input.val()) ||
                    (this._fileInputs.second.back._input.prop("required") && !this._fileInputs.second.back._input.val())) {
                this._submitButton.toggleClass(CLASS_HIDDEN, true);
            } else {
                this._submitButton.toggleClass(CLASS_HIDDEN, false);
            }
            $(SELECTOR_ERROR_DESCRIPTION.replace("{{ELEMENT_NAME}}", $(input).attr("name"))).toggleClass(CLASS_HIDDEN, true);
        };

        module.exports = function(form) {
            return new DocumentsUploadForm(form);
        };
    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/file-uploader.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("file-uploader", function(require, exports, module) {
        
        var SELECTOR_INPUT = "input[type=file]";
        var SELECTOR_SUBMIT = "button[type=submit]";
        var SELECTOR_CHOOSE = ".choose";
        var SELECTOR_FILE_DIV = ".file";
        var SELECTOR_FILE_NAME = ".file-name";
        var SELECTOR_FILE_INPUT_DIV = ".file-input"
        
        var CLASS_HIDDEN = "hidden";
        
        function FileUploader(form) {
            this._form = $(form);
            this._fileInput = $(SELECTOR_INPUT, this._form);
            this._submitButton = $(SELECTOR_SUBMIT, this._form);
            this._fileInputDiv = $(SELECTOR_FILE_INPUT_DIV, this._form);
            this._fileDiv = $(SELECTOR_FILE_DIV, this._form);
            this._fileInputDiv = $(SELECTOR_FILE_INPUT_DIV, this._form);
            this._chooseButton = $(SELECTOR_CHOOSE, this._form);
            this.initialize();
            this.initializeEvents();
        }
        
        FileUploader.prototype.initialize = function() {
            this._submitButton.toggleClass(CLASS_HIDDEN, true);
            this._fileDiv.toggleClass(CLASS_HIDDEN, true);
            this._fileInputDiv.toggleClass(CLASS_HIDDEN, false);
            this._chooseButton.toggleClass(CLASS_HIDDEN, false);
        }
        
        FileUploader.prototype.onValueSet = function(elm) {
            var name = elm.value.split(/[\\|\/]/);
            name = name[name.length - 1];
            $(SELECTOR_FILE_NAME, this._fileDiv).html(name);
            this._fileDiv.toggleClass(CLASS_HIDDEN, false);
            this._submitButton.toggleClass(CLASS_HIDDEN, false);
            this._fileInputDiv.toggleClass(CLASS_HIDDEN, true);
        }
        
        FileUploader.prototype.initializeEvents = function() {
            var that = this;
            this._form.on("submit", function(evt) {
                if (!that._fileInput) {
                    evt.preventDefault();
                }
            });
            this._fileInput.on("change", function(evt) {
                if (!this.value) {
                    that.initialize();
                } else {
                    that.onValueSet(this);
                }
            });
            this._fileDiv.delegate("click", "a", function(evt) {
                evt.preventDefault();
                that._fileInput.val(null);
                that.initialize();
            });
        }
        
        module.exports = function(form) {
            return new FileUploader(form);
        };
        
    });
    
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/form-validator.js */
(function($) {
    "use strict";

    if (!$) return;

    modulr.define("form-validator", function(require, exports, module) {

      var SELECTOR_VALIDATION_FIELDS = "input.validation, select.validation, textarea.validation";
      var SELECTOR_ERROR_ELEM = "span.error-description";
      var CLASS_ERROR = "error";
      var CLASS_ERROR2 = "MaterialInput--error";
      var CLASS_SUCCESS = "MaterialInput--success";
      var TPL_ERROR_MESSAGE = '<span class="error-description">{{message}}</span>';

      var VALIDATORS = {
        "required": function (testElm, formValidator) {
          var value = testElm.value;
          if (value) {
            formValidator.hideError(testElm);
            return true;
          } else {
            formValidator.showError(testElm, "data-validation-error-required-message");
            return false;
          }
        },
        "email": function (testElm, formValidator) {
          var value = testElm.value;
          var exp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (exp.test(value)) {
            formValidator.hideError(testElm);
            return true;
          } else {
            formValidator.showError(testElm, "data-validation-error-email-message");
            return false;
          }
        },
        "equals": function (testElm, formValidator) {
          var value = testElm.value;
          var eqElm = $(testElm.getAttribute("data-validation-equals-relation"));
          var eqElmValue = eqElm.val();
          if (value === eqElmValue) {
            formValidator.hideError(testElm);
            return true;
          } else {
            formValidator.showError(testElm, "data-validation-equals-error-message");
            return false;
          }
        },
        "passwordSameAsOld": function (testElm, formValidator) {
          var value = testElm.value;
          var eqElm = $(testElm.getAttribute("data-validation-password-same-as-old-relation"));
          var eqElmValue = eqElm.val();
          if (value !== eqElmValue) {
            formValidator.hideError(testElm);
            return true;
          } else {
            formValidator.showError(testElm, "data-validation-password-same-as-old-error-message");
            return false;
          }
        },
        "password": function (testElm, formValidator) {
          var value = testElm.value;
          var exp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?!.*[\s]).{8,}$/;
          if (exp.test(value)) {
            formValidator.hideError(testElm);
            return true;
          } else {
            formValidator.showError(testElm, "data-validation-error-password-message");
            return false;
          }
        },
        "float": function (testElm, formValidator) {
          var value = testElm.value;
          value = value.replace(/,/g, ".");
          var parsedValue = parseFloat(value);
          if (isNaN(parsedValue)) {
            formValidator.showError(testElm, "data-validation-float-error-message");
            return false;
          } else {
            formValidator.hideError(testElm);
            return true;
          }
        },
        "no-special-chars": function (testElm, formValidator) {
          var value = testElm.value;
          var exp = /[<>'"%+]/;
          if (exp.test(value)) {
            formValidator.showError(testElm, "data-validation-no-special-tags-error-message");
            return false;
          } else {
            formValidator.hideError(testElm);
            return true;
          }
        },
        "iban-without-country": function (testElm, formValidator) {
          var value = testElm.value;
          var exp = /^[0-9]{26}$/;
          if (exp.test(value)) {
            formValidator.hideError(testElm);
            return true;
          } else {
            formValidator.showError(testElm, "data-validation-bank-account-error-message");
            return false;
          }
        },
        "pattern": function (testElm, formValidator) {
          var value = testElm.value;
          var pattern = testElm.getAttribute("pattern");
          if (pattern) {
            var re = new RegExp(pattern);
            if (re.test(value)) {
              formValidator.hideError(testElm);
              return true;
            } else {
              formValidator.showError(testElm, "data-validation-pattern-message");
              return false;
            }
          } else {
            formValidator.hideError(testElm);
            return true;
          }
        }
      };

      var FormValidator = function (form) {
        this._form = $(form);
      };

      FormValidator.prototype.isValid = function () {
        var hasErrors = false;
        var that = this;
        $(SELECTOR_VALIDATION_FIELDS, this._form).forEach(function (item) {
          if (!that.validateField(item)) {
            hasErrors = true;
            that.showError(item);
          } else {
            that.hideError(item);
          }
        });
        return !hasErrors;
      };

      FormValidator.prototype.showError = function (item, errorDataAttr) {
        var $item = $(item);
        var $item_parent = $item.parents('.MaterialInput');

        $item_parent.toggleClass(CLASS_SUCCESS, false);
        if ($item_parent.hasClass(CLASS_ERROR2)) return;
        $item_parent.toggleClass(CLASS_ERROR2, true);
        var message = $item.attr(errorDataAttr) || $item.attr("data-error-message");
        if (message) {
          if ($item.is(".mobile-select select")) {
            $item = $item.parent(".mobile-select");
            $item.toggleClass(CLASS_ERROR, true);
          }

          $item_parent.insertAfter($(TPL_ERROR_MESSAGE.replace("{{message}}", message))[0]);
        }
      };

      FormValidator.prototype.hideError = function (item) {
        var $item = $(item);
        var $item_parent = $item.parents('.MaterialInput');

        if ($item_parent.length > 0) {
          $item_parent.toggleClass(CLASS_ERROR2, false);
          $item_parent.toggleClass(CLASS_SUCCESS, true);

          if ($item.is(".mobile-select select")) {
            item = $item.parent(".mobile-select");
            item.toggleClass(CLASS_ERROR, false);
          }
          var nextElem = $item_parent[0].nextElementSibling;
          if (nextElem && $(nextElem).is(SELECTOR_ERROR_ELEM)) {
            nextElem.parentElement.removeChild(nextElem);
          }
        }
      };


        FormValidator.prototype.validateField = function(item) {
            var itemValidators = item.getAttribute("data-validators");
            if (!itemValidators) return true;
            itemValidators = itemValidators.split(" ");
            for (var i = 0, l = itemValidators.length; i < l; i++) {
                var validator = VALIDATORS[itemValidators[i]];
                if (validator && typeof validator === "function" && !validator(item, this)) {
                    return false;
                }
            }
            return true;
        };

        module.exports = function(form) {
            return new FormValidator(form);
        };
    });

})($);

;
/* assets/src/scripts/fortuna/forms/form.js */
(function($) {
    "use strict";
    if (!$) return;

    modulr.define("form", function(require, exports, module) {
      var FormValidator = require("form-validator");

        var SELECTOR_INPUTS = "input[type=text], input[type=password], input[type=email], input[type=number], textarea, select";
        var SELECTOR_LABEL = "label[for={{ELEM_ID}}]";
        var SELECTOR_PARENT_CLASS = ".MaterialInput";

        var CLASS_NO_LABEL_HIGHLIGHT = "no-label-highlight";
        var CLASS_FOCUS = "focus";

        var Form = function(form) {
          var that = this
          setTimeout(function() {
            that._form = $(form);
            that._initialHeight = window.innerHeight;
            that._validator = FormValidator(form);
            that.initialize();
          }, 0);
        };

        Form.prototype.getForm = function() {
            return this._form;
        };

        // debouncer so we don't spam after every key input
        function debounce(func, wait, immediate) {
          var timeout;
          return function() {
            var context = this, args = arguments;
            var later = function() {
              timeout = null;
              if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
          };
        }

        Form.prototype.initialize = function() {
            var that = this;
            var textInputs = $(SELECTOR_INPUTS, this._form);
            textInputs.on("focus", function(evt) {
                that.onTextInputFocus(evt.target, evt);
            });
            textInputs.on("blur", function(evt) {
                that.onTextInputBlur(evt.target, evt);
            });
            textInputs.on("input", debounce(function(evt) {
              that.onTextInputChange(evt.target, evt);
            }, 250));
            this.checkValues()
        };

        Form.prototype.checkValues = function() {
          var inputs = $('.MaterialInput input')
          inputs.forEach(function (input) {
            $(input).parents(SELECTOR_PARENT_CLASS).toggleClass('focused', input.value.length > 0);
          });
        };

        Form.prototype.onTextInputChange = function(elm, e) {
          this._validator.validateField(elm)
        };

        Form.prototype.onTextInputFocus = function(elm, e) {
            if (!elm || !elm.id || elm.className.indexOf(CLASS_NO_LABEL_HIGHLIGHT) > -1) return;
            var that = this;
            $(elm).parents(SELECTOR_PARENT_CLASS).toggleClass('focused', (e.type === 'focus' || elm.value.length > 0));
            $(SELECTOR_LABEL.replace("{{ELEM_ID}}", elm.id), this._form).toggleClass(CLASS_FOCUS, true);
        };

        Form.prototype.onTextInputBlur = function(elm, e) {
            $(elm).parents(SELECTOR_PARENT_CLASS).toggleClass('focused', (e.type === 'focus' || elm.value.length > 0));
            if (!elm || !elm.id || elm.className.indexOf(CLASS_NO_LABEL_HIGHLIGHT) > -1) return;
            $(SELECTOR_LABEL.replace("{{ELEM_ID}}", elm.id), this._form).toggleClass(CLASS_FOCUS, false);
            this._validator.validateField(elm)
        };

        module.exports = function(form) {
            return new Form(form);
        }

    });

})($);
;
/* assets/src/scripts/fortuna/forms/input-with-currency.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("input-with-currency", function(require, exports, module) {
        
        var SELECTOR_INPUT_WITH_CURRENCY = ".input-with-currency";
        
        function moveCurrency(elm) {
            var $elm = $(elm);
            var input = $elm.find("input");
            var currency = $elm.find("span");
            
            var value = input.val();
            if (value) {
                $elm.append('<span class="fake">' + input.val() + '</span>');
                var $fake = $elm.find(".fake");
                var fakeW = $fake.width();
                $fake.remove();
                currency.css({"left": fakeW + "px", "right": "initial"});
            } else {
                currency.css({"left": null, "right": null});
            }
            
        };
        
        function initialize(form) {
            $(SELECTOR_INPUT_WITH_CURRENCY, form).forEach(function(elm) {
                var $elm = $(elm);
                var input = $elm.find("input");
                moveCurrency(elm);
                input.on("blur", function() {
                    moveCurrency(elm);
                });
            });
        };
        
        module.exports = initialize;
        
    });
})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/notifications-settings-form.js */
(function ($) {

  modulr.define("notifications-settings-form", function (require, exports, module) {

    var AlertDialog = window.mobile.dialogs.dialogAlert;

    var SELECTOR_CHECKBOXES = "input[type=checkbox]";
    var CLASS_NO_SUBMIT = "hide-submit";
    var DATA_ATTR_CONSENT_AGREEMENT_REQUIRED = "data-consent-agreement-needed";

    var NotificationsSettingsForm = function (form, consents) {
      this._form = $(form);
      this._consents = consents;
      var tempCheck = $(SELECTOR_CHECKBOXES, this._form);
      var checkboxes = [];
      var checkboxesIterator = 0;
      for (var i = 0; i < tempCheck.length; i++) {
        if (!tempCheck[i].getAttribute("name").includes("consent")) {
          checkboxes[checkboxesIterator] = tempCheck[i];
          checkboxesIterator++;
        }
      }
      this._checkboxes = $(checkboxes);
      this._form.toggleClass(CLASS_NO_SUBMIT, true);
      if(consents.displayError) {
        this.showErrorDialog(consents.displayError);
      } else {
        this.initializeEvents();
      }
    };

    NotificationsSettingsForm.prototype.initializeEvents = function () {
      var that = this;
      this._checkboxes.on("change", function () {
        that.agreeAndSend(this);
      });
    };

    NotificationsSettingsForm.prototype.agreeAndSend = function (elm) {
      var that = this;
      var consentType = elm.getAttribute(DATA_ATTR_CONSENT_AGREEMENT_REQUIRED);
      if (typeof consentType !== "undefined" && consentType != null) {
        var neededConsents = $(this._consents.list).filter(function (c) {
          return c.type === consentType;
        });
        if (neededConsents.length) {
          this._consents.commonData.beforeAccept = function () {
            var consentCheckboxId = elm.getAttribute("data-consent-checkbox");
            if (consentCheckboxId) {
              $(consentCheckboxId).attr("checked", "checked");
            }
          };
          this._consents.commonData.onAccept = function () {
            that.send(elm);
          };
          this._consents.commonData.onDecline =
            this._consents.commonData.onError =
              function () {
                elm.checked = !elm.checked;
                var consentCheckboxId = elm.getAttribute("data-consent-checkbox");
                if (consentCheckboxId) {
                  $(consentCheckboxId).attr("checked", null);
                }
              };
          this._consents.commonData.disableBackendCallOnDecline = true;
          require("consents.popups")(neededConsents.asArray(), this._consents.commonData, null);
        }
      } else {
        this.send(elm);
      }
    };

    NotificationsSettingsForm.prototype.send = function (elm) {
      var that = this;
      var values = this._form.serialize();
      var id = elm.id;
      if (!id.startsWith("newsletter")) {
        values += ("&clicked=true");
      }
      $.ftnAjax($.Link(this._form.attr("action")).ajaxify().getUrl(), {
        "method": "POST",
        "data": values,
        beforeSend: function () {
          window.mobile.offer.toggleLoader(true);
        },
        success: function (data) {
          var jsonData = JSON.parse(data);
          if (jsonData.status === "popup") {
            window.mobile.offer.toggleLoader(false);
            that.showPopup(that, elm);
          }
          else if (jsonData.status !== "ok") {
            window.mobile.offer.toggleLoader(false);
            that.showErrorDialog(jsonData.message);
          } else {
            window.mobile.offer.toggleLoader(true);
            window.location.href = window.location.href;
          }
        },
        error: function () {
          window.mobile.offer.toggleLoader(false);
          that.showErrorDialog();
        }
      });
    };


    NotificationsSettingsForm.prototype.showPopup = function (formInsta, elm) {
      window.mobile.dialogs.dialogConfirm(formInsta._consents.commonData.popup.QUESTION, {
        okButtonLabel: formInsta._consents.commonData.popup.CANCEL,
        cancelButtonLabel: formInsta._consents.commonData.popup.ACCEPT,
        okCallback: function () {
          window.mobile.offer.toggleLoader(false);
          if (elm){
            elm.checked = !elm.checked;
          }
        },
        cancelCallback: function () {
          formInsta.onOkClick(formInsta);
        }
      });
    };

    NotificationsSettingsForm.prototype.onOkClick = function (formInsta) {
      var values = formInsta._form.serialize();
      values += ("&clicked=true");
      $.ftnAjax($.Link(formInsta._form.attr("action")).ajaxify().getUrl(), {
        "method": "POST",
        "data": values,
        beforeSend: function () {
          window.mobile.offer.toggleLoader(true);
        },
        success: function (data) {
          var jsonData = JSON.parse(data);
          if (jsonData.status !== "ok") {
            window.mobile.offer.toggleLoader(false);
            formInsta.showErrorDialog(jsonData.message);
          } else {
            window.location.href = window.location.href;
          }
        },
        error: function () {
          window.mobile.offer.toggleLoader(false);
          formInsta.showErrorDialog();
        }
      });
    };


    NotificationsSettingsForm.prototype.showErrorDialog = function (message) {
      message = message || this._form.attr("data-error-message");
      var that = this;
      AlertDialog(message, {
        titleLabel: that._form.attr("data-error-title"),
        okCallback: function () {
          window.mobile.offer.toggleLoader(true);
          window.location.href = window.location.href;
        }
      });
    };

    module.exports = function (form, consents) {
      return new NotificationsSettingsForm(form, consents);
    };

  });

})($);
;
/* assets/src/scripts/fortuna/forms/payment-card-form.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("payment-card-form", function(require, exports, module) {
       
        var Alert = window.mobile.dialogs.dialogAlert;
        
        var PAYMENT_CARDS = [
             "maestro",
             "mastercard",
             "visa",
             "visa-electron"
         ];
         
         var PAYMENT_CARDS_PREFIXES = {
                 "american-express": [
                     [34],
                     [37]
                 ],
                 "bankcard": [
                     [5610],
                     [560221, 560225]
                 ],
                 "china-union-pay": [
                     [62]
                 ],
                 "diners-club-carte-blanche": [
                     [300, 305]
                 ],
                 "diners-club-enroute": [
                     [2014],
                     [2149]
                 ],
                 "diners-club-international": [
                     [300, 305],
                     [309],
                     [36],
                     [38, 39]
                 ],
                 "diners-club-united-states-and-canada": [
                     [54],
                     [55]
                 ],
                 "discover-card": [
                     [6011],
                     [622126, 622925],
                     [644, 649],
                     [65]
                 ],
                 "interpayment": [
                     [636]
                 ],
                 "instapayment": [
                     [637, 639]
                 ],
                 "jcb": [
                     [3528, 3589]
                 ],
                 "laser": [
                     [6304],
                     [6706],
                     [6771],
                     [6709]
                 ],
                 "dankort": [
                     [5019],
                     [4175],
                     [4571]
                 ],
                 "nspk-mir": [
                     [2200, 2204]
                 ],
                 
                 "solo": [
                     [6334],
                     [6767]
                 ],
                 "switch": [
                     [4903],
                     [4905],
                     [4911],
                     [4936],
                     [564182],
                     [633110],
                     [6333],
                     [6759]
                 ],
                 "uatp": [
                     [1]
                 ],
                 "verve": [
                     [506099, 506198],
                     [650002, 650027],
                     [5392]
                 ],
                 "cardguard-ead-bg-ils": [
                     [5392]
                 ],
                 "maestro": [
                     [50],
                     [56, 69]
                 ],
                 "mastercard": [
                     [2221, 2720],
                     [51, 55]
                 ],
                 "visa": [
                     [4]
                 ],
                 "visa-electron": [
                   [4026],
                   [417500],
                   [4405],
                   [4508],
                   [4844],
                   [4913],
                   [4917]
               ]
             };
    
             function getCardName(prefix) {
                 if (!prefix || prefix.length !== 6) return false;
                 var matchesTable = {};
                 for (var p = 0; p <  PAYMENT_CARDS_PREFIXES.lenght; p++) {
                     for (var i = 0, l = PAYMENT_CARDS_PREFIXES[p].length; i < l; i++) {
                         if (PAYMENT_CARDS_PREFIXES[p][i].length === 1) {
                             if (parseInt(prefix.substr(0, (""+PAYMENT_CARDS_PREFIXES[p][i][0]).length)) === PAYMENT_CARDS_PREFIXES[p][i][0]) {
                                 matchesTable[p] = (""+PAYMENT_CARDS_PREFIXES[p][i][0]).length;
                             }
                         } else if (PAYMENT_CARDS_PREFIXES[p][i].length === 2) {
                             var t = parseInt(prefix.substr(0, (""+PAYMENT_CARDS_PREFIXES[p][i][0]).length));
                             if (t >= PAYMENT_CARDS_PREFIXES[p][i][0] && t <= PAYMENT_CARDS_PREFIXES[p][i][1]) {
                                 matchesTable[p] = (""+PAYMENT_CARDS_PREFIXES[p][i][0]).length;
                             }
                         }
                     }
                 }
                 var r = false, max = 0;
                 for (var i = 0; i < matchesTable.length; i++) {
                     if (max <= matchesTable[i]) {
                         r = i;
                         max = matchesTable[i];
                     }
                 }
                 return r;
             };
             
             var SELECTOR_FIRST_FOUR = "#id-first-four";
             var SELECTOR_FIFTH_AND_SIXTH = "#id-fifth-and-sixth";
             var SELECTOR_INPUTS = "input[type=number]";
             var SELECTOR_CARDS = ".card .info .cards";
             
             function PaymentCardForm(form) {
                 this._form = $(form);
                 this._firstFour = $(SELECTOR_FIRST_FOUR, this._form);
                 this._fifthAndSixth = $(SELECTOR_FIFTH_AND_SIXTH, this._form);
                 this._cardsInfo = $(SELECTOR_CARDS, this._form);
                 this.initialize();
             };
             
             PaymentCardForm.prototype.initialize = function() {
                 var that = this;
                 $(SELECTOR_INPUTS, this._form).on("keyup", function(evt) {
                     var maxLength = parseInt(this.getAttribute("data-required-length"));
                     if (this.value.length >= maxLength) {
                         this.value = this.value.substr(0, maxLength);
                         $(this).toggleClass("error", false);
                         if (!this._justFocused) {
                             var nextId = this.getAttribute("data-next");
                             var next = document.getElementById(nextId);
                             if (next) {
                                 next.focus();
                             }
                         }
                         that.getCardName();
                     }
                 });
                 $(SELECTOR_INPUTS, this._form).on("focus", function(evt) {
                     var that = this;
                     this._justFocused = true;
                     window.setTimeout(function() {
                         that._justFocused = null;
                     }, 1000);
                 });
                 this._form.on("submit", function(evt) {
                     var inputs = $(SELECTOR_INPUTS, that._form);
                     var hasErrors = false;
                     for (var i = 0, l = inputs.length; i < l; i++) {
                         if (!inputs[i].value || (inputs[i].min && inputs[i].min > inputs[i].value) || (inputs[i].max && inputs[i].max < inputs[i].value)) {
                             $(inputs[i]).toggleClass("error", true);
                             hasErrors = true;
                         } else {
                             $(inputs[i]).toggleClass("error", false);
                         }
                     }
                     if (hasErrors) {
                         evt.preventDefault();
                         Alert(that._form.attr("data-form-error"), {
                             titleLabel: that._form.attr("data-form-error-title")
                         });
                     }
                 });
             };
             
             PaymentCardForm.prototype.getCardName = function() {
                 var detectedCard = getCardName(this._firstFour.val() + this._fifthAndSixth.val());
                 this._cardsInfo.toggleClass(PAYMENT_CARDS.join(" "), false);
                 if (PAYMENT_CARDS.indexOf(detectedCard) > -1) {
                     this._cardsInfo.toggleClass(detectedCard, true);
                 }
             };
             
             module.exports = function(form) {
                 return new PaymentCardForm(form);
             };
        
    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/personal-data-settings-form.js */
(function($) {

    modulr.define("personal-data-settings-form", function(require, exports, module) {

        var FormValidator = require("form-validator");
        var AlertDialog = window.mobile.dialogs.dialogAlert;
        var MessageDialog = window.mobile.dialogs.dialogMessage;
        var Form = require("form");

        var SELECTOR_INFO_BUTTON = ".information-button";

        var PersonalDataSettingsForm = function(form) {
            this._formInstance = Form(form);
            this._form = this._formInstance.getForm();
            this._validator = FormValidator(form);
            this.initializeEvents();
        };

        PersonalDataSettingsForm.prototype.initializeEvents = function() {
            var that = this;
            this._form.on("submit", function(evt) {
                that.onFormSubmit(evt);
            });
            $(SELECTOR_INFO_BUTTON, this._form).on("click", function(evt) {
                that.onInfoButtonClick(evt);
            });
        };

        PersonalDataSettingsForm.prototype.onFormSubmit = function(evt) {
            evt.preventDefault();
            if (this._validator.isValid()) {
                this.send();
            }
        };

        PersonalDataSettingsForm.prototype.send = function() {
            var that = this;
            var values = this._form.serialize();
            $.ftnAjax($.Link(this._form.attr("action")).ajaxify().getUrl(), {
                "method": "POST",
                "data": values,
                success: function(data) {
                    var jsonData = JSON.parse(data);
                    var title;
                    var text;
                    if (jsonData.status === "ok") {
                        that.showSuccessMessage();
                    } else {
                        that.showErrorMessage();
                    }
                },
                error: function() {
                    that.showErrorMessage();
                }
            });
        };

        PersonalDataSettingsForm.prototype.showSuccessMessage = function() {
            var text = this._form.attr("data-success-message");
            var title = this._form.attr("data-success-title");
            this.showMessage(title, text);
        };

        PersonalDataSettingsForm.prototype.showErrorMessage = function() {
            var text = this._form.attr("data-error-message");
            var title = this._form.attr("data-error-title");
            this.showMessage(title, text);
        };

        PersonalDataSettingsForm.prototype.showMessage = function(title, message) {
            AlertDialog(message, {
                titleLabel: title
            });
        };

        PersonalDataSettingsForm.prototype.onInfoButtonClick = function(evt) {
            evt.preventDefault();
            var target = $(evt.target);
            var message = target.attr("data-message");
            MessageDialog(message);
        };

        module.exports = function(form) {
            return new PersonalDataSettingsForm(form);
        }

    });

})($);
;
/* assets/src/scripts/fortuna/forms/pl-add-personal-info-form.js */
(function(modulr) {
  "use strict";

  modulr.define("pl-add-personal-info-form", function(require, exports, module) {

    var CLASS_HIDDEN = "hidden";

    var SELECTOR_FIELD_NATIONALITY = "#nationality";
    var SELECTOR_FIELD_IDENTITY_NUMBER = "#identity-number";
    var SELECTOR_DIV_IDENTITY_NUMBER = "#identity-number-div";
    var SELECTOR_FIELD_COUNTRY = "#country";
    var SELECTOR_FIELD_REGION = "#region";
    var SELECTOR_ROW_PESEL = "#pesel-row";
    var SELECTOR_SELECTMENU_REGION = "#region-mobile-select";
    var SELECTOR_DIV_REGION = "#div-region";

    var TPL_SELECTOR_LABEL = "label[for={{ID}}]";

    var REQUEST_PARAM_COUNTRY = "country";

    var HOME_NATIONALITY = "PL";

    var Form = require("form");
    var TogglingLabels = require("_toggling-labels");
    var FormValidator = require("form-validator");

    function PLAddPersonalInfoForm(selector) {
      this._form = $(selector);
      this._formValidator = FormValidator(this._form);
      Form(this._form);
      TogglingLabels(this._form);
      this._initializeEvents();
    }

    PLAddPersonalInfoForm.prototype._initializeEvents = function() {
      var that = this;
      var numberInputsFix = require("forms._number-inputs-fix");
      if (typeof numberInputsFix === "function") {
        numberInputsFix(this._form);
      }
      this._form.find("input[type=text], input[type=number], input[type=tel]")
        .on("blur", function() {
          that._formValidator.validateField(this);
        })
        .on("focus", function() {
          that._formValidator.hideError(this);
        });
      this._form.find(SELECTOR_FIELD_NATIONALITY)
        .on("change", function() {
          that._form.find(SELECTOR_ROW_PESEL).toggleClass(CLASS_HIDDEN, this.value !== HOME_NATIONALITY);
          that._form.find(SELECTOR_DIV_IDENTITY_NUMBER).toggleClass(CLASS_HIDDEN, this.value === HOME_NATIONALITY);
          // kept here because of possible change request to easily remember previous functionality
          var $identityNumberField = that._form.find(SELECTOR_FIELD_IDENTITY_NUMBER);
          var $identityNumberLabel = that._form.find($.Utils.expandVariables(TPL_SELECTOR_LABEL, {"ID": $identityNumberField.attr("id")}));
          if(this.value !== HOME_NATIONALITY) {
            $identityNumberField
              .attr("placeholder", $identityNumberField.attr("data-foreign-placeholder"))
              .attr("data-validation-error-required-message", $identityNumberField.attr("data-foreign-validation-error-required-message"));
            $identityNumberLabel.html($identityNumberField.attr("placeholder"));
          }
        });
      this._form.find(SELECTOR_FIELD_COUNTRY).on("change", function() {
        var url = that._form.attr("data-url-get-regions");
        if (url) {
          url = $.Link(url);
          url.appendParam(REQUEST_PARAM_COUNTRY, this.value);
          $.ftnAjax({
            url: url.ajaxify().getUrl(),
            success: function(data) {
              if (data) {
                var $select = that._form.find(SELECTOR_FIELD_REGION);
                $select.html(data);
                that._form.find(SELECTOR_DIV_REGION).toggleClass(CLASS_HIDDEN, false);
                that._form.find(SELECTOR_SELECTMENU_REGION).toggleClass(CLASS_HIDDEN, false);
                that._form.find(SELECTOR_SELECTMENU_REGION).find("a.select").html($select.find("option:first-child").html());
              } else{
                that._form.find(SELECTOR_DIV_REGION).toggleClass(CLASS_HIDDEN, true);
              }
            }
          })
        }
      });
    };

    module.exports = function(selector) {
      return new PLAddPersonalInfoForm(selector);
    }

  });

})(modulr);
;
/* assets/src/scripts/fortuna/forms/pl-documents-upload-form.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("pl-documents-upload-form", function(require, exports, module) {
        
        var CLASS_HIDDEN = "hidden";
        var CLASS_UPLOADING = "uploading";
        
        var SELECTOR_FIRST_DOCUMENT_FRONT_PAGE = "#first-document-front-page";
        var SELECTOR_FIRST_DOCUMENT_BACK_PAGE = "#first-document-back-page";
        var SELECTOR_SUBMIT_BUTTON = "button[type=submit]";
        var SELECTOR_ERROR_DESCRIPTION = ".error-description[data-for={{ELEMENT_NAME}}]";
        
        var MobileFileInput = require("mobile-file-input");
        
        function PlDocumentsUploadForm(selector) {
            this._form = $(selector);
            this._fileInputs = {
                first: {
                    front: MobileFileInput.newInstance(SELECTOR_FIRST_DOCUMENT_FRONT_PAGE),
                    back: MobileFileInput.newInstance(SELECTOR_FIRST_DOCUMENT_BACK_PAGE)
                }
            };
            this._submitButton = this._form.find(SELECTOR_SUBMIT_BUTTON);
            this._initializeEvents();
            this._onFileInputChange();
        };
        
        PlDocumentsUploadForm.prototype._initializeEvents = function() {
            var that = this;
            this._fileInputs.first.front._input.on("change", function() {
                that._onFileInputChange(this);
            });
            this._fileInputs.first.back._input.on("change", function() {
                that._onFileInputChange(this);
            });
            this._fileInputs.first.front._clearButton.on("click", function() {
                that._onFileInputChange(this);
            });
            this._fileInputs.first.back._clearButton.on("click", function() {
                that._onFileInputChange(this);
            });
        };
        
        PlDocumentsUploadForm.prototype._onFileInputChange = function(elm) {
            var isHidden = !(this._fileInputs.first.front._input.val() && this._fileInputs.first.back._input.val());
            this._submitButton.toggleClass(CLASS_HIDDEN, isHidden);
            if (elm) {
                $(SELECTOR_ERROR_DESCRIPTION.replace("{{ELEMENT_NAME}}", elm.getAttribute("name"))).toggleClass(CLASS_HIDDEN, true);                
            }
        };
        
        module.exports = function(selector) {
            return new PlDocumentsUploadForm(selector);
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/player-limits-settings-form.js */
(function (modulr, $) {
  "use strict";

  modulr.define("player-limits-settings", function (require, exports, module) {

    var SELECTOR_FORM = ".player-limits-form";
    var SELECTOR_SELECTS = SELECTOR_FORM + " select";
    var SELECTOR_INPUTS = SELECTOR_FORM + " input[type=number]";

    var AlertDialog = window.mobile.dialogs.dialogAlert;

    function PlayerLimitsSettingsForm(opts, dyEnabled) {
      var that = this;
      this.form = $(SELECTOR_FORM);
      this.opts = opts;
      this._dyEnabled = dyEnabled;
      $(function () {
        that.initializeInputs();
      });
      this.initializeEvents();
    };

    PlayerLimitsSettingsForm.prototype.initializeInputs = function () {
      $(SELECTOR_INPUTS).forEach(function (item) {
        var $item = $(item);
        var $parent = $(item.parentNode);
        var $unit = $(".unit", $parent);
        $item.toggleClass("ta-left", false);
        if ($unit.length) {
          var isHidden = $parent.hasClass("hidden");
          $parent.toggleClass("hidden", false);
          $item.css({"padding-right": $unit.width() + 5 + "px"});
          $parent.toggleClass("hidden", isHidden);
        }
      });
    };

    PlayerLimitsSettingsForm.prototype.initializeEvents = function () {
      var that = this;
      this.form.on("submit", function (evt) {
        that.onFormSubmit(evt);
      });
      $(SELECTOR_SELECTS).on("change", function () {
        that.onSelectorChange(this);
      }).on("blur", function () {
        that.onSelectorChange(this);
      });
      $(SELECTOR_INPUTS).on("blur", function () {
        that.validateInputItem(this);
      });
    };

    PlayerLimitsSettingsForm.prototype.onSelectorChange = function (elm) {
      if (!(elm || elm.id)) {
        return;
      }
      var inputHolder = $(".input-holder." + elm.id.replace("id-", ""), this.form);
      if (elm.value === "RECOMMENDED") {
        $("#" + elm.id + "_input")
          .prop("value", $("[name=" + elm.id.replace("id-", "") + "_default" + "]", inputHolder).attr("value"))
          .attr("readonly", "readonly")
          .toggleClass("error", false);
        inputHolder.toggleClass("hidden", this._getOpt("HIDE_INPUT_FOR_RECOMMENDED", false));
      } else {
        $("#" + elm.id + "_input")
          .prop("value", "")
          .attr("readonly", null);
        inputHolder.toggleClass("hidden", true);
      }
      if (elm.value === "OWN") {
        inputHolder.toggleClass("hidden", false);
        var $input = $("#" + elm.id + "_input");
        if (this._getOpt("FILL_OWN_WITH_MAX", false)) {
          $input.val($input.attr("max"));
        }
        $input[0].focus();
      }
      var unit = $(".unit", inputHolder);
      var input = $("#" + elm.id + "_input", this.form);
      if (unit.length) {
        input.css({"padding-right": unit.width() + 5 + "px"});
      }
      input.fireEvent("focus");
      this.markField(elm, !!elm.value);
    };

    PlayerLimitsSettingsForm.prototype.validateInputItem = function (elm) {
      if (!elm.value) {
        this.markField(elm, false);
        return false;
      }
      var min = parseFloat(elm.getAttribute("min"));
      if (!isNaN(min) && elm.value < min) {
        this.markField(elm, false);
        return false;
      }
      var max = parseFloat(elm.getAttribute("max"));
      if (!isNaN(max) && elm.value > max) {
        this.markField(elm, false);
        return false;
      }
      var pattern = elm.getAttribute("data-pattern");
      if (pattern) {
        var regexp = new RegExp(pattern);
        if (!regexp.test(elm.value)) {
          this.markField(elm, false);
          return false;
        }
      }
      this.markField(elm, true, false);
      return true;
    };

    PlayerLimitsSettingsForm.prototype.onFormSubmit = function (evt) {
      var that = this;
      var isValid = true;
      $(SELECTOR_SELECTS).forEach(function (item) {
        if (!item.value) {
          if (isValid) {
            $(item).scrollIntoView();
          }
          that.markField(item, false);
          isValid = false;
        } else if (item.value === "OWN") {
          that.markField(item, true);
          var result = that.validateInputItem(document.getElementById(item.id + "_input"));
          if (!result) {
            if (isValid) {
              $(item).scrollIntoView();
            }
            isValid = false;
          }
        } else {
          that.markField(item, true);
        }
      });
      evt.preventDefault();
      if (isValid) {
        this.send();
      } else {
        var invalidItemsError = this.form.attr("data-error-missing-values");
        if (invalidItemsError) {
          AlertDialog(invalidItemsError, {
            titleLabel: this.form.attr("data-error-title")
          });
        }
      }
    };

    PlayerLimitsSettingsForm.prototype.send = function () {
      var that = this;
      var values = this.form.serializeMap();
      var url = $.Link(this.form.attr("action"));
      $.ftnAjax(url.ajaxify().getUrl(), {
        method: "POST",
        data: values,
        beforeSend: function () {
          window.mobile.offer.toggleLoader(true);
        },
        success: function (data) {
          var jsonData = JSON.parse(data);
          if (jsonData.status === "ok") {
            AlertDialog(that.form.attr("data-success-message"), {
              titleLabel: that.form.attr("data-success-title"),
              okCallback: function () {
                window.location.href = window.location.href;
              }
            });
          } else {
            AlertDialog((jsonData.messages && jsonData.messages.global) || that.form.attr("data-error-message"), {
              titleLabel: that.form.attr("data-error-title")
            });
            for (var i in jsonData.messages) {
              if (i === "global") {
                continue;
              }
              that.markField(document.getElementById("id-" + i), false, jsonData.messages[i]);
            }
          }
        },
        error: function () {
          AlertDialog(that.form.attr("data-error-message"), {
            titleLabel: that.form.attr("data-error-title")
          });
        },
        complete: function () {
          window.mobile.offer.toggleLoader(false);

          var GTM = require("gtm")(this._dyEnabled);
          if (GTM) {
            GTM.trackLimitsVerification();
            if (GTM.dyEnabled) {
              GTM.trackLimitsVerificationDY();
            }
          }
        }
      });
    };

    PlayerLimitsSettingsForm.prototype.markField = function (elm, isValid, message) {
      if (elm && elm.tagName) {
        var labelId = elm.id.replace("_input", "");
        $("label[for=" + labelId + "]", this.form).toggleClass("error", !isValid);
        if (elm.tagName === "SELECT") {
          elm = elm.parentElement;
        }
        var $elm = $(elm);
        $elm.toggleClass("error", !isValid);
        if (typeof message !== "undefined") {
          if (message) {
            $elm.parent(".row").append('<span class="error-description">' + message + '</span>');
          } else {
            $(".error-description", $elm.parent(".row")).remove();
          }
        }
      }
    };

    PlayerLimitsSettingsForm.prototype._getOpt = function (name, defaultValue) {
      if (this.opts && typeof this.opts[name] != "undefined") {
        return this.opts[name];
      }
      return defaultValue;
    };

    module.exports = function (opts, dyEnabled) {
      return new PlayerLimitsSettingsForm(opts, dyEnabled);
    };
  });
})(modulr, $);
;
/* assets/src/scripts/fortuna/forms/quickbet-settings-form.js */
(function($) {

    modulr.define("quickbet-settings-form", function(require, exports, module) {

        var FormValidator = require("form-validator");
        var InputWithCurrency = require("input-with-currency");
        var AlertDialog = window.mobile.dialogs.dialogAlert;
        var Form = require("form");

        var SELECTOR_GLOBAL_ERROR = ".error-message";
        var SELECTOR_INPUTS = "input[type=number]";

        var QuickbetSettingsForm = function(form, settings) {
            Form(form);
            this._settings = settings;
            this._form = $(form);
            this._inputs = $(SELECTOR_INPUTS, this._form);
            this._validator = FormValidator(form);
            InputWithCurrency(this._form);
            this.initialize();
        };

        QuickbetSettingsForm.prototype.initialize = function() {
            this.initializeEvents();
        };

        QuickbetSettingsForm.prototype.initializeEvents = function() {
            var that = this;
            this._form.on("submit", function(evt) {
                that.onFormSubmit(evt);
            });
            this._inputs.on("focus", function(evt) {
                this.setAttribute("data-raw-value", this.value);
                this.setAttribute("data-first-press", true);
            });
            this._inputs.on("blur", function(evt) {
                this.removeAttribute("data-raw-value");
                this.removeAttribute("data-first-press");
            });
            this._inputs.on("keypress", function(evt) {
                that.onKeyPress(evt);
            });
            this._inputs.on("keyup", function(evt) {
                that.onKeyUp(evt);
            });
        };

        QuickbetSettingsForm.prototype.onFormSubmit = function(evt) {
            evt.preventDefault();
            if (this._validator.isValid()) {
                this.send();
            }
        };

        QuickbetSettingsForm.prototype.onKeyPress = function(evt) {
            if (!evt.target.getAttribute("max")) return;
            var char = String.fromCharCode(evt.which);
            if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ",", "."].indexOf(char) < 0) {
                evt.preventDefault();
                return;
            }
            var value;
            if (evt.target.getAttribute("data-first-press")) {
                value = "";
                evt.target.removeAttribute("data-first-press")
            } else {
                value = "" + evt.target.getAttribute("data-raw-value");
            }

            if ([",", "."].indexOf(char) > -1 && (value.indexOf(".") > -1 || value.indexOf(",") > -1)) {
                evt.preventDefault();
                return;
            }
            value += char;
            value = value.replace(",", ".");
            if (parseFloat(value) > parseFloat(evt.target.getAttribute("max"))) {
                evt.preventDefault();
                return;
            }
            var splitVal;
            if (value.indexOf(".") > -1) {
                splitVal = value.split(".");
            } else if (value.indexOf(",") > -1) {
                splitVal = value.split(",");
            }
            if (splitVal && splitVal.length > 1) {
                var max = evt.target.getAttribute("max");
                var splitMax = max.split(".");
                if (splitMax.length > 1) {
                    if (splitMax[1].length < splitVal[1].length) {
                        evt.preventDefault();
                        return;
                    }
                }
            }
            evt.target.setAttribute("data-raw-value", value);
        };

        QuickbetSettingsForm.prototype.onKeyUp = function(evt) {
            if (8 === evt.keyCode) {
                evt.target.setAttribute("data-raw-value", evt.target.value);
            }
        };

        QuickbetSettingsForm.prototype.send = function() {
            var that = this;
            var values = this._form.serialize();
            $.ftnAjax($.Link(this._form.attr("action")).ajaxify().getUrl(), {
                "method": "POST",
                "data": values,
                "success": function(data) {
                    var jsonData = JSON.parse(data);
                    if (jsonData.status === "ok") {
                        var text = that._form.attr("data-success-message");
                        var title = that._form.attr("data-success-title");
                        $(SELECTOR_GLOBAL_ERROR, that._form).toggleClass("hidden", true);
                        AlertDialog(text, {
                            titleLabel: title
                        });
                    } else {
                        $(SELECTOR_GLOBAL_ERROR, that._form).toggleClass("hidden", false);
                        $(SELECTOR_GLOBAL_ERROR, that._form)[0].innerText = jsonData.message;
                    }
                }
            });
        };

        module.exports = function(form, settings) {
            return new QuickbetSettingsForm(form, settings);
        }

    });

})($);
;
/* assets/src/scripts/fortuna/forms/set-password-form.js */
(function($) {
    "use strict";

    modulr.define("set-password-form", function(require, exports, module) {

        var FormValidator = require("form-validator");
        var AlertDialog = window.mobile.dialogs.dialogAlert;
        var PasswordToggler = require("password-toggler");

        var SELECTOR_SHOW_PASSWORD = ".show-password";
        var SELECTOR_PASSWORD = "#id-password";
        var SELECTOR_PASSWORD_CONFIRM = "#id-password-confirm";
        var SELECTOR_PASSWORD_INPUTS = "input[type=text], input[type=password]";
        var CLASS_HIDDEN = "hidden";

        var SetPasswordForm = function(form) {
            this._form = $(form);
            this._validator = FormValidator(form);
            this.initializeEvents();
        };

        SetPasswordForm.prototype.initializeEvents = function() {
            var that = this;
            this._form.on("submit", function(evt) {
               that.onFormSubmit(evt);
            });
            $(SELECTOR_PASSWORD_INPUTS, this._form).on("blur", function() {
                that._validator.validateField(this);
            });
            var onToggle = function(input, toggler) {
            	var $input = $(input);
        		if ($input.is(SELECTOR_PASSWORD)) {
        			$(SELECTOR_PASSWORD_CONFIRM).parent("div").toggleClass(CLASS_HIDDEN, $input.attr("type") !== "password");
        		}
        	};
        	$(SELECTOR_SHOW_PASSWORD, this._form).forEach(function(item) {
            	PasswordToggler(item, onToggle);
            });
        };

        SetPasswordForm.prototype.onFormSubmit = function(evt) {
            evt.preventDefault();
            this.precheck();
            if (this._validator.isValid()) {
                this.send();
            }
        };

        SetPasswordForm.prototype.precheck = function() {
            var newPassword = $(SELECTOR_PASSWORD);
            if (newPassword.length && newPassword.attr("type") === "text") {
                $(SELECTOR_PASSWORD_CONFIRM).val(newPassword.val());
            }
        }

        SetPasswordForm.prototype.send = function() {
            var that = this;
            var values = this._form.serialize();
            $.ftnAjax($.Link(this._form.attr("action")).ajaxify().getUrl(), {
                "method": that._form[0].method,
                "data": values,
                success: function(data) {
                    var jsonData = {};
                    try {
                        jsonData = JSON.parse(data);
                    } catch (e) {}
                    var text = (jsonData.popupData && jsonData.popupData.message);
                    if (text) {
                        var title = (jsonData.popupData && jsonData.popupData.title);
                        var buttonLabel = (jsonData.popupData && jsonData.popupData.buttonLabel);
                        var buttonHref = (jsonData.popupData && jsonData.popupData.buttonHref);
                        AlertDialog(text, {
                            titleLabel: title,
                            okButtonLabel: buttonLabel,
                            link: buttonHref
                        });
                    }
                    that._form.invoke("reset");
                },
                error: function(xhr, status, responseData) {
                    var jsonData = {};
                    try {
                        jsonData = JSON.parse(responseData);
                    } catch (e) {}
                    var text = (jsonData.errors && jsonData.errors.global) || (jsonData.popupData && jsonData.popupData.message);
                    var title = jsonData.popupData && jsonData.popupData.title;
                    if (text) {
                        AlertDialog(text, {
                            titleLabel: title
                        });
                    }
                    if (jsonData.errors) {
                      for (var i = 0; i < jsonData.errors.length; i++){
                        $("input[name=" + i + "]").toggleClass("error", true).after('<span class="error-description" data-for="' + i + '">' + jsonData.errors[i] + '</span>');
                      }
                    }
                }
            });
        };

        module.exports = function(form) {
            return new SetPasswordForm(form);
        }

    });

})($);
;
/* assets/src/scripts/fortuna/forms/user-offer-settings-form.js */
(function($) {
    "use strict";

    modulr.define("user-offer-settings-form", function(require, exports, module) {

        var AlertDialog = window.mobile.dialogs.dialogAlert;

        var SELECTOR_INPUTS = "input[type=checkbox]";
        var SELECTOR_SELECT_ALL_INPUT = "input[type=checkbox].select-all";
        var SELECTOR_CHECKED_INPUTS_WO_SELECT_ALL = "input[type=checkbox]:checked:not(.select-all)";
        var SELECTOR_INPUTS_WO_SELECT_ALL = "input[type=checkbox]:not(.select-all)";
        var SELECTOR_PARENT = ".content";
        var CLASS_SELECT_ALL = "select-all";
        var CLASS_HIDDEN = "hidden";

        var UserOfferSettingsForm = function(form) {
            this._form = $(form);
            this._loadingOverlay = null;
            this.initialize();
        };

        UserOfferSettingsForm.prototype.initialize = function() {
            var that = this;
            this.markSelectAllChecked();
            this._form.delegate("change", SELECTOR_INPUTS, function(evt) {
                that.onCheckboxChange(evt.target);
            });
        };

        UserOfferSettingsForm.prototype.onCheckboxChange = function(elm) {
            var that = this;
            if (elm.className.indexOf(CLASS_SELECT_ALL) > -1) {
                $(SELECTOR_INPUTS_WO_SELECT_ALL, $(elm).parent(".content")).forEach(function(item) {
                    item.checked = elm.checked;
                });
            } else {
                this.checkGroupSelectAllIfNeeded(elm);
            }
            var data = this._form.serializeMap();
            $.ftnAjax($.Link(this._form[0].action).ajaxify().getUrl(), {
                "method": "post",
                "data": $.Utils.serializeMap(data),
                "proccessor": "json",
                beforeSend: function() {
                    window.mobile.offer.toggleLoader(true);
                },
                success: function(data) {
                    if (data.status !== "ok") {
                        that.showErrorMessage();
                        elm.checked = !elm.checked;
                    }
                },
                error: function() {
                    that.showErrorMessage();
                },
                complete: function() {
                    window.mobile.offer.toggleLoader(false);
                }
            });
        };

        UserOfferSettingsForm.prototype.showErrorMessage = function() {
            var message = this._form.attr("data-error-message");
            var title = this._form.attr("data-error-title");
            AlertDialog(message, {
                titleLabel: title
            });
        };

        UserOfferSettingsForm.prototype.markSelectAllChecked = function() {
            var that = this;
            $(SELECTOR_SELECT_ALL_INPUT).forEach(function(item) {
                that.checkGroupSelectAllIfNeeded(item);
            });
        };

        UserOfferSettingsForm.prototype.checkGroupSelectAllIfNeeded = function(elm) {
            var parent = $(elm).parent(SELECTOR_PARENT);
            var selectAllElm;
            if (elm.className.indexOf(CLASS_SELECT_ALL) > -1) {
                selectAllElm = $(elm);
            } else {
                selectAllElm = $(SELECTOR_SELECT_ALL_INPUT, parent);
            }

            var presentLength = $(SELECTOR_INPUTS_WO_SELECT_ALL, parent).length;
            var checkedLength = $(SELECTOR_CHECKED_INPUTS_WO_SELECT_ALL, parent).length;
            var value = (presentLength === checkedLength);
            selectAllElm.forEach(function(item) {
                item.checked = value;
            });
        };

        module.exports = function(form) {
            return new UserOfferSettingsForm(form);
        };

    });

})($);
;
/* assets/src/scripts/fortuna/inApp-notification/handler.js */
(function (modulr, $) {
  "use strict";

  /**
   * InApp Notifications handler for Simple Messages, Logout and Balance notifications
   */
  modulr.define('inApp-notification/handler', function (require, exports, module) {

    function InAppNotificationHandler(staticData) {

      this.STATIC = {
        DATA: staticData,
        NOTIFICATION_TYPES: {
          LOGOUT: "FTNInAppLogOut",
          BALANCE: "FTNInAppBalance",
          SIMPLE: "FTNInAppSimple"
        },
        EVENT_TYPE: 'FTNInAppCustomEvent',
        LOGOUT: {
          APIGW: "/_logout-gm",
        },
        BALANCE: {
          MAIN_ACCOUNT: '#header-user .amount-wrapper .credit',
          LIVE_ACCOUNT: 'dl.main-header__action--account dd',
          GM_ACCOUNT: '.user-panel__amount.credit',
          REFRESH_URL: '/ajax/user/refresh/balance'
        },
        CHECK_TOKEN_URL: window.APIGW_URL + '/_notification-token'
      };

      this.init();

      if(this.STATIC.DATA.INAAPP_MICRO_FE_ENABLED) {
        this.connectAndSubscribe();
      }
    }

    InAppNotificationHandler.prototype = {
      init: function () {
        this.addListener();
      },

      connectAndSubscribe: function () {
        var InAppService = new window.mobile.InAppService.Subscription.InAppServiceSubcription();
        var that = this;
        fetch( this.STATIC.CHECK_TOKEN_URL, {
          credentials: 'include',
          cache: 'no-cache',
          method: 'GET'
        }).then(function (response) {
          if(response.status === 401) {
            throw new Error('401 Unauthorized');
          }
          return response.json();
        }).then(function (data) {
          if (data) {
            const notificationToken = data.notification_token;
            if (notificationToken) {
              if (window.InAppService)
              {
                window.InAppService.initializeStompConnection(notificationToken);
                window.InAppService.subscribeNotification(InAppService.subscriptionIds.inAppNotification(),
                  '/user/topic/notifications');
              }
            }
          }
        }).catch(function (err) {

          window.postMessage({
            type: 'FTNUserLoggedOut',
            payload: {username: window.Playtech.getUserDetails.username}
          }, "*");

          that.invalidateLoggedInUserData();
          that.handle_apigw_logout();
        });
      },
      addListener: function () {
        window.addEventListener(this.STATIC.EVENT_TYPE, function (notification) {
          if (this.checkNotificationType(notification.detail.notificationType)) {
            this.resolveNotification(notification);
          }
        }.bind(this));
      },

      checkNotificationType: function (type) {
        return Object.values(this.STATIC.NOTIFICATION_TYPES).includes(type);
      },

      resolveNotification: function (notification) {
        switch (notification.detail.notificationType) {
          case this.STATIC.NOTIFICATION_TYPES.LOGOUT:
            this.handleLogout();
            break;
          case this.STATIC.NOTIFICATION_TYPES.BALANCE:
            this.handleBalance();
            break;
          case this.STATIC.NOTIFICATION_TYPES.SIMPLE:
            break;
          default:
            return;
        }
      },

      handleBalance: function () {
        var _this = this;
        $.ftnAjax(this.STATIC.BALANCE.REFRESH_URL, {
          method: "POST",
          success: function (data) {
            var userBalance = JSON.parse(data);
            if (userBalance.status === "ok") {
              _this.resolveBalance(userBalance);
            }
          }
        });
      },

      resolveBalance: function (balance) {

        var drawerBalanceElement = document.querySelector('#drawer .user-info-holder .account-balance');
        var primaryBalanceHTML = balance.creditFormattedMobile + "&nbsp;";
        var secondaryBalanceHTML = this.getSecondaryBalance(balance);

        if(drawerBalanceElement) {
          drawerBalanceElement.innerHTML = primaryBalanceHTML + this.STATIC.DATA.CURRENCY.PRIMARY + "&nbsp;" + secondaryBalanceHTML;
        }
        this.resolveAccountTopBarElement(primaryBalanceHTML);
      },

      resolveAccountTopBarElement: function (balance) {
        var liveAccountElement = document.querySelector(this.STATIC.BALANCE.LIVE_ACCOUNT);
        var mainAccountElement = document.querySelector(this.STATIC.BALANCE.MAIN_ACCOUNT);
        var gmTopBarAccountElement = document.querySelector(this.STATIC.BALANCE.GM_ACCOUNT);

        if (liveAccountElement !== null) {
          // Currency + balance for Live
          liveAccountElement.innerHTML = balance + this.STATIC.DATA.CURRENCY.PRIMARY + "&nbsp;";
        }
        if (mainAccountElement !== null) {
          mainAccountElement.innerHTML = balance;
        }
        if (gmTopBarAccountElement !== null) {
          gmTopBarAccountElement.innerHTML = balance;
        }
      },

      getSecondaryBalance: function (balance) {
        return this.STATIC.DATA.CURRENCY.DUAL_ENABLED ?
          ' <span>( <span>' + balance.convertedCreditFormattedMobile + '&nbsp;' + this.STATIC.DATA.CURRENCY.SECONDARY + '&nbsp;</span> )</span>' :
          '';
      },

      handleSimpleNotification: function (notification) {
        window.mobile.dialogs.dialogAlert(notification.detail.data.body.message, {
          "specialClass": "in-app-simple-dialog",
          "titleLabel": this.STATIC.DATA.SIMPLE_TITLE,
          "okButtonLabel": this.STATIC.DATA.SIMPLE_BUTTON
        });
      },

      invalidateLoggedInUserData: function () {
        window.getLoggedInUserData = function () {
          return {
            "username": null,
            "loggedIn": false,
            "lastModifiedDate": null
          }
        }
      },

      handleLogout: function () {
        window.postMessage({
          type: 'FTNUserLoggedOut',
          payload: {
            username: window.Playtech.getUserDetails.username,
            logoutDialog: true
          }
        }, "*");

        this.invalidateLoggedInUserData();
      },

      handle_apigw_logout: function () {
        var form = document.createElement("form");
        form.method = "POST";
        form.action = window.APIGW_URL + this.STATIC.LOGOUT.APIGW;
        document.body.appendChild(form);
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "logoutNotification");
        hiddenField.setAttribute("value", "true");
        form.appendChild(hiddenField);
        form.submit();
      }

    };

    module.exports = function (staticData) {
      return new InAppNotificationHandler(staticData);
    }
  });
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/detail.js */
(function(modulr, $) {
    "use strict";

    modulr.define("live.detail", function(require, exports, module) {
        const SPORT_FORMULA_1 = "FORMULA_1";

        const SELECTOR_LIVE_DETAIL = "#live-detail";
        const SELECTOR_TOP = SELECTOR_LIVE_DETAIL + " > .top";
        const SELECTOR_SCOREBOARD = "#scoreboard";
        const SELECTOR_MARKETS = ".markets";
        const SELECTOR_MARKET_LIST = ".market-list";
        const SELECTOR_SCOREBOARD_CONTROLS = SELECTOR_SCOREBOARD + " .control";
        const SELECTOR_NOTE_OPENER = ".note";
        const SELECTOR_APPENDIX_CONTROL = SELECTOR_SCOREBOARD + " .tabs .control.appendix";
        const SELECTOR_MATCH_TRACKER_CONTAINER = "#match-tracker-widget";

        const CLASS_SCOREBOARD_PINNED = "pinned";
        const CLASS_DETAIL_INITIALIZED = "initialized";
        const CLASS_OVERVIEW = "overview";
        const CLASS_APPENDIX = "appendix";
        const CLASS_MATCH_TRACKER = "match-tracker";
        const CLASS_SHOW_DETAIL = "detail";
        const CLASS_INFO_BUTTON = "bet-market-subname__description-icon";
        const CLASS_NO_SCROLL = "no-scroll";

        // Magic constants
        var detail = null;
        var top = null;
        var scoreboard = null;
        var markets = null;
        var currentSport = null;
        var availableViewsForScoreboard = null;

      function onTabSwitchChat(anchor) {
        if (anchor === "event-bets") {
          // For correct functionality of scrolling, height initialization etc. we manually unmount chat
          document.documentElement.style.overflow = "auto";
          var newChatAppDiv = document.createElement("div");
          newChatAppDiv.setAttribute("id", "chat-app");
          document.querySelector("#chat-app").replaceWith(newChatAppDiv);
        }
      }

        function initialize(params) {
            const tabs = require("components.tabs").instance("#live-event-detail-tabs");
            tabs.onSwitch(onTabSwitchChat);
            detail = $(SELECTOR_LIVE_DETAIL);
            top = $(SELECTOR_TOP);
            scoreboard = $(SELECTOR_SCOREBOARD, detail);
            if (scoreboard && scoreboard[0] && scoreboard[0].dataset) {
              currentSport = scoreboard[0].dataset.sport;
            }
            markets = $(SELECTOR_MARKETS, detail);
            initializeEvents();
            connect(params);
            getAvailableViewsForScoreboard();
            $(document).on("scoreboard:appendix", function(evt) {
                toggleAppendix("show" === evt.action);
            });
            $(window).on("load", function() {
                updateScoreboardVisualForPinnedState();
                if (scoreboard.hasClass(CLASS_MATCH_TRACKER)) {
                    initializeMatchTracker();
                } else {
                    arrange();
                }
                activateInfoDetailToggle();
            });

            if (detail.hasClass("paused")) {
              $("html").toggleClass(CLASS_NO_SCROLL, true);
              // the code below handles layoutClass when tab is changed
              $('[href="#event-bets"]')[0].dataset.layoutClass = CLASS_NO_SCROLL;
            }
        }

        function handleTopInfo(showScore, topSelector) {
            if (!document.querySelector(topSelector)) {
                return;
            }
            document.querySelector(topSelector + " .mini-score").style.display = showScore ? "inline" : "none";
            document.querySelector(topSelector + " .league").style.display = !showScore ? "inline" : "none";
        }

        function initializeEvents() {
            scoreboard.delegate("click", SELECTOR_SCOREBOARD_CONTROLS, function(evt) {
                handleScoreboardControlClick(evt, this);
            });
            markets.delegate("click", SELECTOR_NOTE_OPENER, function(evt) {
                showNote(evt, this);
            });

            const observer = new IntersectionObserver(
                function(e) {
                    const isSticky = e[0].intersectionRatio < 1;
                    handleTopInfo(isSticky, SELECTOR_TOP + "." + CLASS_OVERVIEW);
                },
                {
                    threshold: [1],
                    root: null,
                    rootMargin: "0px",
                }
            );

            if (scoreboard && scoreboard[0]) {
              observer.observe(scoreboard[0]);
            }
        }

        function arrange() {
            detail.toggleClass(CLASS_DETAIL_INITIALIZED, true);
            updateScoreboardVisualForPinnedState();
        }

        function updateScoreboardVisualForPinnedState() {
          var scoreBoardEl = document.getElementById('scoreboard');

          if (scoreBoardEl) {
            if (scoreBoardEl.classList.contains(CLASS_SCOREBOARD_PINNED)) {
              $.cookie("scb_pinned", "true", {path: "/", expires: 30});
            } else {
              $.removeCookie("scb_pinned", {path: "/"});
            }
          }
        }

        function activateInfoDetailToggle() {
          var marketList = document.querySelector(SELECTOR_MARKET_LIST);
          if (marketList) {
            marketList.addEventListener('click', function(e) {
              var elm = e.target;
              if (elm.classList.contains(CLASS_INFO_BUTTON)) {
                elm = elm.parentNode;
              }
              if (
                elm.classList.contains('name') &&
                elm.nodeName === 'H3' &&
                elm.getElementsByClassName(CLASS_INFO_BUTTON).length
              ) {
                var container = elm.parentNode.parentNode;
                if (container.classList.contains('live-market')) {
                  if (container.classList.contains(CLASS_SHOW_DETAIL)) {
                    container.classList.remove(CLASS_SHOW_DETAIL);
                  } else {
                    container.classList.add(CLASS_SHOW_DETAIL);
                  }
                }
              }
            }, true);
          }
        }

        function handleScoreboardControlClick(evt, elem) {
            if (!evt) return;
            evt.preventDefault();
            var view = elem.getAttribute("data-scoreboard-cls");
            if (view) {
                handleTopInfo(view !== CLASS_OVERVIEW, SELECTOR_TOP);

                if (scoreboard.hasClass(view)) {
                    scoreboard.toggleClass(CLASS_SCOREBOARD_PINNED);
                } else {
                    if (view === CLASS_MATCH_TRACKER) {
                        initializeMatchTracker();
                    }
                    var actualView;
                    var avArr = availableViewsForScoreboard.split(" ");
                    for (var i = 0, l = avArr.length; i < l; i++) {
                        if (scoreboard.hasClass(avArr[i])) {
                            actualView = avArr[i];
                            break;
                        }
                    }
                    scoreboard.toggleClass(availableViewsForScoreboard, false).toggleClass(view, true);
                    top.toggleClass(availableViewsForScoreboard, false).toggleClass(view, true);
                    $(document).fireEvent("scoreboard:view:change", false, {
                        "scoreboard": {
                            "actual": view,
                            "previous": actualView
                        }
                    });
                }

                updateScoreboardVisualForPinnedState();
                arrange();
            }
        }

        function showNote(evt, elm) {
            evt.preventDefault();
            window.mobile.dialogs.dialogAlert(elm.dataset.note)
        }

        function connect(params) {
            var Updater = require("live.updater")(params.updater);
            if (params.wsUrl) {
                require("stomp-connector")(params.wsUrl, function(client) {
                    if (params.topicMarkets) {
                        client.subscribe(params.topicMarkets, function(message) {
                            Updater.exec(message, Updater.marketChange);
                        }, {
                            id: params.topicMarketsId
                        });
                    }
                    if (params.topicScoreboard) {
                        client.subscribe(params.topicScoreboard, function(message) {
                            Updater.exec(message, Updater.scoreboardChange);
                        }, {
                            id: params.topicScoreboardId
                        });
                    }
                });
            }
        }

        function toggleAppendix(show) {
            var appendixControl = $(SELECTOR_APPENDIX_CONTROL);
            if ((show && appendixControl.hasClass("hidden")) || (!show && !appendixControl.hasClass("hidden"))) {
                var parent = appendixControl[0].parentElement;
                var currentItems = parseInt(parent.getAttribute("data-items"));
                if (show && appendixControl.hasClass("hidden")) {
                    currentItems++;
                    appendixControl.toggleClass("hidden", false);
                } else if (!show && !appendixControl.hasClass("hidden")) {
                    appendixControl.toggleClass("hidden", true);
                    currentItems--;
                    if (scoreboard.hasClass(CLASS_APPENDIX)) {
                        scoreboard.toggleClass(CLASS_APPENDIX, false).toggleClass(CLASS_OVERVIEW, true);
                    }
                }
                parent.setAttribute("data-items", currentItems);
                arrange();
            }
        }

        function getAvailableViewsForScoreboard() {
            availableViewsForScoreboard = $(SELECTOR_SCOREBOARD_CONTROLS, scoreboard).map(function(item) {
                return item.getAttribute("data-scoreboard-cls");
            }).filter(function(item) {
                return typeof item !== "undefined";
            }).join(" ");
        }

        function initializeMatchTracker() {
          var matchId = scoreboard.attr("data-match-tracker-id");

          if (typeof SIR !== 'function' || !matchId) return;
          var lang = document.documentElement.getAttribute('lang');

          if (lang) {
            lang = lang.split('-')[0];
            SIR("changeLanguage", lang);
          }

          var skin = $('body').attr('data-skin');
          var logoBasePath = '/static/images/logo/png/' + skin;

          if (['psk', 'casa'].includes(skin)) {
            logoBasePath += '/alternative';
          }

          const betradarEventIdType = currentSport === SPORT_FORMULA_1 ? "stageId" : "matchId";

          const options = {
            layout: "single",
            scoreboard: "disable",
            detailedScoreboard: "disable",
            logo: logoBasePath + '/' + skin + '-logo@2x.png'
          };

          options[betradarEventIdType] = matchId;

          SIR("addWidget", SELECTOR_MATCH_TRACKER_CONTAINER, "match.lmtPlus", options);
        }

        module.exports = initialize;
    });

})(modulr, $);

;
/* assets/src/scripts/fortuna/live/homepage-live-module.js */
(function($, modulr) {
  "use strict";
  
  modulr.define("homepage-live-module", function(require, exports, module) {
    
    var logger = require("logger")(module.id);
    
    function handleEventMessage(data, events, params) {
      var json;
      try {
        json = JSON.parse(data.body);
      } catch (e) {
        logger.log("Doesn't seem to be valid JSON", e);
      }
      if (json) {
        if (typeof json.length == "undefined") {
          json = [json];
        }
        for (var i = 0; i < json.length; i++) {
          var message = json[i];
          var matchId = message.html ? message.html.id : message.id;
          
          if (message.type !== "liveevent" || typeof events[matchId] === "undefined") {
            continue;
          }

          switch (message.operation) {
            case "updated":
            updateEvent(message.html, events[matchId], params);
            if (message.html.mainMarket) {
              updateMarket(message.html.mainMarket, events[matchId], params);
            } else if (message.html.marketsCount > 0) {
              updateMarketNoMainMarket(message.html.id, events[matchId], params);
            } else {
              updateMarketNoMarkets(events[matchId], params);
            }
            break;
            case "deleted":
            removeEvent(events[matchId]);
            break;
          }
        }
      }
    }
    
    function handleMainMarketMessage(message, events, params) {
      if (typeof events[message.marketHtml.mainMarket.eventId] != "undefined") {
        switch (message.operation) {
          case "updated":
          updateMarket(message.marketHtml.mainMarket, events[message.marketHtml.mainMarket.eventId], params);
          break;
          case "deleted":
          removeMarket(message, events[message.marketHtml.mainMarket.eventId], params);
          break;
        }
      }
    }
    
    function updateEvent(data, event, params) {
      var $event = $(event);
      var $eventLink = $(event.children[0]);
      var scoreFingerprint = generateScoreFingerprint(data, params.updater.locale)
      var marketsCount = $event.find(".more-bets-cell .markets-count");
      var scoreElm = $event.find(".more-bets-cell .score");
      var overviewElm = $event.find(".match-cell .score"); 
      marketsCount.html("+" + data.otherMarketsCount);
      scoreElm.html(data.score);
      overviewElm.html(generateScore(data, params.updater.locale));
      if (scoreFingerprint != $eventLink.attr("data-score")) {
        $event.attr("data-score", scoreFingerprint);
        $event.find(".match-cell .date").toggleClass("hidden", true);
        marketsCount.toggleClass("hidden", true);
        scoreElm.toggleClass("hidden", false);
        overviewElm.toggleClass("hidden", false);
      }
    }
    
    function updateMarket(market, event, params) {
      var marketHtml = '<div id="market-' + market.id + '" class="market in-row odds-' + market.odds.length + '" data-match="market.names[params.updater.locale]">';
      for (var i = 0; i < market.odds.length; i++) {
        var odd = market.odds[i];
        var tipId = odd.id.substring(3);
        if (market.odds[i].value > 1) {
          var previousOdd = $("#odd-" + market.info + "-" + tipId);
          marketHtml += '<a';
          marketHtml += ' id="odd-' + market.info + '-' + tipId + '"';
          var onTicketClass = "";
          var notCompatibleClass = "";
          var combinableClass = "";
          if (previousOdd.hasClass("on-ticket")) {
            onTicketClass = " on-ticket";
          }
          if (previousOdd.hasClass("not-compatible")) {
            notCompatibleClass = " not-compatible";
          }
          if (previousOdd.hasClass("combinable")) {
            combinableClass = " combinable";
          }
          marketHtml += ' class="odd-button bet-link market-' + market.id + ' odd-' + tipId + ' odd-event-id-' + market.eventId + onTicketClass + notCompatibleClass + combinableClass + '"';
          marketHtml += ' href="' + generateAddBetUrl(params.updater.baseTicketAddUrl, market, odd) + '"';
          marketHtml += ' data-remove-bet-url="' + generateRemoveBetUrl(params.updater.baseTicketRemoveUrl, market, odd) + '"';
          marketHtml += ' kind="' + market.eventKind + '"';
          marketHtml += ' data-info="' + market.info + '"';
          marketHtml += ' data-odd="' + tipId + '"';
          marketHtml += ' data-value="' + odd.value + '"';
          marketHtml += ' data-tip="' + odd.names[params.updater.locale] + '"';
          marketHtml += ' data-market-id="' + market.id + '"';
          marketHtml += ' data-display-row="0"';
          marketHtml += ' data-event-id="' + market.eventId + '"';
          marketHtml += ' data-support-group="' + market.supportGroup.markets[odd.marketId] + '"';
          marketHtml += ' data-support-group-ex="' + market.supportGroupEx.markets[odd.marketId] + '"';
          marketHtml += '>';
          marketHtml += '<span class="tip">' + odd.names[params.updater.locale] + '</span>';
          var changeDirectionClass = "";
          if (odd.value > previousOdd.attr("data-value")) {
            changeDirectionClass = " up";
          } else if (odd.value < previousOdd.attr("data-value")) {
            changeDirectionClass = " down";
          }
          marketHtml += '<span class="odd' + changeDirectionClass + '">' + odd.value + '</span>';
          marketHtml += '</a>';
        } else {
          marketHtml += '<span class="odd-button inactive market-' + odd.marketId + '"';
          marketHtml += ' data-info="' + odd.info + '"';
          marketHtml += ' data-odd="' + tipId + '"';
          marketHtml += ' data-value="' + odd.value + '"';
          marketHtml += ' id="odd-' + odd.info + '-' + tipId + '"';
          marketHtml += '>';
          marketHtml += '<span class="tip">' + odd.names[params.updater.locale] + '</span>';
          marketHtml += '<span class="odd"><i class="icon-lock"></i></span>';
          marketHtml += '</span>';
        }
      }			
      marketHtml += '</div>';
      updateMarketCommon(marketHtml, event);
    }
    
    function updateMarketNoMainMarket(eventId, event, params) {
      var marketHtml = '<div class="market in-row odds-1">' +
      '<a href="' + params.updater.baseUrl + '/' + eventId + '" class="odd-button">' +
      '<span class="tip">' + params.updater.i18n.NO_MAIN_MARKET_LABEL + '</span>' +
      '</a>' +
      '</div>';
      updateMarketCommon(marketHtml, event);
    }
    
    function updateMarketNoMarkets(event, params) {
      var marketHtml = '<div class="market in-row odds-1">' +
      '<a href="' + params.updater.baseUrl + '/' + event.id + '" class="odd-button">' +
      '<span class="tip"><i class="icon-lock"></i>' + params.updater.i18n.NO_ODDS_LABEL + '</span>' +
      '</a>' +
      '</div>';
      updateMarketCommon(marketHtml, event);
    }
    
    function updateMarketCommon(html, event) {
      var $event = $(event);
      $event.find(".market").remove();
      $event.append($(html));
      $event.toggleClass("updated");
      window.setTimeout(function() {
        $event.toggleClass("updated");
      }, 5000);
    }
    
    function removeEvent(event) {
      event.parentNode.removeChild(event);
    }
    
    function removeMarket(message, event, params) {
      $("#market-" + message.marketHtml.mainMarket.id).remove();
      updateMarketNoMainMarket(message.marketHtml.mainMarket.eventId, event, params);
    }
    
    function generateScore(data, locale) {
      var out = '';
      if (data.score) {
        out += '<span class="highlight">' + data.score + '</span>';
      }
      if (data.info[locale]) {
        out += '<span class="info">' + data.info[locale] + '</span>';
      }
      if (data.actual[locale]) {
        out += '<span class="actual">' + data.actual[locale] + '</span>';
      }
      if (data.gameTime[locale]) {
        out += '<span class="game-time">' + data.gameTime[locale] + '</span>';
      }
      return out;
    }
    
    function generateScoreFingerprint(data, locale) {
      return (data.score ? data.score : "") + "-" +
      (data.info[locale] ? data.info[locale] : "") + "-" +
      (data.actual[locale] ? data.actual[locale] : "") + "-" +
      (data.gameTime[locale] ? data.gameTime[locale] : "");
    }
    
    function generateAddBetUrl(base, market, odd) {
      return base + "?" +
      [
        "info=" + odd.info,
        "tip_id=" + odd.id.substring(3),
        "value=" + odd.value,
        "kind=" + market.eventKind,
        "event=" + market.eventId,
        "market_id=" + market.id,
        "supportGroup=" + market.supportGroup.markets[odd.marketId],
        "supportGroupEx=" + market.supportGroupEx.markets[odd.marketId]
      ].join("&");
    }
    
    function generateRemoveBetUrl(base, market, odd) {
      return base + "?" +
      [
        "return_type=json",
        "info=" + odd.info,
        "tip_id=" + odd.id.substring(3),
        "kind=" + market.eventKind
      ].join("&");
    }
    
    module.exports = function(matchListSelector, params) {
      var events = {};
      $(matchListSelector + " .event").forEach(function(item) {
        events[item.getAttribute("id").replace("le-", "")] = item;
      });
      if (params.wsUrl) {
        require("stomp-connector")(params.wsUrl, function(client) {
          if (params.topicUpdates) {
            client.subscribe(params.topicUpdates, function(message) {
              handleEventMessage(message, events, params);
            }, {
              id: params.topicUpdatesId
            });
          }
          if (params.topicMainMarketUpdates) {
            client.subscribe(params.topicMainMarketUpdates, function(message) {
              handleMainMarketMessage(message, events, params);
            }, {
              id: params.topicMainMarketUpdatesId
            });
          }
        });
      }
      
      
      
    };
    
  });
  
})($, modulr);
;
/* assets/src/scripts/fortuna/live/impressions.js */
(function($, modulr) {
  "use strict";
  
  modulr.define("live.impressions", function(require, exports, module) {

      module.exports = {
        getElementsToWatchForVisibility: function (section, mandatoryEventAncestorArray) {
          var eventElementList = [];
          if (mandatoryEventAncestorArray) {
            for(var j = mandatoryEventAncestorArray.length - 1; j >= 0; j--) {
              var elementsToAdd = $(mandatoryEventAncestorArray[j] + ' .event');
              if (j === mandatoryEventAncestorArray.length - 1) {
                eventElementList = elementsToAdd;
              } else {
                for(var k = elementsToAdd.length - 1; k >= 0; k--) {
                  eventElementList.push(elementsToAdd[k]);
                }
              }
            }
          } else {
            eventElementList = $('.event');
          }
          
          var impressionService = new window.FrontendCommons.ImpressionService(this.reportElements.bind(this, section));
          var i = eventElementList.length;

          while (i--) {
            var eventElement = eventElementList[i];
            var eventId = eventElement.getAttribute('id');
            impressionService.observe(eventElement, eventId);
          }
        },
        startLiveImpressions: function (tracker) {
          this.tracker = tracker;
          window.addEventListener('DOMContentLoaded', this.getElementsToWatchForVisibility.bind(this, 'live'), false);
        },
        startPrematchImpressions: function (tracker, mandatoryEventAncestorArray) {
          this.tracker = tracker;
          window.addEventListener('DOMContentLoaded', this.getElementsToWatchForVisibility.bind(this, 'prematch', mandatoryEventAncestorArray), false);
        },
        reattachImpressions: function (tracker, section, mandatoryEventAncestorArray) {
          this.tracker = tracker;
          this.getElementsToWatchForVisibility(section, mandatoryEventAncestorArray);
        },
        reportElements: function (section, elementIdsToReport) {
          var elements = [];
          for(var id in elementIdsToReport) {
            elements.push(document.getElementById(id));
          }
          if (section === 'live') {
            this.tracker.trackLiveImpressions(elements);
          } else if (section === 'prematch') {
            this.tracker.trackPrematchImpressions(elements);
          }
        },
      };
      
  });
  
})($, modulr);
;
/* assets/src/scripts/fortuna/live/live.js */
(function($, modulr) {
    "use strict";
    
    modulr.define("live", function(require, exports, module) {

        module.exports = {
            initializeOverview: function(params) {
                require("live.overview")(params)
            },
            initializeDetail: function(params) {
                require("live.detail")(params);
            }
        };
        
    });
    
})($, modulr);
;
/* assets/src/scripts/fortuna/live/overview.js */
(function(modulr, $) {
    "use strict";

    modulr.define("live.overview", function(require, exports, module) {

        var Updater = null;
        var OverviewUpdater = null;

        var SELECTOR_SPORT_MENU = "#live-sports-menu";
        var SELECTOR_SPORT_MENU_SELECTED_ITEM = SELECTOR_SPORT_MENU + " .selected";
        var SELECTOR_LIVE_EVENTS_SPORTS = "#live-events .sport.scrollable-area";

        var CLASS_HIDDEN = "hidden";
        var CLASS_SELECTED = "selected";

        function initialize(params) {
            initializeSportMenu();
            initializeLegalNotices();
            require("live.sport-scroll-memory")();
            connect(params);
            initializeFakeGameTime(params);
        };

        function initializeFakeGameTime(params) {
            OverviewUpdater = require("live.updater.overview")(params);
            $(".event-" + window.FrontendCommons.SPORT_HOCKEY).forEach(function (event) {
                var dataset = Object.assign({}, event.dataset);
                dataset.zonedStart = dataset.zonedStart.split("[")[0];
                OverviewUpdater.handleRenderedLiveEventGameTime(dataset);
            });
        };

        function initializeSportMenu() {
            scrollToSelectedItemInSportsMenu();
            $(SELECTOR_SPORT_MENU).delegate("click", "a", function(evt) {
                onSportMenuItemActivate(evt, this);
            });
            $(window).on("popstate", function() {
                var sport = $.Link(window.location.href).getParam("sport");
                if (!sport) sport = $("#live-sports-menu li:first-child").attr("data-filter");
                if (sport) {
                    filterEventsBySport(sport);
                }
            });
        };

        function initializeLegalNotices() {
        	$("#page main").delegate("click", ".live-legal-notice-box .header", function(evt) {
        		evt.stopPropagation();
        		$(".live-legal-notice-box", "#page main").toggleClass("closed");
        	});
        };

        function scrollToSelectedItemInSportsMenu() {
            $(SELECTOR_SPORT_MENU_SELECTED_ITEM).scrollIntoView();
        };

        function onSportMenuItemActivate(evt, elm) {
            evt.preventDefault();
            var sportFilter = elm.parentElement.getAttribute("data-filter");
            filterEventsBySport(sportFilter, true);
        };

        function filterEventsBySport(sportFilter, saveHistory) {
            $(SELECTOR_LIVE_EVENTS_SPORTS).toggleClass(CLASS_HIDDEN, true);
            $(SELECTOR_SPORT_MENU_SELECTED_ITEM).toggleClass(CLASS_SELECTED, false);
            $("#" + sportFilter).toggleClass(CLASS_HIDDEN, false);
            $("#tab-" + sportFilter).toggleClass(CLASS_SELECTED, true);
            $(document).fireEvent("live:overview:sport:switch", false, {
                selected: sportFilter
            });
            if (saveHistory) {
                require("browse-history").push(window.location.pathname + "?sport=" + sportFilter, true);
                if (history && typeof history.pushState == "function") {
                    history.pushState({}, "", window.location.pathname + "?sport=" + sportFilter);
                }
            }
            document.scrollingElement.scrollTop = 0;
        };

        function connect(params) {
            Updater = require("live.updater")(params.updater);
            if (params.wsUrl) {
                require("stomp-connector")(params.wsUrl, function(client) {
                    if (params.topicUpdates) {
                        client.subscribe(params.topicUpdates, function(message) {
                            Updater.exec(message, Updater.overviewChange);
                        }, {
                            id: params.topicUpdatesId
                        });
                    }
                    if (params.topicMainMarketsUpdates) {
                        client.subscribe(params.topicMainMarketsUpdates, function(message) {
                            Updater.exec(message, Updater.mainMarketChange);
                        }, {
                            id: params.topicMainMarketsUpdates
                        });
                    }
                });
            }
        };

        module.exports = initialize;

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/american-football.js */
(function (modulr, $) {
  "use strict";
  modulr.define("live.updater.scoreboard.AMERICAN_FOOTBALL", function (require, exports, module) {
    module.exports = {
      VALUES: ["state", "actualQuarter", "team1Overtime", "team2Overtime"],
      update: function (item, value, scoreboardData, scoreboardElm, i18n) {
        switch (item) {
          case "state":
            $(".c.state-play", scoreboardElm).toggleClass("hidden", value.newVal !== "PLAY");
            $(".c.state", scoreboardElm).toggleClass("hidden", value.newVal === "PLAY").html(i18n[value.newVal]);
            break;
          case "actualQuarter":
            var showZeroQuarter = value.newVal === 0;
            var showOvertime = value.newVal === 5;
            $(".c.quarter-0", scoreboardElm).toggleClass("hidden", !showZeroQuarter);
            $(".c.quarter-overtime", scoreboardElm).toggleClass("hidden", !showOvertime);
            $(".c.quarter-other", scoreboardElm).toggleClass("hidden", showOvertime || showZeroQuarter);
            $(".c.actualQuarter", scoreboardElm).html(value.newVal);
            if (value.newVal > 0) {
              $(".c.team1Overtime", scoreboardElm).html(scoreboardData.team1Overtime);
              $(".c.team2Overtime", scoreboardElm).html(scoreboardData.team2Overtime);
            }
            if (value.newVal > value.oldVal) {
              $(".c.team1Quarter" + value.newVal, scoreboardElm).html(scoreboardData["team1Quarter" + value.newVal]);
              $(".c.team2Quarter" + value.newVal, scoreboardElm).html(scoreboardData["team2Quarter" + value.newVal]);
            } else {
              if (scoreboardData["team1Quarter" + value.oldVal] === 0 && scoreboardData["team2Quarter" + value.oldVal] === 0) {
                $(".c.team1Quarter" + value.oldVal, scoreboardElm).html("-");
                $(".c.team2Quarter" + value.oldVal, scoreboardElm).html("-");
              }
            }
            $(".c.overtime-col", scoreboardElm).toggleClass("hidden", value.newVal < 5);
            // mark actual quarter
            $(".c.quarter-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
            $(".c.quarter-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
            break;
          case "team1Overtime":
            var computedValue = value.newVal - scoreboardData.team1Quarter1 - scoreboardData.team1Quarter2 - scoreboardData.team1Quarter3 - scoreboardData.team1Quarter4;
            $(".c.team1Quarter5", scoreboardElm).html(computedValue);
            $(".c.team1Overtime", scoreboardElm).html(value.newVal);
            break;
          case "team2Overtime":
            var computedValue = value.newVal - scoreboardData.team2Quarter1 - scoreboardData.team2Quarter2 - scoreboardData.team2Quarter3 - scoreboardData.team2Quarter4;
            $(".c.team2Quarter5", scoreboardElm).html(computedValue);
            $(".c.team2Overtime", scoreboardElm).html(value.newVal);
            break;
        }
      }
    };
  });
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/baseball.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.BASEBALL", function(require, exports, module) {
        
        module.exports = {
            VALUES: ["periods", "inning", "base1", "base2", "base3", "state"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch (item) {
                    case "periods":
                        var length = value.newVal.length > 9 ? 8 : value.newVal.length;
                        for (var i = 0, l = length; i < l; i++) {
                            var period = value.newVal[i];
                            $(".c.team1Score" + i, scoreboardElm).html(period.team1Score);
                            $(".c.team2Score" + i, scoreboardElm).html(period.team2Score);
                            var outs = period["team" + scoreboardData.turn + "Outs"];
                            $(".c.outs", scoreboardElm).toggleClass("outs-1 outs-2 outs-3", false).toggleClass("outs-" + outs, true);
                            
                        }
                        if (value.newVal.length >= 9) {
                            var lastPeriod = value.newVal[value.newVal.length - 1];
                            $(".c.last-inning", scoreboardElm).html(value.newVal.length);
                            $(".c.team1ScoreLast", scoreboardElm).html(lastPeriod.team1Score);
                            $(".c.team2ScoreLast", scoreboardElm).html(lastPeriod.team2Score);
                            var outs = lastPeriod["team" + scoreboardData.turn + "Outs"];
                            $(".c.outs", scoreboardElm).toggleClass("outs-1 outs-2 outs-3", false).toggleClass("outs-" + outs, true);
                        }
                        $("table thead th.active", scoreboardElm).toggleClass("active", false);
                        if (value.newVal.length > 9) {
                        	$(".c.period-last-col", scoreboardElm).toggleClass("active", true);
                        } else {
                        	$(".c.period-" + value.newVal.length + "-col", scoreboardElm).toggleClass("active", true);
                        }
                        break;
                    case "inning":
                        $(".c.inning").html(value.newVal);
                        break
                    case "base1":
                        $(".c.base", scoreboardElm).toggleClass("first", value.newVal);
                        break;
                    case "base2":
                        $(".c.base", scoreboardElm).toggleClass("second", value.newVal);
                        break;
                    case "base3":
                        $(".c.base", scoreboardElm).toggleClass("third", value.newVal);
                        break;
                    case "state":
                        var isPlayState = value.newVal === "PLAY" || value.newVal.startsWith("INNING_");
                        var state =$(".c.state", scoreboardElm).toggleClass("hidden", isPlayState);
                        if (!isPlayState) {
                            state.html(i18n[value.newVal]);
                        }    
                        $(".c.state-play", scoreboardElm).toggleClass("hidden", !isPlayState);
                        break;
                }
            }
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/basketball-3x3.js */
(function(modulr, $) {
  "use strict";

  modulr.define("live.updater.scoreboard.BASKETBALL_3_X_3", function(require, exports, module) {

    module.exports = {
      VALUES: ["state"],
      update: function (item, value, scoreboardData, scoreboardElm, i18n, scoreboardUpdaterInstance) {
        if (item === "state") {
          $(".c.state", scoreboardElm).toggleClass("hidden", (value.newVal === "PLAY" || value.newVal === "OVERTIME")).html(i18n[value.newVal]);
          $(".c.state-play", scoreboardElm).toggleClass("hidden", (value.newVal !== "PLAY" && value.newVal !== "OVERTIME"));
          $(".c.overtime", scoreboardElm).toggleClass("hidden", value.newVal !== "OVERTIME");
        }
      }
    };

  });

})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/basketball.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.BASKETBALL", function(require, exports, module) {
        
        var BasketballScoreboardUpdater = {
            VALUES: ["actualQuarter", "team1Overtime", "team2Overtime"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n, scoreboardUpdaterInstance) {
                switch(item) {
                	case "actualQuarter":
                		$(".c.col-period-overtime", scoreboardElm).toggleClass("hidden", value.newVal < 5);
                		$(".c.actualQuarter", scoreboardElm).html(value.newVal);
                		// mark actual quarter
                        $(".c.quarter-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
                        $(".c.quarter-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
                		break;
                	case "team1Overtime":
                		var computedValue = value.newVal - scoreboardData.team1Quarter4 - scoreboardData.team1Quarter3 - scoreboardData.team1Quarter2 - scoreboardData.team1Quarter1;
                		$(".c.team1Overtime", scoreboardElm).html(computedValue);
                		$(".c.team1Score", scoreboardElm).html(value.newVal);
                		break;
                	case "team2Overtime":
                		var computedValue = value.newVal - scoreboardData.team2Quarter4 - scoreboardData.team2Quarter3 - scoreboardData.team2Quarter2 - scoreboardData.team2Quarter1;
                		$(".c.team2Overtime", scoreboardElm).html(computedValue);
                		$(".c.team2Score", scoreboardElm).html(value.newVal);
                		break;
                }
            }
        };
        
        module.exports = BasketballScoreboardUpdater;
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/beach-football.js */
(function(modulr) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.BEACH_FOOTBALL", function(require, exports, module) {
        
        module.exports = {
            VALUES: ["state", "period", "goalsTeam1Period4", "goalsTeam2Period4", "timer"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch(item) {
                    case "state":
                        $(".c.state", scoreboardElm).html(i18n[value.newVal]);
                        var isPlayState = ["PLAY", "EXTRA"].indexOf(value.newVal) > -1;
                        $(".c.state-play", scoreboardElm).toggleClass("hidden", !isPlayState);
                        $(".c.state", scoreboardElm).toggleClass("hidden", isPlayState);
                        var displayOvertime = value.newVal === "EXTRA";
                        $(".c.state-play .c.normal", scoreboardElm).toggleClass("hidden", displayOvertime);
                        $(".c.state-play .c.extra", scoreboardElm).toggleClass("hidden", !displayOvertime);
                        break;
                    case "period":
                        $(".c.period", scoreboardElm).html(value.newVal);
                        var displayOvertime = value.newVal > 3;
                        $(".c.state-play .c.normal", scoreboardElm).toggleClass("hidden", displayOvertime);
                        $(".c.state-play .c.extra", scoreboardElm).toggleClass("hidden", !displayOvertime);
                        $(".c.col-period-overtime", scoreboardElm).toggleClass("hidden", !displayOvertime);
                        if (value.newVal < 4) {
                        	$(".c.goalsTeam1Period" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam1Period" + value.newVal]);
                            $(".c.goalsTeam2Period" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam2Period" + value.newVal]);
                        }
                        // mark actual period
                        $(".c.period-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
                        $(".c.period-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
                        break;
                    case "goalsTeam1Period4":
                    	var computedValue = value.newVal - scoreboardData.goalsTeam1Period3 - scoreboardData.goalsTeam1Period2 - scoreboardData.goalsTeam1Period1;
                    	$(".c.goalsTeam1Period4", scoreboardElm).html(computedValue);
                    	$(".c.goalsTeam1", scoreboardElm).html(value.newVal);
                    	break;
                    case "goalsTeam2Period4":
                    	var computedValue = value.newVal - scoreboardData.goalsTeam2Period3 - scoreboardData.goalsTeam2Period2 - scoreboardData.goalsTeam2Period1;
                    	$(".c.goalsTeam2Period4", scoreboardElm).html(computedValue);
                    	$(".c.goalsTeam2", scoreboardElm).html(value.newVal);
                    	break;
                    case "timer":
                    	$(".c.timer", scoreboardElm).html(value.newVal);
                    	$(".c.timer-holder", scoreboardElm).toggleClass("hidden", !value.newVal);
                    	break;
                }
            }
        };
        
    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/beach-volleyball.js */
(function (modulr) {
  "use strict";

  var updater = {
    VALUES: ["team1Timeout", "team2Timeout", "team1ServingErrors", "team2ServingErrors", "actualSet"],
    update: function (item, value, scoreboardData, scoreboardElm, i18n, scoreboardUpdaterInstance) {
      switch (item) {
        case "team1Timeout":
        case "team2Timeout":
        case "team1ServingErrors":
        case "team2ServingErrors":
          scoreboardUpdaterInstance.updateConnectedProperties(item, value.newVal);
          var hideAppendix = scoreboardData["team1Timeout"] === 0 && scoreboardData["team2Timeout"] === 0 &&
            scoreboardData["team1ServingErrors"] === 0 && scoreboardData["team2ServingErrors"] === 0;
          $(document).fireEvent("scoreboard:appendix", false, {
            "action": hideAppendix ? "hide" : "show"
          });
          break;
        case "actualSet":
          $(".c.actualSet").html(value.newVal);
          // Set switch
          if (value.newVal < value.oldVal) {
            if (scoreboardData["team1Set" + value.oldVal] === 0 && scoreboardData["team2Set" + value.oldVal] === 0) {
              $(".c.team1Set" + value.oldVal, scoreboardElm).html("-");
              $(".c.team2Set" + value.oldVal, scoreboardElm).html("-");
            }
          } else {
            $(".c.team1Set" + value.newVal, scoreboardElm).html(scoreboardData["team1Set" + value.newVal]);
            $(".c.team2Set" + value.newVal, scoreboardElm).html(scoreboardData["team2Set" + value.newVal]);
          }
          // mark actual set
          $(".c.set-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
          $(".c.set-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
          // display hidden columns if needed
          if (value.newVal > 3) {
            $(".c.set-" + value.newVal + "-col", scoreboardElm).toggleClass("hidden", false);
          }
          if (value.newVal < value.oldVal && value.oldVal > 3) {
            $(".c.set-" + value.oldVal + "-col", scoreboardElm).toggleClass("hidden", true);
          }
          break;
      }
    }
  };

  modulr.define("live.updater.scoreboard.BEACH_VOLLEYBALL", function (require, exports, module) {
    module.exports = updater;
  });

})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/biathlon.js */
(function(modulr) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.BIATHLON", function(require, exports, module) {
        
        module.exports = {
            VALUES: ["actualShooting", "shooting1", "shooting2", "shooting3", "shooting4", "shootingCount"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch (item) {
                    case "actualShooting":
                        $(".c.shooting-info", scoreboardElm).toggleClass("hidden", value.newVal === 0);
                        $(".c.actualShooting", scoreboardElm).html(value.newVal);
                        $(".c.shooting-type", scoreboardElm).toggleClass("shooting" + value.oldVal, false)
                            .toggleClass("shooting" + value.newVal, true).html(i18n[scoreboardData["shooting" + value.newVal]]);
                        break;
                    case "shooting1":
                    case "shooting2":
                    case "shooting3":
                    case "shooting4":
                        $(".c." + item, scoreboardElm).html(i18n[value.newVal]);
                        break;
                    case "shootingCount":
                        $(".c.shooting-3-col").toggleClass("hidden", value.newVal < 3);
                        $(".c.shooting-4-col").toggleClass("hidden", value.newVal < 4);
                        break;
                }
            }
        }
        
    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/curling.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.CURLING", function(require, exports, module) {
        module.exports = {
            VALUES: ["actualEnd"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch (item) {
                	case "actualEnd":
                		// Zmena aktualniho endu
                		$(".c.actualEnd", scoreboardElm).html(value.newVal === 11 ? i18n.OVERTIME : (value.newVal + i18n.GAME_END));
                		// Zvyrazneni sloupce se stavem v aktualnim endu
                		$(".c.actual-end-score", scoreboardElm).toggleClass("active", value.newVal > 0);
                		// Prepnuti ovladaci classy pro aktualni end a nastaveni noveho aktualniho skore pro team1
                		$(".c.actual-end-score.team1End" + value.oldVal, scoreboardElm)
                			.toggleClass("team1End" + value.newVal, true)
                			.toggleClass("team1End" + value.oldVal, false)
                			.html(scoreboardData["team1End" + value.newVal]);
                		// Zobrazeni celkoveho skore
                		$(".c.totalScoreTeam1", scoreboardElm).html(scoreboardData.totalScoreTeam1);
                		$(".c.totalScoreTeam2", scoreboardElm).html(scoreboardData.totalScoreTeam2);
                		// Prepnuti ovladaci classy pro aktualni end a nastaveni noveho aktualniho skore pro team2
                		$(".c.actual-end-score.team2End" + value.oldVal, scoreboardElm)
	            			.toggleClass("team2End" + value.newVal, true)
	            			.toggleClass("team2End" + value.oldVal, false)
	            			.html(scoreboardData["team2End" + value.newVal]);
                		// Prepnuti aktivniho endu na appendixu
                		$(".c.end-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
                		$(".c.end-" + value.newVal + "-col.team1End" + value.newVal, scoreboardElm).html(scoreboardData["team1End" + value.newVal]);
                		$(".c.end-" + value.newVal + "-col.team2End" + value.newVal, scoreboardElm).html(scoreboardData["team2End" + value.newVal]);
                		$(".c.end-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
                		// Zobrazeni/skryti appendixu
                		var hideAppendix = (value.newVal < 2);
                    	$(document).fireEvent("scoreboard:appendix", false, {
                    		"action": hideAppendix ? "hide" : "show"
                    	});
                		break;
                }
            }
        };
    });
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/darts.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.DARTS", function(require, exports, module) {
        module.exports = {
            VALUES: ["state", "type"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch (item) {
                    case "state":
                        if (value.newVal.indexOf("SET") > -1) {
                            $(".c.set").html(value.newVal.replace("SET_", ""));
                            $(".c.state-set").toggleClass("hidden", false);
                            $(".c.state-leg").toggleClass("hidden", true);
                            $(".c.state").toggleClass("hidden", true);
                        } else if (value.newVal.indexOf("LEG") > -1) {
                            $(".c.leg").html(value.newVal.replace("LEG_", ""));
                            $(".c.state-set").toggleClass("hidden", true);
                            $(".c.state-leg").toggleClass("hidden", false);
                            $(".c.state").toggleClass("hidden", true);
                        } else {
                            $(".c.state-set").toggleClass("hidden", true);
                            $(".c.state-leg").toggleClass("hidden", true);
                            $(".c.state").toggleClass("hidden", false).html(i18n[value.newVal]);
                        }
                        break;
                    case "type":
                        $(".c.sets-and-legs-col", scoreboardElm).toggleClass("hidden", value.newVal !== "SETS_AND_LEGS");
                        break;
                
                }
            }
        };
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/floorball.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.FLOORBALL", function(require, exports, module) {
        
        module.exports = {
            VALUES: ["period", "goalsTeam1Period4", "goalsTeam2Period4", "timer"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch(item) {
                    case "period":
                        var showZeroPeriod = value.newVal === 0;
                        $(".c.period-0", scoreboardElm).toggleClass("hidden", !showZeroPeriod);
                        $(".c.period-other", scoreboardElm).toggleClass("hidden", showZeroPeriod);
                        $(".c.period", scoreboardElm).html(value.newVal);
                        if (value.newVal > value.oldVal) {
                            if (value.newVal === 4) {
                            	$(".c.goalsTeam1Period4", scoreboardElm)
                        			.html(scoreboardData.goalsTeam1Period4 - scoreboardData.goalsTeam1Period3 - scoreboardData.goalsTeam1Period2 - scoreboardData.goalsTeam1Period1);
                            	$(".c.goalsTeam2Period4", scoreboardElm)
                        			.html(scoreboardData.goalsTeam2Period4 - scoreboardData.goalsTeam2Period3 - scoreboardData.goalsTeam2Period2 - scoreboardData.goalsTeam2Period1);
                            } else {
                            	$(".c.goalsTeam1Period" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam1Period" + value.newVal]);
                            	$(".c.goalsTeam2Period" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam2Period" + value.newVal]);
                            }
                        } else {
                            if (scoreboardData["goalsTeam1Period" + value.oldVal] === 0 && scoreboardData["goalsTeam2Period" + value.oldVal]) {
                                $(".c.goalsTeam1Period" + value.oldVal, scoreboardElm).html("-");
                                $(".c.goalsTeam2Period" + value.oldVal, scoreboardElm).html("-");
                            }
                        }
                        var showOvertime = value.newVal > 3;
                        $(".c.overtime", scoreboardElm).toggleClass("hidden", !showOvertime);
                        $(".c.normal", scoreboardElm).toggleClass("hidden", showOvertime);
                        $(".c.col-period-overtime").toggleClass("hidden", !showOvertime);
                        // mark actual period
                        $(".c.period-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
                        $(".c.period-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
                        break;
                    case "goalsTeam1Period4":
                    	var countedValue = value.newVal - scoreboardData.goalsTeam1Period3 - scoreboardData.goalsTeam1Period2 - scoreboardData.goalsTeam1Period1;
                    	$(".c.goalsTeam1Period4").html(countedValue);
                    	$(".c.goalsTeam1").html(value.newVal);
                    	break;
                    case "goalsTeam2Period4":
                    	var countedValue = value.newVal - scoreboardData.goalsTeam2Period3 - scoreboardData.goalsTeam2Period2 - scoreboardData.goalsTeam2Period1;
                    	$(".c.goalsTeam2Period4").html(countedValue);
                    	$(".c.goalsTeam2").html(value.newVal);
                    	break;
                    case "timer":
                    	$(".c.timer-holder", scoreboardElm).toggleClass("hidden", !value.newVal);
                    	$(".c.timer", scoreboardElm).html(value.newVal);
                    	break;
                }
            }
        }
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/football.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.FOOTBALL", function(require, exports, module) {
        
        var FootballScoreboardUpdater = {
            VALUES: ["timerActive", "state", "overview" ],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch (item) {
                    case "timerActive":
                        $(".c." + item, scoreboardElm).toggleClass("hidden", value.newVal != "1");
                        break;
                    case "state":
                        $(".c.timerActive", scoreboardElm).toggleClass("hidden", !(value.newVal == "HALF_1" || value.newVal == "HALF_2" || value.newVal == "EXTRA" || value.newVal == "EXTRA_2"));
                        $(".c." + item, scoreboardElm).html(i18n[value.newVal] || "");
                        break;
                    case "overview":
                        $(".c." + item, scoreboardElm).html(value.newVal || "").toggleClass("hidden", !!value.newVal);
                        break;
                }
            }
        };
        
        module.exports = FootballScoreboardUpdater;
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/golf.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.golf", function(require, exports, module) {
        module.exports = {
            VALUES: ["team2Name"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch (item) {
                case "team2Name":
                    $(".c.team2Name").html(value.newVal);
                    $(".c.team-2-name").toggleClass("hidden", !value.newVal.length);
                    break;
                }
            }
        };
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/handball.js */
(function(modulr) {
    "use strict";
    
    var updater = {
        VALUES: ["state", "timer", "simpleTimer"],
        update: function(item, value, scoreboardData, scoreboardElm, i18n) {
            switch (item) {
                case "state":
                    $(".c." + item, scoreboardElm).html(i18n[value.newVal]);
                    $(".c.time-info", scoreboardElm).toggleClass("hidden", ["HALF_1", "HALF_2", "EXTRA", "EXTRA_2"].indexOf(value.newVal) < 0);
                    break;
                case "timer":
                case "simpleTimer":
                	$(".c.time-info", scoreboardElm).toggleClass("hidden", value.newVal <= 0);
                	$(".c.timer", scoreboardElm).html(value.newVal);
                	break;
            }
        }
    };
    
    modulr.define("live.updater.scoreboard.HANDBALL", function(require, exports, module) {
        module.exports = updater;
    });
    
    modulr.define("live.updater.scoreboard.FUTSAL", function(require, exports, module) {
        module.exports = updater;
    });
    
    
})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/hockey.js */
(function(modulr) {
  "use strict";

  modulr.define("live.updater.scoreboard.HOCKEY", function(require, exports, module) {
    var VALUES = ["state", "period", "timer", "simpleTimer", "goalsTeam1Period4", "goalsTeam2Period4", "i18Ns"];

    var GTU_TIMEOUT = null;

    function clearGTUTimeout() {
      if (GTU_TIMEOUT) {
        clearInterval(GTU_TIMEOUT);
        GTU_TIMEOUT = null;
      }
    };

    function fakeGameTimeUpdater(evt) {
      clearGTUTimeout();

      var event = null;

      if (evt.gameTimeInterval) {
        event = evt;
        $(".c.timer", $("#scoreboard")).html(event.hockeyGameTime.long);
      } else {
        event = Object.assign({}, evt.dataset);

        if (event.sport !== window.FrontendCommons.SPORT_HOCKEY) {
          return;
        }

        event.shortGameTime = event.gameTime;
        event = window.FrontendCommons.LiveMatch.getHockeyOverviewWithFakeGameTime(event, event.start);

        if (!event.gameTimeInterval) {
          return;
        }
      }

      GTU_TIMEOUT = setTimeout(function() {
        fakeGameTimeUpdater(event);
      }, 1000);
    };

    function update(item, value, scoreboardData, scoreboardElm, i18n, that) {
      switch (item) {
        case "state":
          $(".c.state", scoreboardElm).toggleClass("hidden", value.newVal === "PLAY").html(i18n[value.newVal]);
          $(".c.state-play", scoreboardElm).toggleClass("hidden", value.newVal !== "PLAY");
          break;
        case "period":
          $(".c.period").html(value.newVal);
          if (value.newVal < value.oldVal) {
            if (scoreboardData["goalsTeam1Period" + value.oldVal] === 0 && scoreboardData["goalsTeam2Period" + value.oldVal] === 0) {
              $(".c.goalsTeam1Period" + value.oldVal, scoreboardElm).html("-");
              $(".c.goalsTeam2Period" + value.oldVal, scoreboardElm).html("-");
            }
          } else {
            if (value.newVal === 4) {
              $(".c.goalsTeam1Period4")
                .html(scoreboardData.goalsTeam1Period4 - scoreboardData.goalsTeam1Period3 - scoreboardData.goalsTeam1Period2 - scoreboardData.goalsTeam1Period1);
              $(".c.goalsTeam2Period4")
                .html(scoreboardData.goalsTeam2Period4 - scoreboardData.goalsTeam2Period3 - scoreboardData.goalsTeam2Period2 - scoreboardData.goalsTeam2Period1);
            } else {
              $(".c.goalsTeam1Period" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam1Period" + value.newVal]);
              $(".c.goalsTeam2Period" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam2Period" + value.newVal]);
            }
          }
          $(".c.period-0", scoreboardElm).toggleClass("hidden", value.newVal > 0);
          $(".c.period-other", scoreboardElm).toggleClass("hidden", value.newVal <= 0);
          $(".c.overtime", scoreboardElm).toggleClass("hidden", value.newVal < 4);
          $(".c.normal", scoreboardElm).toggleClass("hidden", value.newVal > 3);
          $(".c.col-overtime", scoreboardElm).toggleClass("hidden", value.newVal < 4);
          // mark actual period
          $(".c.period-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
          $(".c.period-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
          break;
        case "i18Ns":
          clearGTUTimeout();
          var site = document.body.dataset.site;
          // CP site uses RO i18n
          site = site === "CP" ? "RO" : site;
          // RO site has two different localizations RO/EN so we need to pick to correct one
          if (site === "RO" && document.documentElement.getAttribute('lang') === "en_US") {
            site = "EN";
          }
          var gameTime = scoreboardData.i18Ns.entry.filter(function(x) {
            // there will always be exactly one country with that key
            return x.key === site;
          })[0].value.overviewData.gameTime;
          $(".c.timer", scoreboardElm).html(gameTime);
          var scoreboardEl = $("#scoreboard");
          if (scoreboardEl && scoreboardEl[0]) {
            scoreboardEl[0].dataset.gameTime = gameTime;
            fakeGameTimeUpdater(scoreboardEl[0]);
          }
          break;
        case "goalsTeam1Period4":
          var computedValue = value.newVal - scoreboardData.goalsTeam1Period3 - scoreboardData.goalsTeam1Period2 - scoreboardData.goalsTeam1Period1;
          $(".c.goalsTeam1Period4", scoreboardElm).html(computedValue);
          $(".c.goalsTeam1", scoreboardElm).html(value.newVal);
          break;
        case "goalsTeam2Period4":
          var computedValue = value.newVal - scoreboardData.goalsTeam2Period3 - scoreboardData.goalsTeam2Period2 - scoreboardData.goalsTeam2Period1;
          $(".c.goalsTeam2Period4", scoreboardElm).html(computedValue);
          $(".c.goalsTeam2", scoreboardElm).html(value.newVal);
          break;
      };
    };

    module.exports = {
        VALUES: VALUES,
        update: update,
        fakeGameTimeUpdater: fakeGameTimeUpdater,
    };

  });

})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/inline-hockey.js */
(function(modulr) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.INLINE_HOCKEY", function(require, exports, module) {
        
        module.exports = {
            VALUES: ["state", "actualQuarter", "timer", "simpleTimer", "goalsTeam1Quarter5", "goalsTeam2Quarter5"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch(item) {
                    case "state":
                        $(".c.state", scoreboardElm).toggleClass("hidden", value.newVal === "PLAY").html(i18n[value.newVal]);
                        $(".c.state-play", scoreboardElm).toggleClass("hidden", value.newVal !== "PLAY");
                        break;
                    case "actualQuarter":
                        $(".c.actualQuarter").html(value.newVal);
                        if (value.newVal < value.oldVal) {
                            if (scoreboardData["goalsTeam1Quarter" + value.oldVal] === 0 && scoreboardData["goalsTeam2Quarter" + value.oldVal] === 0) {
                                $(".c.goalsTeam1Quarter" + value.oldVal, scoreboardElm).html("-");
                                $(".c.goalsTeam2Quarter" + value.oldVal, scoreboardElm).html("-");
                            }
                        } else {
                            if (value.newVal === 5) {
                                $(".c.goalsTeam1Quarter5")
                                    .html(scoreboardData.goalsTeam1Quarter5 - scoreboardData.goalsTeam1Quarter4 - scoreboardData.goalsTeam1Quarter3 - scoreboardData.goalsTeam1Quarter2 - scoreboardData.goalsTeam1Quarter1);
                                $(".c.goalsTeam2Period5")
                                    .html(scoreboardData.goalsTeam2Quarter5 - scoreboardData.goalsTeam2Quarter4 - scoreboardData.goalsTeam2Quarter3 - scoreboardData.goalsTeam2Quarter2 - scoreboardData.goalsTeam2Quarter1);
                            } else {
                                $(".c.goalsTeam1Quarter" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam1Quarter" + value.newVal]);
                                $(".c.goalsTeam2Quarter" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam2Quarter" + value.newVal]);
                            }
                        }
                        $(".c.actualQuarter-0", scoreboardElm).toggleClass("hidden", value.newVal > 0);
                        $(".c.actualQuarter-other", scoreboardElm).toggleClass("hidden", value.newVal <= 0);
                        $(".c.overtime", scoreboardElm).toggleClass("hidden", value.newVal < 5);
                        $(".c.normal", scoreboardElm).toggleClass("hidden", value.newVal > 4);
                        $(".c.col-overtime", scoreboardElm).toggleClass("hidden", value.newVal < 5);
                        // mark actual period
                        $(".c.period-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
                        $(".c.period-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
                        break;
                    case "timer":
                    case "simpleTimer":
                        $(".c.timer", scoreboardElm).html(value.newVal);
                        $(".c.timer-holder", scoreboardElm).toggleClass("hidden", value.newVal == null);
                        break;
                    case "goalsTeam1Quarter5":
                        var computedValue = value.newVal - scoreboardData.goalsTeam1Quarter4 - scoreboardData.goalsTeam1Quarter3 - scoreboardData.goalsTeam1Quarter2 - scoreboardData.goalsTeam1Quarter1;
                        $(".c.goalsTeam1Quarter5", scoreboardElm).html(computedValue);
                        $(".c.goalsTeam1", scoreboardElm).html(value.newVal);
                        break;
                    case "goalsTeam2Quarter5":
                        var computedValue = value.newVal - scoreboardData.goalsTeam2Quarter4 - scoreboardData.goalsTeam2Quarter3 - scoreboardData.goalsTeam2Quarter2 - scoreboardData.goalsTeam2Quarter1;
                        $(".c.goalsTeam2Quarter5", scoreboardElm).html(computedValue);
                        $(".c.goalsTeam2", scoreboardElm).html(value.newVal);
                        break;
                }
            }
        };
        
    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/progaming.js */
(function(modulr, $) {
  "use strict";

  modulr.define("live.updater.scoreboard.PROGAMING", function(require, exports, module) {
    module.exports = {
      VALUES: ["state", "team1TurretsDestroyed", "team2TurretsDestroyed", "mapName", "type", "team1ScoreMaps", "team2ScoreMaps"],
      update: function (item, value, scoreboardData, scoreboardElm, i18n, scoreboardUpdaterInstance) {
        switch (item) {
          case "state":
            if (i18n[value.newVal] && i18n[value.newVal] !== "") {
              $(".c.state", this.scoreboard).html(i18n[value.newVal]);
            } else {
              $(".c.state", this.scoreboard).html("");
            }
            break;
          case "mapName":
            $(".c.mapName", scoreboardElm).html(' - ' + value.newVal + ' - ');
            break;
          case "type":
            $(".c.type", scoreboardElm).html('(' + i18n["BO"] + value.newVal + ')');
            break;
          case "team1ScoreMaps":
            $(".c.team1ScoreMaps", scoreboardElm).html(value.newVal);
            break;
          case "team2ScoreMaps":
            $(".c.team2ScoreMaps", scoreboardElm).html(value.newVal);
            break;
          case "team1TurretsDestroyed":
            $(".c.team1TurretsDestroyed", scoreboardElm).html(value.newVal);
            break;
          case "team2TurretsDestroyed":
            $(".c.team2TurretsDestroyed", scoreboardElm).html(value.newVal);
            break;
        }
      }
    };
  });
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/rugby.js */
(function(modulr, $) {
    "use strict";
    
    var updater = {
        VALUES: ["state"],
        update: function(item, value, scoreboardData, scoreboardElm, i18n) {
            switch(item) {
                case "state":
                    var runningStates = ["HALF_1", "HALF_2", "EXTRA_1", "EXTRA_2", "OVERTIME"];
                    $(".c.time-info", scoreboardElm).toggleClass("hidden", runningStates.indexOf(value.newVal) < 0);
                    $(".c.state", scoreboardElm).html(i18n[value.newVal]);
                    break;
            }
        }
    };
    
    modulr.define("live.updater.scoreboard.RUGBY_LEAGUE", function(require, exports, module) {
        module.exports = updater;
    });
    
    modulr.define("live.updater.scoreboard.RUGBY_UNION", function(require, exports, module) {
        module.exports = updater;
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/snooker.js */
(function(modulr) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.SNOOKER", function(require, exports, module) {
        
        module.exports = {
            VALUES: ["frame"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch (item) {
                    case "frame":
                        var showZeroFrame = value.newVal === 0;
                        $(".c.frame-0").toggleClass("hidden", !showZeroFrame);
                        $(".c.frame-other").toggleClass("hidden", showZeroFrame);
                        $(".c.frame").html(value.newVal);
                        break;
                }
            }
        }
        
    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/table_tennis.js */
(function(modulr) {
    "use strict";
    
    modulr.define("live.updater.scoreboard.TABLE_TENNIS", function(require, exports, module) {
        
        module.exports = {
            VALUES: ["actualSet", "sets"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n) {
                switch (item) {
                    case "actualSet":
                        $(".c.actualSet").html(value.newVal);
                        // Set switch
                        if (value.newVal < value.oldVal) {
                            if (scoreboardData["team1Set" + value.oldVal] === 0 && scoreboardData["team2Set" + value.oldVal] === 0) {
                                $(".c.team1Set" + value.oldVal, scoreboardElm).html("-");
                                $(".c.team2Set" + value.oldVal, scoreboardElm).html("-");
                                $(".c.set-" + value.oldVal + "-col").toggleClass("hidden", value.oldVal > 3);
                            }
                        } else {
                            $(".c.team1Set" + value.newVal, scoreboardElm).html(scoreboardData["team1Set" + value.newVal]);
                            $(".c.team2Set" + value.newVal, scoreboardElm).html(scoreboardData["team2Set" + value.newVal]);
                            $(".c.set-" + value.newVal + "-col").toggleClass("hidden", false);
                            for (var i = 1; i < value.newVal; i++) {
                            	$(".c.team1Set" + i, scoreboardElm).html(scoreboardData["team1Set" + i]);
                                $(".c.team2Set" + i, scoreboardElm).html(scoreboardData["team2Set" + i]);
                            }
                            $(".c.team1SetsWon", scoreboardElm).html(scoreboardData.team1SetsWon);
                            $(".c.team2SetsWon", scoreboardElm).html(scoreboardData.team2SetsWon);
                        }
                        var showZeroSet = value.newVal === 0;
                        $(".c.set-0", scoreboardElm).toggleClass("hidden", !showZeroSet);
                        $(".c.set", scoreboardElm).toggleClass("hidden", showZeroSet);
                        // mark actual set
                        $(".c.set-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
                        $(".c.set-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
                        break;
                    case "sets":
                        $(".c.sets-7", scoreboardElm).toggleClass("hidden", value.newVal < 6);
                        break;
                }
            }
        }
    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/tennis.js */
(function(modulr, $) {
    "use strict";
    modulr.define("live.updater.scoreboard.TENNIS", function(require, exports, module) {
        var TennisScoreboardUpdater = {
            VALUES: ["actualSet", "team1Check", "team2Check", "team1DoubleFault", "team2DoubleFault", "fifteenMatchType", "checkType"],
            update: function(item, value, scoreboardData, scoreboardElm, i18n, scoreboardUpdaterInstance) {
                switch(item) {
                    case "actualSet":
                        // set actual set
                        $(".c.actualSet", scoreboardElm).html(value.newVal);
                        // show or hide columns when needed
                        if (value.newVal > value.oldVal) {
                            $(".c.team1Set" + value.newVal, scoreboardElm).html(scoreboardData["team1Set" + value.newVal]);
                            $(".c.team2Set" + value.newVal, scoreboardElm).html(scoreboardData["team2Set" + value.newVal]);
                        } else if (scoreboardData["team1Set" + value.oldVal] === 0 && scoreboardData["team2Set" + value.oldVal] === 0) {
                            $(".c.team1Set" + value.oldVal, scoreboardElm).html("-");
                            $(".c.team2Set" + value.oldVal, scoreboardElm).html("-");
                        }
                        // reset games counter on mini scoreboard
                        $(".c.set-0", scoreboardElm).toggleClass("hidden", value.newVal > 0);
                        $(".c.set-other", scoreboardElm).toggleClass("hidden", value.newVal === 0);
                        // mark actual set
                        $(".c.set-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
                        $(".c.set-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
                        break;
                    case "team1Check":
                    case "team2Check":
                    case "team1DoubleFault":
                    case "team2DoubleFault":
                        scoreboardUpdaterInstance.updateConnectedProperties(item, value.newVal);
                        var hideAppendix = (scoreboardData["team1Check"] === 0 && scoreboardData["team2Check"] === 0 &&
                                scoreboardData["team1DoubleFault"] === 0 && scoreboardData["team2DoubleFault"] === 0);
                    	$(document).fireEvent("scoreboard:appendix", false, {
                    		"action": hideAppendix ? "hide" : "show"
                    	});
                        break;
                    case "fifteenMatchType":
                    	$(".c.set-4-col, .c.set-5-col", scoreboardElm).toggleClass("hidden", ["ATP_WTA_3_SETS_TIEBREAK", "ATP_3_SETS_20_GAMES"].indexOf(value.newVal) < 0);
                    	break;
                    case "team1Score":
                    case "team2Score":
                    	var value = value.newVal || "0";
                    	$(".c." + item, scoreboardElm).html(value);
                    	break;
                    case "checkType":
                    	$(".c.checkType", scoreboardElm).toggleClass("umpire", value.newVal === 2).toggleClass("hawk-eye", value.newVal !== 2);
                    	break;
                }
            }
        };
        module.exports = TennisScoreboardUpdater;
    });
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/volleyball.js */
(function (modulr) {
  "use strict";

  var updater = {
    VALUES: ["team1Timeout", "team2Timeout", "team1ServingErrors", "team2ServingErrors", "actualSet"],
    update: function (item, value, scoreboardData, scoreboardElm, i18n, scoreboardUpdaterInstance) {
      switch (item) {
        case "team1Timeout":
        case "team2Timeout":
        case "team1ServingErrors":
        case "team2ServingErrors":
          scoreboardUpdaterInstance.updateConnectedProperties(item, value.newVal);
          var hideAppendix = scoreboardData["team1Timeout"] === 0 && scoreboardData["team2Timeout"] === 0 &&
            scoreboardData["team1ServingErrors"] === 0 && scoreboardData["team2ServingErrors"] === 0;
          $(document).fireEvent("scoreboard:appendix", false, {
            "action": hideAppendix ? "hide" : "show"
          });
          break;
        case "actualSet":
          $(".c.actualSet").html(value.newVal);
          // Set switch
          if (value.newVal < value.oldVal) {
            if (scoreboardData["team1Set" + value.oldVal] === 0 && scoreboardData["team2Set" + value.oldVal] === 0) {
              $(".c.team1Set" + value.oldVal, scoreboardElm).html("-");
              $(".c.team2Set" + value.oldVal, scoreboardElm).html("-");
            }
          } else {
            $(".c.team1Set" + value.newVal, scoreboardElm).html(scoreboardData["team1Set" + value.newVal]);
            $(".c.team2Set" + value.newVal, scoreboardElm).html(scoreboardData["team2Set" + value.newVal]);
          }
          // Golden set
          if (value.newVal > 5) {
            $(".c.set-golden-col").toggleClass("hidden", false);
            $(".c.state-play > i").toggleClass("hidden", true);
            $(".c.state-play .c.set-golden").toggleClass("hidden", false);
          } else {
            $(".c.state-play > i").toggleClass("hidden", true);
            $(".c.state-play .c.set").toggleClass("hidden", false);
            if (scoreboardData["team1Set6"] === 0 && scoreboardData["team2Set6"] === 0) {
              $(".c.set-golden-col").toggleClass("hidden", true);
            }
          }
          // mark actual set
          $(".c.set-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
          $(".c.set-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
          break;
      }
    }
  };

  modulr.define("live.updater.scoreboard.VOLLEYBALL", function (require, exports, module) {
    module.exports = updater;
  });

  modulr.define("live.updater.scoreboard.BADMINTON", function (require, exports, module) {
    module.exports = updater;
  });
})(modulr);
;
/* assets/src/scripts/fortuna/live/scoreboard-updater/water-polo.js */
(function(modulr) {
	"use strict";
	
	modulr.define("live.updater.scoreboard.WATER_POLO", function(require, exports, module) {
		
		var updater = {
			VALUES: ["actualQuarter", "goalsTeam1Quarter5", "goalsTeam2Quarter5"],
			update: function(item, value, scoreboardData, scoreboardElm, i18n, scoreboardUpdaterInstance) {
				switch (item) {
					case "actualQuarter":
						$(".c.actualQuarter", scoreboardElm).html(value.newVal);
		                if (value.newVal < value.oldVal) {
		                	if (scoreboardData["goalsTeam1Quarter" + value.oldVal] === 0 && scoreboardData["goalsTeam2Quarter" + value.oldVal] === 0) {
		                        $(".c.goalsTeam1Quarter" + value.oldVal, scoreboardElm).html("-");
		                        $(".c.goalsTeam2Quarter" + value.oldVal, scoreboardElm).html("-");
		                    }
		                } else {
		                    $(".c.goalsTeam1Quarter" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam1Quarter" + value.newVal]);
		                    $(".c.goalsTeam2Quarter" + value.newVal, scoreboardElm).html(scoreboardData["goalsTeam2Quarter" + value.newVal]);
		                }
		                $(".c.quarter-overtime", scoreboardElm).toggleClass("hidden", value.newVal < 5);
		                $(".c.quarter-0", scoreboardElm).toggleClass("hidden", value.newVal > 0);
		                $(".c.quarter-other", scoreboardElm).toggleClass("hidden", value.newVal === 0 || value.newVal > 4);
		                // mark actual quarter
		                $(".c.quarter-" + value.oldVal + "-col", scoreboardElm).toggleClass("active", false);
		                $(".c.quarter-" + value.newVal + "-col", scoreboardElm).toggleClass("active", true);
		                $(".c.quarter-5-col", scoreboardElm).toggleClass("hidden", value.newVal < 5);
		                break;
					case "goalsTeam1Quarter5":
					case "goalsTeam2Quarter5":
						$(".c." + item, scoreboardElm).html(value.newVal);
						var base = item.substring(0, item.length - 1);
						var overtime = value.newVal - scoreboardData[base + "4"] - scoreboardData[base + "3"] - scoreboardData[base + "2"] - scoreboardData[base + "1"];
						$(".c." + item.replace("Quarter5", "Overtime"), scoreboardElm).html(overtime);
						break;
				}
			}
		};
		
		module.exports = updater;
		
	});
	
})(modulr);
;
/* assets/src/scripts/fortuna/live/sport-scroll-memory.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.sport-scroll-memory", function(require, exports, module) {
        
        var STORAGE_KEY = "live_sports_scroll_positions";
        var SELECTOR_SCROLL_ELEMENT = "#live-events";
        var SELECTOR_ACTIVE_TAB = "#live-sports-menu ul .selected";
        
        var _instance;
        
        function _load() {
            var values = $.getSessionStorage(STORAGE_KEY);
            if (!values) return {};
            try {
                return JSON.parse(values);                
            } catch (e) {}
            return {};
        };
        
        function _loadActiveSport(sportScrollMemory) {
            return sportScrollMemory._scrollElement.attr("data-filter");
        };
        
        function _initializeEvents(sportScrollMemory) {
            $(document).on("live:overview:sport:switch", function(evt) {
                sportScrollMemory._activeSport = evt.selected;
                _scrollToSavedPosition(sportScrollMemory, evt.selected);
            });
            sportScrollMemory._scrollElement.on("scroll", $.debounce(function(evt) {
                _set(sportScrollMemory, this);
            }, 300))
        };
        
        function _saveToMemory(sportScrollMemory) {
            $.sessionStorage(STORAGE_KEY, JSON.stringify(sportScrollMemory._positions));
        };
        
        function _scrollToSavedPosition(sportScrollMemory, sport) {
            var position = sportScrollMemory._positions[sport] || 0;
            sportScrollMemory._scrollElement.get(0).scrollTop = position;
        };
        
        function _set(sportScrollMemory, scrollElement) {
            sportScrollMemory._positions[sportScrollMemory._activeSport] = scrollElement.scrollTop;
            _saveToMemory(sportScrollMemory);
        };
        
        function SportScrollMemory() {
            this._scrollElement = $(SELECTOR_SCROLL_ELEMENT);
            this._positions = _load();
            this._activeSport = _loadActiveSport(this);
            _scrollToSavedPosition(this, this._activeSport);
            _initializeEvents(this);
        };
        
        module.exports = function() {
            if (!_instance) {
                _instance = new SportScrollMemory();
            }
            return _instance;
        };
        
    });
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/stream/updater.js */
(function(modulr) {
    "use strict";

    modulr.define("live.stream.updater.overview", function(require, exports, module) {

        var SPORT_ID = "STREAMS";
        var TAB_ORDER = 2147483647;

        var TPLS = require("live.updater.templates");
        var SELECTORS = require("live.updater.selectors");

        function SORT_BY_DATA_ATTR_ORDER(e1, e2) {
            var e1Order = $.Utils.asInt(e1.getAttribute("data-order"), $.CONSTANTS.MAX_SAFE_INTEGER);
            var e2Order = $.Utils.asInt(e2.getAttribute("data-order"), $.CONSTANTS.MAX_SAFE_INTEGER);
            return e1Order - e2Order;
        }

        function SORT_BY_DATA_ATTR_START_AND_CLASS_NAME(e1, e2) {
            var e1Header = $("a", e1)[0];
            var e2Header = $("a", e2)[0];
            var e1Start = e1.getAttribute("data-start");
            var e2Start = e2.getAttribute("data-start");
            if (e1Start < e2Start) {
                return -1;
            } else if (e1Start > e2Start) {
                return 1;
            }
            var e1Name = $(".name", e1).text().trim();
            var e2Name = $(".name", e2).text().trim();
            return e1Name.localeCompare(e2Name);
        }

        function StreamTabUpdater(params, updaterHelpers) {
            this._params = params;
            this._helpers = updaterHelpers;
            this._$tab = $();
            this._$eventsHolder = $();
        }

        StreamTabUpdater.prototype.getOrCreateTab = function() {
            if (!this._$tab.length) {
                var that = this;
                this._$tab = $("#tab-" + SPORT_ID);
                if (!this._$tab.length) {
                    this._$tab = TPLS.SPORT_TAB({
                        tabId: "tab-" + SPORT_ID,
                        link: that.params.baseUrl + "?sport=" + SPORT_ID,
                        id: SPORT_ID,
                        icon: "streams",
                        title: params.streamTabTitle,
                        order: TAB_ORDER
                    });
                    this._helpers.appendSportTabToCorrectPosition({
                        "order": TAB_ORDER
                    }, this._$tab);
                }
            }
            return this._$tab;
        };

        StreamTabUpdater.prototype.getOrCreateEventsHolder = function() {
            if (!this._$eventsHolder.length) {
                this._$eventsHolder = $("#" + SPORT_ID);
                if (!this._$eventsHolder.length) {
                    this._$eventsHolder = TPLS.EVENTS_HOLDER({
                        id: SPORT_ID
                    });
                    this._helpers.appendEventsHolder(this._$eventsHolder);
                }
            }
            return this._$eventsHolder;
        };

        StreamTabUpdater.prototype.addEvent = function(event, liveEventHtml) {
            var that = this;
            var sportId = this._helpers.getSportId(event.sportId);
            if (this.getOrCreateEventsHolder().hasClass("upcoming")) {
                this.getOrCreateEventsHolder()
                    .toggleClass("upcoming", false)
                    .html("");
                this.getOrCreateTab().find(".count").toggleClass("hidden", false);
            }
            var $sportGroup = this.getOrCreateEventsHolder().find(SELECTORS.buildStreamSportIdSelector(sportId));
            if (!$sportGroup.length) {
                var sportElements = this._helpers.getLiveSportElementsIfExists(sportId);
                var $tab = $(sportElements.tab);
                var title = $tab.find(".title").text();
                var order = $tab.attr("data-order");
                $sportGroup = $(TPLS.STREAM_COLLAPSIBLE_BOX({
                    "id": that._helpers.getSportId(event.sportId),
                    "title": title,
                    "order": order,
                    "isClosed": $.cookie("stream-" + event.sportId) === "closed"
                }));
                this.getOrCreateEventsHolder().append($sportGroup);
                this._sortSports();
            }
            var $competitionGroup = $sportGroup.find(SELECTORS.buildLiveCompetitionSelector(sportId, event.competitionId));
            if (!$competitionGroup.length) {
                $competitionGroup = $(TPLS.COMPETITION_GROUP({
                    id: SELECTORS.buildLiveCompetitionId(that._helpers.getSportId(event.sportId), event.competitionId),
                    name: event.names[that._params.locale],
                    order: event.order
                }));
                $sportGroup.append($competitionGroup);
                this._sortCompetitions($sportGroup);
            }
            this._helpers.appendLiveEventToCorrectPositionInCompetition(event, liveEventHtml, {id: SPORT_ID}, $competitionGroup[0]);
        };

      StreamTabUpdater.prototype.sortMatchOrder = function(event) {
        var sportId = this._helpers.getSportId(event.sportId);

        var $sportGroup = this.getOrCreateEventsHolder().find(SELECTORS.buildStreamSportIdSelector(sportId));
        if ($sportGroup.length) {
          var $competitionGroup = $sportGroup.find(SELECTORS.buildLiveCompetitionSelector(sportId, event.competitionId));
          if ($competitionGroup.length) {
            this._helpers.sortEventListByOrder($competitionGroup[0]);
          }
        }
      };

        StreamTabUpdater.prototype.removeSport = function(sportId) {
            this.getOrCreateEventsHolder()
                .find(SELECTORS.buildStreamSportIdSelector(this._helpers.getSportId(sportId)))
                .remove();
            this.showUpcomingEventsIfNeeded();
        };

        StreamTabUpdater.prototype.removeEmptySports = function() {
            this.getOrCreateEventsHolder()
                .find(SELECTORS.SELECTOR_STREAM_SPORT)
                .forEach(function(itm) {
                    var $itm = $(itm);
                    if (!$itm.find(".content")[0].children.length) {
                        $itm.remove();
                    }
                });
            this.showUpcomingEventsIfNeeded();
        };

        StreamTabUpdater.prototype._sortSports = function() {
            var sports = [];
            this.getOrCreateEventsHolder()
                .find(SELECTORS.SELECTOR_STREAM_SPORT)
                .forEach(function(itm) {
                    sports.push(itm);
                })
                .remove();
            sports.sort(SORT_BY_DATA_ATTR_ORDER);
            this.getOrCreateEventsHolder().append(sports);
        };

        StreamTabUpdater.prototype._sortCompetitions = function($sportGroup) {
            $sportGroup.forEach(function(itm) {
                var competitions = [];
                var $itm = $(itm);
                $itm.find(".competition")
                    .forEach(function(c) {
                        competitions.push(c);
                    })
                    .remove();
                competitions.sort(SORT_BY_DATA_ATTR_ORDER);
                $itm.append(competitions);
            });
        };

        StreamTabUpdater.prototype.showUpcomingEventsIfNeeded = function() {
            if (!this.getOrCreateEventsHolder()
                    .find(SELECTORS.SELECTOR_STREAM_SPORT).length) {
                this.showUpcomingEvents();
            }
        };

        StreamTabUpdater.prototype.showUpcomingEvents = function() {
            var that = this;
            var link = $.Link(this._params.baseUrl).appendToPath("/upcoming-streams");
            $.ftnAjax(link.ajaxify().getUrl(), {
                success: function(data) {
                    that.getOrCreateEventsHolder()
                        .toggleClass("upcoming", true)
                        .html(data);
                    $(document).fireEvent("content:box:arrange");
                    that.getOrCreateTab().find(".count").toggleClass("hidden", true);
                }
            });
        };

        module.exports = function(params, updaterHelpers) {
            return new StreamTabUpdater(params, updaterHelpers);
        };

    });

})(modulr);
;
/* assets/src/scripts/fortuna/live/updater-constants.js */
(function(modulr) {
    "use strict";
    
    modulr.define("live.updater.constants", function(require, exports, module) {
        
    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/live/updater-helpers.js */
(function(modulr, $) {
    "use strict";

    modulr.define("live.updater.helpers", function(require, exports, module) {

        var TPLS = require("live.updater.templates");
        var SELECTORS = require("live.updater.selectors");

        var Logger = require("logger")(module.id);

        var _params = null;

        function getEventElementIfExists(eventId) {
            return $(SELECTORS.buildLiveEventSelector(eventId));
        };

        function getLiveSportElementsIfExists(sportId) {
            return {
                tab: document.getElementById("tab-" + sportId),
                eventsHolder: document.getElementById(sportId)
            };
        };

        function createEventName(event) {
            var name = (event && event.names && event.names[_params.locale]) || null;
            var participantH1 = (event && event.participantH1 && event.participantH1[_params.locale]) || null;
            var participantA1 = (event && event.participantA1 && event.participantA1[_params.locale]) || null;
            return TPLS.EVENT_NAME({
                name: name,
                participantH1: participantH1,
                participantA1: participantA1
            })
        };

        function createEventLink(event) {
            return _params.baseUrl + "/" + event.id;
        };

        function parseEventDate(event) {
            var date = event.start;
            if (!date) return "";
            var year = date.substring(0, 4);
            var month = date.substring(5, 7);
            var day = date.substring(8, 10);
            var hours = date.substring(11, 13);
            var minutes = date.substring(14, 16);
            return (parseInt(day) + ". " + parseInt(month) + ". " + parseInt(year) + " " + hours + ":" + minutes);
        };

        function adjustLiveEventsCounter(sportId, direction) {
            if (sportId && (direction === "+" || direction === "-")) {
                var tab = document.getElementById("tab-" + sportId);
                if (tab) {
                    var counts = tab.getElementsByClassName("count");
                    for (var i = 0, l = counts.length; i < l; i++) {
                        var act = parseFloat(counts[i].innerHTML);
                        if (isNaN(act)) act = 0;
                        switch (direction) {
                            case "+":
                                act++;
                                break;
                            case "-":
                                act--;
                                break;
                        }
                        counts[i].innerHTML = act;
                    }
                }
            }
        };

        function getParentMarketGroup(id) {
            return $("#" + id).parent(".market-group");
        };

        function isMarketGroupEmpty(marketGroup) {
            return $(".live-market", marketGroup).length === 0;
        };

        function areAllMarketGroupsHidden() {
            return $(".market-group:not(.hidden)").length === 0;
        };

        function getSportId(sportId) {
            if (sportId.substring(0, _params.prefix.length) !== _params.prefix) {
                sportId = _params.prefix + sportId;
            }
            return sportId;
        };

        function appendSportTabToCorrectPosition(message, tabHtml) {
            var menu = document.getElementById(SELECTORS.ID_LIVE_SPORTS_MENU);
            if (menu) {
                var list = menu.getElementsByTagName("ul");
                if (list.length) {
                    list = list[0];
                    var sportOrder = message.order;
                    var lastItemLowerOrEqualOrder = null;
                    for (var i = 0, l = list.children.length; i < l; i++) {
                        var order = parseInt(list.children[i].getAttribute("data-order"));
                        if (order <= sportOrder) {
                            lastItemLowerOrEqualOrder = list.children[i];
                        } else {
                            break;
                        }
                    }
                    if (lastItemLowerOrEqualOrder) {
                        $(lastItemLowerOrEqualOrder).after(tabHtml);
                    } else {
                        $(list).prepend(tabHtml);
                    }
                }
            } else {
                Logger.log("Something is totally wrong. There is no sport menu!");
            }
        };

        function appendEventsHolder(eventsHolderHtml) {
            $(SELECTORS.SELECTOR_EVENTS_HOLDERS_HOLDER).append(eventsHolderHtml);
        };

        function sortEventListByOrder(competition){
          var list = competition.getElementsByClassName(SELECTORS.CLASS_EVENTS_LIST);
          var $list = $(list[0]);

          var $eventElms = $("li", $list);
          var eventElms = [];
          $eventElms.forEach(function (itm) {
            eventElms.push(itm);
          });
          eventElms.sort(function (e1, e2) {
            var e1Id = $.Utils.asInt(e1.dataset.order, $.CONSTANTS.MAX_SAFE_INTEGER);
            var e2Id = $.Utils.asInt(e2.dataset.order, $.CONSTANTS.MAX_SAFE_INTEGER);
            return e1Id - e2Id;
          });
          $list.html("");
          $list.append(eventElms);
        };

      function appendLiveEventToCorrectPositionInCompetition(event, liveEventHtml, sport, competition) {
        var list = competition.getElementsByClassName(SELECTORS.CLASS_EVENTS_LIST);
        var $list = $(list[0]);

        $list.append(liveEventHtml);
        sortEventListByOrder(competition);
        adjustLiveEventsCounter(sport.id, "+");
      };

        module.exports = function(params) {
            _params = params;
            return {
                getEventElementIfExists: getEventElementIfExists,
                getLiveSportElementsIfExists: getLiveSportElementsIfExists,
                createEventName: createEventName,
                createEventLink: createEventLink,
                parseEventDate: parseEventDate,
                adjustLiveEventsCounter: adjustLiveEventsCounter,
                getParentMarketGroup: getParentMarketGroup,
                isMarketGroupEmpty: isMarketGroupEmpty,
                areAllMarketGroupsHidden: areAllMarketGroupsHidden,
                getSportId: getSportId,
                appendSportTabToCorrectPosition: appendSportTabToCorrectPosition,
                appendEventsHolder: appendEventsHolder,
                appendLiveEventToCorrectPositionInCompetition: appendLiveEventToCorrectPositionInCompetition,
                sortEventListByOrder: sortEventListByOrder
            };
        };


    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/live/updater-markets-generator.js */
(function(modulr, $) {
    "use strict";
    
    modulr.define("live.updater.markets.generator", function(require, exports, module) {
        
        var TPLS = require("live.updater.templates");
        
        function MarketsGenerator(params) {
            this.params = params;
        };
        
        MarketsGenerator.prototype.renderMarket = function(market, withHeader, marketGroup, rowsLimit) {
            if (!market) return;
            market = this.splitOddsByRows(market, rowsLimit);
            var that = this;
            this.decorateMarket(market);
            // render market to string and return it
            try {
                return TPLS.EVENT_MARKET({
                    oddsCount: market.oddsCount,
                    market: market,
                    baseTicketAddUrl: that.params.baseTicketAddUrl,
                    baseTicketRemoveUrl: that.params.baseTicketRemoveUrl,
                    baseTicketChangeUrl: that.params.baseTicketChangeUrl,
                    locale: that.params.locale,
                    withHeader: withHeader,
                    marketGroupId: marketGroup,
                    event: market.eventId,
                });
            } catch (e) {
                for (var i = 0, l = market.odds.length; i < l; i++) {
                    var g = market.odds[i];
                    for (var j = 0, k = g.length; j < k; j++) {
                        console.log(g[j].id + " - " + market.eventKind);
                    }
                }
                throw e;
            }
            
        };
        
        MarketsGenerator.prototype.checkIfMarketAlreadyExists = function(marketId) {
            return document.getElementById("market-" + marketId);
        };
        
        MarketsGenerator.prototype.decorateMarket = function(market) {
        	// Check wheter market is already present on page
            var marketElm = this.checkIfMarketAlreadyExists(market.id);
            // If true decorate market object to show specialClasses and up/down icons
        	for (var i = 0, l = market.odds.length; i < l; i++) {
                var group = market.odds[i];
                for (var j = 0, k = group.length; j < k; j++) {
                    var odd = group[j];
                    if (!odd || odd.readOnly) continue;
                    var valArr = (""+odd.value).split(".");
                    if (valArr.length < 2) {
                    	odd.decoratedValue = valArr[0] + ".00";
                    } else if (valArr[1].length < 2) {
                    	odd.decoratedValue = valArr[0] + "." + valArr[1] + "0";
                    }
                    if (marketElm) {
	                    var oddElm = $("#odd-" + odd.info + "-" + odd.id.substring(3));
	                    if (oddElm.length) {
	                        // If odd value change
	                        var oddElmValue = parseFloat(oddElm.attr("data-value"));
	                        if (odd.value > oddElmValue) {
	                            odd.decoratedChangeDirection = "up";
	                        } else if (odd.value < oddElmValue) {
	                            odd.decoratedChangeDirection = "down";
	                        }
	                        // If on ticket
	                        if (oddElm.hasClass("on-ticket")) {
	                            odd.decoratedOnTicket = true;
	                        }
                            // If not compatible
	                        if (oddElm.hasClass("not-compatible")) {
	                            odd.decoratedNotCompatible = true;
	                        }
                            // If combinable
	                        if (oddElm.hasClass("combinable")) {
	                            odd.decoratedCombinable = true;
	                        }
	                    }
	                }
                }
            }
            return market;
        };
        
        MarketsGenerator.prototype.splitOddsByRows = function(market, rowsLimit) {
            var splitted = [];
            var lastDisplayRow;
            var index;
            if (market && market.odds.length && market.odds[0].readOnly && rowsLimit && rowsLimit % 2 === 1) {
                rowsLimit++;
            }
            for (var i = 0, l = market.odds.length; i < l; i++) {
                var odd = market.odds[i];
                if (typeof lastDisplayRow !== "undefined" && rowsLimit && odd.displayRow >= rowsLimit) break;
                if (odd.displayRow !== lastDisplayRow) {
                    splitted.push([]);
                    lastDisplayRow = odd.displayRow;
                }
                splitted[splitted.length - 1].push(odd);
            }
            market.odds = splitted;
            return market;
        };
        
        module.exports = function(params) {
            return new MarketsGenerator(params);
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/updater-markets.js */
(function() {
    "use strict";
    
    modulr.define("live.updater.markets", function(require, exports, module) {
        
        var TPLS = require("live.updater.templates");
        
        var Logger = require("logger")(module.id);

        const CLASS_NO_SCROLL = "no-scroll";
        
        function MarketsUpdater(params) {
            this.params = params;
            this.MarketsGenerator = require("live.updater.markets.generator")(params);
            this.helpers = require("live.updater.helpers")(params);
            this.marketsHolder = $(".markets");
        };
        
        MarketsUpdater.prototype.handleMainMarketMessage = function(message) {
            switch (message.operation) {
                case "updated":
                    this.updateMainMarket(message);
                    break;
                case "deleted":
                    this.deleteMainMarket(message.marketHtml.mainMarket.id);
                    break;
                default:
                    Logger.log("Uknown operation '" + message.operation + "' for main market message");
            }
        };

        MarketsUpdater.prototype.updateMainMarket = function(message) {
            var marketHtml = this.MarketsGenerator.renderMarket(message.marketHtml.mainMarket);
            var elm = document.getElementById("market-" + message.marketHtml.mainMarket.id);
            if (elm) {
                // Market exists -> update it
                $(elm).after(marketHtml);
                elm.parentNode.removeChild(elm);
            } else {
                // Market doesn't exist -> append it to live event
                var liveEvent = document.getElementById("le-" + message.marketHtml.id);
                if (liveEvent) {
                    $(".market", liveEvent).remove();
                    $(liveEvent).append(marketHtml);
                } else {
                    // Live event does not exist -> not enough data, skip
                }
            }
            
        };
        
        MarketsUpdater.prototype.deleteMainMarket = function(marketId) {
            var elm = document.getElementById("market-" + marketId);
            if (elm) {
                elm.parentNode.removeChild(elm);
            }
        };
        
        MarketsUpdater.prototype.handleMarketMessage = function(message) {
            switch (message.operation) {
                case "create":
                case "update":
                case "insert":
                    this.createOrUpdateMarket(message);
                    break;
                case "delete":
                    this.deleteMarket(message.id);
                    this.filteredMarketGroupHasBeenRemoved();
                    break;
                default:
                    Logger.log("Unknown operation '" + message.operation + "' for market message");
            }
        };
        
        MarketsUpdater.prototype.createOrUpdateMarket = function(message) {
            var that = this;
            var market = message.marketHtml;
            var participants = null;


            if (message.marketSubject === 'PLAYER' || market.marketSubject === 'PLAYER') {
              var odds = [];

              var sortedOdds = market.odds.filter(function (odd) {
                return odd.value > 1;
              }).sort(function (a, b) {
                return a.value - b.value;
              });

              var teams = {
                home: sortedOdds.filter(function (odd) {
                  return odd.teamAssociation === 'HOME_1';
                }),
                away: sortedOdds.filter(function (odd) {
                  return odd.teamAssociation === 'AWAY_1';
                }),
              };

              const rows = Math.max(teams.home.length, teams.away.length);

              for (var i = 0; i < rows; i++) {
                ['home', 'away'].forEach(function (team, colIdx) {
                  var odd = teams[team][i];
                  if (odd) {
                    odd.displayRow = i;
                    odd.displayOrder = colIdx + 1;
                    odd.displayCount = 2;
                    odds.push(odd);
                  }
                });
              }

              if (odds.length) {
                participants = {
                  home: this.params.homeParticipant,
                  away: this.params.awayParticipant,
                };
              }

              market.odds = odds;
            }


            market.oddsCount = market.odds.length;
            const activeFilterElement = document.getElementsByClassName('event-list-market-filter--active')[0];
            const activeGroupId = activeFilterElement.dataset.marketGroupId;
            const isMarketHidden = !(activeGroupId === 'ALL' || activeGroupId === 'COMBINABLE') && !market.marketGroupIds.includes(activeGroupId);

            // Generate market html
            var marketHtml = TPLS.DETAIL_EVENT({
                market: market,
                odds: that.MarketsGenerator.renderMarket(market, true, "market-group-id-" + message.marketGroupId),
                withHeader: true,
                locale: that.params.locale,
                marketGroupIds: '[' + message.marketHtml.marketGroupIds.join(',') + ']',
                participants: participants,
                isMarketHidden: isMarketHidden,
            });
            // Load markets which contain odds
            this.deleteMarketsWithOdds(market.id);
            // Handle incoming market groups
            //this.prepareMarketGroups(message.marketGroups);
            this.appendToMarketGroup(market.sortOrder, marketHtml, message.id);
            this.toggleMarketFilters();
            //this.prepareMarketGroups();
            this.filteredMarketGroupHasBeenRemoved();
        };

        MarketsUpdater.prototype.prepareMarketGroups = function(groups) {
            if (!groups) return;
            var that = this;
            var onPageMarketGroups = $("#page .markets .market-group");
            var onPageMarketGroupsIds = onPageMarketGroups.map(function(item) {return item.id;});
            var newMarketGroups = {};
            for (var i = 0, l = groups.length; i < l; i++) {
                var id = "market-group-id-" + groups[i].id;
                // If there is marketGroup with specified id then skip to next step
                if (onPageMarketGroupsIds.indexOf(id) > -1) continue;
                this.addNewMarketGroupFilter(groups[i], that.params.locale)
                newMarketGroups[i] = TPLS.MARKET_GROUP({
                    id: id,
                    title: groups[i].name[that.params.locale],
                    isClosed: ($.cookie(id) === "closed")
                });
            }
            // Append to correct positions
            for (var i in newMarketGroups) {
                if (!onPageMarketGroups.length) {
                    // kdyz tam neni nic, tak to dame hned na zacatek
                	$("#page .markets .market-list").prepend(newMarketGroups[i]);
                	onPageMarketGroups.push(newMarketGroups[i]);
                } else {
                	var currI = onPageMarketGroups[i];
                	if (currI) {
                		$(currI).before(newMarketGroups[i]);
                		onPageMarketGroups.splice(i, 0, newMarketGroups[i]);
                	} else {
                	    // kdyz jsme nenasli group, pred kterou by to mohlo byt, tak to dame na konec .market-list elementu
                		$("#page .markets .market-list").append(newMarketGroups[i]);
                		onPageMarketGroups.push(newMarketGroups[i]);
                	}
                }
                // Search in stack for markets which belongs to this market group
                $("." + newMarketGroups[i].id, this.getStack()).appendTo(newMarketGroups[i]);
            }
        };
        
        MarketsUpdater.prototype.deleteMarket = function(marketId) {
            var marketElm = document.getElementById("lm-" + marketId);
            if (marketElm) {
                this.deleteMarketElement(marketElm);
            } else {
            	var elms = document.getElementsByClassName("market-" + marketId);
            	var top;
            	while (elms.length) {
            		var el = elms[0];
            		var parent = el.parentElement;
            		parent.removeChild(el);
            		if (!parent.children.length) {
            			top = parent.parentElement;
            			top.removeChild(parent);
            		}
            	}
            	if (top) {
            		var market = $(top).parent(".live-market");
            		if (!$(".odd-button", market).length) {
            			this.deleteMarketElement(market)
            		}
            	}
            }
        };

        MarketsUpdater.prototype.deleteMarketsWithOdds = function(marketId) {
            this.deleteMarketElement(document.getElementById("lm-" + marketId));
        };

        MarketsUpdater.prototype.filteredMarketGroupHasBeenRemoved = function() {
          const activeFilterElements = document.getElementsByClassName('event-list-market-filter--active');
          if (!activeFilterElements.length) {
            return;
          }
          const activeFilterElement = document.getElementsByClassName('event-list-market-filter--active')[0];
          const activeGroupId = activeFilterElement.dataset.marketGroupId;
          if (activeGroupId === 'ALL' || activeGroupId === 'COMBINABLE') {
            return;
          }
          const markets = document.querySelectorAll('.js-event:not(.market-group--hidden)');
          const isActiveFilterEmpty = !Array.from(markets).some(function(market) {
            return market.dataset.marketGroupIds.includes(activeGroupId);
          });
          if (isActiveFilterEmpty) {
            this.switchToAllGroupsView();
          }
        };

        MarketsUpdater.prototype.switchToAllGroupsView = function() {
          document.querySelector('.event-list-market-filter--active').classList.remove('event-list-market-filter--active');
          const allFilterElement = document.querySelector('#market-filter-button--ALL');
          allFilterElement.classList.add('event-list-market-filter--active');
          const marketGroups = document.querySelectorAll('.market-group .js-event');

          marketGroups.forEach(function(group) {
            group.classList.remove('market-group--hidden');
          });
        };

        MarketsUpdater.prototype.getMarketGroupFilterElement = function(marketGroupId) {
            const filters = document.querySelectorAll('#event-list-market-filter li');
            var filterElement;
            if (filters.length) {
                filters.forEach(function(filter) {
                    if(filter.dataset.marketGroupId === marketGroupId) {
                        filterElement = filter;
                    }
                })  
            }
            return filterElement;
        }
        MarketsUpdater.prototype.toggleMarketFilters = function() {
            window.mobile.offer.marketFilter.markCombinable();
            const filterWrapper = document.getElementById('event-list-market-filter');
            const filters = filterWrapper.querySelectorAll('li:not(.event-list-market-filter--hidden):not(.hidden)');
            if(filters.length > 2) {
                filterWrapper.classList.remove('hidden');
            } else {
                filterWrapper.classList.add('hidden');
            }
        }

        MarketsUpdater.prototype.addNewMarketGroupFilter = function(marketGroup, locale) {
          const filterList = document.querySelector('#event-list-market-filter ul');
          const newFilter = document.createElement('li');
          newFilter.dataset.marketGroupId = marketGroup.id;
          newFilter.textContent = marketGroup.name[locale];
          filterList.appendChild(newFilter);
        }
        
        MarketsUpdater.prototype.deleteMarketElement = function(elem) {
            if (!elem) {
                return;
            }
            var $elem = $(elem);
            var marketGroup = $elem.parent(".market-group");
            $elem.parent().remove();
            if (this.helpers.isMarketGroupEmpty(marketGroup)) {
                marketGroup.toggleClass("hidden", true);
                const marketGroupId = marketGroup[0].id.split('-')[3]
                if (this.helpers.areAllMarketGroupsHidden()) {
                    this.marketsHolder.toggleClass("paused", true);
                    $("html").toggleClass(CLASS_NO_SCROLL, true);
                    // the code below handles layoutClass when tab is changed
                    $('[href="#event-bets"]')[0].dataset.layoutClass = CLASS_NO_SCROLL;
                }
            }
        };
        
        MarketsUpdater.prototype.appendToMarketGroup = function(order, marketHtml, eventId) {
            var marketGroup = document.querySelector(".market-group");
            if (!marketGroup) {
                this.appendToStack(marketHtml);
                return;
            }
            var marketsInGroup = $(".live-market", marketGroup);
            // Find market with order lower
            var lastItemLowerOrEqualOrder = null;
            for (var i = 0, l = marketsInGroup.length; i < l; i++) {
                var iOrder = parseInt(marketsInGroup[i].getAttribute("data-order"));
                if (iOrder <= order) {
                    lastItemLowerOrEqualOrder = marketsInGroup[i];
                } else {
                    break;
                }
            }
            var market = $(marketHtml);
            if (lastItemLowerOrEqualOrder) {
                $(lastItemLowerOrEqualOrder).parent().after(market)
            } else {
                $(marketGroup).prepend(market);
            }
            $(marketGroup).toggleClass("hidden", false);
            this.marketsHolder.toggleClass("paused", false);
            $("html").toggleClass(CLASS_NO_SCROLL, false);
            $('[href="#event-bets"]')[0].dataset.layoutClass = "";
            window.setTimeout(function() {
                market.find(".live-market").toggleClass("updated", false);
            }, 5000);

            window.mobile.ticket().markOddsInOffer();
        };
        
        /**
         * Stack is special hidden element on page which stores markets which can not be placed into market group
         *  because it does not exist yet. When market group is created, it should look into stack if there is some
         *  market which belongs to it.
         */
        MarketsUpdater.prototype.appendToStack = function(marketHtml) {
            this.getStack().append(marketHtml);
        };
        
        MarketsUpdater.prototype.getStack = function() {
            if (!this._stack) {
                this._stack = $("#markets-stack");
                if (!this._stack.length) {
                    this._stack = $('<div id="markets-stack" class="hidden"></div>');
                    $("#page .markets").append(this._stack);
                }
            }
            return this._stack;
        }
        
        module.exports = function(params) {
            return new MarketsUpdater(params);
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/live/updater-overview.js */
(function (modulr, $) {
  "use strict";

  modulr.define("live.updater.overview", function (require, exports, module) {

    var Logger = require("logger")(module.id);
    var TPLS = require("live.updater.templates");
    var SELECTORS = require("live.updater.selectors");

    var ID_PREFIX_LIVE_EVENT = "le-";
    var ID_TPL_LIVE_COMPETITION = "lc-{{SPORTID}}-{{COMPETITIONID}}";
    var CLASS_EVENTS_LIST = "events-list";
    var ID_LIVE_EVENTS_HOLDER = "live-events";

    var GAME_TIME_TIMEOUTS = {};

    function OverviewUpdater(params) {
      this.params = params;
      this.helpers = require("live.updater.helpers")(params);
      this.MarketsGenerator = require("live.updater.markets.generator")(params);
      if (params.streamEnabled) {
        this.tabStreamUpdater = require("live.stream.updater.overview")(params, this.helpers);
      }
    };

    OverviewUpdater.prototype.handleSingleMessage = function (message) {
      switch (message.type) {
        case "liveevent":
          this.handleLiveEventMessage(message);
          break;
        case "livesport":
          this.handleLiveSportMessage(message);
          break;
        default:
          Logger.log("Unknown message type '" + message.type + "'!");
      }
    };

    OverviewUpdater.prototype.handleMessage = function (message) {
      if (message.length) {
        for (var i = 0; i < message.length; i++) {
          this.handleSingleMessage(message[i])
        }
      } else {
        handleSingleMessage(message)
      }
    };

    OverviewUpdater.prototype.handleLiveEventMessage = function (message) {
      switch (message.operation) {
        case "inserted":
        case "created":
        case "updated":
          this.handleLiveEventCreateOrUpdate(message.html, message.sportId);
          break;
        case "deleted":
          this.handleLiveEventDelete(message);
          break;
        default:
          Logger.log("Unknown operation '" + message.operation + "' for type + '" + message.type + "'!")
      }
    };

    OverviewUpdater.prototype.handleLiveEventCreateOrUpdate = function (event, sportId) {
      if (!event.id) return;
      var eventElm = this.helpers.getEventElementIfExists(event.id);
      var competitionId = $.Utils.expandVariables(ID_TPL_LIVE_COMPETITION, {
        SPORTID: sportId,
        COMPETITIONID: event.competitionId
      });
      var competitionElm = document.getElementById(competitionId);
      if (!eventElm.length) {
        this.handleLiveEventCreate(event, sportId, competitionElm);
      } else {
        this.handleLiveEventUpdate(event, eventElm, competitionElm);
      }
    };

    OverviewUpdater.prototype.handleLiveEventDelete = function (message) {
      var that = this;
      var eventElm = this.helpers.getEventElementIfExists(message.id);
      if (eventElm.length) {
        eventElm.forEach(function (itm) {
          var $itm = $(itm);
          var sportId = $(itm).parent(".sport").attr("id");
          that.helpers.adjustLiveEventsCounter(sportId, "-");
          var parent = itm.parentElement;
          $itm.remove();
          const id = itm.dataset.id;

          if (Object.keys(GAME_TIME_TIMEOUTS).includes(id)) {
            clearTimeout(GAME_TIME_TIMEOUTS[id]);
            GAME_TIME_TIMEOUTS[id] = null;
          }
          // Pokud uz neni uvnitr competition zadny zapas, bude se muset odebrat i cela competition
          if (!parent.children.length) {
            parent.parentNode.parentNode.removeChild(parent.parentNode);
          }
        });
        if (this.params.streamEnabled) {
          this.tabStreamUpdater.removeEmptySports();
        }
      }
    };

    OverviewUpdater.prototype.handleGameTime = function (event) {
      var gameTime = typeof event.gameTime === "object" ? event.gameTime[this.params.locale] : (event.gameTime ? event.gameTime : "");

      if (event.hockeyGameTime) {
        gameTime = event.hockeyGameTime.long;
      } else {
        event.gameTime = gameTime;
        event.shortGameTime = gameTime;
        event = window.FrontendCommons.LiveMatch.getHockeyOverviewWithFakeGameTime(event, event.zonedStart);
      }

      var res = {};
      res.event = event;
      res.gameTime = gameTime;

      return res;
    };

    OverviewUpdater.prototype.updateGameTime = function (evt, eventElm) {
      if (evt.sportId !== window.FrontendCommons.SPORT_HOCKEY) {
        return;
      }

      if (Object.keys(GAME_TIME_TIMEOUTS).includes(evt.id)) {
        clearTimeout(GAME_TIME_TIMEOUTS[evt.id]);
        GAME_TIME_TIMEOUTS[evt.id] = null;
      }

      const that = this;
      const handledEvt = that.handleGameTime(evt);
      const event = handledEvt.event;
      const gameTime = handledEvt.gameTime;
      eventElm.find(".game-time").html(gameTime);

      if (event.gameTimeInterval) {
        GAME_TIME_TIMEOUTS[event.id] = setTimeout(function() {
          that.updateGameTime(evt, eventElm);
        }, 1000);
      }
    }

    OverviewUpdater.prototype.handleRenderedLiveEventGameTime = function (evt) {
      var eventElm = this.helpers.getEventElementIfExists(evt.id);
      this.updateGameTime(evt, eventElm);
    };

    OverviewUpdater.prototype.handleLiveEventCreate = function (event, sportId, competitionElm) {
      var that = this;

      var le = TPLS.EVENT_NEW({
        name: that.helpers.createEventName(event),
        link: that.helpers.createEventLink(event),
        date: that.helpers.parseEventDate(event),
        marketsCount: event.otherMarketsCount,
        markets: that.MarketsGenerator.renderMarket(event.mainMarket, false, false, 1),
        eventId: SELECTORS.buildLiveEventId(event.id),
        order: event.matchOrder,
        actual: (event.actual ? event.actual[that.params.locale] : ""),
        info: (event.info ? event.info[that.params.locale] : ""),
        gameTime: (event.gameTime ? event.gameTime[that.params.locale] : ""),
        score: event.score,
        noOddsLabel: that.params.i18n.NO_ODDS_LABEL,
        noMainMarketLabel: that.params.i18n.NO_MAIN_MARKET_LABEL,
        isMatchTracker: (event.isMatchTracker && that.params.isMatchTracker),
        matchTrackerId: event.matchTrackerId,
        isStream: (that.params.streamEnabled && event.isStream && event.streamType),
        start: event.start
      });

      var sport = document.getElementById(sportId);

      if (!sport) {
        Logger.log("There is no sport '" + sportId + "' yet. Creating new one");
        sport = this.handleLiveSportCreateFromEvent(event, sportId);
      }
      if (!competitionElm) {
        competitionElm = this.handleLiveCompetitionCreateFromEvent(event, sport);
      }

      this.helpers.appendLiveEventToCorrectPositionInCompetition(event, le, sport, competitionElm);
      if (this.params.streamEnabled && event.isStream) {
        this.tabStreamUpdater.addEvent(event, le);
      }
      this.hideUpcomingOverlay();
      var eventElm = that.helpers.getEventElementIfExists(event.id);
      that.updateGameTime(event, eventElm);
    };

    OverviewUpdater.prototype.handleLiveEventUpdate = function (event, eventElm, competition) {
      var that = this;

      var le = TPLS.EVENT_UPDATE({
        name: that.helpers.createEventName(event),
        link: that.helpers.createEventLink(event),
        date: that.helpers.parseEventDate(event),
        marketsCount: event.otherMarketsCount,
        markets: that.MarketsGenerator.renderMarket(event.mainMarket, false, false, 1),
        actual: (event.actual ? event.actual[that.params.locale] : ""),
        info: (event.info ? event.info[that.params.locale] : ""),
        score: event.score,
        gameTime: (event.gameTime ? event.gameTime[that.params.locale] : ""),
        noOddsLabel: that.params.i18n.NO_ODDS_LABEL,
        noMainMarketLabel: that.params.i18n.NO_MAIN_MARKET_LABEL,
        isMatchTracker: (event.isMatchTracker && that.params.isMatchTracker),
        matchTrackerId: event.matchTrackerId,
        isStream: (that.params.streamEnabled && event.isStream && event.streamType),
        start: event.start
      });
      // set live event as updated and remove it after some time
      eventElm.html(le).toggleClass("updated");
      window.setTimeout(function () {
        eventElm.toggleClass("updated");
      }, 5000);

      if (this.isMatchOrderChanged(event, eventElm) && competition) {
        eventElm.attr("data-order", event.matchOrder);
        this.helpers.sortEventListByOrder(competition);
        this.tabStreamUpdater.sortMatchOrder(event);
      }

      that.updateGameTime(event, eventElm);
    };

    OverviewUpdater.prototype.isMatchOrderChanged = function (event, eventElm) {
      var oldMatchOrder = eventElm.attr("data-order");
      return event.matchOrder !== oldMatchOrder;
    };

    OverviewUpdater.prototype.handleLiveSportMessage = function (message) {
      switch (message.operation) {
        case "inserted":
        case "created":
        case "updated":
          this.handleLiveSportCreateOrUpdate(message);
          break;
        case "deleted":
          this.handleLiveSportDelete(message);
          break;
        default:
          Logger.log("Unknown operation '" + message.operation + "' for type + '" + message.type + "'!");
      }
    };

    OverviewUpdater.prototype.handleLiveSportCreateOrUpdate = function (message) {
      var sportElements = this.helpers.getLiveSportElementsIfExists(message.sportId);
      if (sportElements.tab && sportElements.eventsHolder) {
        this.handleLiveSportUpdate(message, sportElements);
      } else {
        this.handleLiveSportCreate(message, sportElements);
      }
    };

    OverviewUpdater.prototype.handleLiveSportDelete = function (message) {
      var sportElements = this.helpers.getLiveSportElementsIfExists(message.id);
      if (sportElements.tab) {
        if ($(sportElements.tab).hasClass("selected")) {
          var nextSelected;
          if (sportElements.tab.previousElementSibling) {
            nextSelected = sportElements.tab.previousElementSibling;
          } else if (sportElements.tab.nextElementSibling) {
            nextSelected = sportElements.tab.nextElementSibling;
          }
          if (nextSelected) {
            $(nextSelected).toggleClass("selected", true);
            var nextElementsHolder = document.getElementById(nextSelected.getAttribute("data-filter"));
            if (nextElementsHolder) {
              $(nextElementsHolder).toggleClass("hidden", false);
            }
          }
        }
        sportElements.tab.parentElement.removeChild(sportElements.tab);
      }
      if (sportElements.eventsHolder) {
        sportElements.eventsHolder.parentElement.removeChild(sportElements.eventsHolder);
      }
      if (this.params.streamEnabled) {
        this.tabStreamUpdater.removeSport(message.id);
      }
      if ($("#live-sports-menu ul li").length === (this.params.streamEnabled ? 1 : 0)) {
        this.showUpcomingOverlay();
      }
    };

    OverviewUpdater.prototype.handleLiveSportCreate = function (message, sportElements) {
      var that = this;
      // Create tab in sports menu if not exists
      if (!sportElements.tab) {
        this.helpers.appendSportTabToCorrectPosition(message, TPLS.SPORT_TAB({
          tabId: "tab-" + message.id,
          link: that.params.baseUrl + "?sport=" + message.id,
          id: message.id,
          icon: message.id.substring(3).toLowerCase(),
          title: message.html.names[that.params.locale],
          order: message.order
        }));
      }
      // Create events holder if not exists
      if (!sportElements.eventsHolder) {
        var legalNoticeBoxes = $("#page main .live-legal-notice-box");
        var isLegalNoticeBoxClosed = !legalNoticeBoxes.length ? true : legalNoticeBoxes.hasClass("closed");
        this.helpers.appendEventsHolder(TPLS.EVENTS_HOLDER({
          id: message.id,
          legalNoticeTitle: that.params.i18n.LEGAL_NOTICE_TITLE,
          legalNoticeText: that.params.i18n.LEGAL_NOTICE_TEXT,
          legalNoticeClosed: isLegalNoticeBoxClosed
        }));
      }
      // Append incoming live events
      for (var i = 0, l = message.html.liveEvents.length; i < l; i++) {
        this.handleLiveEventCreateOrUpdate(message.html.liveEvents[i], message.id);
      }
      this.hideUpcomingOverlay();
    };

    /**
     * Creates sport data from basic data in event. This is needed when no create message for livesport comes.
     * This just creates placeholder with available data and we are waiting for livesport create.
     *
     * @return generated events holder
     */
    OverviewUpdater.prototype.handleLiveSportCreateFromEvent = function (event, sportId) {
      var that = this;
      this.helpers.appendSportTabToCorrectPosition({order: 2147483637}, TPLS.SPORT_TAB({
        tabId: "tab-" + sportId,
        link: that.params.baseUrl + "?sport=" + sportId,
        id: sportId,
        icon: event.sportId.toLowerCase(),
        title: event.sportId
      }));
      this.helpers.appendEventsHolder(TPLS.EVENTS_HOLDER({
        id: sportId,
        legalNoticeTitle: that.params.i18n.LEGAL_NOTICE_TITLE,
        legalNoticeText: that.params.i18n.LEGAL_NOTICE_TEXT,
        legalNoticeClosed: $("#page main .live-legal-notice-box").hasClass("closed")
      }));
      return document.getElementById(sportId);
    };

    OverviewUpdater.prototype.handleLiveCompetitionCreateFromEvent = function (event, sport) {
      var that = this;
      var group = $(TPLS.COMPETITION_GROUP({
        id: SELECTORS.buildLiveCompetitionId(that.helpers.getSportId(event.sportId), event.competitionId),
        name: event.names[that.params.locale],
        order: event.order
      }))[0];
      var $sport = $(sport);
      $sport.append(group);
      var competitions = $(".competition", $sport);
      var competitionElems = [];
      competitions.forEach(function (itm) {
        competitionElems.push(itm);
      });
      competitionElems.sort(function (e1, e2) {
        var e1Id = $.Utils.asInt(e1.getAttribute("data-order"), $.CONSTANTS.MAX_SAFE_INTEGER);
        var e2Id = $.Utils.asInt(e2.getAttribute("data-order"), $.CONSTANTS.MAX_SAFE_INTEGER);
        return e1Id - e2Id;
      });
      competitions.remove();
      $sport.prepend(competitionElems);
      return group;
    };

    OverviewUpdater.prototype.handleLiveSportUpdate = function (message, sportElements) {
      var order = sportElements.tab.getAttribute("data-order");
      $(".title", sportElements.tab).html(message.html.names[this.params.locale]);
      if (!order) {
        this.helpers.appendSportTabToCorrectPosition(message, sportElements.tab);
      }
    };

    OverviewUpdater.prototype.hideUpcomingOverlay = function () {
      var upcoming = document.getElementById("upcoming");
      if (upcoming) {
        upcoming.parentElement.removeChild(upcoming);
        var liveSportsMenu = document.getElementById("live-sports-menu");
        if (liveSportsMenu) {
          var list = liveSportsMenu.getElementsByTagName("ul");
          if (list.length) {
            list = list[0];
            var firstItem = list.children[0];
            if (firstItem) {
              $(firstItem).toggleClass("selected", true);
              firstItem = firstItem.getElementsByTagName("a");
              if (firstItem.length) {
                firstItem = firstItem[0];
                var filter = firstItem.getAttribute("data-filter");
                if (filter) {
                  var content = document.getElementById(filter);
                  if (content) {
                    $(content).toggleClass("hidden", false);
                  }
                }
              }
            }
          }
        }
      }
    };

    OverviewUpdater.prototype.showUpcomingOverlay = function () {
      var lnk = $.Link(window.location.href);
      $.ftnAjax(lnk.appendToPath("upcoming").ajaxify().getUrl(), {
        success: function (data) {
          var overview = $("#page main .live-overview");
          overview.html(overview.html() + data);
        }
      });
    };

    module.exports = function (params) {
      return new OverviewUpdater(params);
    }

  });

})(modulr, $);
;
/* assets/src/scripts/fortuna/live/updater-scoreboards.js */
(function (modulr, $) {
  "use strict";

  modulr.define("live.updater.scoreboard", function (require, exports, module) {

    var instance = null;
    const CLASS_ENDED = "ended";
    const CLASS_NO_SCROLL = "no-scroll";

    function ScoreboardUpdater(params) {
      for (var i in params) {
        this[i] = params[i];
      }
      this.scoreboard = $("#live-detail");
      this.SpecialScoreboardUpdater = null;
      try {
        this.SpecialScoreboardUpdater = require("live.updater.scoreboard." + this.sport);
        this.SpecialScoreboardUpdater.fakeGameTimeUpdater(this.scoreboard);
      } catch (e) {
        // do nothing
      }
    };

    ScoreboardUpdater.prototype.update = function (message) {
      var changedValues = this.updateScoreboard(message);
      this.updateFrontend(changedValues);
    };

    ScoreboardUpdater.prototype.updateScoreboard = function (message) {
      var changedValues = {};
      if (message.html) {
        for (var i in this.values) {
          if (typeof message.html[i] !== "undefined" && message.html[i] !== this.values[i]) {
            var val = {
              oldVal: this.values[i],
              newVal: message.html[i]
            };
            this.values[i] = val.newVal;
            changedValues[i] = val;
          }
        }
      }
      return changedValues;
    };

    ScoreboardUpdater.prototype.updateFrontend = function (changedValues) {
      for (var i in changedValues) {
        if (i === "state") {
          const isEnded = changedValues[i].newVal === "END";
          $(".markets").toggleClass(CLASS_ENDED, isEnded);

          if (isEnded) {
            $("html")[0].classList.add(CLASS_NO_SCROLL);
            // the code below handles layoutClass when tab is changed
            $('[href="#event-bets"]')[0].dataset.layoutClass = CLASS_NO_SCROLL;
          }
        }
        if (this.SpecialScoreboardUpdater && (this.SpecialScoreboardUpdater.VALUES.indexOf(i) > -1)) {
          this.SpecialScoreboardUpdater.update(i, changedValues[i], this.values, this.scoreboard, this.i18n, this);
        } else {
          switch (i) {
            case "state":
              $(".c.state", this.scoreboard).toggleClass("hidden", changedValues[i].newVal === "PLAY").html(this.i18n[changedValues[i].newVal]);
              $(".c.state-play", this.scoreboard).toggleClass("hidden", changedValues[i].newVal !== "PLAY");
              break;
            case "server":
            case "actualPlayer":
            case "turn":
            case "lastStone":
              $(".c.server", this.scoreboard).toggleClass("active", false);
              $(".c.server.server-" + changedValues[i].newVal, this.scoreboard).toggleClass("active", true);
              break;
            case "actualQuarter":
              $(".c.actualQuarter", this.scoreboard).html(changedValues[i].newVal);
              if (changedValues[i].newVal < changedValues[i].oldVal) {
                if (this.values["team1Quarter" + changedValues[i].oldVal] === 0 && this.values["team2Quarter" + changedValues[i].oldVal] === 0) {
                  $(".c.team1Quarter" + changedValues[i].oldVal, this.scoreboard).html("-");
                  $(".c.team2Quarter" + changedValues[i].oldVal, this.scoreboard).html("-");
                }
              } else {
                $(".c.team1Quarter" + changedValues[i].newVal, this.scoreboard).html(this.values["team1Quarter" + changedValues[i].newVal]);
                $(".c.team2Quarter" + changedValues[i].newVal, this.scoreboard).html(this.values["team2Quarter" + changedValues[i].newVal]);
              }
              $(".c.overtime", this.scoreboard).toggleClass("hidden", changedValues[i].newVal < 5);
              $(".c.normal", this.scoreboard).toggleClass("hidden", changedValues[i].newVal > 4);
              $(".c.period-0", this.scoreboard).toggleClass("hidden", changedValues[i].newVal > 0);
              $(".c.period-other", this.scoreboard).toggleClass("hidden", changedValues[i].newVal === 0);
              // mark actual quarter
              $(".c.quarter-" + changedValues[i].oldVal + "-col", this.scoreboard).toggleClass("active", false);
              $(".c.quarter-" + changedValues[i].newVal + "-col", this.scoreboard).toggleClass("active", true);
              break;
            case "team1Corners":
            case "team2Corners":
            case "team1YellowCards":
            case "team2YellowCards":
            case "team1RedCards":
            case "team2RedCards":
            case "penaltyTeam1":
            case "penaltyTeam2":
            case "team1Disq":
            case "team2Disq":
            case "team1Throws7M":
            case "team2Throws7M":
            case "team1Timeout":
            case "team2Timeout":
            case "team1ServingErrors":
            case "team2ServingErrors":
            case "team1Penalty":
            case "team2Penalty":
            case "player1OneHundredEighties":
            case "player2OneHundredEighties":
            case "penaltyTeam1Period4":
            case "penaltyTeam2Period4":
            case "team1Substitutions":
            case "team2Substitutions":
            case "penaltyTeam1Quarter5":
            case "penaltyTeam2Quarter5":
            case "rounds":
            case "actualRound":
            case "fighter1Wins":
            case "fighter1Loses":
            case "fighter2Wins":
            case "fighter2Loses":
              this.updateConnectedProperties(i, changedValues[i].newVal);
              break;
            default:
              $(".c." + i, this.scoreboard).html(changedValues[i].newVal);
          }
        }
      }
    };

    ScoreboardUpdater.prototype.updateConnectedProperties = function (property, newValue) {
      var connectedProperty;
      if (property.toLowerCase().indexOf("eam1") > -1) {
        connectedProperty = property.replace("eam1", "eam2");
      } else {
        connectedProperty = property.replace("eam2", "eam1");
      }
      var connectedPropertyValue = this.values[connectedProperty];
      $(".c." + property, this.scoreboard).html(newValue);
      if (newValue > 0 && connectedPropertyValue === 0) {
        $(".c." + connectedProperty, this.scoreboard).html(connectedPropertyValue);
      } else if (newValue === 0 && connectedPropertyValue === 0) {
        $(".c." + property, this.scoreboard).html("-");
        $(".c." + connectedProperty, this.scoreboard).html("-");
      }
    };

    module.exports = function (params) {
      if (!instance) {
        instance = new ScoreboardUpdater(params);
      }
      return instance;
    };

  });

})(modulr, $);
;
/* assets/src/scripts/fortuna/live/updater-selectors.js */
(function(modulr) {
    "use strict";
    
    modulr.define("live.updater.selectors", function(require, exports, module) {
        
        var ID_PREFIX_LIVE_EVENT = "le-";
        var ID_LIVE_SPORTS_MENU = "live-sports-menu";
        var ID_LIVE_EVENTS_HOLDER = "live-events";
        
        var CLASS_EVENTS_LIST = "events-list";
        
        var SELECTOR_EVENTS_HOLDERS_HOLDER = "#" + ID_LIVE_EVENTS_HOLDER + " > .content";
        var SELECTOR_EVENTS_LIST = ".events-list";
        var SELECTOR_STREAM_SPORT = ".stream-sport";
        
        var ID_TPL_LIVE_COMPETITION = "lc-{{SPORTID}}-{{ORDER}}";
        
        function buildLiveEventId(liveEventId) {
            return ID_PREFIX_LIVE_EVENT + liveEventId;
        };
        
        function buildLiveCompetitionId(sportId, order) {
            return $.Utils.expandVariables(ID_TPL_LIVE_COMPETITION, {
                "SPORTID": sportId,
                "ORDER": order
            });
        };
        
        function buildStreamSportId(sportId) {
            return "stream-" + sportId;
        };
        
        module.exports = {
            ID_PREFIX_LIVE_EVENT: ID_PREFIX_LIVE_EVENT,
            ID_LIVE_SPORTS_MENU: ID_LIVE_SPORTS_MENU,
            ID_LIVE_EVENTS_HOLDER: ID_LIVE_EVENTS_HOLDER,
            ID_TPL_LIVE_COMPETITION: ID_TPL_LIVE_COMPETITION,
            
            CLASS_EVENTS_LIST: CLASS_EVENTS_LIST,
            
            SELECTOR_EVENTS_HOLDERS_HOLDER: SELECTOR_EVENTS_HOLDERS_HOLDER,
            SELECTOR_EVENTS_LIST: SELECTOR_EVENTS_LIST,
            SELECTOR_STREAM_SPORT: SELECTOR_STREAM_SPORT,
            
            buildLiveEventId: buildLiveEventId,
            buildLiveEventSelector: function(liveEventId) {
                return "." + buildLiveEventId(liveEventId);
            },
            
            buildStreamSportId: buildStreamSportId,
            buildStreamSportIdSelector: function(sportId) {
                return "#" + buildStreamSportId(sportId);
            },
            
            buildLiveCompetitionId: buildLiveCompetitionId,
            buildLiveCompetitionSelector: function(sportId, order) {
                return "." + buildLiveCompetitionId(sportId, order);
            }
        };
        
    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/live/updater-templates.js */
(function(modulr, doT) {
    "use strict";

    modulr.define("live.updater.templates", function(require, exports, module) {

        var EVENT_UPDATE = [
          '<a href="{{=it.link}}" class="match-cell" data-start="{{=it.start}}">',
            '<span class="match-cell-details">',
              '<span class="name">',
                '{{=it.name}}{{? it.isStream}} ',
                '<i class="icon-stream"></i>',
                '{{?}}{{? it.isMatchTracker && it.matchTrackerId}} ',
                '<i class="icon matchtracker"></i>',
                '{{?}}',
              '</span>',
              '<span class="score">',
                '{{? it.score}}',
                '<span class="highlight">{{=it.score}}</span>',
                '{{?}}{{? it.info}}',
                '<span class="info">{{=it.info || ""}}</span>',
                '{{?}}{{? it.actual}}<span class="actual">{{=it.actual || ""}}</span>',
                '{{?}}{{? it.gameTime}}<span class="game-time">{{=it.gameTime || ""}}</span>',
                '{{?}}',
              '</span>',
            '</span>',
            '<span class="more-bets-cell">',
              '<span>{{? it.marketsCount > 0}}+{{=it.marketsCount}}{{?}}</span>',
            '</span>',
          '</a>',
          '{{? it.markets}}{{=it.markets || ""}}{{??}}{{? it.marketsCount > 0}}',
          '<a href="{{=it.link}}" class="no-main-market">',
            '<div class="inner">',
              '{{=it.noMainMarketLabel}}',
            '</div>',
            '</div>', // TODO WAT??
            '{{??}}',
            '<div class="no-odds">',
              '<div class="inner">{{=it.noOddsLabel}}</div>',
            '</div>',
            '{{?}}{{?}}'
        ].join('');
        var EVENT_NEW = '<li class="event {{=it.eventId}}" id="{{=it.eventId}}" data-order="{{=it.order}}">' + EVENT_UPDATE + '</li>';
        var EVENT_NAME = [
          '{{? it.participantH1}}',
          '{{=it.participantH1}}',
          '{{? it.participantA1}} ',
          '<span class="separator">&times;</span> ',
          '{{=it.participantA1}}',
          '{{?}}',
          '{{??}}',
          '{{=it.name}}',
          '{{?}}'
        ].join('');
        // var EVENT_NAME = '{{=it.name}}';
        var SPORT_TAB = [
          '<li ',
            'id="{{=it.tabId}}"{{? it.order }} ',
            'data-order="{{=it.order}}"{{?}} ',
            'data-filter="{{=it.id}}"',
          '>',
            '<a href="{{=it.link}}">',
              '<svg class="f-icon-m">',
                '<use href="#{{=it.icon}}"></use>',
              '</svg>',
              '<span class="count">0</span>',
              '<span class="title">{{=it.title}}</span>',
            '</a>',
          '</li>'
        ].join('');
        var EVENTS_HOLDER = [
          '<div id="{{=it.id}}" class="sport scrollable-area hidden">',
            '<ul class="events-list"></ul>',
            '{{?it.legalNoticeTitle}}',
            '<div class="content-box collapsible live-legal-notice-box{{? it.legalNoticeClosed}} closed{{?}}">',
              '<div class="header">',
                '<h4 class="title">{{=it.legalNoticeTitle}}</h4>',
              '</div>',
              '<div class="content">{{=it.legalNoticeText}}</div>',
            '</div>',
            '{{?}}',
          '</div>'
        ].join('');
        var EVENT_MARKET = [
          '{{? it.oddsCount > 0}}',
          '<div ',
            'class="market wrapped" ',
            'id="market-{{=it.market.id}}"',
          '>',
            '{{? it.market.odds}}',
            '{{~it.market.odds :group:groupIndex}}',
            '<div class="row">',
              '{{~group :odd:oddIndex}}',
              '{{? odd.readOnly}}',
              '<div class="syntetic-odd market-{{=odd.marketId}}" id="so-{{=odd.marketId}}">',
                '{{=odd.names[it.locale] || ""}}',
              '</div>',
              '{{?? odd.value > 1}}',
              '<a ',
                'href="{{=it.baseTicketAddUrl}}?',
                  'info={{=odd.info}}&',
                  'tip_id={{=odd.id.substring(3)}}&',
                  'value={{=odd.value}}&',
                  'kind={{=it.market.eventKind}}&',
                  'event={{=it.event}}&',
                  'market_id={{=odd.marketId}}&',
                  'supportGroup={{=it.market.supportGroup.markets[odd.marketId]}}&',
                  'supportGroup_ex={{=it.market.supportGroupEx.markets[odd.marketId]}}" ',
                'data-remove-bet-url="{{=it.baseTicketRemoveUrl}}?',
                  'return_type=json&',
                  'info={{=odd.info}}&',
                  'tip_id={{=odd.id.substring(3)}}&',
                  'kind={{=it.market.eventKind}}" ',
                'data-info="{{=odd.info}}" ',
                'data-odd="{{=odd.id.substring(3)}}" ',
                'data-value="{{=odd.value}}" ',
                'data-market-id="{{=odd.marketId}}" ',
                'data-eventId="{{=it.market.eventId}}" ',
                'data-support-group="{{=it.market.supportGroup.markets[odd.marketId]}}" ',
                'data-support-group-ex="{{=it.market.supportGroupEx.markets[odd.marketId]}}" ',
                'data-is-player-market="{{? odd.teamAssociation }}true{{?}}" ',
                'class="odd-button bet-link market-{{=odd.marketId}} odd-{{=odd.id.substring(3)}} odd-event-id-{{=it.market.eventId}}{{? odd.decoratedOnTicket}} on-ticket{{?}}{{? odd.decoratedNotCompatible}} not-compatible{{?}}{{? odd.decoratedCombinable}} combinable{{?}}{{? odd.teamAssociation }} odd-button__player-market odd-button--{{=odd.teamAssociation}}{{?}}" ',
                'id="odd-{{=odd.info}}-{{=odd.id.substring(3)}}"',
              '>',
                '<span class="tip">{{=odd.names[it.locale] || ""}}</span>',
                '<span class="odd {{=odd.decoratedChangeDirection || ""}}">',
                  '{{=odd.decoratedValue || odd.value || ""}}',
                '</span>',
              '</a>',
              '{{??}}',
              '<span ',
                'class="odd-button inactive market-{{=odd.marketId}}" ',
                'data-info="{{=odd.info}}" ',
                'data-odd="{{=odd.id.substring(3)}}" ',
                'data-value="{{=odd.value}}" ',
                'id="odd-{{=odd.info}}-{{=odd.id.substring(3)}}"',
              '>',
                '<span class="tip">{{=odd.names[it.locale] || ""}}</span>',
                '<span class="odd"><i class="icon-lock"></i></span>',
              '</span>',
              '{{?}}{{~}}',
            '</div>',
            '{{~}}{{?}}',
          '</div>',
          '{{?}}'
        ].join('');
        var MARKET_GROUP = [
          '<div ',
            'id="{{=it.id}}" ',
            'class="market-group"',
          '>',
            '<div class="content"></div>',
          '</div>'
        ].join('');
        var DETAIL_EVENT = [
          '<div class="content js-event {{? it.isMarketHidden}} market-group--hidden {{?}}" data-market-group-ids="{{=it.marketGroupIds || []}}">',
            '<div ',
              'id="lm-{{=it.market.id}}" ',
              'class="event live-market updated" ',
              'data-order="{{=it.market.sortOrder}}"',
            '>',
              '{{? it.withHeader}}',
              '<header class="market-header">',
                '<h3 class="name">',
                  '{{= it.market.subNames[it.locale] || ""}}',
                  '{{? it.market.desc && it.market.desc[it.locale]}}',
                    '<i class="bet-market-subname__description-icon icon info-rounded"></i>',
                  '{{?}}',
                '</h3>',
                '<span class="info">',
                  '{{? it.market.notes && it.market.notes[it.locale]}}',
                  '<a ',
                    'href="#" ',
                    'class="info-icon note" ',
                    'data-note="{{=it.market.notes[it.locale] || ""}}"',
                  '></a>',
                  '{{?}}',
                '</span>',
              '</header>',
            '{{? it.participants}}',
            '<div class="bet-market__participants"><div class="bet-market__participant">',
            '{{=it.participants.home}}',
            '</div><div class="bet-market__participant">',
            '{{=it.participants.away}}',
            '</div></div>',
            '{{?}}',
            '{{? it.market.desc && it.market.desc[it.locale]}}',
                '<div class="bet-market-subname__description">{{=it.market.desc[it.locale]}}</div>',
              '{{?}}',
              '{{?}}{{=it.odds || ""}}',
            '</div>',
          '</div>'
        ].join('');
        var COMPETITION_GROUP = [
          '<div id="{{=it.id}}" class="competition {{=it.id}}" data-order="{{=it.order}}">',
            '<h3>{{=it.name}}</h3>',
            '<ul class="events-list"></ul>',
          '</div>'
        ].join('');
        var STREAM_COLLAPSIBLE_BOX = [
          '<div ',
            'id="stream-{{=it.id}}" ',
            'class="stream-sport content-box collapsible{{?it.isClosed}} closed{{?}}" ',
            'data-order="{{=it.order}}"',
          '>',
            '<div class="header">',
              '<h3 class="title">{{=it.title}}</h3>',
            '</div>',
            '<div class="content"></div>',
          '</div>'
        ].join('');

        module.exports = {
            EVENT_UPDATE: doT.template(EVENT_UPDATE),
            EVENT_NEW: doT.template(EVENT_NEW),
            EVENT_NAME: doT.template(EVENT_NAME),
            SPORT_TAB: doT.template(SPORT_TAB),
            EVENTS_HOLDER: doT.template(EVENTS_HOLDER),
            EVENT_MARKET: doT.template(EVENT_MARKET),
            MARKET_GROUP: doT.template(MARKET_GROUP),
            DETAIL_EVENT: doT.template(DETAIL_EVENT),
            COMPETITION_GROUP: doT.template(COMPETITION_GROUP),
            STREAM_COLLAPSIBLE_BOX: doT.template(STREAM_COLLAPSIBLE_BOX)
        };

    });

})(modulr, doT);

;
/* assets/src/scripts/fortuna/live/updater.js */
(function(modulr, $, doT) {
    "use strict";
    
    modulr.define("live.updater", function(require, exports, module) {
        
        var Logger = require("logger")(module.id);
        
        var TPLS = require("live.updater.templates");
        
        
        var ID_PREFIX_SPORT_TAB = "tab-";
        var ID_SPORT_TABS = "live-sports-menu";
        var ID_EVENTS_LIST = "live-events";
        
        
        var CLASS_SPORT_TAB_COUNTER = "count";
        
        function Updater(params) {
            this.params = params;
            this.OverviewUpdater = null;
        };
        
        Updater.prototype.exec = function(message, func) {
            if (!(message && message.body && func && typeof func === "function")) return;
            var json;
            try {
                json = JSON.parse(message.body);
            } catch(e) {
                Logger.log("Doesn't seem to be valid JSON", e);
            }
            if (json) {
                func.call(this, json);
            }
        };
        
        Updater.prototype.overviewChange = function(message) {
            if (!this.OverviewUpdater) {
                this.OverviewUpdater = require("live.updater.overview")(this.params);
            }
            this.OverviewUpdater.handleMessage(message);
        };
        
        Updater.prototype.mainMarketChange = function(message) {
            if (!this.MarketsUpdater) {
                this.MarketsUpdater = require("live.updater.markets")(this.params);
            }
            this.MarketsUpdater.handleMainMarketMessage(message);
        };
        
        Updater.prototype.marketChange = function(message) {
            if (!this.MarketsUpdater) {
                this.MarketsUpdater = require("live.updater.markets")(this.params);
            }
            this.MarketsUpdater.handleMarketMessage(message);
        };
        
        Updater.prototype.scoreboardChange = function(message) {
            if (!this.scoreboardUpdater) {
                this.ScoreboardUpdater = require("live.updater.scoreboard")();
            }
            this.ScoreboardUpdater.update(message);
        };
        
        module.exports = function(params) {
            return new Updater(params);
        }
    });
    
})(modulr, $, doT);
;
/* assets/src/scripts/fortuna/playtech/ims-auto-reminder.js */
(function(modulr, $) {
    "use strict";

    modulr.define("ims.auto-reminder", function(require, exports, module) {

        function showReminder(instance) {
            window.mobile.dialogs.dialogAlert(instance.__i18n.message.replace(/\{0\}/g, countLoggedInTime(instance)), {
                okButtonLabel: instance.__i18n.ok
            });
            instance.__configuration.remainingLimit = instance.__configuration.configuredLimit;
            instance.__displayCount++;
            start(instance);
        }
        
        function countLoggedInTime(instance) {
            return instance.__configuration.configuredLimit / 60;
        }

        function start(instance) {
            if (instance.__configuration.remainingLimit === 0) {
                showReminder(instance);
            } else if (instance.__configuration.remainingLimit > 0) {
                window.setTimeout(function() {
                    showReminder(instance);
                }, instance.__configuration.remainingLimit * 1000);
            }
        }

        function ImsAutoReminder(configuration, i18n) {
            this.__configuration = configuration;
            this.__i18n = i18n;
            this.__displayCount = 0;
            start(this);
        }

        module.exports = function(configuration, i18n) {
            return new ImsAutoReminder(configuration, i18n);
        };
        
    });
    
})(modulr, $);
;
/* assets/src/scripts/fortuna/playtech/ims-change-password-popup.js */
(function(modulr, $) {
    "use strict";

    modulr.define("playtech.ims-change-password-popup", function(require, exports, module) {

        var ID_OLD_PASSWORD = "id-old-password";
        var ID_NEW_PASSWORD = "id-new-password";
        var ID_CONFIRM_PASSWORD = "id-confirm-password";

        var SELECTOR_OLD_PASSWORD = "#" + ID_OLD_PASSWORD;
        var SELECTOR_NEW_PASSWORD = "#" + ID_NEW_PASSWORD;
        var SELECTOR_CONFIRM_PASSWORD = "#" + ID_CONFIRM_PASSWORD;
        
        var TPL_FORM = '<div class="scrollable-area scrollable-area__position-relative">' +
                            '<form method="post" class="full-page change-password-form" id="changepwd" novalidate>' +
                                '<div class="MaterialInput row">' +
                                    '<a href="#" class="show-password icon eye" rel="' + SELECTOR_OLD_PASSWORD + '">&nbsp;</a>' +
                                    '<input type="password" class="validation" name="oldPassword" id="' + ID_OLD_PASSWORD + '"' +
                                    ' data-validators="required" data-validation-error-required-message="{{ERROR_OLD_PASSWORD_EMPTY}}" tabindex="1">' +
                                    '<label>{{OLD_PASSWORD}}</label>' +
                                '</div>' +
                                '<div class="MaterialInput row">' +
                                    '<a href="#" class="show-password icon eye" rel="' + SELECTOR_NEW_PASSWORD + '">&nbsp;</a>' +
                                    '<input type="password" class="validation" name="newPassword" id="' + ID_NEW_PASSWORD + '"' +
                                    'data-validators="password passwordSameAsOld" data-validation-error-password-message="{{ERROR_PASSWORDS_NOT_MET_CONDITIONS}}" data-validation-password-same-as-old-relation="' + SELECTOR_OLD_PASSWORD + '" data-validation-password-same-as-old-error-message="{{ERROR_NEW_PASSWORD_PREVIOUSLY_USED}}" tabindex="2">' +
                                    '<label>{{NEW_PASSWORD}}</label>' +
                                '</div>' +
                                '<div class="MaterialInput row">' +
                                    '<input type="password" class="validation" name="confirmPassword" id="' + ID_CONFIRM_PASSWORD + '"' +
                                    ' data-validators="equals" data-validation-equals-relation="' + SELECTOR_NEW_PASSWORD + '" data-validation-equals-error-message="{{ERROR_PASSWORDS_NOT_EQUAL}}"  tabindex="3">' +
                                    '<label>{{CONFIRM_PASSWORD}}</label>' +
                                '</div>' +
                            '</form>' +
                        '</div>';
        var TPL_ERROR_MESSAGE = '<div class="error-message margin-top-10 margin-bottom-10">{{CONTENT}}</div>';


        function initialize(imsChangePasswordPopupInstance) {
            imsChangePasswordPopupInstance._popup = window.mobile.dialogs.dialogConfirm($.Utils.expandVariables(TPL_FORM, imsChangePasswordPopupInstance._options.i18n), {
                titleLabel: imsChangePasswordPopupInstance._options.i18n.POPUP_TITLE,
                okButtonLabel: imsChangePasswordPopupInstance._options.i18n.SUBMIT_CHANGES,
                cancelButtonLabel: imsChangePasswordPopupInstance._options.i18n.CANCEL,
                okCallback: function() {},
                cancelCallback: function() {
                    typeof imsChangePasswordPopupInstance._closeCallback === "function" && imsChangePasswordPopupInstance._closeCallback();
                }
            });
            require("form")("form#changepwd")
            var changePasswordForm = imsChangePasswordPopupInstance._popup._html.find("form");
            initializeForm(imsChangePasswordPopupInstance, changePasswordForm);
            displayImsErrorMessageInPopup(imsChangePasswordPopupInstance, changePasswordForm);
        }

        function displayImsErrorMessageInPopup(imsChangePasswordPopupInstance, changePasswordForm) {
            if (typeof imsChangePasswordPopupInstance._options.playerErrorMessage !== "undefined") {
                var message = imsChangePasswordPopupInstance._options.playerErrorMessage || imsChangePasswordPopupInstance._options.i18n.ERROR_DEFAULT;
                changePasswordForm.prepend($.Utils.expandVariables(TPL_ERROR_MESSAGE, {
                    "CONTENT": message.replace(/\\r/g, "<br>")
                }));
                imsChangePasswordPopupInstance._popup.resize();
            }
        }

        function initializeForm(imsChangePasswordPopupInstance, changePasswordForm) {
            require("_toggling-labels")(changePasswordForm);
            var confirmPasswordInput = changePasswordForm.find(SELECTOR_CONFIRM_PASSWORD);
            changePasswordForm.find(".show-password").forEach(function(item) {
                require("password-toggler")(item, function(input, elm) {
                    if (input.is(SELECTOR_NEW_PASSWORD)) {
                        confirmPasswordInput.closest(".row").toggleClass("hidden", input.prop("type") === "text");
                        imsChangePasswordPopupInstance._popup.resize();
                    }
                });
            });
            initializeFormValidations(imsChangePasswordPopupInstance, changePasswordForm);
        }

        function initializeFormValidations(imsChangePasswordPopupInstance, changePasswordForm) {
            var formValidator = require("form-validator")(changePasswordForm);
            var oldPasswordInput = changePasswordForm.find(SELECTOR_OLD_PASSWORD);
            var newPasswordInput = changePasswordForm.find(SELECTOR_NEW_PASSWORD);
            var confirmPasswordInput = changePasswordForm.find(SELECTOR_CONFIRM_PASSWORD);

            $([oldPasswordInput[0], newPasswordInput[0], confirmPasswordInput[0]]).on("blur", function() {
                formValidator.validateField(this);
                imsChangePasswordPopupInstance._popup.resize();
            });

            imsChangePasswordPopupInstance._popup._params.okCallback = function() {
                var confirmPasswordInputOldValue;
                if (newPasswordInput.prop("type") === "text") {
                    confirmPasswordInputOldValue = confirmPasswordInput.val();
                    confirmPasswordInput.val(newPasswordInput.val());
                }
                if (formValidator && formValidator.isValid()) {
                    if (typeof imsChangePasswordPopupInstance._setPasswordCallback === "function") {
                        var oldPassword = changePasswordForm.find(SELECTOR_OLD_PASSWORD).val();
                        var newPassword = changePasswordForm.find(SELECTOR_NEW_PASSWORD).val();
                        imsChangePasswordPopupInstance._setPasswordCallback(oldPassword, newPassword);
                        return true;
                    }
                }
                if (newPasswordInput.prop("type") === "text") {
                    confirmPasswordInput.val(confirmPasswordInputOldValue);
                }
                window.setTimeout(function() {
                    imsChangePasswordPopupInstance._popup.resize();
                }, 100);
                return false;
            };
        }

        function ImsChangePasswordPopup(options, setPasswordCallback, closeCallback) {
            this._options = options;
            this._setPasswordCallback = setPasswordCallback;
            this._closeCallback = closeCallback;
            initialize(this);
        }

        module.exports = function(options, setPasswordCallback, closeCallback) {
            return new ImsChangePasswordPopup(options, setPasswordCallback, closeCallback);
        }

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/playtech/ims-chat.js */
(function(modulr, $) {
  "use strict";

  modulr.define("playtech.chat", function(require, exports, module) {

    const ImsCommons = require("playtech.ims-commons");

    const CHAT_SELECTOR = "#chat-box";
    const CLIENT_PLATFORM = "mobile";
    const CLIENT_TYPE = "sportsbook";
    const NO_CUSTOM_TRANSLATIONS = true;

    function Chat(casinoName, site, openApiSystemId, language, translations, shouldInitialize) {
      this.casinoName = casinoName;
      this.site = site;
      this.openApiSystemId = openApiSystemId;
      this.language = language.toUpperCase();
      this.translations = translations;
      this.config = {};

      this.init(shouldInitialize);
    }

    Chat.prototype = {
      init: function (shouldInitialize) {
        if (shouldInitialize || !Playtech.getUserDetails.loggedin) {
          this.initChat();
        }
      },

      chatStart: function () {
        Communication.API.chat.maximize();
      },

      chatLogin: function (username, token) {

        Communication.API.common.loginByToken({
          userName: username,
          sessionToken: token,
          casinoName: this.casinoName,
          clientPlatform: CLIENT_PLATFORM,
          languageCode: this.language || ImsCommons.LANGUAGE.toUpperCase(),
          realMode: ImsCommons.REAL_MODE,
          clientType: CLIENT_TYPE
        });
      },

      initChat: function () {
        console.log("Starting chat initialization..");

        const that = this;
        $.ftnAjax('/restapi/cms/chat', {
          method: "GET",
          contentType: "application/json",
          data: {
            "site": that.site,
            "url": window.location.pathname,
            "channel": 'mobile'
          },
          success: function (data) {
            that.config = JSON.parse(data);

            Communication.API.common.setConnectionOptions({
              url: that.config.url
            })

            Communication.API.chat.init({
              selector: CHAT_SELECTOR,
              casinoName: that.casinoName,
              clientSkin: that.casinoName,
              clientType: CLIENT_TYPE,
              clientPlatform: CLIENT_PLATFORM,
              languageCode: that.language || ImsCommons.LANGUAGE.toUpperCase(),
              clientVersion: '1.1',
              doNotUseCustomTranslationFile: NO_CUSTOM_TRANSLATIONS
            }).then( function () {
              if (Playtech.getUserDetails.loggedin) {
                iapiSetCallout(iapiCALLOUT_TEMPORARYTOKEN, that.calloutRequestTemporaryToken().bind(that));
                iapiRequestTemporaryToken(ImsCommons.REAL_MODE, that.openApiSystemId, ImsCommons.SERVICE_TYPE);
              }
            });

            if (Object.keys(that.translations).length !== 0) {
              console.log("Adding chat translations: %O for site: %s and language: %s",
                that.translations, that.site, that.language)
              Communication.API.chat.addChatTranslations(that.translations);
            } else {
              console.log("Not adding chat translations as they are not defined in CMS for this environment");
            }



            that.adjustForLottery();

            var messages = Communication.API.chat.getUnreadCount();
            var numOfMessages = messages.adminMessagesCount;

            Communication.API.chat.minimize();

            Communication.API.chat.onUpdateUnreadCount(that.toggleOutboundNotification.bind(that))

            Communication.API.chat.onChatCloseWindow(that.toggleInboundNotification.bind(that))

            that.setChatVisibility(that.config.inboundChat, numOfMessages);
          }
        })

      },

      calloutRequestTemporaryToken: function () {
        return function(response) {
          if (response.errorCode) {
            console.log("Failed to get temporary token for chat login", response);
          } else {
            this.chatLogin(response.username, response.sessionToken.sessionToken);
          }
        }.bind(this);
      },

      adjustForLottery: function () {
        var chatOut = document.getElementById("chat-outbound");
        var chatIn = document.getElementById("chat-inbound");
        var lotteryHeader = document.getElementsByClassName('sticky-layout__header')[0];

        if (lotteryHeader && chatOut && chatIn) {
          lotteryHeader.appendChild(chatOut);
          lotteryHeader.appendChild(chatIn);
        }
      },

      setChatVisibility: function (inboundChat, messages) {
        if (!inboundChat) {
          return;
        }

        if (messages < 1) {
          this.toggleInboundNotification();
        } else {
          this.toggleOutboundNotification();
        }
      },

      toggleInboundNotification: function () {
        var inbound = document.getElementById("chat-inbound");
        var outbound = document.getElementById("chat-outbound");

        outbound.classList.remove("show");

        if (this.config.inboundChat) {
          inbound.addEventListener("click", function () {
            this.chatStart();
          }.bind(this));
          inbound.classList.add("show");
        }
      },

      toggleOutboundNotification: function () {
        var inbound = document.getElementById("chat-inbound");
        var outbound = document.getElementById("chat-outbound");

        inbound.classList.remove("show");

        if (this.config.outboundChat) {
          outbound.addEventListener("click", function () {
            this.chatStart();
          }.bind(this));
          outbound.classList.add("show");
        }
      }
    }

    module.exports = function (casinoName, site, openApiSystemId, lang, translations, shouldInitialize) {
      return new Chat(casinoName, site, openApiSystemId, lang, translations, shouldInitialize);
    }
  })
})(modulr, $);

;
/* assets/src/scripts/fortuna/playtech/ims-commons.js */
(function(modulr) {
    "use strict";

    modulr.define("playtech.ims-commons", function(require, exports, module) {

        var CLIENT_TYPE = "sportsbook";
        var CLIENT_PLATFORM = "mobile";
        var FTN_LOGIN_URL =  window.APIGW_URL + '/_login-gm';

        module.exports = {
            REAL_MODE: 1,
            SERVICE_TYPE: "GamePlay",
            LANGUAGE: "en",

            setCommonParams: function(systemId) {
                iapiSetClientType(CLIENT_TYPE);
                iapiSetClientPlatform(CLIENT_PLATFORM);
                if (systemId) {
                    iapiSetSystemId(systemId);
                }
            },

          doFtnLogin: function(username, token) {
              var loginRequest = {};
              loginRequest.username = username;
              loginRequest.password = token;

              fetch(FTN_LOGIN_URL, {
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body : JSON.stringify(loginRequest),
                method: 'POST'
              }).then(function (response) {
                return response.json();
              }).then(function (data) {
                if (data && (data.errorText ||
                  (data.additionalInformation && data.additionalInformation.phoneVerificationRequired === true))) {
                  window.mobile.offer.toggleLoader(false);
                  require("logout").doLogout();
                } else if (data) {
                  if (data.additionalInformation)
                  {
                    const hostname = window.location && window.location.hostname;
                    const cookieDomain = '.' + hostname.split('.').slice(-2).join('.');
                    document.cookie = "loginTimeCounter=" + data.additionalInformation.firstLoginTimestamp + "; expires=undefined; path=/; domain=" + cookieDomain;
                  }
                  // window.location redirection doesn't work when location contains '#'
                  if (window.location.href === data.Location) {
                    window.location.reload();  // Reload the page if the locations match
                  } else {
                    window.location.assign(data.Location);  // Redirect to the new location
                  }
                }
              }).catch(function(err) {
                window.mobile.offer.toggleLoader(false);
                require("logout").doLogout();
              });
            },
      };
    })
})(modulr);
;
/* assets/src/scripts/fortuna/playtech/ims-login.js */
(function(modulr, $) {
    "use strict";

    modulr.define("playtech.ims-login", function(require, exports, module) {

        var SELECTOR_LOGIN = "input[name=username]";
        var SELECTOR_PASSWORD = "input[name=password]";

        var Logger = require("logger")("playtech.ims-login");
        var ImsCommons = require("playtech.ims-commons");

        function initialize(imsLoginInstance) {
          doLogin(imsLoginInstance);
        }

        function doLogin(imsLoginInstance) {
          var loginName = imsLoginInstance._loginInstance._form.find(SELECTOR_LOGIN).val();
          var password = imsLoginInstance._loginInstance._form.find(SELECTOR_PASSWORD).val();
          if (loginName && password) {
              imsLoginInstance.loginName = loginName;
              imsLoginInstance.password = password;
              doImsLogin(imsLoginInstance);
          }
        }

        function doFtnLogin(imsLoginInstance, username, sessionToken) {
          window.mobile.offer.toggleLoader(true);
          Logger.log("Performing FTN login")
          ImsCommons.doFtnLogin(username, sessionToken);
        }

        function doGetLoggedInPlayer(imsLoginInstance) {
          window.mobile.offer.toggleLoader(true);
          Logger.log("Getting logged in player")

          try {
              iapiSetCallout(iapiCALLOUT_GETLOGGEDINPLAYER, imsLoginInstance.calloutGetLoggedInPlayer());
              iapiGetLoggedInPlayer(ImsCommons.REAL_MODE);
          }
          catch (e) {
              Logger.log("Error:", e);
              $.ftnAjax("/restapi/logging", {
                  method: "POST",
                  contentType: "application/json;",
                  data: JSON.stringify([{
                      severity: "ERROR",
                      data: {
                          error: e.message,
                          username: imsLoginInstance.loginName
                      }
                  }])
              });
          }
        }

        function doRequestTemporaryToken(imsLoginInstance) {
          window.mobile.offer.toggleLoader(true);
          iapiSetCallout(iapiCALLOUT_TEMPORARYTOKEN, imsLoginInstance.calloutRequestTemporaryToken());
          iapiRequestTemporaryToken(ImsCommons.REAL_MODE, imsLoginInstance._systemId, ImsCommons.SERVICE_TYPE);
        }

        function doImsLogin(imsLoginInstance) {
          window.mobile.offer.toggleLoader(true);
          if (imsLoginInstance.loginName && imsLoginInstance.password) {
              Logger.log("Performing IMS login..");
              iapiSetCallout(iapiCALLOUT_LOGIN, imsLoginInstance.calloutLogin());
              iapiLogin(imsLoginInstance.loginName, imsLoginInstance.password, ImsCommons.REAL_MODE, imsLoginInstance._locale);
              $.cookie("ims-logout", '1', {path: "/"});
          }
        }

        function doImsChangePassword(imsLoginInstance, oldPassword, newPassword) {
            window.mobile.offer.toggleLoader(true);
            iapiSetCallout(iapiCALLOUT_SESSIONVALIDATION, imsLoginInstance.calloutChangePassword());
            iapiValidatePasswordChange(oldPassword, newPassword, 1, ImsCommons.REAL_MODE);
        }

        function showTermsAndConditions(imsLoginInstance, imsResponse) {
            var url = imsResponse.SessionValidationByTCVersionData[0].tcVersionUrl;
            var iframeHeight = window.innerHeight - 157;
            var content = '<div style="overflow: scroll !important; -webkit-overflow-scrolling: touch !important;height: ' + iframeHeight + 'px;"><iframe src="' + url + '" style="width: 100%; height: 100%; border: 0;" scrolling="yes"></iframe></div>';
            var options = (imsLoginInstance._opts && imsLoginInstance._opts.termsAndConditionsPopup) || {i18n: {}};
            window.mobile.dialogs.dialogConfirm(content, {
                okButtonLabel: options.i18n.OK_BUTTON_LABEL,
                cancelButtonLabel: options.i18n.CANCEL_BUTTON_LABEL,
                okCallback: function() {
                    iapiSetCallout(iapiCALLOUT_SESSIONVALIDATION, imsLoginInstance.calloutValidateTCVersion());
                    iapiValidateTCVersion(imsResponse.SessionValidationByTCVersionData[0].termVersionReference, 1, ImsCommons.REAL_MODE);
                    $.cookie("ims-logout", '1', {path: "/"});
                },
                cancelCallback: function() {
                    $.cookie("ims-logout", '1', {path: "/"});
                    require("playtech.ims-logout")(imsLoginInstance._opts);
                }
            });
        }

        function showChangePasswordPopup(imsLoginInstance, playerErrorMessage) {
            var changePasswordOptions = (imsLoginInstance._opts && imsLoginInstance._opts.changePasswordPopup) || {};
            if (playerErrorMessage) {
                changePasswordOptions.playerErrorMessage = playerErrorMessage;
            }
            require("playtech.ims-change-password-popup")(changePasswordOptions, function(oldPassword, newPassword) {
                doImsChangePassword(imsLoginInstance, oldPassword, newPassword);
            }, function() {
                $.cookie("ims-logout", '1', {path: "/"});
                require("playtech.ims-logout")(imsLoginInstance._opts);
            });
        }

        function showError(imsLoginInstance, imsResponse) {
            if (imsLoginInstance && imsResponse) {
                imsLoginInstance._loginInstance.showError(imsResponse);
            }
        }

        function showUnhandledError(imsLoginInstance, error) {
          if (imsLoginInstance && error) {
            imsLoginInstance._loginInstance.showUnhandledError(error);
          }
        }

        function ImsLogin(loginInstance, opts) {
            this._opts = opts || {};
            this._loginInstance = loginInstance;
            this._systemId = opts.systemId || "";
            this._locale = opts.locale || ImsCommons.LANGUAGE;

            try{
              ImsCommons.setCommonParams(this._systemId);
            } catch(err) {
              $.ftnAjax("/restapi/logging", {
                method: "POST",
                contentType: "application/json;",
                data: JSON.stringify([{
                  severity: "ERROR",
                  data: {
                    error: err.message,
                    username: this._loginInstance._form.find(SELECTOR_LOGIN).val()
                  }
                }])
              });

              var errMssg = "Pas integration script unreachable. " + err;
              showUnhandledError(this, errMssg);
              Logger.log(err);
              throw errMssg;
            }

            initialize(this);
        }

        ImsLogin.prototype.calloutGetLoggedInPlayer = function() {
            var that = this;
            return function(response) {
                window.mobile.offer.toggleLoader(false);
                if (response) {
                    if (response.errorCode) {
                        showError(that, response);
                    } else {
                        if (response.cookieExists === "1") {
                            doRequestTemporaryToken(that);
                        } else {
                            doImsLogin(that);
                        }
                    }
                } else {
                    Logger.log("Invalid response, response is " + response);
                }
            };
        };

        ImsLogin.prototype.calloutRequestTemporaryToken = function() {
            var that = this;
            return function(response) {
                window.mobile.offer.toggleLoader(false);
                if (response) {
                    if (response.errorCode) {
                        $.ftnAjax("/restapi/logging", {
                            method: "POST",
                            contentType: "application/json;",
                            data: JSON.stringify([{
                                severity: "ERROR",
                                data: {
                                    username: response.username,
                                    errorCode: response.errorCode,
                                    errorText: response.errorText,
                                    playerMessage: response.playerMessage
                                }
                            }])
                        });

                        if (response.errorCode === 104) {
                            Logger.log("Player PT session is invalid. Will perform login to PT.");
                            doImsLogin(that);
                        } else if (response.errorCode === 175) {
                            var logoutOptions = $.extend({}, that._opts);
                            logoutOptions.success = function() {
                                doImsLogin(that);
                            };
                            $.cookie("ims-logout", '1', {path: "/"});
                            require("playtech.ims-logout")(logoutOptions);
                        } else {
                            showError(that, response);
                        }
                    } else {
                      doFtnLogin(that, response.username, response.sessionToken.sessionToken);
                    }
                } else {
                    Logger.log("Invalid response, response is " + response);
                }
            };
        };

        ImsLogin.prototype.calloutLogin = function() {
            var that = this;
            return function(response) {
                window.mobile.offer.toggleLoader(false);
                if (response) {
                    if (response.errorCode) {
                        Logger.log("PT Login failed, error:" + response.errorCode + " errorText:" + response.errorText + " playerMessage:" + response.playerMessage);
                        $.ftnAjax("/restapi/logging", {
                            method: "POST",
                            contentType: "application/json;",
                            data: JSON.stringify([{
                                severity: "ERROR",
                                data: {
                                    username: response.username,
                                    errorCode: response.errorCode,
                                    errorText: response.errorText,
                                    playerMessage: response.playerMessage
                                }
                            }])
                        });

                        showError(that, response);
                    } else {
                        Logger.log("PT Login success.");
                        if (response.sessionValidationData) {
                          response = response.sessionValidationData;
                            if (response.SessionValidationByPasswordChangeData) {
                                showChangePasswordPopup(that);
                            }
                            if (response.SessionValidationByTCVersionData) {
                                showTermsAndConditions(that, response);
                            }
                        } else {
                            doRequestTemporaryToken(that);
                        }
                    }
                } else {
                    Logger.log("Invalid response, response is " + response);
                }
            };
        };

        ImsLogin.prototype.calloutValidateTCVersion = function() {
            var that = this;
            return function(response) {
                window.mobile.offer.toggleLoader(false);
                if (response) {
                    if (response.errorCode) {
                        Logger.log("PT Login failed, error:" + response.errorCode + " errorText:" + response.errorText + " playerMessage:" + response.playerMessage);
                        showError(that, response);

                        $.ftnAjax("/restapi/logging", {
                            method: "POST",
                            contentType: "application/json;",
                            data: JSON.stringify([{
                                    severity: "ERROR",
                                    data: {
                                        username: response.username,
                                        errorCode: response.errorCode,
                                        errorText: response.errorText,
                                        playerMessage: response.playerMessage
                                    }
                            }])
                        });
                    } else {
                        doRequestTemporaryToken(that);
                    }

                } else {
                    Logger.log("Invalid response, response is " + response);
                }
            };
        };

        ImsLogin.prototype.calloutChangePassword = function() {
            var that = this;
            return function(response) {
                window.mobile.offer.toggleLoader(false);
                if (response) {
                    if (response.errorCode) {
                        Logger.log("PT Change password failed, error:" + response.errorCode + " errorText:" + response.errorText + " playerMessage:" + response.playerMessage);
                        var errorMessage = response.playerMessage;
                        switch (response.errorCode) {
                            case 2:
                                errorMessage = that._opts.changePasswordPopup.i18n.ERROR_NEW_PASSWORD_INVALID;
                                break;
                            case 105:
                                errorMessage = that._opts.changePasswordPopup.i18n.ERROR_NEW_PASSWORD_EMPTY;
                                break;
                            case 149:
                                errorMessage = that._opts.changePasswordPopup.i18n.ERROR_NEW_PASSWORD_PREVIOUSLY_USED;
                                break;
                            case 152:
                                errorMessage = that._opts.changePasswordPopup.i18n.ERROR_NEW_PASSWORD_SAME_AS_CURRENT;
                                break;
                        }
                        showChangePasswordPopup(that, errorMessage);
                    } else {
                        doRequestTemporaryToken(that);
                    }
                } else {
                    Logger.log("Invalid response, response is " + response);
                }
            };
        };

        module.exports = function(loginInstance, opts) {
            return new ImsLogin(loginInstance, opts);
        };

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/playtech/ims-logout.js */
(function(modulr, $) {
    "use strict";

    modulr.define("playtech.ims-logout", function(require, exports, module) {

        var ImsCommons = require("playtech.ims-commons");
        var Logger = require("logger")("playtech.ims-logout");

      var REMEMBER_ME_USERNAME_KEY = "username_rememberMe";
        function doLogout(imsLogoutInstance) {
            $(function() {
                if ($.cookie("ims-logout") === '1') {
                    iapiSetCallout(iapiCALLOUT_LOGOUT, imsLogoutInstance.calloutLogout());
                    var allSessions = transformAllSession(imsLogoutInstance._allSession);

                    var rememberMeUsername = $.cookie(REMEMBER_ME_USERNAME_KEY);

                    var invalidateRememberMe = !(rememberMeUsername);

                    iapiLogout(allSessions, ImsCommons.REAL_MODE, imsLogoutInstance._behaviour, invalidateRememberMe);
                    $.removeCookie("ims-logout", {path: "/"});
                    //apiGw cookie
                    const match = window.location.hostname.match(/\.(\w+\.[a-z]+)$/);
                    if (match) {
                      $.removeCookie("ims-logout", {path: "/", domain: match[0]});
                      $.removeCookiePrivateDomain("ims-logout", {path: "/"});
                    }
                    $.removeCookie("responsible-gaming-seen", {path: "/"});
                    $.removeCookie("RGL_MODAL_SEEN", {path: "/", domain: getDomain()});

                  if (rememberMeUsername) {
                    window.location.reload();
                    }

                }
                if($.cookie("auth_token_exists") === 'true') {
                  $.removeCookie("auth_token_exists", {path: "/"});
                }
            });
        }

      function doLogoutLoginManager(imsLogoutInstance) {
        $(function() {
          if ($.cookie("ims-logout") === '1') {
            iapiSetCallout(iapiCALLOUT_LOGOUT, imsLogoutInstance.calloutLogout());
            var allSessions = transformAllSession(imsLogoutInstance._allSession);

            $.removeCookie("ims-logout", {path: "/"});
            //apiGw cookie
            const match = window.location.hostname.match(/\.(\w+\.[a-z]+)$/);
            if (match) {
              $.removeCookie("ims-logout", {path: "/", domain: match[0]});
              $.removeCookiePrivateDomain("ims-logout", {path: "/"});
            }
            $.removeCookie("responsible-gaming-seen", {path: "/"});
            $.removeCookie("RGL_MODAL_SEEN", {path: "/", domain: getDomain()});

          }
          if($.cookie("auth_token_exists") === 'true') {
            $.removeCookie("auth_token_exists", {path: "/"});
          }


          iapiLogout(allSessions, ImsCommons.REAL_MODE, imsLogoutInstance._behaviour, 0);
        });
      }

        function transformAllSession(zeroOrFalse) {
          switch (zeroOrFalse){
            case "false":
              var allSession = false;
              break;
            case "true":
              var allSession = true;
              break;
            case "0":
              var allSession = 0;
              break;
            default: var allSession = 1;
          }
          return allSession;
        }

        function ImsLogout(opts) {
            opts = opts || {};
            this._systemId = opts.systemId || "";
            this._locale = opts.locale || ImsCommons.LANGUAGE;
            this._behaviour = opts.behaviour;
            this._allSession = opts.allSession;
            this._loginManagerEnabled= opts.loginManagerEnabled || false
            this._opts = opts;
            ImsCommons.setCommonParams(this._systemId);

            if(this._loginManagerEnabled && window.AuthApi) {
              doLogoutLoginManager(this);
            }
            else {
              doLogout(this);
            }
        }

        function getDomain() {
          var hostname = location.hostname;
          var parts = hostname.split('.');
          if (parts.length > 2) {
            parts = parts.slice(-2);
          }
          var domain = parts.join('.');
          return '.'.concat(domain);
        };

        ImsLogout.prototype.calloutLogout = function() {
            var that = this;
            return function(response) {
                if (response.errorCode) {
                    Logger.log("PT logout failed, error:" + response.errorCode + " errorText:" + response.errorText + " playerMessage:" + response.playerMessage);
                } else {
                    Logger.log("PT logout success");
                    if (typeof that._opts.success === "function") {
                        that._opts.success();
                    }
                    window.dispatchEvent(new CustomEvent("yellowAI:logout"));
                }

              if('loginManagerEnabled' in that._opts && that._opts.loginManagerEnabled &&
                window.AuthApi)
              {
                window.AuthApi.sso();
              }
            }
        };

        module.exports = function(opts) {
            return new ImsLogout(opts);
        };

    });

})(modulr, $);

;
/* assets/src/scripts/fortuna/playtech/ims-sso.js */
(function(modulr, $) {
    "use strict";

    modulr.define("playtech.ims-sso", function(require, exports, module) {

        var ImsCommons = require("playtech.ims-commons");
        var Logger = require("logger")("playtech.ims-sso");
        var SSO_REDIRECT_APIGW = "sso_redirect_apigw";
        const REMEMBER_ME_USERNAME_KEY = "username_rememberMe";

        function doSso(imsSsoInstance) {


          $(function() {
                doGetLoggedInPlayer(imsSsoInstance);
            });
        }

        function doGetLoggedInPlayer(imsSsoInstance) {
          iapiSetCallout(iapiCALLOUT_GETLOGGEDINPLAYER, imsSsoInstance.calloutGetLoggedInPlayer());
          iapiGetLoggedInPlayer(ImsCommons.REAL_MODE);
        }

        function doRequestTemporaryToken(imsSsoInstance) {
          iapiSetCallout(iapiCALLOUT_TEMPORARYTOKEN, imsSsoInstance.calloutRequestTemporaryToken());
          iapiRequestTemporaryToken(ImsCommons.REAL_MODE, imsSsoInstance._systemId, ImsCommons.SERVICE_TYPE);
        }

        function doFtnLogin(imsSsoInstance, sessionToken) {
          ImsCommons.doFtnLogin(imsSsoInstance.loginName, sessionToken);
        }

        function doRememberMeLogin(rememberMeUsername, imsSsoInstance) {
          window.mobile.LoginManager.mount();
          const rememberMeLogin = 1;
          const realMode = 1;
          iapiSetCallout(iapiCALLOUT_LOGIN, imsSsoInstance.calloutRememberMeLogin());
          if (window.LoginManager) {
            window.LoginManager.setDeviceID();
          }
          iapiLogin(rememberMeUsername, "", realMode, imsSsoInstance._locale, rememberMeLogin);
        }

        function getRefererBeforeLogin() {
          const url = new URL(window.location.href);
          const referer = url.searchParams.get('referer');
          const makeSSO = url.searchParams.get('makeSSO') === 'true';

          return referer && makeSSO ? encodeURIComponent(referer) : encodeURIComponent(url.href);
        }

        function ImsSso(opts) {
            opts = opts || {};
            this._systemId = opts.systemId || "";
            this._locale = opts.locale || ImsCommons.LANGUAGE;
            this._behaviour = opts.behaviour;
            this._allSession = opts.allSession;
            this._openLoginDialog = opts.openLoginDialog || false;
            this._loginManagerEnabled = opts.loginManagerEnabled || false;
            ImsCommons.setCommonParams(this._systemId);
            if(this._loginManagerEnabled && window.AuthApi) {
              window.AuthApi.sso();
            }
            else {
                doSso(this);
            }
        }

        ImsSso.prototype.calloutGetLoggedInPlayer = function() {
            var that = this;
            var rememberMeUsername = $.cookie(REMEMBER_ME_USERNAME_KEY);
            return function(response) {
                if (response) {
                    if (response.errorCode) {
                      logDetails(response, "GetLoggedInPlayer", "ErrorCode");
                      Logger.log("PT SSO failed, error:" + response.errorCode + " errorText:" + response.errorText + " playerMessage:" + response.playerMessage);
                      openLoginDialog(that);
                    } else {
                        if (response.cookieExists && response.cookieExists !== "0") {
                            if (!response.username) {
                                logDetails(response, "GetLoggedInPlayer", "EmptyUsername");
                                Logger.log("PT SSO failed, response OK but username is empty");
                                openLoginDialog(that);
                            } else {
                                window.mobile.offer.toggleLoader(true);
                                that.loginName = response.username;
                                doRequestTemporaryToken(that);
                              }
                        } else {

                          if(rememberMeUsername) {
                            doRememberMeLogin(rememberMeUsername, that);
                          } else {
                            openLoginDialog(that);
                          }
                        }
                    }
                } else {
                    openLoginDialog(that);
                    Logger.log("Invalid response, response is " + response);
                }
            };
        };

        ImsSso.prototype.calloutRequestTemporaryToken = function() {
            var that = this;
            return function(response) {
                if (response) {
                    if (response.errorCode) {
                      logDetails(response, "GetTemporaryToken", "ErrorCode");
                      Logger.log("PT SSO failed, error:" + response.errorCode + " errorText:" + response.errorText + " playerMessage:" + response.playerMessage);
                      window.mobile.offer.toggleLoader(false);
                      if (response.errorCode === 104 ) {
                        var rememberMeUsername = $.cookie(REMEMBER_ME_USERNAME_KEY);
                        if (rememberMeUsername) {
                          doRememberMeLogin(rememberMeUsername, that);
                        }
                      }
                      openLoginDialog(that);
                    } else {
                      const expiryDate = new Date();
                      // the cookie should expire after 10 seconds
                      expiryDate.setTime(expiryDate.getTime() + (10 * 1000));
                      $.cookie(SSO_REDIRECT_APIGW, getRefererBeforeLogin(), {path: "/", domain: getDomain(), expires: expiryDate.toUTCString()});
                      doFtnLogin(that, response.sessionToken.sessionToken);
                    }
                } else {
                    Logger.log("Invalid response, response is " + response);
                    window.mobile.offer.toggleLoader(false);
                }
            };
        };

      ImsSso.prototype.calloutRememberMeLogin = function() {
        var that = this;
        return function (response) {
          $.cookie("18plusShownRememberMe", "false", { path: "/", domain: getDomain() });
          if (response.errorCode) {
            logDetails(response, "RememberMeLogin", "FailedLogin");
            $.removeCookie(REMEMBER_ME_USERNAME_KEY, {domain: getDomain()});
            window.mobile.offer.toggleLoader(false);
            openLoginDialog(that);
            window.dispatchEvent(new CustomEvent('FTN_notificationSnackRequired', {}));
          } else if (
            typeof response.sessionValidationData !== 'undefined' &&
            typeof response.sessionValidationData.SessionValidationBy2FAData !== 'undefined') {
            window.dispatchEvent(new CustomEvent('FTN_LoginValidationRequired', {
              detail: response.sessionValidationData
            }));
            window.dispatchEvent(new CustomEvent('FTN_notificationSnackRequired', {}));
            window.mobile.offer.toggleLoader(false);
          } else {
            var username = response.username.username;
            if (username) {
              that.loginName = username;
              document.cookie = "RGL_AFTERLOGIN = true; path=/; max-age = 20; domain=;" + getDomain();
              doRequestTemporaryToken(that);
            }
          }
        };
      };

      function logDetails(response, calloutFunction, additionalInfo) {
        var rememberMeUsername = $.cookie(REMEMBER_ME_USERNAME_KEY);
        if (rememberMeUsername || response.username) {
        $.ftnAjax("/restapi/logging", {
          method: "POST",
          contentType: "application/json;",
          data: JSON.stringify([{
            severity: "ERROR",
            data: {
              username: response.username,
              errorCode: response.errorCode,
              errorText: response.errorText,
              rememberMeUsername: rememberMeUsername,
              callout: calloutFunction,
              additionalInfo: additionalInfo
            }
          }])
        });
        }
      }
      function openLoginDialog(imsSsoInstance) {
        if (!imsSsoInstance._openLoginDialog) return;

        window.addEventListener('message', function (event) {
          if(event.data.type === 'FTN_LoginDialogMounted'){
            window.postMessage({type: 'FTN_LoginRequired', payload: {errorMessage: "$errorMessage"}}, '*');
          }
        });
        window.postMessage({type: 'FTN_LoginRequired', payload: {errorMessage: "$errorMessage"}}, '*');
      }

      function getDomain() {
          var hostname = location.hostname;
          var parts = hostname.split('.');
          if (parts.length > 2) {
              parts = parts.slice(-2);
          }
          var domain = parts.join('.');
          return '.'.concat(domain);
      };

      module.exports = function(opts) {
            return new ImsSso(opts);
        }

    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/playtech/ims-waiting-messages/displayer/ims-waiting-messages-displayer-bonus-message.js */
(function(modulr) {
    "use strict";

    modulr.define("playtech.ims-waiting-messages.displayer-bonus-message", function(require, exports, module) {

        var Logger = require("logger")(module.id);
        var Bonuses = require("bonuses");

        function fetchBonus(bonusData, options, imsWaitingMessagesInstance) {
            if (options && options.fetchDetailLink) {
                var url = $.Link(options.fetchDetailLink).ajaxify().getUrl();
                $.ftnAjax(url, {
                    data: {
                        "bonusId": bonusData.customerBonusId
                    },
                    beforeSend: function() {
                        window.mobile.offer.toggleLoader(true);
                    },
                    success: function(data, status, xhr) {
                        try {
                            displayBonusPopup(JSON.parse(data), bonusData, options, imsWaitingMessagesInstance);
                        } catch (e) {
                            Logger.log("Failed to parse bonus details because of error: " + JSON.stringify(e));
                            imsWaitingMessagesInstance.displayNextMessage();
                        }
                    },
                    error: function(xhr, status, responseText) {
                        handleFetchBonusError(xhr, bonusData, imsWaitingMessagesInstance);
                    },
                    complete: function() {
                        window.mobile.offer.toggleLoader(false);
                    }
                });
            } else {
                Logger.log("Failed to load bonus details for " + JSON.stringify(bonusData) + " because no fetchUlr is defined");
                imsWaitingMessagesInstance.displayNextMessage();
            }

        }

        function handleFetchBonusError(xhr, bonusData, imsWaitingMessagesInstance) {
            Logger.log("Failed to load bonus details for " + JSON.stringify(bonusData) + " because of xhr response " + JSON.stringify(xhr));
            imsWaitingMessagesInstance.displayNextMessage();
        }

        function displayBonusPopup(bonusDetails, bonusData, options, imsWaitingMessagesInstance) {
            Bonuses.showBonusPopup({offeredAndDirectlyActivated: [bonusDetails]}, options, {
                show: function() {
                    imsWaitingMessagesInstance.displayNextMessage();
                }
            });
        }

        function display(message, imsWaitingMessagesInstance) {
            var options = (imsWaitingMessagesInstance && imsWaitingMessagesInstance._opts && imsWaitingMessagesInstance._opts.staticData && imsWaitingMessagesInstance._opts.staticData.templates &&
                    imsWaitingMessagesInstance._opts.staticData.templates.bonusMessage) || {};
            var bonusData = message.strippedMessage;
            if (typeof bonusData === "string") {
                try {
                    bonusData = JSON.parse(message.strippedMessage);
                } catch (e) {}
            }
            if (bonusData) {
                fetchBonus(bonusData, options, imsWaitingMessagesInstance);
            } else {
                Logger.log("There are unparsable strippedMessage data in ims waiting bonus message (" + message + "). Skipping to next message...")
                imsWaitingMessagesInstance.displayNextMessage();
            }
        };

        module.exports = {
            display: display
        };

    });

})(modulr);
;
/* assets/src/scripts/fortuna/playtech/ims-waiting-messages/displayer/ims-waiting-messages-displayer-default.js */
(function(modulr) {
    "use strict";
    
    modulr.define("playtech.ims-waiting-messages.displayer-default", function(require, exports, module) {
        
        module.exports = {
            display: function(message, imsWaitingMessagesInstance) {
                window.mobile.dialogs.dialogAlert(message.strippedMessage, {
                    okCallback: function() {
                        imsWaitingMessagesInstance.displayNextMessage();
                    }
                });
            }
        };

    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/playtech/ims-waiting-messages/displayer/ims-waiting-messages-displayer-gdpr-message.js */
(function(modulr) {
    "use strict";

    modulr.define("playtech.ims-waiting-messages.displayer-gdpr-message", function(require, exports, module) {

        function processMessage(message, errorText, options) {
            return (message || "") +
                (errorText ? '<div class="error-message margin-left-0 margin-right-0 margin-bottom-20">' + errorText + '</div>' : "") +
                (options && options.checkbox ? options.checkbox : "");
        };

        function doAjaxRequest(message, options, imsWaitingMessagesInstance, data) {
            if (options.updateUrl) {
                var url = $.Link(options.updateUrl).ajaxify().getUrl();
                $.ftnAjax(url, {
                    method: "post",
                    data: data,
                    beforeSend: function() {
                        window.mobile.offer.toggleLoader(true);
                    },
                    success: function(data, status, xhr) {
                        imsWaitingMessagesInstance.displayNextMessage();
                    },
                    error: function(xhr, status, responseText) {
                        display(message, imsWaitingMessagesInstance, responseText);
                    },
                    complete: function() {
                        window.mobile.offer.toggleLoader(false);
                    }
                });
            }
        };

        function display(message, imsWaitingMessagesInstance, errorText) {
            var options = (imsWaitingMessagesInstance && imsWaitingMessagesInstance._opts && imsWaitingMessagesInstance._opts.staticData && imsWaitingMessagesInstance._opts.staticData.templates &&
                    imsWaitingMessagesInstance._opts.staticData.templates.gdprMessage) || {};
            window.mobile.dialogs.dialogConfirm(processMessage(message.strippedMessage, errorText, options), {
                okButtonLabel: options.i18n.ok,
                cancelButtonLabel: options.i18n.cancel,
                okCallback: function() {
                    doAjaxRequest(message, options, imsWaitingMessagesInstance, {
                        "userChoice": "agree"
                    });
                },
                cancelCallback: function() {
                    doAjaxRequest(message, options, imsWaitingMessagesInstance, {
                        "userChoice": "cancel",
                        "notShowNextTime": $("#consents-show-never-more")[0].checked
                    });
                }
            });
        };

        module.exports = {
            display: display
        };

    });

})(modulr);
;
/* assets/src/scripts/fortuna/playtech/ims-waiting-messages/ims-waiting-messages-commons.js */
(function(modulr) {
    "use strict";
    
    modulr.define("playtech.ims-waiting-messages.commons", function(require, exports, module) {
        
        module.exports = {
            stripMessage: function(message) {
                if (message &&  typeof message.replace == "function") {
                    return message
                      .replace(/[\r\n\t]/g,"")    // Remove whitespace
                      .replace(/<head[^>]*>.*?<\/head>/i,"")   // Remove head element
                      .replace(/<\/?(html|body)[^>]*>/gi,"")  // Remove html and body tags
                      .replace(/<script[^>]*>.*?<\/script>/gi,"");  // Remove script elements
                }
                return message;
            }
        };

    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/playtech/ims-waiting-messages/ims-waiting-messages-processor.js */
(function(modulr) {
    "use strict";
    
    modulr.define("playtech.ims-waiting-messages.processor", function(require, exports, module) {

        var BONUS_TEMPLATE = "bonus-message";
        
        var ACTIONS = {
            "PlayerActionShowLoginMessage": 999
        };
        
        var TEMPLATES = {
            "GDPR_Message": 999
        };
        
        function getActionsOrder(action) {
            return ACTIONS[action] || 0;
        };
        
        function getTemplatesOrder(templateId) {
            return TEMPLATES[templateId] || 0;
        };
        
        function messageComparator(m1, m2) {
            var diff = getActionsOrder(m2["action"]) - getActionsOrder(m1["action"]);
            if (diff != 0) return diff;
            return getTemplatesOrder(m2["templateId"]) - getTemplatesOrder(m1["templateId"]);
        };
        
        function quessTemplateIdFromMessage(message) {
            if (message) {
                if (message.customerBonusId || message.indexOf("\"customerBonusId\"") > -1) {
                    return BONUS_TEMPLATE;
                }
            }
            return null;
        };

        function isBonusTemplate(templateId){
            return templateId && templateId === "sbBonusMessage";
        }
        
        module.exports = function(actions) {
            var messages = [];
            for (var a in actions) {
                for (var i = 0; i < actions[a].length; i++) {
                    var m = {};
                    for (var attr in actions[a][i]) {
                        m[attr] = actions[a][i][attr];
                    }
                    if (!m['templateId']) {
                        m['templateId'] = quessTemplateIdFromMessage(actions[a][i]["message"]);
                    } else {
                        var templateId = m['templateId'];
                        if (isBonusTemplate(templateId)) {
                            m['templateId'] = BONUS_TEMPLATE;
                        }
                    }
                    m["strippedMessage"] = require("playtech.ims-waiting-messages.commons").stripMessage(actions[a][i]["message"]);
                    m["action"] = a;
                    messages.push(m);
                }
            }
            messages.sort(messageComparator);
            return messages;
        };

    });
    
})(modulr);
;
/* assets/src/scripts/fortuna/playtech/ims-waiting-messages/ims-waiting-messages.js */
(function(modulr) {
    "use strict";

    modulr.define("playtech.ims-waiting-messages", function(require, exports, module) {
        
        var ImsCommons = require("playtech.ims-commons");
        var Logger = require("logger")("playtech.ims-waiting-messages");
        
        function doGetWaitingMessages(imsWaitingMessagesInstance) {
            $(function() {
                getAndDisplay(imsWaitingMessagesInstance);
            });
        }
        
        function getAndDisplay(imsWaitingMessagesInstance) {
            iapiSetCallout(iapiCALLOUT_MESSAGES, imsWaitingMessagesInstance.calloutGetWaitingMessages());
            iapiGetWaitingMessages(imsWaitingMessagesInstance._messageTypes.join(","), imsWaitingMessagesInstance._realMode);
        }
        
        function processMessages(imsWaitingMessagesInstance, actions) {
            imsWaitingMessagesInstance._messages = require("playtech.ims-waiting-messages.processor")(actions);
            imsWaitingMessagesInstance.displayNextMessage();
        }
        
        function displayMessage(imsWaitingMessagesInstance, message) {
            var messageDisplayer = null;
            try {
              var templateId = message.templateId.toLowerCase().replace(/[^a-z0-9]/g, "-");
              // Skip displaying bonus-message if isNewBonusPopup is true
              if (window.features.isNewBonusPopup && templateId === "bonus-message") {
                return;
              } else {

                  if (templateId.includes("gdpr")) {
                    templateId = "gdpr-message"
                  }
                  messageDisplayer = require("playtech.ims-waiting-messages.displayer-" + templateId);
              }
            } catch (e) {
                messageDisplayer = require("playtech.ims-waiting-messages.displayer-default");
            }
            if (messageDisplayer && typeof messageDisplayer.display === "function") {
                messageDisplayer.display(message, imsWaitingMessagesInstance);
            } else {
                displayNextMessage(imsWaitingMessagesInstance);
            }
        }
        
        function ImsWaitingMessages(opts, afterLoginPopupsInstance) {
            this._opts = opts || {};
            this._messageTypes = this._opts.messageTypes || [];
            this._realMode = this._opts.realMode || ImsCommons.REAL_MODE;
            this._afterLoginPopupsInstance = afterLoginPopupsInstance;
            require("playtech.ims-commons").setCommonParams();
            doGetWaitingMessages(this);
        }
        
        ImsWaitingMessages.prototype.calloutGetWaitingMessages = function() {
            var that = this;
            return function(response) {

                if (response && response.actions) {
                    processMessages(that, response.actions);
                } else {
                    Logger.log("Can not process waiting messages because data does not contain actions object.");
                    that._afterLoginPopupsInstance.show();
                }
            };
        };
        
        ImsWaitingMessages.prototype.displayNextMessage = function() {
            var message = this._messages.shift();
            if (message) {
                displayMessage(this, message);
            } else {
                this._afterLoginPopupsInstance.show();
            }
        };
        
        module.exports = function(opts, afterLoginPopupsInstance) {
            return new ImsWaitingMessages(opts, afterLoginPopupsInstance);
        };
        
    });
    

})(modulr);
;
/* assets/src/scripts/fortuna/playtech/pas-sso-connector.js */
(function(modulr, $) {
    "use strict";

    modulr.define("playtech.pas-sso-connector", function(require, exports, module) {

        var Logger = require("logger")("playtech-pas-sso-connector");

        var CLIENT_TYPE = "sportsbook";
        var CLIENT_PLATFORM = "mobile";
        var LANGUAGE = "cs";

        function doLogin() {
            var login = $.cookie("pt-pid");
            var password = $.cookie("pt-tmp");
            if (login && password) {
                iapiSetCallout("GetLoggedInPlayer", function(response) {
                    if (!response.username) {
                        iapiSetCallout("Login", function(response) {
                            switch (response.errorCode) {
                                case 0:
                                case "0":
                                    // Nothing to do, login successful
                                    break;
                                default:
                                    $.ftnAjax("/restapi/logging", {
                                        method: "POST",
                                        contentType: "application/json;",
                                        data: JSON.stringify([{
                                              severity: "ERROR",
                                              data: {
                                                  errorCode: response.errorCode,
                                                  errorText: response.errorText,
                                                  playerMessage: response.playerMessage
                                            }
                                        }])
                                    });
                                    Logger.log("Error occured during login to PAS.\nErrorcode: " + response.errorCode + "\nErrorText: " + response.errorText + "\nPlayerMessage: " + response.playerMessage);
                            }
                        });
                        iapiSetClientType(CLIENT_TYPE);
                        iapiSetClientPlatform(CLIENT_PLATFORM);
                        iapiLogin(login, password, 1, LANGUAGE);
                        $.cookie("ims-logout", '1', {path: "/"});
                    } else {
                        Logger.log(response);
                    }
                });
                iapiGetLoggedInPlayer();
            }
        }

        module.exports = {
            doLogin: doLogin
        }
    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/ticket/add-bet-buttons.js */
(function($) {

    $(function() {
        $(document.body).delegate("click", ".bet-link", function(evt) {
            var ticket = window.mobile.ticket(true);
            if (ticket) {
                evt.preventDefault();
                ticket.handleBetLink(evt.target);
            }
        });
    });

})($);
;
/* assets/src/scripts/fortuna/ticket/early-cashout.js */
(function(modulr, $) {
    "use strict";

    modulr.define("early-cashout", function(require, exports, module) {

        var AUTHORIZATION_INTERVAL = 5000;
        var PERIODICAL_REFRESH_SETTINGS = {
            0: 5000,
        	20: {
                LIVE: 5000,
                MAIN: 30000
            },
            21: 5000,
            22: 5000,
            23: 5000
        };

        var AlertDialog = window.mobile.dialogs.dialogAlert;
        var ConfirmDialog = window.mobile.dialogs.dialogConfirm;

        function handleResponse(data, instance) {
            var xml = $($.parseXml(data));
            var root = $("early-cashout", xml);
            switch (root.attr("mode")) {
                case "errors":
                    handleModeErrors(xml, instance);
                    break;
                case "confirm":
                    handleModeConfirm(xml, instance);
                    break;
                case "result":
                    handleModeResult(xml, instance);
                    break;
                default:
                    // Nothing
            }
        };

        function handleModeErrors(xml, instance) {
            window.mobile.offer.toggleLoader(false);
            var error = $("error", xml);
            AlertDialog(error.html(), {
                titleLabel: instance.i18n.DIALOG_ERROR_TITLE,
                okButtonLabel: instance.i18n.DIALOG_CLOSE_BUTTON,
                okCallback: function() {
                	refreshTicketContent(instance, true);
                },
                specialClass: "early-cashout"
            });
        };

        function handleModeConfirm(xml, instance) {
        	window.mobile.offer.toggleLoader(false);
            var title = $("title", xml).html();
            var content = $("content", xml);
            if (content.length) {
                content = $.parseCData(content[0]);
                ConfirmDialog(content, {
                    titleLabel: title,
                    okButtonLabel: instance.i18n.DIALOG_CASH_BUTTON,
                    cancelButtonLabel: instance.i18n.DIALOG_CANCEL_BUTTON,
                    okCallback: function() {
                      confirmAndHandle(xml, instance);
                    },
                    specialClass: "early-cashout"
                });
            }
        };

        function confirmAndHandle(xml, instance) {
            var link = $("link", xml);
            if (link.length) {
                link = $.parseCData(link[0]);
                if (link) {
                    link = $.Link(link).ajaxify();
                    var acceptChangesCheckbox = document.getElementById("accept-changes");
                    if (acceptChangesCheckbox && acceptChangesCheckbox.checked) {
                        link.appendOrReplaceParam("accept_changes", "true");
                    }
                    var data = link.params;
                    link = link.removeAllParams();
                    $.ftnAjax(link.getUrl(), {
                        method: "post",
                        data: data,
                        beforeSend: function() {
                            window.mobile.offer.toggleLoader(true);
                        },
                        success: function(data) {
                            handleResponse(data, instance);
                        },
                        error: function() {
                            AlertDialog(instance.i18n.GLOBAL_ERROR);
                        }
                    });
                }
            }
        };

        function handleModeResult(xml, instance) {
            var phase = $("phase", xml).html();
            var title = $("title", xml).html();
            var message = $("message", xml);
            if (message.length) {
                message = $.parseCData(message[0]);
            }
            switch (phase) {
                case "ACCEPTED":
                    onStateAccepted(instance, message, title);
                    break;
                case "REJECTED":
                    onStateRejected(instance, message, title);
                    break;
                case "AUTHORIZATION":
                case "UNRESOLVED":
                case "DELAYING":
                    window.setTimeout(function() {
                        onStateAuthorization(instance, xml);
                    }, AUTHORIZATION_INTERVAL);
                    break;
                default:
                    onStateUnknown(instance, message, title, phase);
            }
        };

        function onStateAccepted(instance, message, title) {
            if (message) {
                window.mobile.offer.toggleLoader(false);
                AlertDialog(message, {
                    titleLabel: title,
                    okCallback: function() {
                        refreshTicketContent(instance, true);
                    },
                    specialClass: "early-cashout",
                    okButtonLabel: instance.i18n.DIALOG_CLOSE_BUTTON
                });
            } else {
                refreshTicketContent(instance, true);
            }
        };

        function onStateRejected(instance, message, title) {
            if (message) {
                window.mobile.offer.toggleLoader(false);
                AlertDialog(message, {
                    titleLabel: title,
                    okCallback: function() {
                        refreshTicketContent(instance, true);
                    },
                    specialClass: "early-cashout",
                    okButtonLabel: instance.i18n.DIALOG_CLOSE_BUTTON
                });
            } else {
                refreshTicketContent(instance, true);
            }
        };

        function onStateAuthorization(instance, xml) {
            var link = $("link", xml);
            if (link.length) {
                link = $.parseCData(link[0]);
            }
            if (link) {
                link = $.Link(link);
                var data = link.params;
                link.removeAllParams();
                $.ftnAjax(link.ajaxify().getUrl(), {
                    method: "post",
                    data: data,
                    success: function(data) {
                        handleResponse(data, instance);
                    },
                    error: function() {
                        window.mobile.offer.toggleLoader(false);
                        AlertDialog(instance.i18n.GLOBAL_ERROR);

                    }
                });
            }
        };

        function onStateUnknown(instance, message, title, phase) {
            if (message) {
                window.mobile.offer.toggleLoader(false);
                AlertDialog(message, {
                    titleLabel: title,
                    okButtonLabel: instance.i18n.DIALOG_CLOSE_BUTTON,
                    okCallback: function() {
                        refreshTicketContent(instance, true);
                    }
                });
            } else {
                refreshTicketContent(instance, true);
            }
        };

        function runPeriodicalRefresh(instance) {
            if (instance.status in PERIODICAL_REFRESH_SETTINGS) {
                var value = PERIODICAL_REFRESH_SETTINGS[instance.status];
                if (typeof value == "object") {
                    value = value[instance.kind];
                }
                if (value) {
                    window.setTimeout(function() {
                        refreshTicketContent(instance, false);
                    }, value);
                }
            }
        };

        function refreshTicketContent(instance, showLoaders) {
            $.ftnAjax($.Link(window.location.href).ajaxify().getUrl(), {
                beforeSend: function() {
                    showLoaders && window.mobile.offer.toggleLoader(true);
                },
                success: function(data) {
                    setTicketContentAndUpdateInstance(data, instance);
                    runPeriodicalRefresh(instance);
                },
                error: function() {
                    runPeriodicalRefresh(instance);
                },
                complete: function() {
                    showLoaders && window.mobile.offer.toggleLoader(false);
                }
            });
        };

        function setTicketContentAndUpdateInstance(data, instance) {
            var xml = $.parseXml(data);
            var status = $("selling-status", xml).html();
            var ticket = $("content", xml);
            instance.status = parseInt(status);
            if (ticket.length) {
                ticket = $.parseCData(ticket[0]);
                if (ticket) {
                    $(".content-box.ticket-preview > .content").html(ticket);
                }
            }
        };

        function EarlyCashout(status, kind, i18n) {
            this.status = status;
            this.kind = kind;
            this.i18n = i18n;
            this.initialize();
        };

        EarlyCashout.prototype.initialize = function() {
            var that = this;
            $(".content-box.ticket-preview > .content").delegate("click", "#early-cashout-button", function(evt) {
            	evt.preventDefault();
                that.requestEarlyCashout(this);
            });
            runPeriodicalRefresh(this);
        };


        EarlyCashout.prototype.requestEarlyCashout = function(button) {
            var that = this;
            var link = button.href;
             if (link) {
                 link = $.Link(link);
                 $.ftnAjax(link.ajaxify().getUrl(), {
                     beforeSend: function() {
                         window.mobile.offer.toggleLoader(true);
                     },
                     success: function(data) {
                         handleResponse(data, that);
                     },
                     error: function() {
                         AlertDialog(that.i18n.GLOBAL_ERROR);
                     },
                     complete: function() {
                         window.mobile.offer.toggleLoader(false);
                     }
                 });
             }
        };

        EarlyCashout.prototype._refresh = function() {
            refreshTicketContent(this);
        };

        module.exports = function() {
            return new EarlyCashout(arguments[0], arguments[1], arguments[2], arguments[3]);
        };
    });

})(modulr, $);
;
/* assets/src/scripts/fortuna/ticket/ticket-analytics.js */
(function($) {
    "use strict";

    var Analytics = modulr.require("analytics");

    $(function() {
        $(document).on("ticket:main:accepted", function() {
            Analytics.trackEvent("Ticket", "Accepted");
        });
        $(document).on("ticket:live:accepted", function() {
            Analytics.trackEvent("TicketLIVE", "Accepted");
        });
    });

})($);
;
/* assets/src/scripts/fortuna/ticket/ticket-preview.js */
(function($, modulr) {
    "use strict";

    modulr.define("ticket-preview", function(require, exports, module) {

        var SELECTOR_TICKET_PREVIEW = "#page main .ticket-preview";
        var SELECTOR_IMPORT_TICKET = ".import-ticket";
        var SELECTOR_SHOW_COMBINATIONS = ".show-combinations";

        var ID_COMBINATIONS_HOLDER = "ticket-combinations-list";

        var ToastDialog = window.mobile.dialogs.dialogToast;
        var AlertDialog = window.mobile.dialogs.dialogAlert;

        function TicketPreview() {
            this.initializeEvents();
        };

        TicketPreview.prototype.initializeEvents = function() {
            var that = this;
            var ticketPreview = $(SELECTOR_TICKET_PREVIEW);
            ticketPreview.delegate("click", SELECTOR_IMPORT_TICKET, function(evt) {
                evt.preventDefault();
                that.importTicket(evt.target)
            });
            ticketPreview.delegate("click", SELECTOR_SHOW_COMBINATIONS, function(evt) {
                evt.preventDefault();
                that.showCombinations();
            })
        };

        TicketPreview.prototype.importTicket = function(elm) {
            var url = elm.href;
            if (url) {
                $.ftnAjax($.Link(url).ajaxify().getUrl(), {
                    beforeSend: function() {
                        window.mobile.offer.toggleLoader(true);
                    },
                    success: function(data) {
                        var jsonData = JSON.parse(data);
                        ToastDialog(jsonData.message);
                    },
                    error: function() {
                        ToastDialog(elm.getAttribute("data-communication-error"));
                    },
                    complete: function() {
                        window.mobile.offer.toggleLoader(false);
                    }
                });
            }
        };

        TicketPreview.prototype.showCombinations = function() {
            var combinations = document.getElementById(ID_COMBINATIONS_HOLDER);
            if (combinations) {
                var title = combinations.getAttribute("data-title");
                var closeLabel = combinations.getAttribute("data-close-label");
                AlertDialog(combinations.innerHTML, {
                    titleLabel: title,
                    specialClass: "combinations-list with-results",
                    okButtonLabel: closeLabel
                });
            }
        };

        module.exports = function() {
            return new TicketPreview();
        };
    });

})($, modulr);
;
/* assets/src/scripts/fortuna/xtremepush/cp-ro/service-worker.js */
typeof importScripts === 'function' && importScripts(decodeURIComponent(
  location.search.substring(location.search.indexOf('ref=') + 4)
));
;
/* assets/src/scripts/fortuna/xtremepush/ftn-cz/service-worker.js */
typeof importScripts === 'function' && importScripts(decodeURIComponent(
  location.search.substring(location.search.indexOf('ref=') + 4)
));
;
/* assets/src/scripts/fortuna/xtremepush/ftn-pl/service-worker.js */
typeof importScripts === 'function' && importScripts(decodeURIComponent(
  location.search.substring(location.search.indexOf('ref=') + 4)
));
;
/* assets/src/scripts/fortuna/xtremepush/ftn-ro/service-worker.js */
typeof importScripts === 'function' && importScripts(decodeURIComponent(
  location.search.substring(location.search.indexOf('ref=') + 4)
));
;
/* assets/src/scripts/fortuna/xtremepush/ftn-sk/service-worker.js */
typeof importScripts === 'function' && importScripts(decodeURIComponent(
  location.search.substring(location.search.indexOf('ref=') + 4)
));
;
/* assets/src/scripts/fortuna/xtremepush/psk-hr/service-worker.js */
typeof importScripts === 'function' && importScripts(decodeURIComponent(
  location.search.substring(location.search.indexOf('ref=') + 4)
));
;
/* assets/src/scripts/fortuna/yellowAI/yellowAI-chat.js */
(function (modulr, $) {
  "use strict";

  modulr.define("yellowAI.chat", function(require, exports, module){

    const ImsCommons = require("playtech.ims-commons");
    const YellowAILoader = require("yellowAI.loader");
    const CLIENT_PLATFORM = "mobile";
    const CLIENT_TYPE = "sportsbook";
    var YAI_COOKIE = {
      logout: "yai_logout",
      token: "yai_token",
      expires: 10000
    };
    var country = '';
    var CHAT_ICON_LOADING_CLASS = "chat-icon-loading";
    var CHAT_ICONS_LIST = {
      inbound: document.getElementById("icon-chat-inbound"),
      outbound: document.getElementById("icon-chat-outbound"),
      dot: document.getElementById("icon-chat-dot"),
    }
    const ANONYMOUS_CONTEXT = "anonymous";
    const LOGIN_CONTEXT = "after_login";

    var userStatus = {
      loggedIn: false,
      hasJustLoggedIn: false,
      hasJustLoggedOut: false
    };

    var _systemId;

    var chatStatus = {
      hidden: true,
      hasUnreadMessages: false,
      initialized: false,
      toggling: false,
      doingLogout: false
    };

    var chatConfig = {};

    var ymConfig = {
      bot: '',
      host: '',
      hideChatButton: true,
      payload: {}
    };

    var botInitializedStatus = {
      afterAuth: 'after_authentication',
      afterClick: 'after_chat_icon_click',
      afterRefresh: 'after_web_page_refresh',
      afterLogout: 'after_logout'
    };

    function country_class() {
      CHAT_ICONS_LIST.inbound.classList.add(country);
      CHAT_ICONS_LIST.outbound.classList.add(country);
    }

    function chat_loading(loading) {
      if (loading) {
        CHAT_ICONS_LIST.inbound.classList.add(CHAT_ICON_LOADING_CLASS);
        CHAT_ICONS_LIST.outbound.classList.add(CHAT_ICON_LOADING_CLASS);
        CHAT_ICONS_LIST.dot.style.opacity = "0";
        return;
      }

      CHAT_ICONS_LIST.inbound.classList.remove(CHAT_ICON_LOADING_CLASS);
      CHAT_ICONS_LIST.outbound.classList.remove(CHAT_ICON_LOADING_CLASS);
      CHAT_ICONS_LIST.dot.style.opacity = "1";
    }

    function Chat(casinoName, site, systemId, host, bot, shouldInitialize){
      ymConfig.host = host;
      ymConfig.bot = bot;
      this.casinoName = casinoName;
      this.site = site;
      this.systemId = systemId;
      _systemId = systemId;
      this.init(shouldInitialize);
      country_class();
    }

    function registerMessageListener(){
      window.addEventListener("message", function (e) {

        if (userStatus.hasJustLoggedOut) {
          return;
        }

        try{
          if (e.data.indexOf("event_code") !== -1) {
            var data = JSON.parse(e.data);
            if(window.YellowMessengerPlugin){
              switch (data.event_code) {
                case "ym-bot-closed":
                  chatStatus.hidden = true;
                  if(chatStatus.initialized){
                    chatStatus.toggling = false;
                    window.YellowMessengerPlugin.hide();
                  }
                  chat_loading(false);
                  break;
                case "ym-bot-opened":
                  chatStatus.hidden = false;
                  chatStatus.hasUnreadMessages = false;
                  if(chatStatus.initialized) {
                    chatStatus.toggling = false;
                    window.YellowMessengerPlugin.show();
                    toggleChatVisibility();
                  }
                  chat_loading(false);
                  break;
                case "connected":
                  if(userStatus.loggedIn) {
                    handleUnreadMessages();
                  }
                  break;
                case "message-received":
                  if(chatStatus.hidden) {
                    chatStatus.hasUnreadMessages = true;
                    toggleChatVisibility();
                  }
                  break;
                case "create-ui":
                  chatStatus.initialized = true;
              }
            }
          }
        } catch (e) {}
      });
    }

    function calloutRequestTemporaryToken(botInitializedStatus){
      return function (response){
        if(response.errorCode) {
          console.log("Failed to get temporary token for chat login", response);
        } else {
          setLoggedInUserStatus(response.username, _systemId, response.sessionToken.sessionToken, botInitializedStatus);
          if(window.YellowMessengerPlugin) {
            window.YellowMessengerPlugin.init(ymConfig);
          } else {
            registerMessageListener();
            toggleChatVisibility();
            YellowAILoader.load(ymConfig);
            initClickHandlers();
          }
        }
      };
    }

    function setLoggedInUserStatus(username, systemId, sessionToken, botInitializedStatus){
      ymConfig.ymAuthenticationToken = username;
      ymConfig.payload = {
        "context": LOGIN_CONTEXT,
        "systemId": systemId,
        "clientPlatform":CLIENT_PLATFORM,
        "clientType":CLIENT_TYPE,
        "tempToken":sessionToken,
        "botInitialized": botInitializedStatus
      };
      setupLogoutEventListeners();
    }

    function setupLogoutEventListeners() {

      // Manual logout
      window.addEventListener("yellowAI:before_logout", function(e) {
        setupLogout();
      });

      // inApp logout notification
      window.addEventListener("message", function(event) {
        if (event.data.type === "FTNUserLoggedOut") {
          setupLogout();
        }
      });
    }

    function setupLogout() {
      userStatus.hasJustLoggedOut = true;
      window.YellowMessengerPlugin.hide();
      var expires = new Date(Date.now() + YAI_COOKIE.expires).toUTCString();
      document.cookie = YAI_COOKIE.logout + "=1; expires=" + expires + "; path=/";
      document.cookie = YAI_COOKIE.token + "=" + ymConfig.ymAuthenticationToken + "; expires=" + expires + "; path=/";
    }

    function afterLogoutCheck() {
      if ($.cookie(YAI_COOKIE.logout) === null) {
        return false;
      }
      handleLogout($.cookie(YAI_COOKIE.token));
      $.removeCookie(YAI_COOKIE.logout, {path: "/"})
      $.removeCookie(YAI_COOKIE.token, {path: "/"});

      return true;
    }

    function handleLogout(ymAuthenticationToken){
      chatStatus.doingLogout = true;
      ymConfig.ymAuthenticationToken = ymAuthenticationToken;
      ymConfig.payload = {
        "context": ANONYMOUS_CONTEXT,
        "botInitialized": botInitializedStatus.afterLogout
      };
      ymConfig.autoOpen = false;
      YellowAILoader.load(ymConfig);
      toggleChatVisibility();
      initClickHandlers();

    }

    function initClickHandlers() {
      var inbound = document.getElementById("chat-inbound");
      var outbound = document.getElementById("chat-outbound");

      registerClickHandler(inbound);
      registerClickHandler(outbound);
    }

    function registerClickHandler(el) {
      el.addEventListener('click', function () {
        if(!chatStatus.toggling) {
          chatStatus.toggling = true;
          chat_loading(true);
          if(userStatus.loggedIn) {
            if(window.YellowMessengerPlugin && ymConfig.payload.botInitialized !== botInitializedStatus.afterClick){
              chatStatus.initialized = false;
              configureBotInitialized(botInitializedStatus.afterClick);
              window.YellowMessengerPlugin.init(ymConfig);
            } else {
              window.YellowMessengerPlugin.toggleChat();
            }
          } else {
            if (window.YellowMessengerPlugin && chatStatus.doingLogout === false) {
              window.YellowMessengerPlugin.toggleChat();
            } else {
              loadAnonymousContext(true, botInitializedStatus.afterClick);
            }
          }
        }
      });
    }

    function configureBotInitialized(botInitializedStatus){
      ymConfig.autoOpen = true;
      if(userStatus.loggedIn){
        loadChatWithContext(botInitializedStatus);
      } else {
        setAnonymousPayload(botInitializedStatus);
      }
    }

    function setAnonymousPayload(botInitializedStatus){
      ymConfig.ymAuthenticationToken = Guid.newGuid();
      ymConfig.payload = {
        "context": ANONYMOUS_CONTEXT,
        "botInitialized": botInitializedStatus
      };
    }

    function loadChatWithContext(botInitializedStatus){
      if(userStatus.loggedIn){
        iapiSetCallout(iapiCALLOUT_TEMPORARYTOKEN, calloutRequestTemporaryToken(botInitializedStatus));
        iapiRequestTemporaryToken(ImsCommons.REAL_MODE, _systemId, ImsCommons.SERVICE_TYPE);
      } else {
        if (afterLogoutCheck() === false) {
          toggleChatVisibility();
          initClickHandlers();
        }
      }
    }

    function loadAnonymousContext(autoOpen, botInitializedStatus) {
      setAnonymousPayload(botInitializedStatus)
      ymConfig.autoOpen = autoOpen;
      registerMessageListener();
      if (chatStatus.doingLogout) {
        chatStatus.doingLogout = false;
        chatStatus.toggling = false;
        chat_loading(false);
        window.YellowMessengerPlugin.init(ymConfig);
        return;
      }
      YellowAILoader.load(ymConfig);
    }

    function handleUnreadMessages() {
      if (window.YellowMessengerPlugin === undefined) return;
      window.YellowMessengerPlugin
        .getUnreadMessagesCount()
        .then(function (result) {
          chatStatus.hasUnreadMessages = result.unreadCount > 0;
          toggleChatVisibility();
        })
        .catch(function(err) {
          console.log("Failed get unread chat msgs:", err);
        });
    }

    function toggleChatVisibility() {
      var inbound = document.getElementById("chat-inbound");
      var outbound = document.getElementById("chat-outbound");
      if (chatConfig.inboundChat && !chatStatus.hasUnreadMessages){
        inbound.classList.add("show");
        outbound.classList.remove("show");
      } else if(chatConfig.outboundChat && chatStatus.hasUnreadMessages){
        outbound.classList.add("show");
        inbound.classList.remove("show");
      }
    }

    function adjustForLottery(){
      var chatOut = document.getElementById("chat-outbound");
      var chatIn = document.getElementById("chat-inbound");
      var lotteryHeader = document.getElementsByClassName('sticky-layout__header')[0];

      if (lotteryHeader && chatOut && chatIn) {
        lotteryHeader.appendChild(chatOut);
        lotteryHeader.appendChild(chatIn);
      }
    }

    Chat.prototype = {
      init: function (shouldInitialize) {
        if(shouldInitialize){
          this.initChat();
        }
      },

      initChat: function () {
        const that = this;
        $.ftnAjax('/restapi/cms/chat', {
          method: 'GET',
          data: {
            'site': that.site,
            'url': window.location.pathname,
            'channel': CLIENT_PLATFORM
          },
          success: function(data) {
            try {
              chatConfig = JSON.parse(data);
              if(chatConfig.inboundChat || chatConfig.outboundChat) {
                adjustForLottery();
                loadChatWithContext(userStatus.hasJustLoggedIn ? botInitializedStatus.afterAuth : botInitializedStatus.afterRefresh);
                window.dispatchEvent(new CustomEvent("oldChatLoaded"));
              }
            } catch (error) {}
          },
          error: function (){
            console.log("Failed to load chat configuration");
          }
        });
      }
    };

    var crypto = window.crypto || window.msCrypto || null; // IE11 fix

    var Guid = Guid || (function() {

      var EMPTY = '00000000-0000-0000-0000-000000000000';

      var _padLeft = function(paddingString, width, replacementChar) {
        return paddingString.length >= width ? paddingString : _padLeft(replacementChar + paddingString, width, replacementChar || ' ');
      };

      var _s4 = function(number) {
        var hexadecimalResult = number.toString(16);
        return _padLeft(hexadecimalResult, 4, '0');
      };

      var _cryptoGuid = function() {
        var buffer = new window.Uint16Array(8);
        crypto.getRandomValues(buffer);
        return [_s4(buffer[0]) + _s4(buffer[1]), _s4(buffer[2]), _s4(buffer[3]), _s4(buffer[4]), _s4(buffer[5]) + _s4(buffer[6]) + _s4(buffer[7])].join('-');
      };

      var _guid = function() {
        var currentDateMilliseconds = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(currentChar) {
          var randomChar = (currentDateMilliseconds + Math.random() * 16) % 16 | 0;
          currentDateMilliseconds = Math.floor(currentDateMilliseconds / 16);
          return (currentChar === 'x' ? randomChar : (randomChar & 0x7 | 0x8)).toString(16);
        });
      };

      var create = function() {
        var hasCrypto = crypto != 'undefined' && crypto !== null,
          hasRandomValues = typeof(window.crypto.getRandomValues) != 'undefined';
        return (hasCrypto && hasRandomValues) ? _cryptoGuid() : _guid();
      };

      return {
        newGuid: create,
        empty: EMPTY
      };
    })();

    module.exports = function (casinoName, site, systemId, host, bot, shouldInitialize, hasJustLoggedIn, skin, loggedIn){
      userStatus.hasJustLoggedIn = hasJustLoggedIn;
      userStatus.loggedIn = loggedIn;
      country = skin;
      return new Chat(casinoName, site, systemId, host, bot, shouldInitialize);
    };
  });
})(modulr, $);
;
/* assets/src/scripts/fortuna/yellowAI/yellowAI-loader.js */
(function (modulr, $) {
    "use strict";

    modulr.define("yellowAI.loader", function(require, exports, module) {

      function l() {
        var d = document;
        var e = d.createElement("script");
        e.type = "text/javascript", e.async = !0, e.src = "https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js";
        var t = d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(e, t);
      }

      var loadYellowAI =  function () {
        var w = window,
          ic = w.YellowMessenger;
        if ("function" === typeof ic) {
          ic("reattach_activator");
          ic("update", window.ymConfig);
        } else {
          var i = function () {
            i.c(arguments);
          };

          i.q = [];
          i.c = function (e) {
            i.q.push(e);
          };
          w.YellowMessenger = i;
          l();
        }
      };

      module.exports= {
        load: function (config) {
            window.ymConfig = config;
            loadYellowAI();
        }
      };
    });


})(modulr, $);
